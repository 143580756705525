const stats = {
  openedPositions: "Opened Positions",
  pool: "Pool",
  currentPositionValue: "Current Position Value",
  earnedFee: "Earned Fee",
  feeApr: "Fee APR",
  leverage: "Leverage",
  startTime: "Start Time",
  closedPositions: "Closed Positions",
  positionId: "Position Id",
  initialInvestmentAmount: "Initial Investment Amount",
  endingAmount: "Ending Amount",
  type: "Type",
  positionValue: "Position Value",
  link: "Link",
  openPositionValue: "Open Position Value",
  stats: "Stats",
  tvl: "TVL",
  totalEarned: "Total Earned",
  totalPosition: "Total Position",
};

export default stats;
