import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";
import Colors from "../styles/Colors";

const AprWithHint: React.FC<{
  labelComponent: any;
  liquidityProvidingApr: number;
  pcsRewardApr: number | null;
  hintPlace?: "auto" | "top" | "right" | "left" | "bottom";
  type: "pool" | "factory";
}> = ({
  labelComponent,
  hintPlace,
  liquidityProvidingApr,
  pcsRewardApr,
  type,
}) => {
  const feeTypes: { [key: string]: string } = {
    pool: "Interest APR",
    factory: "LP Fee APR",
  };

  return pcsRewardApr ? (
    <OverlayTrigger
      placement={hintPlace || "bottom"}
      delay={{ show: 0, hide: 100 }}
      overlay={(props: any) => (
        <StyledTooltip multiline={"true"} {...props}>
          <Text>{`Combined APR: ${(
            liquidityProvidingApr + pcsRewardApr
          ).toFixed(2)}%`}</Text>
          <Text>{`• ${feeTypes[type]}: ${liquidityProvidingApr.toFixed(
            2
          )}%`}</Text>
          <Text>{`• CAKE Reward APR: ${pcsRewardApr.toFixed(2)}%`}</Text>
        </StyledTooltip>
      )}
    >
      {labelComponent}
    </OverlayTrigger>
  ) : (
    labelComponent
  );
};

export default AprWithHint;

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    font-size: 12px;
    text-align: left;
    background-color: ${Colors.backgroundGray1};
    border: 1px solid ${Colors.backgroundGray3};
  }
  .tooltip-arrow::before {
    border-bottom-color: ${Colors.backgroundGray1};
  }
  @media (max-width: 576px) {
    .tooltip-inner {
      font-size: 12px;
      max-width: 150px;
    }
  }
`;

const Text = styled.span`
  display: block;
  color: ${Colors.gray3};
`;
