import { isSupportedChain } from "../constant/chains";
import getProvider from "../getProvider";
import erc721Abi from "../../abi/erc721.json";
import { ethers } from "ethers";

interface NFTProps {
  id: number;
  name: string;
  imageUrl: string;
  permalink: string;
}

/**
 * Get info of ERC721 tokens
 * @param userAddress user address
 * @param contractAddress contract address
 * @param chainId chain id
 * @param provider provider
 * @returns ERC721 tokens info
 */
export const getERC721Token = async (
  userAddress: string,
  contractAddress: string,
  chainId: number,
  provider: any
) => {
  let nfts: NFTProps[] = [];

  if (isSupportedChain(chainId)) {
    try {
      const p = await getProvider(provider, chainId);
      const signer = p.getSigner(userAddress);
      const erc721 = new ethers.Contract(contractAddress, erc721Abi, signer);
      const balance = await erc721.balanceOf(userAddress);

      for (let i = 0; i < balance; i++) {
        const tokenId = await erc721.tokenOfOwnerByIndex(userAddress, i);

        const metadataUri = await erc721.tokenURI(tokenId);

        const metadata = await fetch(metadataUri);
        const metadataJson = await metadata.json();

        nfts.push({
          id: tokenId,
          name: metadataJson.name,
          imageUrl: metadataJson.image,
          permalink: metadataJson.external_link,
        });
      }
    } catch (e) {
      // console.log(e);
    }
  }
  return nfts;
};

/**
 * Get all ERC721 tokens info
 * @param userAddress user address
 * @param contractAddresses contract addresses
 * @param chainId chain id
 * @param provider provider
 * @returns all ERC721 tokens info
 */
export const getDiamondNFTs = async (
  userAddress: string,
  contractAddresses: string[],
  chainId: number,
  provider: any
) => {
  let nfts: NFTProps[] = [];

  await Promise.all(
    contractAddresses.map(async (contractAddress) => {
      const _nfts = await getERC721Token(
        userAddress,
        contractAddress,
        chainId,
        provider
      );
      nfts.push(..._nfts);
    })
  );

  return nfts;
};
