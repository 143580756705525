import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getUserNfts } from "../redux/accountAction";
import { useWeb3React } from "@web3-react/core";

const useUserNfts = () => {
  const { nfts, nftIsFetching } = useAppSelector((state) => state.account);

  const { account, chainId, provider } = useWeb3React();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!account || !chainId || !provider) return;

    dispatch(
      getUserNfts(
        account,
        [
          "0xa8224b1553fbC0860f496A4a023311C81E6451EF",
          "0x267448DAb24e49658517Dc4e0E6fc2E0E3A399Af",
          "0x90133e228B4b5CBCe833627E39F55EA3Fc89D3CA",
        ],
        chainId,
        provider
      )
    );
  }, [dispatch, account, chainId, provider]);

  return { nfts, isFetching: nftIsFetching };
};

export default useUserNfts;
