import { AppThunk } from "./store";
import {
  setUniswapPool24HVolume,
  setUniswapPool24HFee,
  setUniswapPoolTvl,
  setUserActivePositions,
  setUserClosedPositions,
  setAllClosedPositions,
  setAllActivePositions,
  clearUserPositions,
  setActivePositionIsFetched,
  setClosedPositionIsFetched,
  setUnibotTvl,
  setUnibotCumulativeFee,
  setUserActivePositionIsFetched,
  setUserClosedPositionIsFetched,
  setTopUsersByPnLIsFetching,
  setTopUsersByPnLIsFetched,
  setTopOpenedByEarnedFeeIsFetching,
  setTopOpenedByEarnedFeeIsFetched,
  setTopOpenedByFeeAprIsFetching,
  setTopOpenedByFeeAprIsFetched,
  setTopClosedByPnLIsFetching,
  setTopClosedByPnLIsFetched,
  setTopClosedByPercentageIsFetching,
  setTopClosedByPercentageIsFetched,
  setUserPositionEntryTick,
} from "./factorySlice";
import {
  getUserClosedFactoryPositions,
  getUniV3PoolData,
  getUserActiveFactoryPositionsWithAggregator,
} from "../utils/api/factoryV3Api";
import {
  getGinzaFactoryPositions,
  getGinzaBalancesSummary,
  getGinzaFactoryIds,
  getGinzaFactorySummary,
  getGinzaFactoryTopPositions,
  getGinzaFactoryTopUsers,
  getGinzaOpenedPositionEntryTicks,
} from "../utils/api/ginzaApi";
import { utils } from "ethers";
import {
  CHAIN_IDS_TO_NAMES,
  SupportedChainId,
  UnsupportedChainId,
} from "../utils/constant/chains";
import { getTruncNum } from "../utils/common";
import { getSlicedAddress } from "../utils/api/common";
import { ALL_PAIRS } from "../utils/constant/pairs";
import { RPC_PROVIDERS } from "../utils/constant/providers";
import { getTokenPriceFromBinance } from "../utils/api/common";
import { FEE_DECIMAL } from "../utils/constant/decimals";
import { UNISWAP_V3_APP_URL } from "../utils/constant/urls";
import { TimeRangeProps } from "../pages/LeaderboardPage";

export const setUniPoolData =
  (
    poolAddress: string,
    chainId: number,
  ): AppThunk =>
  async (dispatch) => {
    const data = await getUniV3PoolData(
      poolAddress,
      chainId,
    );

    dispatch(setUniswapPoolTvl({ poolAddress, tvl: +data.tvl }));
    dispatch(setUniswapPool24HVolume({
      poolAddress,
      volume: +data.volume24H,
    }))
    dispatch(setUniswapPool24HFee({
      poolAddress,
      fee: +data.fee24H,
    }))
  };

export const getUserActivePositions =
  (
    userAddress: string,
    factoryAddress: string,
    wantTokenDecimal: number,
    borrowTokenDecimal: number,
    wantTokenSymbol: string,
    wantTokenIsToken0: boolean,
    chainId: number,
    provider: any
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setUserActivePositionIsFetched(false));
    const positionInfos = await getUserActiveFactoryPositionsWithAggregator(
      userAddress,
      factoryAddress,
      wantTokenDecimal,
      borrowTokenDecimal,
      wantTokenSymbol,
      wantTokenIsToken0,
      chainId,
      provider
    );
    dispatch(
      setUserActivePositions({ factoryAddress, positionInfos, chainId })
    );
    dispatch(setUserActivePositionIsFetched(true));
  };

export const getUserClosedPositions =
  (
    accountAddress: string,
    factoryAddress: string,
    poolAddress: string,
    wantTokenIsToken0: boolean,
    wantTokenDecimal: number,
    borrowTokenDecimal: number,
    chainId: number,
    provider: any
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setUserClosedPositionIsFetched(false));
      const positions = await getUserClosedFactoryPositions(
        accountAddress,
        factoryAddress,
        poolAddress,
        wantTokenIsToken0,
        wantTokenDecimal,
        borrowTokenDecimal,
        chainId,
        provider
      );
      dispatch(setUserClosedPositions({ factoryAddress, positions, chainId }));
      dispatch(setUserClosedPositionIsFetched(true));
    } catch (error) {}
  };

export const clearUserRecords = (): AppThunk => async (dispatch) => {
  dispatch(clearUserPositions());
};

export const getAllClosePosition =
  (selectedChainId: number): AppThunk =>
  async (dispatch) => {
    dispatch(setClosedPositionIsFetched(false));
    try {
      const { records, totalRecord } = await getGinzaFactoryPositions(
        selectedChainId,
        "closed"
      );
      const recordsWithPairInfo = records.map(
        (record: { DiamondFactoryV3: { ContractAddr: string } }) => {
          const pairs = Object.values(ALL_PAIRS).flat();
          const pair = pairs.find(
            (item) =>
              item.factoryAddress.toUpperCase() ===
              record.DiamondFactoryV3.ContractAddr.toUpperCase()
          );
          if (!!pair) {
            return { ...record, pair };
          } else {
            return undefined;
          }
        }
      );
      const filteredRecordsWithPairInfo = recordsWithPairInfo.filter(
        (r: any) => r !== undefined
      );
      dispatch(
        setAllClosedPositions({
          chainId: selectedChainId,
          records: filteredRecordsWithPairInfo,
          totalRecord: totalRecord,
        })
      );
    } catch (error) {}
    dispatch(setClosedPositionIsFetched(true));
  };

export const getAllOpenPosition =
  (selectedChainId: number): AppThunk =>
  async (dispatch) => {
    dispatch(setActivePositionIsFetched(false));
    try {
      const { records, totalRecord } = await getGinzaFactoryPositions(
        selectedChainId,
        "open"
      );
      const recordsWithPairInfo = records.map(
        (record: { DiamondFactoryV3: { ContractAddr: string } }) => {
          const pairs = Object.values(ALL_PAIRS).flat();
          const pair = pairs.find(
            (item) =>
              item.factoryAddress.toUpperCase() ===
              record.DiamondFactoryV3.ContractAddr.toUpperCase()
          );
          if (!!pair) {
            return { ...record, pair };
          } else {
            return undefined;
          }
        }
      );
      const filteredRecordsWithPairInfo = recordsWithPairInfo.filter(
        (r: any) => r !== undefined
      );
      dispatch(
        setAllActivePositions({
          chainId: selectedChainId,
          records: filteredRecordsWithPairInfo,
          totalRecord: totalRecord,
        })
      );
    } catch (error) {}
    dispatch(setActivePositionIsFetched(true));
  };

export const getTvl =
  (chainId: number): AppThunk =>
  async (dispatch) => {
    let tvl = 0;
    try {
      const tokenBalances = await getGinzaBalancesSummary(chainId);
      await Promise.all(
        tokenBalances.map(async (balance) => {
          try {
            const tokenPrice = await getTokenPriceFromBinance(balance.symbol);
            const tokenAmount = +utils.formatUnits(
              balance.sum.toString(),
              balance.decimal
            );
            tvl += tokenAmount * tokenPrice;
          } catch (e) {
            // console.log(e);
          }
        })
      );
    } catch (error: any) {}
    dispatch(setUnibotTvl({ chainId: chainId, tvl: tvl }));
  };

export const getCumulativeFee =
  (chainId: SupportedChainId): AppThunk =>
  async (dispatch) => {
    let cumulativeFee = 0;
    try {
      const ids = await getGinzaFactoryIds(chainId);
      await Promise.all(
        await ids.map(async (id: string) => {
          const {
            token_info: tokenInfo,
            close_position_borrow_token_total_fee: closedPositionBorrowTokenFee,
            close_position_want_token_total_fee: closedPositionWantTokenFee,
            open_position_borrow_token_total_fee: activePositionBorrowTokenFee,
            open_position_want_token_total_fee: activePositionWantTokenFee,
          } = await getGinzaFactorySummary(chainId, id);

          // Get borrow token and want token prices
          const borrowTokenPrice = await getTokenPriceFromBinance(
            tokenInfo.borrow_token.symbol
          );
          const wantTokenPrice = await getTokenPriceFromBinance(
            tokenInfo.want_token.symbol
          );

          // Calculate borrow token fee
          try {
            const decimal = tokenInfo.borrow_token.decimal;
            const closedFee = utils.formatUnits(
              closedPositionBorrowTokenFee || 0,
              decimal
            );
            const activeFee = utils.formatUnits(
              activePositionBorrowTokenFee || 0,
              decimal
            );
            const borrowTokenFee = (+closedFee + +activeFee) * borrowTokenPrice;

            if (borrowTokenFee) {
              cumulativeFee += +borrowTokenFee;
            }
          } catch (e) {
            // console.log(e);
          }

          // Calculate want token fee
          try {
            const wantTokenFee = utils.formatUnits(
              (+closedPositionWantTokenFee ||
                0 + +activePositionWantTokenFee ||
                0) * wantTokenPrice,
              tokenInfo.want_token.decimal
            );

            if (wantTokenFee) {
              cumulativeFee += +wantTokenFee;
            }
          } catch (e) {}
        })
      );
    } catch (error: any) {
      // console.log(error);
    }
    dispatch(
      setUnibotCumulativeFee({
        chainId: chainId,
        cumulativeFee: cumulativeFee,
      })
    );
  };

export const getTopUsersByRealizedProfit =
  (tokenSymbol: string, chainId: number, timeRange: TimeRangeProps): AppThunk =>
  async (dispatch) => {
    let records = [];
    dispatch(setTopUsersByPnLIsFetching());
    try {
      const res = await getGinzaFactoryTopUsers(
        tokenSymbol,
        chainId,
        timeRange
      );
      records = await Promise.all(
        res.records.map(async (record: { [key: string]: string }) => {
          let name = "";

          try {
            const ensName = await RPC_PROVIDERS[
              UnsupportedChainId.MAINNET
            ].lookupAddress(record.UserAddr);
            if (ensName) {
              name = ensName;
            }
          } catch (e) {}

          return {
            leftValue: name || getSlicedAddress(record.UserAddr),
            rightValue: record.SumPnl,
          };
        })
      );
    } catch (error: any) {}
    dispatch(
      setTopUsersByPnLIsFetched({
        tokenSymbol,
        timeRange: `${timeRange.startTime}-${timeRange.endTime}`,
        records,
      })
    );
  };

export const getTopOpenedPositionsByEarnedFee =
  (tokenSymbol: string, chainId: number, timeRange: TimeRangeProps): AppThunk =>
  async (dispatch) => {
    let records = [];
    dispatch(setTopOpenedByEarnedFeeIsFetching());
    try {
      const res = await getGinzaFactoryTopPositions(
        tokenSymbol,
        chainId,
        "open",
        "earn_fee",
        timeRange
      );
      records = res.map(
        (record: { [key: string]: { [key: string]: string } }) => {
          return {
            ...record,
            leftValue: record.PositionId,
            rightValue: record.EarnFee,
            url: `${UNISWAP_V3_APP_URL}#/pool/${
              record.PositionId
            }?chain=${CHAIN_IDS_TO_NAMES[chainId].toLowerCase()}`,
          };
        }
      );
    } catch (error: any) {}
    dispatch(
      setTopOpenedByEarnedFeeIsFetched({
        tokenSymbol,
        timeRange: `${timeRange.startTime}-${timeRange.endTime}`,
        records,
      })
    );
  };

export const getTopOpenedPositionsByFeeApr =
  (tokenSymbol: string, chainId: number, timeRange: TimeRangeProps): AppThunk =>
  async (dispatch) => {
    let records = [];
    dispatch(setTopOpenedByFeeAprIsFetching());
    try {
      const res = await getGinzaFactoryTopPositions(
        tokenSymbol,
        chainId,
        "open",
        "fee_apr",
        timeRange
      );
      records = res.map(
        (record: { [key: string]: { [key: string]: string } }) => {
          return {
            ...record,
            leftValue: record.PositionId,
            rightValue: getTruncNum(
              +Math.trunc(+record.PositionInfo.MeasuredFeeAPR * 100) / 100,
              FEE_DECIMAL
            ),
          };
        }
      );
    } catch (error: any) {}
    dispatch(
      setTopOpenedByFeeAprIsFetched({
        tokenSymbol,
        timeRange: `${timeRange.startTime}-${timeRange.endTime}`,
        records,
      })
    );
  };

export const getTopClosedPositionsByPnl =
  (tokenSymbol: string, chainId: number, timeRange: TimeRangeProps): AppThunk =>
  async (dispatch) => {
    let records = [];
    dispatch(setTopClosedByPnLIsFetching());
    try {
      const res = await getGinzaFactoryTopPositions(
        tokenSymbol,
        chainId,
        "closed",
        "pnl",
        timeRange
      );
      records = res.map(
        (record: { [key: string]: { [key: string]: string } }) => {
          return {
            ...record,
            leftValue: record.PositionId,
            rightValue: +record.PositionInfo.PNL,
          };
        }
      );
    } catch (error: any) {}

    dispatch(
      setTopClosedByPnLIsFetched({
        tokenSymbol,
        timeRange: `${timeRange.startTime}-${timeRange.endTime}`,
        records: records,
      })
    );
  };

export const getTopClosedPositionsByPnlPercentage =
  (tokenSymbol: string, chainId: number, timeRange: TimeRangeProps): AppThunk =>
  async (dispatch) => {
    let records = [];
    dispatch(setTopClosedByPercentageIsFetching());
    try {
      const res = await getGinzaFactoryTopPositions(
        tokenSymbol,
        chainId,
        "closed",
        "pnl_percent",
        timeRange
      );
      records = res.map(
        (record: { [key: string]: { [key: string]: string } }) => {
          return {
            ...record,
            leftValue: record.PositionId,
            rightValue: getTruncNum(+record.PNLPercent, FEE_DECIMAL),
          };
        }
      );
    } catch (error: any) {}

    dispatch(
      setTopClosedByPercentageIsFetched({
        tokenSymbol,
        timeRange: `${timeRange.startTime}-${timeRange.endTime}`,
        records: records,
      })
    );
  };

export const getUserPositionEntryTicks =
  (
    userAddress: string,
    tokenSymbol: string,
    chainId: number,
    wantTokenIsToken0: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await getGinzaOpenedPositionEntryTicks(
        userAddress,
        tokenSymbol,
        chainId,
        wantTokenIsToken0
      );
      dispatch(setUserPositionEntryTick(res));
    } catch (error: any) {}
  };
