const common = {
  factory: "Factory",
  pool: "Pool",
  stats: "Stats",
  leaderboard: "Leaderboard",
  doc: "Docs",
  account: "Account",
  referral: "Referral",
  connectWallet: "Connect Wallet",
  wrongNetwork: "Wrong Network",
  noRecord: "No Record",
  amount: "Amount",
  max: "Max",
  bridgeOptions: "Bridge Options",
  officialBridge: "Official Bridge",
  lifi: "LI.FI",
  selectABlockchain: "Select A Blockchain",
  next: "Next",
  installMetaMask: "Install MataMask",
  metaMask: "MetaMask",
  injected: "Injected",
  walletConnect: "WalletConnect",
  referralCodeDetected: "Referral Code Detected",
  referralCode: "Referral code",
  detected: "detected.",
  setReferralCode: "Set Referral Code",
  connectWalletToContinue: "Connect Wallet to Continue",
  switchNetwork: "Switch Network",
  switchNetworkDesc1: "Currently this page only supported on",
  switchNetworkDesc2: ". Please switch your network to continue.",
  switchTo: "Switch To",
  connectYourWallet: "Connect your wallet",
  noMatchPage: "No Match Page",
  totalBorrowed: "Total Borrowed",
  recordDelayHint:
    "There may be a delay in the recording of transactions. The updated transaction record will be available 3-5 minutes after the transaction has been completed",
  depositAndWithdraw: "Deposit & Withdraw",
  deposit: "Deposit",
  withdraw: "Withdraw",
  action: "Action",
  time: "Time",
  TxID: "TxID",
  claimReward: "Claim Reward",
  stake: "Stake",
  unstake: "Unstake",
  stakeAndUnstake: "Stake & Unstake",
  vault: "Vault"
};

export default common;
