import t from "../content";

export enum SupportedChainId {
  GOERLI = 5,
  ARBITRUM_ONE = 42161,
  // POLYGON_MUMBAI = 80001,
  OPTIMISM_GOERLI = 420,
  // POLYGON = 137,
  BINANCE_SMART_CHAIN = 56,
  BINANCE_TESTNET = 97,
}

export enum UnsupportedChainId {
  MAINNET = 1,
  OPTIMISM = 10,
  AVALANCHE = 43114,
  FANTOM_OPERA = 250,
  CELO = 42220,
}

export enum TrustUnsupportedChainId {
  GOERLI = 5,
  // POLYGON_MUMBAI = 80001,
  OPTIMISM_GOERLI = 420,
  BINANCE_TESTNET = 97,
}

/**
 * Displayed name of the network
 */
export const CHAIN_IDS_TO_NAMES: { [key: number]: string } = {
  [SupportedChainId.GOERLI]: t.networks.goerli,
  [SupportedChainId.ARBITRUM_ONE]: t.networks.arbitrum,
  // [SupportedChainId.POLYGON_MUMBAI]: t.networks.polygonMumbai,
  [SupportedChainId.OPTIMISM_GOERLI]: t.networks.optimismGoerli,
  // [SupportedChainId.POLYGON]: t.networks.polygon,
  [SupportedChainId.BINANCE_SMART_CHAIN]: t.networks.binanceSmartChain,
  [SupportedChainId.BINANCE_TESTNET]: t.networks.binanceTestnet,

  [UnsupportedChainId.MAINNET]: t.networks.mainnet,
  [UnsupportedChainId.OPTIMISM]: t.networks.optimism,
  [UnsupportedChainId.AVALANCHE]: t.networks.avalanche,
  [UnsupportedChainId.FANTOM_OPERA]: t.networks.fantomOpera,
  [UnsupportedChainId.CELO]: t.networks.celo,
};

/**
 * URL id used to represent the network
 */
export const CHAIN_IDS_TO_URL_IDS: { [key: number]: string } = {
  [SupportedChainId.GOERLI]: "goerli",
  [SupportedChainId.ARBITRUM_ONE]: "arbitrum",
  // [SupportedChainId.POLYGON_MUMBAI]: "polygonMumbai",
  [SupportedChainId.OPTIMISM_GOERLI]: "optimismGoerli",
  // [SupportedChainId.POLYGON]: "polygon",
  [SupportedChainId.BINANCE_SMART_CHAIN]: "bnbChain",
  [SupportedChainId.BINANCE_TESTNET]: "bnbTestnet",
};

/**
 * Convert URL id back to the chain id of the network
 */
export const CHAIN_URL_IDS_TO_IDS: { [key: string]: number } = {
  goerli: SupportedChainId.GOERLI,
  arbitrum: SupportedChainId.ARBITRUM_ONE,
  // polygonMumbai: SupportedChainId.POLYGON_MUMBAI,
  optimismGoerli: SupportedChainId.OPTIMISM_GOERLI,
  // polygon: SupportedChainId.POLYGON,
  bnbChain: SupportedChainId.BINANCE_SMART_CHAIN,
  bnbTestnet: SupportedChainId.BINANCE_TESTNET,

  mainnet: UnsupportedChainId.MAINNET,
  optimism: UnsupportedChainId.OPTIMISM,
  avalanche: UnsupportedChainId.AVALANCHE,
  fantomOpera: UnsupportedChainId.FANTOM_OPERA,
  celo: UnsupportedChainId.CELO,
};

/**
 * URL id used to represent the network
 */
export const CHAIN_IDS_TO_UNISWAP_IDS: { [key: number]: string } = {
  [SupportedChainId.GOERLI]: "goerli",
  [SupportedChainId.ARBITRUM_ONE]: "arbitrum",
  // [SupportedChainId.POLYGON]: "polygon",
  [SupportedChainId.BINANCE_SMART_CHAIN]: "bsc",
  [SupportedChainId.BINANCE_TESTNET]: "bscTestnet",
};

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(
  SupportedChainId
).filter((id) => typeof id === "number") as SupportedChainId[];

/**
 * Array of all the unsupported chain IDs
 */
export const ALL_UNSUPPORTED_CHAIN_IDS: UnsupportedChainId[] = Object.values(
  UnsupportedChainId
).filter((id) => typeof id === "number") as UnsupportedChainId[];

/**
 * Array of the unsupported chain IDs for Trust Wallet
 */
export const TRUST_UNSUPPORTED_CHAIN_IDS: TrustUnsupportedChainId[] = Object.values(TrustUnsupportedChainId).filter((id) => typeof id === "number") as TrustUnsupportedChainId[];

export function isSupportedChain(
  chainId: number | null | undefined
): chainId is SupportedChainId {
  return !!chainId && !!SupportedChainId[chainId];
}

export const swapPlatforms: { [key: string]: string } = {
  [SupportedChainId.GOERLI]: "uniswap",
  [SupportedChainId.ARBITRUM_ONE]: "uniswap",
  // [SupportedChainId.POLYGON_MUMBAI]: "uniswap",
  [SupportedChainId.OPTIMISM_GOERLI]: "uniswap",
  // [SupportedChainId.POLYGON]: "uniswap",
  [SupportedChainId.BINANCE_SMART_CHAIN]: "pancakeSwap",
  [SupportedChainId.BINANCE_TESTNET]: "pancakeSwap",
};

export const isPanCakeSwap = (chainId: number) =>
  swapPlatforms[chainId] === "pancakeSwap";
