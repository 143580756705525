import { LiFiWidget as Widget, WidgetConfig } from "@lifi/widget";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Colors from "../styles/Colors";

import type { Route } from "@lifi/sdk";
import type { RouteExecutionUpdate } from "@lifi/widget";
import { useWidgetEvents, WidgetEvent } from "@lifi/widget";

const LiFiWidget: React.FC<{ setShowModal: (v: boolean) => void }> = (
  props
) => {
  const { setShowModal } = props;

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const location = useQuery();
  const tokenAddress = location.get("tokenAddress") || "";
  const chainId = location.get("chainId") || 1;

  const widgetConfig: WidgetConfig = useMemo(() => {
    return {
      // Enable Testnets
      // sdkConfig: {
      //   apiUrl: "https://staging.li.quest/v1/",
      // },
      integrator: "UniBot",
      variant: "default",
      containerStyle: {
        borderRadius: "8px",
        minWidth: "300px",
        marginRight: "24px",
        marginLeft: "24px",
      },
      theme: {
        palette: {
          primary: { main: Colors.lightKhaki },
          secondary: { main: Colors.ivory },
        },
        shape: {
          borderRadius: 8,
          borderRadiusSecondary: 8,
        },
      },
      appearance: "dark",
      disableAppearance: true,
      toChain: +chainId,
      toToken: tokenAddress,
      languages: { allow: ["en"] },
    };
  }, [tokenAddress, chainId]);

  const widgetEvents = useWidgetEvents();

  useEffect(() => {
    const onRouteExecutionStarted = (route: Route) => {
      // console.log('onRouteExecutionStarted fired.');
    };
    const onRouteExecutionUpdated = (update: RouteExecutionUpdate) => {
      // console.log('onRouteExecutionUpdated fired.');
    };
    const onRouteExecutionCompleted = (route: Route) => {
      setTimeout(() => setShowModal(true), 500);
    };
    const onRouteExecutionFailed = (update: RouteExecutionUpdate) => {
      // console.log('onRouteExecutionFailed fired.');
    };

    widgetEvents.on(WidgetEvent.RouteExecutionStarted, onRouteExecutionStarted);
    widgetEvents.on(WidgetEvent.RouteExecutionUpdated, onRouteExecutionUpdated);
    widgetEvents.on(
      WidgetEvent.RouteExecutionCompleted,
      onRouteExecutionCompleted
    );
    widgetEvents.on(WidgetEvent.RouteExecutionFailed, onRouteExecutionFailed);

    return () => widgetEvents.all.clear();
  }, [widgetEvents, setShowModal]);

  return <Widget config={widgetConfig} />;
};

export default LiFiWidget;
