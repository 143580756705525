import React, { Fragment, useMemo } from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { H4 } from "../../styles/Fonts";
import { getTruncNum, handleOpenV3PositionPage } from "../../utils/common";
import PairTokenIcon from "../../components/PairTokenIcon";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AMOUNT_DECIMAL } from "../../utils/constant/decimals";
import t from "../../utils/content";
import { isPanCakeSwap } from "../../utils/constant/chains";
import { useAppSelector } from "../../hooks";
import NumberWithHint from "../../components/NumberWithHint";

const ActivePosition: React.FC<{ pos: { [key: string]: any } }> = ({ pos }) => {
  const { OpenTime: openTime, pair } = pos;
  const {
    BorrowRatio: borrowRatio,
    WantTokenAmount: wantTokenAmount,
    BorrowTokenAmount: borrowTokenAmount,
    WantTokenFee: wantTokenFee,
    BorrowTokenFee: borrowTokenFee,
    MeasuredBorrowTokenFeeInWant: borrowTokenFeeInWant,
    MeasuredFeeAPR: feeApr,
    PositionValueMeasuredInWantToken: positionValue,
    RewardTokenValueInWant: rewardAmountInWant,
  } = pos.PositionInfo;

  const {
    wantToken: {
      tokenDecimal: wantTokenDecimal,
      tokenSymbol: wantTokenSymbol,
      tokenIcon: wantTokenIcon,
    },
    borrowToken: {
      tokenDecimal: borrowTokenDecimal,
      tokenSymbol: borrowTokenSymbol,
      tokenIcon: borrowTokenIcon,
    },
    label: pairLabel,
  } = pair;

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const earnedFeeInWant = useMemo(() => {
    let sum = 0;
    if (wantTokenDecimal) {
      const wantFee = Math.trunc(wantTokenFee) / 10 ** wantTokenDecimal;
      const borrowFee =
        Math.trunc(+borrowTokenFeeInWant) / 10 ** wantTokenDecimal;
      sum = +wantFee + +borrowFee;
    }
    return sum;
  }, [borrowTokenFeeInWant, wantTokenFee, wantTokenDecimal]);

  return (
    <PositionRow key={pos.positionId}>
      <PositionDetail>
        <PositionHeaderM>{t.stats.pool}</PositionHeaderM>
        <Row>
          {borrowTokenIcon && wantTokenIcon && (
            <PairTokenIcon
              borrowTokenIcon={borrowTokenIcon}
              wantTokenIcon={wantTokenIcon}
            />
          )}
          <PositionValue>{pairLabel}</PositionValue>
        </Row>
      </PositionDetail>
      <PositionDetail>
        <PositionHeaderM>{t.stats.positionValue}</PositionHeaderM>
        <ValuesContainer>
          {wantTokenDecimal && borrowTokenDecimal ? (
            <Fragment>
              <NumberWithHint
                labelComponent={
                  <PositionValue>
                    {`${getTruncNum(
                      Math.trunc(
                        +positionValue +
                          (rewardAmountInWant ? +rewardAmountInWant : 0)
                      ) /
                        10 ** wantTokenDecimal,
                      AMOUNT_DECIMAL
                    )} ${wantTokenSymbol}`}
                  </PositionValue>
                }
                hints={[
                  `• ${getTruncNum(
                    wantTokenAmount / 10 ** wantTokenDecimal,
                    6
                  )} ${wantTokenSymbol}`,
                  `• ${getTruncNum(
                    borrowTokenAmount / 10 ** borrowTokenDecimal,
                    6
                  )} ${borrowTokenSymbol}`,
                  !!rewardAmountInWant
                    ? `• ${getTruncNum(
                        rewardAmountInWant / 10 ** 18,
                        6
                      )} CAKE Reward`
                    : "",
                ]}
              />
            </Fragment>
          ) : (
            <PositionValueSmall>{"-"}</PositionValueSmall>
          )}
        </ValuesContainer>
      </PositionDetail>
      <PositionDetail>
        <PositionHeaderM>{t.stats.earnedFee}</PositionHeaderM>
        <ValuesContainer>
          {wantTokenDecimal && borrowTokenDecimal ? (
            <Fragment>
              <PositionValueSmall>
                {`${getTruncNum(
                  earnedFeeInWant,
                  AMOUNT_DECIMAL
                )} ${wantTokenSymbol}`}
              </PositionValueSmall>
              <PositionValueTiny>
                {`${getTruncNum(
                  wantTokenFee / 10 ** wantTokenDecimal,
                  6
                )} ${wantTokenSymbol}`}
              </PositionValueTiny>
              <PositionValueTiny>
                {`+ ${getTruncNum(
                  borrowTokenFee / 10 ** borrowTokenDecimal,
                  6
                )} ${borrowTokenSymbol}`}
              </PositionValueTiny>
            </Fragment>
          ) : (
            <PositionValueSmall>{"-"}</PositionValueSmall>
          )}
        </ValuesContainer>
      </PositionDetail>
      <PositionDetail>
        <PositionHeaderM>{t.stats.feeApr}</PositionHeaderM>
        <PositionValue>
          {feeApr ? `${getTruncNum(feeApr, 2)} %` : "-"}
        </PositionValue>
      </PositionDetail>
      <PositionDetail>
        <PositionHeaderM>{t.stats.leverage}</PositionHeaderM>
        <PositionValue>
          {borrowRatio ? `${+borrowRatio / 10000 + 1}X` : "-"}
        </PositionValue>
      </PositionDetail>
      <PositionDetail>
        <PositionHeaderM>{t.stats.startTime}</PositionHeaderM>
        <div>
          <TimeValue>
            {new Date(openTime * 1000).toLocaleDateString("en-US")}
          </TimeValue>
          <TimeValue>
            {new Date(openTime * 1000).toLocaleTimeString("en-US")}
          </TimeValue>
        </div>
      </PositionDetail>
      <PositionDetail>
        <PositionHeaderM>{t.stats.link}</PositionHeaderM>
        <LinkIcon
          icon={faArrowUpRightFromSquare}
          onClick={() =>
            handleOpenV3PositionPage(
              pos.PositionId,
              pos.deployedChainId,
              isPanCakeSwap(selectedChainId)
            )
          }
        />
      </PositionDetail>
    </PositionRow>
  );
};

export default ActivePosition;

const PositionRow = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 0.8fr 0.6fr 0.6fr 60px;
  align-items: center;
  background-color: ${Colors.backgroundGray4};
  border-radius: 8px;
  padding: 20px;
  margin-top: 12px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  div:not(:first-child) {
    justify-content: flex-end;
    text-align: right;
    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }
  h4:not(:first-child) {
    text-align: right;
  }
`;

const PositionHeader = styled(H4)`
  color: ${Colors.gray4};
  font-size: 14px;
  line-height: 22px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const PositionValue = styled(H4)`
  color: ${Colors.gray1};
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const PositionValueSmall = styled(H4)`
  color: ${Colors.gray1};
  font-size: 14px;
  line-height: 22px;
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const PositionDetail = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
    padding: 4px 0;
  }
`;

const PositionHeaderM = styled(PositionHeader)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const ValuesContainer = styled.div`
  @media (max-width: 768px) {
    text-align: right;
  }
`;

const TimeValue = styled(H4)`
  color: ${Colors.gray1};
  font-size: 14px;
  line-height: 22px;
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const LinkIcon = styled(FontAwesomeIcon)`
  color: ${Colors.gray1};
  font-size: 14px;
  padding: 4px;
  :hover {
    cursor: pointer;
  }
  :active {
    transform: scale(0.95);
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const PositionValueTiny = styled(H4)`
  color: ${Colors.gray4};
  font-size: 12px;
  line-height: 16px;
`;
