/**
 * Use the following formula to calculate the liquidation price
 * @param wantTokenAmount the amount of wantToken when opening a position
 * @param hedgeRatio the hedge ratio, e.g. 1.5x => 0.5, 2x => 1
 * @param debtValue the amount of debt in wantToken
 * @param entryPrice the price of borrowToken when opening a position
 * @param lowerTickPrice the lower tick price of the position
 * @param upperTickPrice the upper tick price of the position
 * @param feeIncome the fee income of this position in wantToken
 * @param borrowTokenPrice the current price of borrowToken
 * @param extraCollateral the extra collateral in wantToken
 * @returns the liquidation price
 */
export const getLiquidationPrice = (
  wantTokenAmount: number,
  hedgeRatio: number,
  debtValue: number,
  entryPrice: number,
  lowerTickPrice: number,
  upperTickPrice: number,
  feeIncome: number,
  borrowTokenPrice: number,
  extraCollateral: number
) => {
  const holdAAmount = (wantTokenAmount * (1 + hedgeRatio)) / 2 / entryPrice;

  const Lx =
    (holdAAmount * (entryPrice ** 0.5 * upperTickPrice ** 0.5)) /
    (upperTickPrice ** 0.5 - entryPrice ** 0.5);

  const liquidationPrice =
    (Lx * (upperTickPrice ** 0.5 - lowerTickPrice ** 0.5) +
      feeIncome +
      extraCollateral) /
    (1.25 * (debtValue / borrowTokenPrice));

  return liquidationPrice;
};
