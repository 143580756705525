import { useWeb3React } from "@web3-react/core";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useEffect, useState } from "react";
import { getLendingPoolRecords } from "../redux/accountAction";
import { RecordProps } from "../redux/accountSlice";

const useUserLendingPoolRecord = (lendingPoolAddress: string) => {
  const lendingPoolRecords = useAppSelector(
    (state) => state.account.lendingPoolRecords
  );

  const [records, setRecords] = useState<RecordProps[] | null>(null);

  const { account, chainId } = useWeb3React();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!account || !chainId || !lendingPoolAddress) return;
    dispatch(getLendingPoolRecords(account, lendingPoolAddress, chainId));
  }, [dispatch, account, chainId, lendingPoolAddress]);

  useEffect(() => {
    if (!chainId || !lendingPoolAddress) return;
    if (
      !lendingPoolRecords[chainId] ||
      !lendingPoolRecords[chainId][lendingPoolAddress]
    )
      return;

    setRecords(lendingPoolRecords[chainId][lendingPoolAddress]);
  }, [lendingPoolRecords, chainId, lendingPoolAddress]);

  return records;
};

export default useUserLendingPoolRecord;
