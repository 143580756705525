import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import Button from "../../../components/Button";
import { H4 } from "../../../styles/Fonts";
import CustomModal from "../../../components/Modal";
import { PoolProps } from "../../../utils/constant/pools";
import { useWeb3React } from "@web3-react/core";
import { stakeTokenIntoPCS } from "../../../utils/api/lendingPoolApi";
import AmountInput from "../../../components/AmountInput";
import t from "../../../utils/content";
import { showErrorAlert } from "../../../utils/showAlert";
import useTrackEvent from "../../../hooks/useTrackEvent";
import useTokenApproval from "../../../hooks/useTokenApproval";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StakeModal: React.FC<{
  isShowModal: boolean;
  onHideModal: () => void;
  pool: PoolProps;
  updatePoolState: () => void;
  updateUserSupply: () => void;
  setIsStaking: (v: boolean) => void;
  isStaking: boolean;
  shares: string;
  lpTokenSymbol: string;
}> = (props) => {
  const {
    isShowModal,
    onHideModal,
    pool,
    updatePoolState,
    updateUserSupply,
    setIsStaking,
    isStaking,
    shares,
    lpTokenSymbol,
  } = props;
  const {
    lendingPoolAddress: poolAddress,
    tokenDecimal,
    tokenSymbol,
    stakingAddress,
  } = pool;

  const { account, chainId, provider } = useWeb3React();
  const trackEvent = useTrackEvent();

  const [stakeAmount, setStakeAmount] = useState<string>("0");

  const {
    tokenAmountIsApproved,
    checkTokenAllowance,
    isGranting,
    isGranted,
    grantTokenAllowance,
    initTokenApprovalStates,
  } = useTokenApproval({
    tokenAmount: stakeAmount,
    tokenAddress: poolAddress,
    tokenDecimal,
    contractAddress: stakingAddress || "",
  });

  const closeModal = () => {
    onHideModal();
    setStakeAmount("0");
    initTokenApprovalStates();
  };

  const handleStake = async (address: string, amount: string) => {
    if (!chainId) {
      return;
    }

    if (!isNaN(parseFloat(amount)) && parseFloat(amount) <= 0) {
      showErrorAlert(t.error.inputStakeAmount);
      return;
    }

    closeModal();
    setIsStaking(true);

    trackEvent(`${t.amp.clickStakeBtn} | ${t.amp.poolPage}`, {
      amount: amount,
      poolAddress: poolAddress,
      token: tokenSymbol,
    });

    try {
      await stakeTokenIntoPCS(
        address,
        stakingAddress || "",
        tokenDecimal,
        amount,
        provider,
        (txHash: string) => {
          trackEvent(`${t.amp.stakeToken} | ${t.amp.poolPage}`, {
            txHash: txHash,
            amount: amount,
            poolAddress: poolAddress,
            token: tokenSymbol,
          });
        }
      );
      updatePoolState();
      updateUserSupply();
    } catch (e) {
      // console.log(e);
    }

    setIsStaking(false);
    setStakeAmount("0");
  };

  useEffect(() => {
    if (+stakeAmount > 0) {
      checkTokenAllowance();
    }
  }, [stakeAmount, checkTokenAllowance]);

  return (
    <CustomModal show={isShowModal} onHide={closeModal} title={t.pool.stake}>
      <AmountInput
        setAmount={setStakeAmount}
        tokenSymbol={`${lpTokenSymbol}`}
        tokenDecimal={tokenDecimal}
        showMaxBtn={true}
        maxAmount={shares}
      />
      <InfoContainer>
        <BalanceRow>
          <Label>{t.pool.unstakedAsset}</Label>
          <Value>
            {shares}
            {` ${lpTokenSymbol}`}
          </Value>
        </BalanceRow>
      </InfoContainer>
      <BtnContainer>
        {account && (
          <>
            {!!stakeAmount && !tokenAmountIsApproved && !isGranted && (
              <Button
                btnType="primary"
                btnText={`${t.pool.approveUseOf} dp${tokenSymbol}v3`}
                onClick={!isGranting ? grantTokenAllowance : () => {}}
                disabled={!stakeAmount}
                isLoading={isGranting}
              />
            )}
            {isGranted && (
              <Reminder>
                <FontAwesomeIcon icon={faCheckCircle} color={Colors.green} />
                <ReminderText>{t.pool.canStake}</ReminderText>
              </Reminder>
            )}
          </>
        )}
        <Button
          btnType="primary"
          btnText={t.pool.stake}
          onClick={() => (account ? handleStake(account, stakeAmount) : null)}
          disabled={
            !stakeAmount || (!!account && !tokenAmountIsApproved) || isStaking
          }
          isLoading={isStaking}
        />
      </BtnContainer>
    </CustomModal>
  );
};

export default StakeModal;

const InfoContainer = styled.div`
  margin-top: 20px;
`;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const Value = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
  color: ${Colors.ivory};
  text-align: right;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12px;
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 12px;
  button {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;

const Reminder = styled.div`
  width: 100%;
  min-height: 32px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.ivory};
  background-color: ${Colors.backgroundGray4};
  border: 1px solid ${Colors.gray5};
  padding: 4px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (max-width: 768px) {
    min-height: 28px;
    min-width: 80px;
  }
`;

const ReminderText = styled(H4)`
  font-size: 16px;
  line-height: 24px;
  font-family: Fira Sans, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  /* font-weight: 600; */
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
  }
`;
