import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import Button from "../../../components/Button";
import { H4 } from "../../../styles/Fonts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../../components/Modal";
import { PoolProps } from "../../../utils/constant/pools";
import { useWeb3React } from "@web3-react/core";
import { depositTokenIntoPool } from "../../../utils/api/lendingPoolApi";
import AmountInput from "../../../components/AmountInput";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import useTokenApproval from "../../../hooks/useTokenApproval";
import t from "../../../utils/content";
import { CHAIN_IDS_TO_NAMES } from "../../../utils/constant/chains";
import { showErrorAlert } from "../../../utils/showAlert";
import useTrackEvent from "../../../hooks/useTrackEvent";

const DepositModal: React.FC<{
  isShowModal: boolean;
  onHideModal: () => void;
  pool: PoolProps;
  updatePoolState: () => void;
  updateUserSupply: () => void;
  setIsDepositing: (v: boolean) => void;
  tokenBalance: string;
  updateTokenBalance: () => void;
  showBridgeModal: () => void;
}> = (props) => {
  const {
    isShowModal,
    onHideModal,
    pool,
    updatePoolState,
    updateUserSupply,
    setIsDepositing,
    tokenBalance,
    updateTokenBalance,
    showBridgeModal,
  } = props;
  const {
    lendingPoolAddress: poolAddress,
    tokenAddress,
    tokenDecimal,
    tokenSymbol,
    deployedChainId,
  } = pool;

  const { account, chainId, provider } = useWeb3React();
  const trackEvent = useTrackEvent();

  const [ethAmount, setEthAmount] = useState<string>("0");

  const {
    tokenAmountIsApproved,
    checkTokenAllowance,
    isGranting,
    isGranted,
    grantTokenAllowance,
    initTokenApprovalStates,
  } = useTokenApproval({
    tokenAmount: ethAmount,
    tokenAddress,
    tokenDecimal,
    contractAddress: poolAddress,
  });

  const closeModal = () => {
    onHideModal();
    setEthAmount("0");
    initTokenApprovalStates();
  };

  const handleDeposit = async (address: string, amount: string) => {
    if (!chainId) {
      return;
    }

    if (!isNaN(parseFloat(amount)) && parseFloat(amount) <= 0) {
      showErrorAlert(t.error.inputDepositAmount);
      return;
    }

    closeModal();
    setIsDepositing(true);

    trackEvent(`${t.amp.clickDepositBtn} | ${t.amp.poolPage}`, {
      amount: amount,
      poolAddress: poolAddress,
      token: tokenSymbol,
    });

    try {
      await depositTokenIntoPool(
        address,
        poolAddress,
        tokenAddress,
        tokenDecimal,
        tokenSymbol,
        amount,
        chainId,
        provider,
        (txHash: string) => {
          trackEvent(`${t.amp.depositToken} | ${t.amp.poolPage}`, {
            txHash: txHash,
            amount: amount,
            poolAddress: poolAddress,
            token: tokenSymbol,
          });
        }
      );
      updateTokenBalance();
      updatePoolState();
      updateUserSupply();
    } catch (e) {
      // console.log(e);
    }

    setIsDepositing(false);
    setEthAmount("0");
  };

  useEffect(() => {
    if (+ethAmount > 0) {
      checkTokenAllowance();
    }
  }, [ethAmount, checkTokenAllowance]);

  return (
    <CustomModal show={isShowModal} onHide={closeModal} title={t.pool.deposit}>
      <AmountInput
        setAmount={setEthAmount}
        tokenSymbol={tokenSymbol}
        tokenDecimal={tokenDecimal}
        showMaxBtn={true}
        maxAmount={tokenBalance}
      />
      <InfoContainer>
        <BalanceRow>
          <Label>{t.pool.walletBalance}</Label>
          <Value>
            {tokenBalance}
            {` ${tokenSymbol}`}
          </Value>
        </BalanceRow>
      </InfoContainer>
      <BtnContainer>
        {account && (
          <Fragment>
            {!!ethAmount && !tokenAmountIsApproved && !isGranted && (
              <Button
                btnType="primary"
                btnText={`${t.pool.approveUseOf} ${tokenSymbol}`}
                onClick={!isGranting ? grantTokenAllowance : () => {}}
                disabled={!ethAmount}
                isLoading={isGranting}
              />
            )}
            {isGranted && (
              <Reminder>
                <FontAwesomeIcon icon={faCheckCircle} color={Colors.green} />
                <ReminderText>{t.pool.canDeposit}</ReminderText>
              </Reminder>
            )}
          </Fragment>
        )}
        <Button
          btnType="primary"
          btnText={t.pool.deposit}
          onClick={() => (account ? handleDeposit(account, ethAmount) : null)}
          disabled={!ethAmount || (!!account && !tokenAmountIsApproved)}
          isLoading={false}
        />
      </BtnContainer>
      {account && (
        <BridgeBtn onClick={showBridgeModal}>
          {`${t.pool.bridgeYourAsset} ${CHAIN_IDS_TO_NAMES[deployedChainId]}`}
          <LinkIcon icon={faArrowUpRightFromSquare} />
        </BridgeBtn>
      )}
    </CustomModal>
  );
};

export default DepositModal;

const InfoContainer = styled.div`
  margin-top: 20px;
`;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const Value = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
  color: ${Colors.ivory};
  text-align: right;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12px;
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 12px;
  button {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;

const BridgeBtn = styled.button`
  margin-top: 20px;
  width: 100%;
  padding: 4px 12px;
  border: 1px solid ${Colors.ivory};
  border-radius: 100px;
  font-size: 13px;
  line-height: 22px;
  color: ${Colors.ivory};
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  :hover {
    border: 1px solid ${Colors.lightKhaki};
    color: ${Colors.lightKhaki};
  }
`;

const LinkIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
`;

const Reminder = styled.div`
  width: 100%;
  min-height: 32px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.ivory};
  background-color: ${Colors.backgroundGray4};
  border: 1px solid ${Colors.gray5};
  padding: 4px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (max-width: 768px) {
    min-height: 28px;
    min-width: 80px;
  }
`;

const ReminderText = styled(H4)`
  font-size: 16px;
  line-height: 24px;
  font-family: Fira Sans, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  /* font-weight: 600; */
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
  }
`;
