import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { H2, H4, SmallRegular } from "../../../../styles/Fonts";
import { PoolProps } from "../../../../utils/constant/pools";
import useUserPoolSupply from "../../../../hooks/useUserPoolSupply";
import useUserLendingPoolRecord from "../../../../hooks/useUserLendingPoolRecord";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import TransactionRecord from "../../../../components/TransactionRecord";
import t from "../../../../utils/content";
import useUserStakingRecord from "../../../../hooks/useUserStakingRecord";
import LabelWithHint from "../../../../components/LabelWithHint";

enum RecordType {
  Main = 0,
  Staking = 1,
}

const PoolBalanceItem: React.FC<{ pool: PoolProps }> = ({ pool }) => {
  const { tokenSymbol, lendingPoolAddress, tokenDecimal, stakingAddress } =
    pool;

  const {
    depositedAmount,
    withdrawnAmount,
    shares,
    estimatedExchangeRate,
    earned,
    balance,
    stakedAmountInPoolToken,
  } = useUserPoolSupply(lendingPoolAddress, tokenDecimal, stakingAddress);

  const records = useUserLendingPoolRecord(lendingPoolAddress);
  const stakingRecords = useUserStakingRecord(stakingAddress);

  const [recordIsShow, setRecordIsShow] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<RecordType>(
    RecordType.Main
  );

  const roi = useMemo(
    () =>
      depositedAmount !== null &&
      withdrawnAmount !== null &&
      stakedAmountInPoolToken !== null &&
      shares !== null &&
      +depositedAmount > 0
        ? (
            ((+shares * estimatedExchangeRate +
              +stakedAmountInPoolToken -
              (depositedAmount - withdrawnAmount)) /
              depositedAmount) *
            100
          ).toFixed(6)
        : 0,
    [
      shares,
      estimatedExchangeRate,
      depositedAmount,
      withdrawnAmount,
      stakedAmountInPoolToken,
    ]
  );

  const stakingAddressExist = stakingAddress !== undefined;

  return (
    <Container>
      <TopContainer>
        <TokenRow>
          <TokenIcon src={pool.tokenIcon} />
          <TokenName>{pool.tokenSymbol}</TokenName>
        </TokenRow>
        <ItemRow>
          <Label>{t.pool.roi}</Label>
          <Value>{roi} %</Value>
        </ItemRow>
        <ItemRow>
          <Label>{t.pool.balance}</Label>
          <Value>
            {balance !== null && stakedAmountInPoolToken !== null
              ? `${+balance.replace(/,/g, '') + +stakedAmountInPoolToken} ${tokenSymbol}`
              : "-"}
          </Value>
        </ItemRow>
        <ItemRow>
          <Label>{t.pool.earned}</Label>
          <Value>
            {earned !== null
              ? `${earned} ${tokenSymbol}`
              : "-"}
          </Value>
        </ItemRow>
        <ItemRow>
          <Label></Label>
          <ExpandBtn onClick={() => setRecordIsShow((pre) => !pre)}>
            <ArrowIcon icon={faAngleDown} isShow={recordIsShow} />
          </ExpandBtn>
        </ItemRow>
      </TopContainer>
      <BottomContainer isShow={recordIsShow}>
        {stakingAddressExist ? (
          <SwitcherContainer>
            <Switcher
              onClick={() => setSelectedRecord(RecordType.Main)}
              isSelected={selectedRecord === RecordType.Main}
            >
              <LabelWithHint
                labelComponent={t.common.depositAndWithdraw}
                hintText={t.common.recordDelayHint}
              />
            </Switcher>

            <Switcher
              onClick={() => setSelectedRecord(RecordType.Staking)}
              isSelected={selectedRecord === RecordType.Staking}
            >
              <LabelWithHint
                labelComponent={t.common.stakeAndUnstake}
                hintText={t.common.recordDelayHint}
              />
            </Switcher>
          </SwitcherContainer>
        ) : (
          <TitleContainer>
            <LabelWithHint
              labelComponent={
                <TitleText>{t.common.depositAndWithdraw}</TitleText>
              }
              hintText={t.common.recordDelayHint}
            />
          </TitleContainer>
        )}
        <TransactionRecord
          records={
            selectedRecord === RecordType.Main ? records : stakingRecords
          }
          tokenDecimal={tokenDecimal}
          tokenSymbol={tokenSymbol}
        />
      </BottomContainer>
    </Container>
  );
};

export default PoolBalanceItem;

const Container = styled.div`
  background-color: ${Colors.backgroundGray1};
  border-radius: 8px;
`;

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 100px 0.5fr 1fr 1fr 40px;
  align-items: center;
`;

const TopContainer = styled(RowContainer)`
  padding: 24px;
  @media (max-width: 768px) {
    padding: 20px;
    grid-template-columns: none;
  }
`;

const BottomContainer = styled.div<{ isShow: boolean }>`
  max-height: ${(props) => (props.isShow ? "1000px" : "0px")};
  transition: all 200ms ease-in-out;
  overflow-y: hidden;
`;

const TokenRow = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
`;

const TokenIcon = styled.img`
  height: 28px;
  margin-right: 12px;
  @media (max-width: 768px) {
    height: 20px;
    margin-right: 8px;
  }
`;

const TokenName = styled(H2)`
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.ivory};
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const ItemRow = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 4px 0;
  }
`;

const Value = styled(SmallRegular)`
  color: ${Colors.ivory};
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 576px) {
    text-align: right;
  }
`;

const Label = styled(Value)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const ExpandBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  transition: transform 0.1s ease-in-out;
  color: ${Colors.ivory};
  :hover {
    transform: scale(1.1);
    color: ${Colors.lightKhaki};
  }
`;

const ArrowIcon = styled(FontAwesomeIcon)<{ isShow: boolean }>`
  font-size: 16px;
  transform: ${(props) => (props.isShow ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.1s ease-in-out;
`;

const SwitcherContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px 0 24px;
  border-top: 6px solid ${Colors.backgroundGray3};
  border-bottom: 1px solid ${Colors.gray5};
  @media (max-width: 576px) {
    padding: 4px 0 0 0;
  }
`;

const Switcher = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  color: ${Colors.ivory};
  font-size: 14px;
  line-height: 22px;
  margin-right: 8px;
  border-bottom: ${({ isSelected }) =>
    isSelected ? `3px solid ${Colors.ivory}` : `3px solid transparent`};
  color: ${({ isSelected }) => (isSelected ? Colors.ivory : Colors.gray4)};
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 20px;
  }
  @media (max-width: 576px) {
    flex: 1;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 24px 12px 24px;
  border-top: 6px solid ${Colors.backgroundGray3};
  border-bottom: 1px solid ${Colors.gray5};
`;

const TitleText = styled(H4)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.ivory};
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.ivory};
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
