import { SupportedChainId, UnsupportedChainId } from "./chains";

/**
 * Known JSON-RPC endpoints.
 * These are the URLs used by the interface when there is not another available source of chain data.
 */
export const RPC_URLS: {
  [key: number]: string;
} = {
  [UnsupportedChainId.MAINNET]: process.env.REACT_APP_ALCHEMY_MAINNET_URL || "",

  [SupportedChainId.GOERLI]: process.env.REACT_APP_DEV_JSONRPC_URL || "",
  [SupportedChainId.OPTIMISM_GOERLI]: `https://goerli.optimism.io`,
  [SupportedChainId.ARBITRUM_ONE]:
    process.env.REACT_APP_ALCHEMY_ARBITRUM_URL || "",
  [SupportedChainId.POLYGON]: "https://polygon-rpc.com/",
  [SupportedChainId.POLYGON_MUMBAI]: "https://rpc-mumbai.maticvigil.com/",
  [SupportedChainId.BINANCE_SMART_CHAIN]: "https://bsc-dataseed.binance.org/",
  [SupportedChainId.BINANCE_TESTNET]:
    "https://data-seed-prebsc-1-s1.binance.org:8545",
};
