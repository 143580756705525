import React, { Fragment, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { H2, H4 } from "../../../styles/Fonts";
import { useAppSelector } from "../../../hooks";
import ActivePosition from "./ActivePosition";
import ClosedPosition from "./ClosedPosition";
import LabelWithHint from "../../../components/LabelWithHint";
import Pagination from "./Pagination";
import { motion } from "framer-motion";
import { PairProps } from "../../../utils/constant/pairs";
import { useWeb3React } from "@web3-react/core";
import {
  UserActivePositionProps,
  UserClosedPositionProps,
} from "../../../redux/factorySlice";
import { StateProps } from "../../../hooks/useUniswapPoolState";
import useIsUnderCorrectNetwork from "../../../hooks/useIsUnderCorrectNetwork";
import { ClipLoader } from "react-spinners";
import t from "../../../utils/content";

const ITEM_PER_PAGE = 4;

const currHeads = [
  { label: t.factory.pair, hint: "" },
  {
    label: t.factory.positionValue,
    hint: t.factory.positionValueDesc,
  },
  { label: t.factory.feeApr, hint: t.factory.feeAprDesc },
];
const hisHeads = [
  { label: t.factory.pair, hint: "" },
  { label: t.factory.event, hint: "" },
  {
    label: t.factory.openAmount,
    hint: t.factory.openAmountDesc,
  },
  { label: t.factory.closeAmount, hint: t.factory.closeAmountDesc },
];

type Props = {
  pair: PairProps;
  vaultBalance: string;
  updateVaultBalance: () => void;
  uniPoolState: StateProps;
  slippage: number;
  updateUserActivePositions: () => void;
  updateUserClosedPositions: () => void;
};

const YourBot: React.FC<Props> = (props) => {
  const {
    pair,
    vaultBalance,
    updateVaultBalance,
    uniPoolState,
    slippage,
    updateUserActivePositions,
    updateUserClosedPositions,
  } = props;

  const { wantToken, factoryAddress, deployedChainId } = pair;

  const {
    userActivePositions: activePositions,
    userClosedPositions: closedPositions,
    userActivePositionIsFetched: activePIsFetched,
    userClosedPositionIsFetched: closedPIsFetched,
  } = useAppSelector((state) => state.factory);

  const { account } = useWeb3React();

  const isUnderCorrectNetwork = useIsUnderCorrectNetwork();

  const allActivePositions = useMemo(
    () => activePositions?.[deployedChainId]?.[factoryAddress] || [],
    [activePositions, deployedChainId, factoryAddress]
  );
  const allClosedPositions = useMemo(
    () => closedPositions?.[deployedChainId]?.[factoryAddress] || [],
    [closedPositions, deployedChainId, factoryAddress]
  );

  const [isShowActive, setIsShowActive] = useState<boolean>(true);

  const [closedPage, setClosedPage] = useState<number>(1);
  const [maxClosedPage, setMaxClosedPage] = useState<number>(0);
  const [closedPos, setClosedPos] = useState<UserClosedPositionProps[]>([]);

  const [activePage, setActivePage] = useState<number>(1);
  const [maxActivePage, setMaxActivePage] = useState<number>(0);
  const [activePos, setActivePos] = useState<UserActivePositionProps[]>([]);

  const canShowPosition = !!account && isUnderCorrectNetwork;

  useEffect(() => {
    setMaxClosedPage(Math.ceil(allClosedPositions?.length / ITEM_PER_PAGE));
  }, [allClosedPositions]);

  useEffect(() => {
    if (allClosedPositions?.length > 0) {
      setClosedPos(
        allClosedPositions?.slice(
          (closedPage - 1) * ITEM_PER_PAGE,
          closedPage * ITEM_PER_PAGE
        )
      );
    }
  }, [closedPage, allClosedPositions]);

  useEffect(() => {
    const total = allActivePositions?.length;
    setMaxActivePage(Math.ceil(total / ITEM_PER_PAGE));
  }, [allActivePositions]);

  useEffect(() => {
    if (allActivePositions?.length > 0) {
      setActivePos(
        allActivePositions.slice(
          (activePage - 1) * ITEM_PER_PAGE,
          activePage * ITEM_PER_PAGE
        )
      );
    }
  }, [activePage, allActivePositions]);

  const _renderNoRecord = () => {
    return (
      <NoRecordContainer>
        <NoRecord>{t.common.noRecord}</NoRecord>
      </NoRecordContainer>
    );
  };

  const _renderLoader = () => {
    return (
      <NoRecordContainer>
        <ClipLoader color={Colors.ivory} loading={!closedPIsFetched} />
      </NoRecordContainer>
    );
  };

  const _renderActivePositions = () => {
    return (
      <Fragment>
        <RowContainer>
          {currHeads.map((head) => (
            <LabelContainer key={head.label}>
              <LabelWithHint
                labelComponent={<HeadText>{head.label}</HeadText>}
                hintText={head.hint}
              />
            </LabelContainer>
          ))}
          <BtnContainer></BtnContainer>
        </RowContainer>
        {canShowPosition && allActivePositions?.length > 0 && (
          <Fragment>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={activePage}
            >
              <PositionsContainer>
                {activePos.map((position) => (
                  <ActivePosition
                    key={position.positionId}
                    position={position}
                    pair={pair}
                    currency={wantToken.tokenSymbol}
                    decimal={wantToken.tokenDecimal}
                    updateUserActivePositions={updateUserActivePositions}
                    updateUserClosedPositions={updateUserClosedPositions}
                    updateVaultBalance={updateVaultBalance}
                    vaultBalance={vaultBalance}
                    uniPoolState={uniPoolState}
                    slippage={slippage}
                  />
                ))}
              </PositionsContainer>
            </motion.div>
            <Pagination
              currPage={activePage}
              maxPage={maxActivePage}
              setPage={setActivePage}
            />
          </Fragment>
        )}
        {canShowPosition &&
          allActivePositions?.length === 0 &&
          !activePIsFetched &&
          _renderLoader()}
        {canShowPosition &&
          allActivePositions?.length === 0 &&
          activePIsFetched &&
          _renderNoRecord()}
        {!canShowPosition && _renderNoRecord()}
      </Fragment>
    );
  };

  const _renderClosedPositions = () => {
    return (
      <Fragment>
        <RowContainer>
          {hisHeads.map((head) => (
            <LabelContainer key={head.label}>
              <LabelWithHint
                labelComponent={<HeadText>{head.label}</HeadText>}
                hintText={head.hint}
              />
            </LabelContainer>
          ))}
          <BtnContainerH />
        </RowContainer>

        {canShowPosition && allClosedPositions?.length > 0 && (
          <Fragment>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={closedPage}
            >
              <PositionsContainer>
                {closedPos.map((position) => (
                  <ClosedPosition
                    key={position.positionId}
                    position={position}
                    pair={pair}
                  />
                ))}
              </PositionsContainer>
            </motion.div>
            <Pagination
              currPage={closedPage}
              maxPage={maxClosedPage}
              setPage={setClosedPage}
            />
          </Fragment>
        )}
        {canShowPosition &&
          allClosedPositions?.length === 0 &&
          !closedPIsFetched &&
          _renderLoader()}
        {canShowPosition &&
          allClosedPositions?.length === 0 &&
          closedPIsFetched &&
          _renderNoRecord()}
        {!canShowPosition && _renderNoRecord()}
      </Fragment>
    );
  };

  return (
    <Container>
      <TitleRow>
        <Subtitle>{t.factory.yourPositions}</Subtitle>
        <Switchers>
          <Switcher
            isSelect={isShowActive}
            onClick={() => setIsShowActive(true)}
          >
            {t.factory.current}
          </Switcher>
          <Switcher
            isSelect={!isShowActive}
            onClick={() => setIsShowActive(false)}
          >
            {t.factory.history}
          </Switcher>
        </Switchers>
      </TitleRow>
      {/* Positions */}
      {isShowActive ? _renderActivePositions() : _renderClosedPositions()}
    </Container>
  );
};

export default YourBot;

const Container = styled.div`
  padding: 24px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 400px) {
    flex-direction: column;
    gap: 12px;
  }
`;

const Subtitle = styled(H2)`
  font-size: 20px;
  line-height: 32px;
  text-align: left;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
  }
`;

const Switchers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: ${Colors.backgroundGray4};
`;

const Switcher = styled.button<{ isSelect: boolean }>`
  font-size: 13px;
  line-height: 20px;
  padding: 2px 20px;
  border-radius: 100px;
  /* font-weight: ${(props) => (props.isSelect ? "bold" : "normal")}; */
  color: ${(props) => (props.isSelect ? Colors.lightKhaki : Colors.gray4)};
  border: 1px solid
    ${(props) => (props.isSelect ? Colors.lightKhaki : "transparent")};
  background-color: ${(props) =>
    props.isSelect ? "transparent" : "transparent"};
`;

const LabelContainer = styled.div`
  flex: 1 1 160px;
`;

const HeadText = styled(H4)`
  display: block;
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
  }
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding: 12px 0;
  border-bottom: 1px solid ${Colors.gray3};
  @media (max-width: 768px) {
    display: none;
  }
`;

const BtnContainer = styled.div`
  flex: 1 1 160px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  @media (max-width: 768px) {
    flex: 0 0 200px;
  }
  button {
    margin: 0;
  }
  @media (max-width: 576px) {
    width: 100%;
    flex: initial;
    margin: 12px 0 18px 0;
    gap: 12px;
    button {
      width: 100%;
    }
  }
`;

const NoRecordContainer = styled.div`
  text-align: center;
  padding: 60px 0;
`;

const NoRecord = styled.span`
  display: inline-block;
  padding: 4px 20px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGray4};
  margin: auto;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.ivory};
`;

const BtnContainerH = styled.div`
  flex: 0 0 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    flex: initial;
    margin: 6px 0;
  }
`;

const PositionsContainer = styled.div`
  > div:not(:last-child) {
    border-bottom: 0.5px solid ${Colors.gray4};
  }
`;
