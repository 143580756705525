import { utils } from "ethers";
import {
  getUserLendingPoolRecordFromGinza,
  getUserBalanceVaultRecordFromGinza,
  getUserStakingRecordFromGinza,
} from "../utils/api/ginzaApi";
import { getDiamondNFTs } from "../utils/api/diamondNftApi";
import {
  updateAccountNfts,
  setNftIsFetching,
  updateUserLendingPoolRecords,
  updateUserBalanceVaultRecords,
  updateUserStakingRecords,
} from "./accountSlice";
import { AppThunk } from "./store";

export const getUserNfts =
  (
    userAddress: string,
    assetContractAddresses: string[],
    chainId: number,
    provider: any
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setNftIsFetching(true));
    try {
      const nfts = await getDiamondNFTs(
        userAddress,
        assetContractAddresses,
        chainId,
        provider
      );
      if (nfts.length > 0) {
        dispatch(updateAccountNfts(nfts));
      }
    } catch (error) {}
    dispatch(setNftIsFetching(false));
  };

export const getLendingPoolRecords =
  (
    userAddress: string,
    lendingPoolAddress: string,
    chainId: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      const records = await getUserLendingPoolRecordFromGinza(
        userAddress,
        lendingPoolAddress,
        chainId
      );
      dispatch(
        updateUserLendingPoolRecords({ records, lendingPoolAddress, chainId })
      );
    } catch (error) {}
  };

export const getBalanceVaultRecords =
  (userAddress: string, tokenAddress: string, tokenDecimal: number, chainId: number): AppThunk =>
  async (dispatch) => {
    try {
      const records = await getUserBalanceVaultRecordFromGinza(
        userAddress,
        tokenAddress,
        chainId
      );
      const parsedRecords = records.map(record => ({
        ...record,
        amount: utils.formatUnits(record.amount, tokenDecimal)
      }))
      dispatch(
        updateUserBalanceVaultRecords({ records: parsedRecords, tokenAddress, chainId })
      );
    } catch (error) {}
  };

export const getStakingRecords =
  (userAddress: string, stakingAddress: string, chainId: number): AppThunk =>
  async (dispatch) => {
    try {
      const records = await getUserStakingRecordFromGinza(
        userAddress,
        stakingAddress,
        chainId
      );
      dispatch(updateUserStakingRecords({ records, stakingAddress, chainId }));
    } catch (error) {}
  };
