import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";
import Colors from "../styles/Colors";

const LabelWithHint: React.FC<{
  labelComponent: any;
  hintText: string | React.ReactNode;
  hintPlace?: "auto" | "top" | "right" | "left" | "bottom";
}> = ({ labelComponent, hintText, hintPlace }) => {
  return (
    <LabelRow>
      {labelComponent}
      {hintText && (
        <OverlayTrigger
          placement={hintPlace || "top"}
          delay={{ show: 0, hide: 100 }}
          overlay={(props: any) => (
            <StyledTooltip multiline={"true"} {...props}>
              {hintText}
            </StyledTooltip>
          )}
        >
          <HintIcon icon={faQuestionCircle} />
        </OverlayTrigger>
      )}
    </LabelRow>
  );
};

export default LabelWithHint;

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    font-size: 12px;
    text-align: left;
  }
  @media (max-width: 576px) {
    .tooltip-inner {
      font-size: 12px;
      max-width: 150px;
    }
  }
`;

const HintIcon = styled(FontAwesomeIcon)`
  display: inline;
  color: ${Colors.gray3};
  font-size: 12px;
  margin-left: 6px;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  @media (max-width: 786px) {
    font-size: 11px;
    margin-left: 4px;
  }
`;

const LabelRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
