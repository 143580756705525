import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import Button from "../../../components/Button";
import { H4 } from "../../../styles/Fonts";
import CustomModal from "../../../components/Modal";
import { PoolProps } from "../../../utils/constant/pools";
import { useWeb3React } from "@web3-react/core";
import { unstakeTokenFromPCS } from "../../../utils/api/lendingPoolApi";
import AmountInput from "../../../components/AmountInput";
import t from "../../../utils/content";
import { showErrorAlert } from "../../../utils/showAlert";
import useTrackEvent from "../../../hooks/useTrackEvent";

const UnstakeModal: React.FC<{
  isShowModal: boolean;
  onHideModal: () => void;
  pool: PoolProps;
  updatePoolState: () => void;
  updateUserSupply: () => void;
  setIsUnstaking: (v: boolean) => void;
  isUnstaking: boolean;
  stakedAmount: string;
  lpTokenSymbol: string;
}> = (props) => {
  const {
    isShowModal,
    onHideModal,
    pool,
    updatePoolState,
    updateUserSupply,
    setIsUnstaking,
    isUnstaking,
    stakedAmount,
    lpTokenSymbol,
  } = props;
  const {
    lendingPoolAddress: poolAddress,
    tokenDecimal,
    tokenSymbol,
    stakingAddress,
  } = pool;

  const { account, chainId, provider } = useWeb3React();
  const trackEvent = useTrackEvent();

  const [unstakeAmount, setUnstakeAmount] = useState<string>("0");

  const closeModal = () => {
    onHideModal();
    setUnstakeAmount("0");
  };

  const handleUnstake = async (address: string, amount: string) => {
    if (!chainId) {
      return;
    }

    if (!isNaN(parseFloat(amount)) && parseFloat(amount) <= 0) {
      showErrorAlert(t.error.inputStakeAmount);
      return;
    }

    closeModal();
    setIsUnstaking(true);

    trackEvent(`${t.amp.clickUnstakeBtn} | ${t.amp.poolPage}`, {
      amount: amount,
      poolAddress: poolAddress,
      token: tokenSymbol,
    });

    try {
      await unstakeTokenFromPCS(
        address,
        stakingAddress || "",
        tokenDecimal,
        amount,
        provider,
        (txHash: string) => {
          trackEvent(`${t.amp.unstakeToken} | ${t.amp.poolPage}`, {
            txHash: txHash,
            amount: amount,
            poolAddress: poolAddress,
            token: tokenSymbol,
          });
        }
      );
      updatePoolState();
      updateUserSupply();
    } catch (e) {
      // console.log(e);
    }

    setIsUnstaking(false);
    setUnstakeAmount("0");
  };

  return (
    <CustomModal show={isShowModal} onHide={closeModal} title={t.pool.unstake}>
      <AmountInput
        setAmount={setUnstakeAmount}
        tokenSymbol={`${lpTokenSymbol}`}
        tokenDecimal={tokenDecimal}
        showMaxBtn={true}
        maxAmount={stakedAmount}
      />
      <InfoContainer>
        <BalanceRow>
          <Label>{t.pool.stakedAsset}</Label>
          <Value>
            {stakedAmount}
            {` ${lpTokenSymbol}`}
          </Value>
        </BalanceRow>
      </InfoContainer>
      <BtnContainer>
        <Button
          btnType="primary"
          btnText={t.pool.unstake}
          onClick={() =>
            account ? handleUnstake(account, unstakeAmount) : null
          }
          disabled={!unstakeAmount || isUnstaking}
          isLoading={isUnstaking}
        />
      </BtnContainer>
    </CustomModal>
  );
};

export default UnstakeModal;

const InfoContainer = styled.div`
  margin-top: 20px;
`;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const Value = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
  color: ${Colors.ivory};
  text-align: right;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12px;
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 12px;
  button {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;
