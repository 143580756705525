const leaderboard = {
  leaderboard: "Leaderboard",
  profitLeaderboard: "Profit Leaderboard",
  user: "User",
  realizedProfit: "Realized Profit",
  topOpenBy: "Top Open Positions By",
  positionId: "Position Id",
  earnedFee: "Earned Fee",
  topOpenByApr: "Top Open Positions By APR%",
  feeApr: "Fee APR (%)",
  topCloseBy: "Top Closed Positions By",
  pnl: "PnL",
  topCloseByPnlPercentage: "Top Closed Positions By PnL%",
  pnlPercentage: "PnL (%)",
  positionDetail: "Position Detail",
  closeEvent: "Close Event",
};

export default leaderboard;
