import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { Modal } from "react-bootstrap";
import { H4 } from "../../styles/Fonts";
import PairTokenIcon from "../../components/PairTokenIcon";
import LabelWithHint from "../../components/LabelWithHint";
import { getTruncNum, handleOpenV3PositionPage } from "../../utils/common";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { PairProps } from "../../utils/constant/pairs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPositionRangesFromV3 } from "../../utils/api/factoryV3Api";
import { AMOUNT_DECIMAL, getPriceDecimal } from "../../utils/constant/decimals";
import { isPanCakeSwap } from "../../utils/constant/chains";
import t from "../../utils/content";
import { convertTickToPrice } from "../../utils/api/common";
import { formatUnits } from "ethers/lib/utils";
import NumberWithHint from "../../components/NumberWithHint";

const currHeads = [
  { label: t.factory.pair, hint: "" },
  {
    label: t.factory.positionValue,
    hint: t.factory.positionValueDesc,
  },
  { label: t.factory.feeApr, hint: t.factory.feeAprDesc },
];

const ActivePositionModal: React.FC<{
  isShowModal: boolean;
  setShowModal: (v: boolean) => void;
  record: any;
  pair: PairProps;
}> = (props) => {
  const { isShowModal, setShowModal, record, pair } = props;

  const {
    wantToken: {
      tokenDecimal: wantTokenDecimal,
      tokenSymbol: wantTokenSymbol,
      tokenIcon: wantTokenIcon,
    },
    borrowToken: {
      tokenDecimal: borrowTokenDecimal,
      tokenSymbol: borrowTokenSymbol,
      tokenIcon: borrowTokenIcon,
    },
    deployedChainId,
    label: pairLabel,
    wantTokenIsToken0,
  } = pair;

  const [ranges, setRanges] = useState({ upperPrice: 0, lowerPrice: 0 });

  const getRanges = useCallback(async () => {
    let upperPrice = 0;
    let lowerPrice = 0;
    try {
      const { tickLower, tickUpper } = await getPositionRangesFromV3(
        +record.PositionId,
        deployedChainId,
        ""
      );
      const decimalDiff = borrowTokenDecimal - wantTokenDecimal;
      if (tickLower && tickUpper) {
        upperPrice = convertTickToPrice(
          wantTokenIsToken0 ? tickUpper : -tickLower,
          decimalDiff
        );
        lowerPrice = convertTickToPrice(
          wantTokenIsToken0 ? tickLower : -tickUpper,
          decimalDiff
        );
      }
    } catch (error) {
      // console.log(error);
    }
    setRanges({ upperPrice, lowerPrice });
  }, [
    record.PositionId,
    borrowTokenDecimal,
    wantTokenDecimal,
    deployedChainId,
    wantTokenIsToken0,
  ]);

  useEffect(() => {
    if (record.PositionId) {
      getRanges();
    }
  }, [getRanges, record.PositionId]);

  return (
    <ModalContainer show={isShowModal} onHide={() => setShowModal(false)}>
      <Header closeButton closeVariant="white">
        <HeaderTitle>{t.leaderboard.positionDetail}</HeaderTitle>
      </Header>
      <Body>
        <RowContainer>
          {currHeads.map((head) => (
            <HeaderLabelContainer key={head.label}>
              <LabelWithHint
                labelComponent={<HeadText>{head.label}</HeadText>}
                hintText={head.hint}
              />
            </HeaderLabelContainer>
          ))}
        </RowContainer>
        <Layer1Container>
          <Layer1Col>
            <LabelContainer>
              <LabelWithHint
                labelComponent={<HeadText>{t.factory.pair}</HeadText>}
                hintText=""
              />
            </LabelContainer>
            <PairNameRow>
              <PairTokenIcon
                borrowTokenIcon={borrowTokenIcon}
                wantTokenIcon={wantTokenIcon}
              />
              <ValueText>{pairLabel}</ValueText>
            </PairNameRow>
          </Layer1Col>
          <Layer1Col>
            <LabelContainer>
              <LabelWithHint
                labelComponent={<HeadText>{t.factory.positionValue}</HeadText>}
                hintText={t.factory.positionValueDesc}
              />
            </LabelContainer>
            <ValueText>
              {`${getTruncNum(
                +record?.PositionInfo?.PositionValueMeasuredInWantToken /
                  10 ** wantTokenDecimal,
                2
              )} ${wantTokenSymbol}`}
            </ValueText>
          </Layer1Col>
          <Layer1Col>
            <LabelContainer>
              <LabelWithHint
                labelComponent={<HeadText>{t.factory.feeApr}</HeadText>}
                hintText={t.factory.feeAprDesc}
              />
            </LabelContainer>
            <ValueText>{`${getTruncNum(
              +record?.PositionInfo?.MeasuredFeeAPR,
              2
            )} %`}</ValueText>
          </Layer1Col>
        </Layer1Container>
        <div>
          <Layer2Row>
            <Layer2Col>
              <Row>
                <LabelWithHint
                  labelComponent={
                    <Layer2Label>{t.factory.positionId}</Layer2Label>
                  }
                  hintText={t.factory.positionIdDesc}
                />
                <Icon
                  icon={faArrowUpRightFromSquare}
                  onClick={() =>
                    handleOpenV3PositionPage(
                      record.PositionId,
                      deployedChainId,
                      isPanCakeSwap(deployedChainId)
                    )
                  }
                />
              </Row>
              <Layer2Value>{record.PositionId}</Layer2Value>
            </Layer2Col>
            <Layer2Col>
              <LabelWithHint
                labelComponent={<Layer2Label>{t.factory.amount}</Layer2Label>}
                hintText={t.factory.positionAmountDesc}
              />
              <Layer2Value>
                {`${getTruncNum(
                  +record.WantAmount / 10 ** wantTokenDecimal,
                  AMOUNT_DECIMAL
                )} ${wantTokenSymbol}`}
              </Layer2Value>
            </Layer2Col>
            <Layer2Col>
              <LabelWithHint
                labelComponent={
                  <Layer2Label>{t.factory.totalDebt}</Layer2Label>
                }
                hintText={`${t.factory.totalDebtDesc1} ${borrowTokenSymbol} ${t.factory.totalDebtDesc2}`}
              />
              <Layer2Value>
                {`${getTruncNum(
                  +record.PositionInfo.BorrowTokenAmount /
                    10 ** borrowTokenDecimal,
                  AMOUNT_DECIMAL
                )} ${borrowTokenSymbol}`}
              </Layer2Value>
              <Layer2ValueSmall>
                {`(${getTruncNum(
                  +record.PositionInfo.DebtValueMeasuredInWantToken /
                    10 ** wantTokenDecimal,
                  AMOUNT_DECIMAL
                )} ${wantTokenSymbol})`}
              </Layer2ValueSmall>
            </Layer2Col>
            <Layer2Col>
              <LabelWithHint
                labelComponent={
                  <Layer2Label>{t.factory.extraCollateral}</Layer2Label>
                }
                hintText={t.factory.extraCollateralDesc}
              />
              <Layer2Value>
                {`${getTruncNum(
                  +record.PositionInfo.ReserveAmount / 10 ** wantTokenDecimal,
                  AMOUNT_DECIMAL
                )} ${wantTokenSymbol}`}
              </Layer2Value>
            </Layer2Col>
            <Layer2Col>
              <LabelWithHint
                labelComponent={<Layer2Label>{t.factory.pnl}</Layer2Label>}
                hintText={t.factory.pnlDesc}
              />
              <NumberWithHint
                labelComponent={
                  <Layer2Value>
                    {`${getTruncNum(
                      +formatUnits(record.PositionInfo.PNL, wantTokenDecimal),
                      AMOUNT_DECIMAL
                    )} ${wantTokenSymbol}`}
                  </Layer2Value>
                }
                hints={
                  !!record.PositionInfo.UserReward
                    ? [
                        `• LP PnL: ${getTruncNum(
                          +formatUnits(
                            record.PositionInfo.PNL,
                            wantTokenDecimal
                          ) -
                            (!!record.PositionInfo.RewardTokenValueInWant
                              ? +formatUnits(
                                  record.PositionInfo.RewardTokenValueInWant,
                                  wantTokenDecimal
                                )
                              : 0),
                          AMOUNT_DECIMAL
                        )} ${wantTokenSymbol}`,
                        `• CAKE Reward: ${getTruncNum(
                          +formatUnits(record.PositionInfo.UserReward, 18),
                          AMOUNT_DECIMAL
                        )} CAKE`,
                        `(≈ ${getTruncNum(
                          +formatUnits(
                            record.PositionInfo.RewardTokenValueInWant || 0,
                            wantTokenDecimal
                          ),
                          AMOUNT_DECIMAL
                        )} ${wantTokenSymbol})`,
                      ]
                    : []
                }
              />
            </Layer2Col>
            <Layer2Col>
              <LabelWithHint
                labelComponent={<Layer2Label>{t.factory.range}</Layer2Label>}
                hintText={t.factory.positionRangeDesc}
              />
              <Layer2Value>
                {`${getTruncNum(
                  +ranges.lowerPrice,
                  getPriceDecimal(+ranges.lowerPrice)
                )} - ${getTruncNum(
                  +ranges.upperPrice,
                  getPriceDecimal(+ranges.upperPrice)
                )} ${wantTokenSymbol} `}
              </Layer2Value>
            </Layer2Col>
            <Layer2Col>
              <LabelWithHint
                labelComponent={
                  <Layer2Label>{t.factory.exitPrices}</Layer2Label>
                }
                hintText={t.factory.exitPricesDesc}
              />
              <Layer2Value>
                {`${getTruncNum(
                  convertTickToPrice(
                    wantTokenIsToken0
                      ? +record.PositionInfo.StopLossLowerPriceTick
                      : -record.PositionInfo.StopLossUpperPriceTick,
                    borrowTokenDecimal - wantTokenDecimal
                  ),
                  getPriceDecimal(
                    +convertTickToPrice(
                      wantTokenIsToken0
                        ? +record.PositionInfo.StopLossLowerPriceTick
                        : -record.PositionInfo.StopLossUpperPriceTick,
                      borrowTokenDecimal - wantTokenDecimal
                    )
                  )
                )} & ${getTruncNum(
                  convertTickToPrice(
                    wantTokenIsToken0
                      ? +record.PositionInfo.StopLossUpperPriceTick
                      : -record.PositionInfo.StopLossLowerPriceTick,
                    borrowTokenDecimal - wantTokenDecimal
                  ),
                  getPriceDecimal(
                    +convertTickToPrice(
                      wantTokenIsToken0
                        ? +record.PositionInfo.StopLossUpperPriceTick
                        : -record.PositionInfo.StopLossLowerPriceTick,
                      borrowTokenDecimal - wantTokenDecimal
                    )
                  )
                )} ${wantTokenSymbol}`}
              </Layer2Value>
            </Layer2Col>
            <Layer2Col>
              <LabelWithHint
                labelComponent={
                  <Layer2Label>{t.factory.uniswapFee}</Layer2Label>
                }
                hintText={t.factory.uniswapFeeDesc}
              />
              <div>
                <Layer2Value>{`${getTruncNum(
                  +record.PositionInfo.MeasuredBorrowTokenFeeInWant /
                    10 ** wantTokenDecimal +
                    +record.PositionInfo.WantTokenFee / 10 ** wantTokenDecimal,
                  AMOUNT_DECIMAL
                )} ${wantTokenSymbol} `}</Layer2Value>
                <Layer2ValueSmall>{`(${getTruncNum(
                  +record.PositionInfo.WantTokenFee / 10 ** wantTokenDecimal,
                  AMOUNT_DECIMAL
                )} ${wantTokenSymbol} & ${getTruncNum(
                  +record.PositionInfo.BorrowTokenFee /
                    10 ** borrowTokenDecimal,
                  AMOUNT_DECIMAL
                )} ${borrowTokenSymbol})`}</Layer2ValueSmall>
              </div>
            </Layer2Col>
            <Layer2Col>
              <LabelWithHint
                labelComponent={
                  <Layer2Label>{t.factory.positionCreatedTime}</Layer2Label>
                }
                hintText=""
              />
              <Layer2Value>
                {new Date(record.OpenTime * 1000).toLocaleString("en")}
              </Layer2Value>
            </Layer2Col>
            <Layer2Col>
              <LabelWithHint
                labelComponent={
                  <Layer2Label>{t.factory.entryPrice}</Layer2Label>
                }
                hintText=""
              />
              <Layer2Value>{`${getTruncNum(
                convertTickToPrice(
                  wantTokenIsToken0 ? +record.OpenTicker : -record.OpenTicker,
                  borrowTokenDecimal - wantTokenDecimal
                ),
                getPriceDecimal(
                  +convertTickToPrice(
                    wantTokenIsToken0 ? +record.OpenTicker : -record.OpenTicker,
                    borrowTokenDecimal - wantTokenDecimal
                  )
                )
              )} ${borrowTokenSymbol} ${
                t.factory.per
              } ${wantTokenSymbol}`}</Layer2Value>
            </Layer2Col>
            <Layer2Col>
              <LabelWithHint
                labelComponent={<Layer2Label>{t.factory.leverage}</Layer2Label>}
                hintText={t.factory.positionLeverageDesc}
              />
              <Layer2Value>
                {+record.PositionInfo.BorrowRatio / 10000 + 1}X
              </Layer2Value>
            </Layer2Col>
            {!!record.PositionInfo.UserReward && (
              <Layer2Col>
                <LabelWithHint
                  labelComponent={<Layer2Label>CAKE Reward</Layer2Label>}
                  hintText={""}
                />
                <Layer2Value>
                  {!!record.PositionInfo.UserReward
                    ? `${getTruncNum(
                        +formatUnits(record.PositionInfo.UserReward, 18),
                        AMOUNT_DECIMAL
                      )} CAKE (≈ ${getTruncNum(
                        record.PositionInfo.RewardTokenValueInWant
                          ? +formatUnits(
                              record.PositionInfo.RewardTokenValueInWant,
                              wantTokenDecimal
                            )
                          : 0,
                        AMOUNT_DECIMAL
                      )} ${wantTokenSymbol})`
                    : "-"}
                </Layer2Value>
              </Layer2Col>
            )}
          </Layer2Row>
        </div>
      </Body>
    </ModalContainer>
  );
};

export default ActivePositionModal;

const ModalContainer = styled(Modal)`
  display: none;
  .modal-content {
    background-color: ${Colors.backgroundGray1};
    width: 90%;
    max-width: 640px;
    margin: auto;
  }
  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;

const Header = styled(Modal.Header)`
  border-bottom: 0.5px solid ${Colors.gray3};
  padding: 16px 24px;
`;

const HeaderTitle = styled(H4)`
  color: ${Colors.ivory};
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Body = styled(Modal.Body)`
  padding: 0px 24px 32px 24px;
`;

const HeaderLabelContainer = styled.div`
  flex: 1;
`;

const LabelContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    flex: initial;
  }
`;

const HeadText = styled(H4)`
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
  color: ${Colors.gray3};
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const ValueText = styled(H4)`
  flex: 1;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: ${Colors.gray1};
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
  @media (max-width: 768px) {
    flex: initial;
  }
`;

const Layer1Container = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0 16px 0;
  button {
    border-radius: 4px;
    font-size: 13px;
    line-height: 18px;
    padding: 4px 8px;
    width: auto;
    margin: auto;
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  > div:last-child {
    flex: 0.5;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 12px 0;
  }
`;

const Layer1Col = styled.div`
  flex: 1;
  padding: 4px 0;
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex: inherit;
    justify-content: space-between;
    gap: 12px;
  }
`;

const Layer2Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${Colors.backgroundGray4};
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const Layer2Col = styled.div`
  width: 50%;
  padding: 6px 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    gap: 12px;
  }
`;

const Layer2Label = styled(H4)`
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
  color: ${Colors.gray3};
`;

const Layer2Value = styled(H4)`
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  color: ${Colors.gray1};
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    text-align: right;
  }
`;

const Layer2ValueSmall = styled(H4)`
  font-size: 12px;
  line-height: 18px;
  color: ${Colors.gray3};
  @media (max-width: 768px) {
    text-align: right;
  }
`;

const PairNameRow = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-right: 4px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 8px;
  color: ${Colors.ivory};
  font-size: 12px;
  cursor: pointer;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    display: none;
  }
  div:last-child {
    flex: 0.5;
  }
`;
