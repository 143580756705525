import React from "react";
import styled from "styled-components";
import InputWithFloatingLabel from "../../../components/InputWithFloatingLabel";
import LabelWithHint from "../../../components/LabelWithHint";
import Colors from "../../../styles/Colors";
import { H4 } from "../../../styles/Fonts";
import { getTruncNum } from "../../../utils/common";
import { getPriceDecimal } from "../../../utils/constant/decimals";
import { PairProps } from "../../../utils/constant/pairs";
import t from "../../../utils/content";
import { InfoDiv } from "./CommonParts";

type Props = {
  isLiteMode: boolean;
  pair: PairProps;
  borrowTokenPrice: number;
  stopLossLowerPrice: number;
  setStopLossLowerPrice: (v: number) => void;
  stopLossUpperPrice: number;
  setStopLossUpperPrice: (v: number) => void;
};

const ExitPrices: React.FC<Props> = (props) => {
  const {
    isLiteMode,
    pair: {
      wantToken: { tokenSymbol: wTokenSymbol },
      borrowToken: { tokenSymbol: bTokenSymbol },
    },
    borrowTokenPrice,
    stopLossLowerPrice,
    setStopLossLowerPrice,
    stopLossUpperPrice,
    setStopLossUpperPrice,
  } = props;

  const currentTokenPrice = getTruncNum(borrowTokenPrice, getPriceDecimal(borrowTokenPrice));

  return (
    <div>
      <LabelWithHint
        labelComponent={<Label>{t.factory.exitPrices}</Label>}
        hintText={t.factory.exitPricesDesc}
      />
      {isLiteMode ? (
        <PricesContainer>
          <PriceContainer>
            <PriceTag>{t.factory.lowerPrice}</PriceTag>
            <PriceValue>
              {stopLossLowerPrice
                ? `${getTruncNum(
                  stopLossLowerPrice,
                  borrowTokenPrice < 1 ? 6 : 2
                )}`
                : "-"}
            </PriceValue>
            <PriceTag>{`${wTokenSymbol} ${t.factory.per} ${bTokenSymbol}`}</PriceTag>
          </PriceContainer>
          <PriceContainer>
            <PriceTag>{t.factory.upperPrice}</PriceTag>
            <PriceValue>
              {stopLossLowerPrice
                ? `${getTruncNum(
                  stopLossUpperPrice,
                  borrowTokenPrice < 1 ? 6 : 2
                )}`
                : "-"}
            </PriceValue>
            <PriceTag>{`${wTokenSymbol} ${t.factory.per} ${bTokenSymbol}`}</PriceTag>
          </PriceContainer>
        </PricesContainer>
      ) : (
        <PriceRow>
          <InputWithFloatingLabel
            labelText={t.factory.lowerPrice}
            value={stopLossLowerPrice}
            setValue={setStopLossLowerPrice}
            prefix=""
            suffix={wTokenSymbol}
            placeholder={currentTokenPrice ? `< ${currentTokenPrice}` : ""}
          />
          <InputWithFloatingLabel
            labelText={t.factory.upperPrice}
            value={stopLossUpperPrice}
            setValue={setStopLossUpperPrice}
            prefix=""
            suffix={wTokenSymbol}
            placeholder={currentTokenPrice ? `> ${currentTokenPrice}` : ""}
          />
        </PriceRow>
      )}
    </div>
  );
};

export default ExitPrices;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
  @media (max-width: 768px) {
  }
`;

const PriceRow = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 8px;
`;

const PricesContainer = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 8px;
`;

const PriceContainer = styled(InfoDiv)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 12px;
`;

const PriceTag = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: ${Colors.gray3};
  text-align: center;
`;

const PriceValue = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.ivory};
  font-weight: bold;
`;
