import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { openNewTab } from "../../../utils/common";
import React from "react";
import { NFTProps } from "../../../redux/accountSlice";
import { H4 } from "../../../styles/Fonts";
import { ClipLoader } from "react-spinners";

type Props = {
  nfts: NFTProps[];
  isFetching: boolean;
};

const Nfts: React.FC<Props> = (props) => {
  const { nfts, isFetching } = props;

  return (
    <NftsContainer>
      {isFetching && (
        <LoaderContainer>
          <ClipLoader color={Colors.ivory} size={36} />
        </LoaderContainer>
      )}
      {!isFetching &&
        (nfts.length > 0 ? (
          nfts.map((nft) => (
            <NftItemContainer
              key={nft.name + nft.id}
              onClick={() => openNewTab(nft.permalink)}
            >
              <NftImageContainer>
                <NftImage src={nft.imageUrl} />
              </NftImageContainer>
              <NftNameContainer>
                <NftName>{nft.name}</NftName>
              </NftNameContainer>
            </NftItemContainer>
          ))
        ) : (
          <NoRecordContainer>
            <NoRecord>No Diamond NFT under your account</NoRecord>
          </NoRecordContainer>
        ))}
    </NftsContainer>
  );
};

export default Nfts;

const NftsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  background: ${Colors.backgroundGray1};
  padding: 32px;
  border-radius: 8px;
`;

const NftItemContainer = styled.div`
  flex: 0 1 200px;
  height: 240px;
  border-radius: 8px;
  overflow: hidden;
  background: ${Colors.backgroundGray4};
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  display: grid;
  grid-template-rows: 1fr 60px;

  :hover {
    transform: scale(1.05);
  }
`;

const NftImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border: none;
`;

const NftImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  border: none;
`;

const NftNameContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 12px;
`;

const NftName = styled(H4)`
  color: ${Colors.ivory};
  line-height: 22px;
`;

const NoRecordContainer = styled.div`
  text-align: center;
  padding: 40px 0;
  margin: auto;
`;

const NoRecord = styled.span`
  display: inline-block;
  padding: 4px 20px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGray4};
  margin: auto;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.ivory};
`;

const LoaderContainer = styled.div`
  flex: 1;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
