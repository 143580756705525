import { useCallback, useEffect, useState } from "react";
import {
  getGinzaPCSRewardApr,
  getGinzaUniPoolApr,
} from "../utils/api/ginzaApi";
import { useAppSelector } from "../hooks";
import { isPanCakeSwap } from "../utils/constant/chains";

const useLiquidityPoolApr = (uniPoolAddress: string) => {
  const [apr, setApr] = useState<number | null>(null);
  const [pcsRewardApr, setPcsRewardApr] = useState<number | null>(null);

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const getAllPoolApr = useCallback(async () => {
    setApr(null);
    const feeApr = await getGinzaUniPoolApr(uniPoolAddress);
    setApr(+feeApr || 0);
  }, [uniPoolAddress]);

  const getPcsRewardApr = useCallback(async () => {
    setPcsRewardApr(null);
    const feeApr = await getGinzaPCSRewardApr(uniPoolAddress);
    setPcsRewardApr(+feeApr || 0);
  }, [uniPoolAddress]);

  useEffect(() => {
    getAllPoolApr();
  }, [getAllPoolApr]);

  useEffect(() => {
    if (!isPanCakeSwap(selectedChainId)) {
      setPcsRewardApr(0);
    } else {
      getPcsRewardApr();
    }
  }, [getPcsRewardApr, selectedChainId]);

  return { apr, pcsRewardApr };
};

export default useLiquidityPoolApr;
