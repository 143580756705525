import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Modal } from "react-bootstrap";
import { H4 } from "../../../styles/Fonts";
import LabelWithHint from "../../../components/LabelWithHint";
import getTotalProfitWithStopLossPrice from "../../../utils/calculation/getTotalProfitWithStopLossPrice";
import t from "../../../utils/content";

type SimulateDataProps = {
  usdcAmount: number;
  borrowingRatio: number;
  duration: number;
  range: number;
  estimatedFeeApy: number;
  borrowRate: number;
  stopLossLowerPrice: number;
  stopLossUpperPrice: number;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
);

const SimulatorModal: React.FC<{
  simulateData: SimulateDataProps;
  marketPrice: number;
  setShowModal: (value: boolean) => void;
  isShowModal: boolean;
}> = ({ simulateData, marketPrice, setShowModal, isShowModal }) => {
  const {
    usdcAmount,
    borrowingRatio,
    duration,
    range,
    estimatedFeeApy,
    borrowRate,
    stopLossLowerPrice,
    stopLossUpperPrice,
  } = simulateData;

  const [labels, setLabels] = useState<number[]>([]);
  const [pnls, setPnls] = useState<number[]>([]);

  const getKArray = useCallback(() => {
    const min =
      stopLossLowerPrice < marketPrice
        ? stopLossLowerPrice / marketPrice / 1.2
        : 1 - 60 / 100;
    const max =
      stopLossUpperPrice > marketPrice
        ? (stopLossUpperPrice / marketPrice) * 1.2
        : 1 + 60 / 100;

    const interval = (max - min) / 100;

    const array = [];

    for (let i = min; i < 1; i += interval) {
      array.push(+i);
    }

    for (let i = 1; i < max; i += interval) {
      array.push(+i);
    }
    array.push(max);

    return array.sort();
  }, [marketPrice, stopLossLowerPrice, stopLossUpperPrice]);

  // Get pnl and label array
  useEffect(() => {
    if (
      usdcAmount &&
      borrowingRatio &&
      duration &&
      range &&
      marketPrice &&
      stopLossLowerPrice &&
      stopLossUpperPrice &&
      estimatedFeeApy
      //  &&
      // borrowRate
    ) {
      const kArray = getKArray();

      const labelArray = kArray.map((k) => (k - 1) * 100);
      setLabels(labelArray);

      const pnlArray = kArray.map((k) =>
        getTotalProfitWithStopLossPrice(
          usdcAmount,
          borrowingRatio,
          duration,
          range,
          k,
          marketPrice,
          estimatedFeeApy / 100,
          borrowRate,
          stopLossLowerPrice,
          stopLossUpperPrice
        )
      );

      setPnls(pnlArray);
    }
  }, [
    usdcAmount,
    borrowingRatio,
    duration,
    range,
    marketPrice,
    stopLossLowerPrice,
    stopLossUpperPrice,
    estimatedFeeApy,
    borrowRate,
    getKArray,
  ]);

  const horizontalLine = (chart: any) => {
    const { ctx, chartArea, scales } = chart;
    const yScale = scales["y"];
    const yValue = yScale.getPixelForValue(0); // get pixel of value 0

    if (yValue < chartArea.bottom) {
      // draw vertical line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(chartArea.left, yValue);
      ctx.lineTo(chartArea.right, yValue);
      ctx.lineWidth = 1;
      ctx.strokeStyle = "white";
      ctx.setLineDash([5, 10]);
      ctx.stroke();
      ctx.restore();
    }
  };

  return (
    <CustomModal show={isShowModal} onHide={() => setShowModal(false)}>
      <Header closeButton closeVariant="white">
        <LabelWithHint
          labelComponent={<HeaderTitle>{t.factory.simulator}</HeaderTitle>}
          hintText={t.factory.simulatorDesc}
          hintPlace="right"
        />
      </Header>
      <Body>
        <ChartContainer>
          <Line
            plugins={[
              {
                id: "horizontalLine",
                beforeDraw: horizontalLine,
              },
            ]}
            options={{
              scales: {
                y: {
                  display: true,
                  title: {
                    display: true,
                    text: "ROI (%)",
                    color: Colors.ivory,
                    padding: {
                      bottom: 16,
                    },
                  },
                  grid: {
                    color: "rgba(266, 266, 266, 0.1)",
                    display: true,
                  },
                  ticks: {
                    color: Colors.gray3,
                    callback(val) {
                      return `${(+val).toFixed(2)} %`;
                    },
                    autoSkip: true,
                    autoSkipPadding: 12,
                  },
                },
                x: {
                  display: true,
                  min: labels && labels[0],
                  max: labels && labels[labels.length - 1],

                  title: {
                    display: true,
                    text: t.factory.priceChanges,
                    color: Colors.ivory,
                    padding: {
                      top: 16,
                    },
                  },
                  grid: {
                    display: false,
                  },
                  type: "linear",
                  ticks: {
                    callback(val) {
                      return `${(+val).toFixed(0)}%`;
                    },
                    color: Colors.gray3,
                    maxRotation: 0,
                    align: "start",
                    maxTicksLimit: 10,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  intersect: false,
                  enabled: true,
                  position: "average",
                  displayColors: false,
                  callbacks: {
                    title: function (context) {
                      let title = context[0].label;
                      return `${t.factory.priceChange} ${title}%`;
                    },
                    label: (context) => {
                      let label = context.dataset.label || "";
                      if (label) {
                        label += ": ";
                      }
                      if (context.parsed.y !== null) {
                        label += `${context.parsed.y.toFixed(2)}%`;
                      }
                      return label;
                    },
                  },
                },
              },
              // animation: false,
              responsive: true,
              maintainAspectRatio: true,
              hover: {
                mode: "nearest",
                intersect: false,
              },
            }}
            data={{
              labels: labels,
              datasets: [
                {
                  label: "ROI (%)",
                  data: pnls.map((pnl) => (pnl / usdcAmount) * 100),
                  borderColor: "#00cc98",
                  pointRadius: 0,
                  pointBorderColor: "#00cc98",
                  pointBackgroundColor: Colors.ivory,
                  pointBorderWidth: 2,
                  pointHoverBackgroundColor: Colors.ivory,
                  pointHoverBorderColor: "#00cc98",
                  pointHoverRadius: 5,
                },
              ],
            }}
          />
        </ChartContainer>
      </Body>
    </CustomModal>
  );
};

export default SimulatorModal;

const ChartContainer = styled.div`
  height: 100%;
  flex: 1;
  padding: 12px 0;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const CustomModal = styled(Modal)`
  display: none;
  .modal-content {
    background-color: ${Colors.backgroundGray2};
    width: 95%;
    max-width: 500px;
    margin: auto;
  }
`;

const Header = styled(Modal.Header)`
  padding: 16px 24px;
  border-bottom: 0.5px solid ${Colors.gray3};
`;

const HeaderTitle = styled(H4)`
  color: ${Colors.ivory};
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Body = styled(Modal.Body)`
  padding: 24px 24px 32px 24px;
`;
