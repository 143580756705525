import { useWeb3React } from "@web3-react/core";
import { BigNumber, utils } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { getAirdropFromGinza } from "../utils/api/ginzaApi";
import { formatDate } from "../utils/common";

export type ClaimableRewardParams = [number, BigNumber, string[]][]; // [WeekNumber, Reward, MerkleProof][]
interface AirdropRewardProps {
  ID: number;
  MerkleProof: string[];
  RewardSession: {
    WeekNumber: number;
    RewardToken: string;
    RewardTokenDecimal: number;
    FromDate: string;
    ToDate: string;
    Distributed: Boolean;
  };
  Claimed: boolean;
  Reward: string;
}

const useUserAirdropRewards = () => {
  const [claimedReward, setClaimedReward] = useState<string>("-");
  const [claimableReward, setClaimableReward] = useState<string>("-");
  const [unvestedReward, setUnvestedReward] = useState<string>("-");
  const [claimableRewardParams, setClaimableRewardParams] =
    useState<ClaimableRewardParams>([]);
  const [nextVestingDate, setNextVestingDate] = useState("");

  const { account, chainId } = useWeb3React();

  const updateRewards = useCallback(async () => {
    if (!account || !chainId) return;

    const { claimed_rewards, data: _rewards } = await getAirdropFromGinza(
      account,
      chainId
    );

    let _claimableRewards = BigNumber.from(0);
    let _unvestedRewards = BigNumber.from(0);
    let _claimableRewardParams: ClaimableRewardParams = [];
    let _date = "";

    _rewards.forEach((r: AirdropRewardProps) => {
      // calculate claimable
      if (r.RewardSession.Distributed && !r.Claimed) {
        _claimableRewards = _claimableRewards.add(BigNumber.from(r.Reward));
        _claimableRewardParams.push([
          r.RewardSession.WeekNumber,
          BigNumber.from(r.Reward),
          r.MerkleProof,
        ]);
      }
      // calculate unvested & get most recent date
      if (!r.RewardSession.Distributed) {
        _unvestedRewards = _unvestedRewards.add(BigNumber.from(r.Reward));
        _date = r.RewardSession.FromDate;
      }
    });

    _date = formatDate(_date).includes("NaN") ? "-" : formatDate(_date);

    setClaimedReward(utils.formatUnits(claimed_rewards));
    setClaimableReward(utils.formatUnits(_claimableRewards, 18));
    setUnvestedReward(utils.formatUnits(_unvestedRewards, 18));
    setClaimableRewardParams(_claimableRewardParams);
    setNextVestingDate(_date);
  }, [account, chainId]);

  useEffect(() => {
    updateRewards();
  }, [updateRewards]);

  return {
    claimedReward,
    claimableReward,
    unvestedReward,
    nextVestingDate,
    claimableRewardParams,
    updateRewards,
  };
};

export default useUserAirdropRewards;
