import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import Colors from "../../styles/Colors";
import { H1, H2, H4 } from "../../styles/Fonts";
import { SmallRegular } from "../../styles/Fonts";
import ActionCol from "./ActionCol";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useUserEvieVaultState from "../../hooks/useUserEvieVaultState";
import useEvieVaultState from "../../hooks/useEvieVaultState";
import { motion } from "framer-motion";
import { ALL_EVIE_VAULT, FarmProps } from "../../utils/constant/evieVault";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useWeb3React } from "@web3-react/core";
import {
  getEvieVaultPositions,
  getSharePrice,
  getPastRoundSharePrices,
} from "../../redux/evieVaultAction";
import {
  CHAIN_IDS_TO_URL_IDS,
  CHAIN_URL_IDS_TO_IDS,
  isSupportedChain,
  SupportedChainId,
} from "../../utils/constant/chains";
import { updateSelectedChainId } from "../../redux/accountSlice";
import PositionRecords from "./PositionRecords";
import ActualChart from "./ActualChart";

const defaultChainId = SupportedChainId.ARBITRUM_ONE;

const EvieVault: React.FC = () => {
  const { chainName } = useParams();
  const navigate = useNavigate();
  const { account, chainId, provider } = useWeb3React();
  const { sharePrice, pastPositions, pastRoundSharePrices, historicalResult } =
    useAppSelector((state) => state.evieVault);
  const [vault, setVault] = useState<FarmProps>(
    ALL_EVIE_VAULT[chainId] || ALL_EVIE_VAULT[defaultChainId]
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const dispatch = useAppDispatch();

  const navigateToDefaultEvieVaultPage = useCallback(() => {
    const defaultChainName = CHAIN_IDS_TO_URL_IDS[defaultChainId];
    navigate(`/vault/${defaultChainName}`);
  }, [navigate]);

  useEffect(() => {
    setVault(ALL_EVIE_VAULT[chainId] || ALL_EVIE_VAULT[defaultChainId]);
  }, [chainId]);

  useEffect(() => {
    const urlChainId = chainName ? CHAIN_URL_IDS_TO_IDS[chainName] : 0;

    // 有連接 wallet
    if (account) {
      if (chainId && urlChainId !== chainId && ALL_EVIE_VAULT[chainId]) {
        const matchedChainName = CHAIN_IDS_TO_URL_IDS[chainId];
        navigate(`/vault/${matchedChainName}`);
      }
    } else {
      // 有指定 network
      if (!!urlChainId && ALL_EVIE_VAULT[urlChainId]) {
        dispatch(updateSelectedChainId({ chainId: urlChainId }));
      } else if (!urlChainId || !ALL_EVIE_VAULT[urlChainId]) {
        navigateToDefaultEvieVaultPage();
      }
    }
  }, [
    dispatch,
    account,
    chainId,
    chainName,
    navigate,
    navigateToDefaultEvieVaultPage,
  ]);

  const {
    updateAutoVaultState,
    totalSupply,
    vaultState,
    rolloverTime,
    currentRoundStartTimestamp,
  } = useEvieVaultState(
    vault.vaultAddress,
    vault.actionAddress,
    vault.wantToken.tokenDecimal,
    vault.deployedChainId
  );

  const { updateUserAutoVaultState, withdrawableAmount } = useUserEvieVaultState(
    vault.vaultAddress,
    vault.wantToken.tokenDecimal
  );

  useEffect(() => {
    dispatch(getEvieVaultPositions(vault.actionAddress, vault.deployedChainId));
    dispatch(getPastRoundSharePrices(vault.deployedChainId, vault.vaultID));
  }, [dispatch, vault]);

  useEffect(() => {
    if (vault.vaultAddress && vault.deployedChainId) {
      dispatch(
        getSharePrice(
          vault.vaultAddress,
          vault.deployedChainId,
          vault.wantToken.tokenDecimal,
          provider
        )
      );
      updateAutoVaultState()
    }
  }, [
    dispatch,
    vault.vaultAddress,
    vault.deployedChainId,
    vault.wantToken.tokenDecimal,
    provider,
  ]);

  if (chainName && !ALL_EVIE_VAULT[CHAIN_URL_IDS_TO_IDS[chainName]]) {
    return <NoRecord>{`No Auto Vault on this network`}</NoRecord>;
  }
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <MainContainer>
        <RowContainer>
          <LeftContainer>
            <Title>Vault</Title>
            <BackgroundContainer>
              <TextContainer>
                {/* <VaultTitle>Vault</VaultTitle> */}
                <DescriptionText>{vault.label} Uniswap V3</DescriptionText>
              </TextContainer>
              <DetailContainer>
                <DetailCol>
                  <Label>TVL</Label>
                  <Value>
                    {`${totalSupply ? (+totalSupply).toLocaleString() : "-"}
                     ${vault.wantToken.tokenSymbol}`}
                  </Value>
                </DetailCol>
                <DetailCol>
                  <Label>Price</Label>
                  <Value>{sharePrice}</Value>
                </DetailCol>
              </DetailContainer>
            </BackgroundContainer>
            <MobileActionContainer>
              <ActionCol
                updateAccountFarmStatus={updateUserAutoVaultState}
                withdrawableAmount={withdrawableAmount}
                updateAutoVaultState={updateAutoVaultState}
                vaultState={vaultState}
                vault={vault}
                rolloverTime={rolloverTime}
                currentRoundStartTimestamp={currentRoundStartTimestamp}
              />
            </MobileActionContainer>
            <BackgroundContainer>
              <SwitchersContainer>
                <Switcher
                  isSelect={selectedTab === 0}
                  onClick={() => setSelectedTab(0)}
                >
                  Performance
                </Switcher>
                <Switcher
                  isSelect={selectedTab === 1}
                  onClick={() => setSelectedTab(1)}
                >
                  Historic Results for Vault Strategy
                </Switcher>
                <Switcher
                  isSelect={selectedTab === 2}
                  onClick={() => setSelectedTab(2)}
                >
                  Transaction Records
                </Switcher>
              </SwitchersContainer>
              {selectedTab === 0 && (
                <ChartContainer>
                  <ActualChart roundSharePrices={pastRoundSharePrices} />
                </ChartContainer>
              )}
              {selectedTab === 1 && (
                <ChartContainer>
                  <ActualChart roundSharePrices={historicalResult} />
                </ChartContainer>
              )}
              {selectedTab === 2 && (
                <PositionRecords
                  pastPositions={pastPositions}
                  wantToken={vault.wantToken}
                  selectedChainId={selectedChainId}
                />
              )}
            </BackgroundContainer>
            <BackgroundContainer>
              <Subtitle>Vault Description:</Subtitle>
              <Introduction>
                -Vault assets are used to provide liquidity for Uniswap V3
                tradings and earn fees. Additional assets are borrowed from
                Diamond Pool to form token pairs and deployed (through Diamond
                Protocol) onto Uniswap V3 to provide liquidity. The Vault will
                thus earn a leveraged fee APR as long as spot price is within
                the range selected by the Vault Manager.
                <br />
                -Vault Manager exercises his own discretion in choosing the
                trading pairs, range of prices to provide liquidity for and the
                leverages to use.
                <br />
                -The Vault Manager is not able to withdraw assets from the
                vault. The Vault Manager is not able to withdraw assets from
                Diamond Protocol. The Vault Manager is not able to use the vault
                assets other than provide liquidity for Uniswap V3.
                <br />
                -Lock up period is 30 days, investor can withdraw every 30 days.
                Once withdrawal request is submitted, assets will be withdrawn
                in the next withdrawal date at then prevailing asset value.
                <br />
              </Introduction>
            </BackgroundContainer>
            <BackgroundContainer>
              <Subtitle>Risks:</Subtitle>
              <Introduction>
                -Vault Manager exercises his own discretion in deploying and
                designing the strategy of the vault. There might be losses in
                the liquidity positions deployed using the vault assets from
                time to time. Investor of the vault might lose some or all of
                their assets invested in the vault, Diamond Protocol cannot be
                held responsible for any potential losses incurred by investors
                of the vault.
                <br />
                -Assets are borrowed from Diamond Pool, during extreme market
                fluctuations, Diamond Protocol might liquidate the liquidity
                positions of the Vault to protect the assets borrowed from
                Diamond Pool. Vault investors might incur losses in such
                occasions.
                <br />
                -Vault Manager's past performance cannot be used as indicator of
                future performance. Investors must execute his/her own
                discretion in investing in this automated vault. Diamond
                Protocol cannot be held responsible for any investor losses from
                this vault.
                <br />
                -Diamond Protocol is a new DeFi protocol, there are inherent
                risks involved with using this protocol and platform. For full
                disclosure of risks, please visit{" "}
                <Link
                  href="https://dmo-protocol.gitbook.io/diamond/vault"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </Link>{" "}
                for more details.
                <br />
              </Introduction>
            </BackgroundContainer>
          </LeftContainer>
          <RightContainer>
            <ActionCol
              updateAccountFarmStatus={updateUserAutoVaultState}
              withdrawableAmount={withdrawableAmount}
              updateAutoVaultState={updateAutoVaultState}
              vaultState={vaultState}
              vault={vault}
              rolloverTime={rolloverTime}
              currentRoundStartTimestamp={currentRoundStartTimestamp}
              // updateUserRecords={updateUserRecords}
            />
          </RightContainer>
        </RowContainer>
      </MainContainer>
    </Container>
  );
};

export default EvieVault;
const Container = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  overflow: hidden;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  flex: 1 1 400px;
  @media (max-width: 992px) {
    flex: initial;
    padding: 0;
    background-color: transparent;
  }
  @media (max-width: 768px) {
    flex: initial;
  }
`;

const RightContainer = styled.div`
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 992px) {
    flex: initial;
    display: none;
  }
`;

const Title = styled(H1)`
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 28px;
  text-align: left;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  @media (max-width: 576px) {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 20px;
  }
`;

const VaultTitle = styled(H2)`
  font-size: 24px;
  line-height: 36px;
  text-align: left;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

const DescriptionText = styled(H4)`
  font-weight: bold;
  color: ${Colors.ivory};
  margin-top: 8px;
  font-size: 14px;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 22px;
  }
`;

const BackgroundContainer = styled.div`
  margin-top: 20px;
  background-color: ${Colors.backgroundGray2};
  padding: 24px;
  border-radius: 8px;
`;

const TextContainer = styled.div`
  flex: 1 1 200px;
  @media (max-width: 768px) {
    flex: inherit;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;
  @media (max-width: 576px) {
    display: block;
    gap: 0px;
    background-color: ${Colors.backgroundGray4};
    padding: 12px 0;
    border-radius: 4px;
  }
`;

const DetailCol = styled.div`
  flex: 1 0 160px;
  border-radius: 4px;
  padding: 12px 20px;
  background-color: ${Colors.backgroundGray4};
  @media (max-width: 576px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 0px;
    padding: 2px 12px;
    background-color: transparent;
  }
`;

const Label = styled(SmallRegular)`
  display: block;
  color: ${Colors.ivory};
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 20px;
  @media (max-width: 576px) {
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 22px;
  }
`;

const Value = styled(SmallRegular)`
  display: block;
  color: ${Colors.ivory};
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 22px;
  }
`;

const Subtitle = styled(VaultTitle)`
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 24px;
`;

const Introduction = styled(SmallRegular)`
  display: block;
  color: ${Colors.ivory};
  font-size: 14px;
  line-height: 22px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ParamRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  @media (max-width: 768px) {
    margin-top: 4px;
  }
`;

const ParamLabel = styled(SmallRegular)`
  flex: 0 0 200px;
  display: block;
  color: ${Colors.ivory};
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  @media (max-width: 768px) {
    flex: 1;
    font-size: 12px;
    line-height: 22px;
  }
`;

const ParamValue = styled(ParamLabel)`
  flex: 1;
  font-weight: bold;
  text-align: right;
`;

const ParamContainer = styled.div`
  max-width: 400px;
  @media (max-width: 576px) {
    max-width: none;
  }
`;

const MobileActionContainer = styled.div`
  display: none;
  @media (max-width: 992px) {
    display: block;
    margin-top: 20px;
  }
`;

const HintIcon = styled(FontAwesomeIcon)`
  display: inline;
  color: ${Colors.ivory};
  font-size: 12px;
  margin: auto 2px auto 6px;
  opacity: 0.8;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
  @media (max-width: 576px) {
    margin: auto 2px auto 6px;
    font-size: 11px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const ChartContainer = styled.div`
  width: 99%;
  margin: 12px auto auto auto;
  padding: 32px 20px;
  position: relative;
  background-color: ${Colors.backgroundGray4};
  border-radius: 4px;
  position: relative;
`;

const Switcher = styled.button<{ isSelect: boolean }>`
  font-size: 15px;
  line-height: 20px;
  font-weight: ${(props) => (props.isSelect ? "bold" : "normal")};
  padding: 4px 12px;
  border-radius: 100px;
  color: ${(props) => (props.isSelect ? Colors.backgroundGray1 : Colors.gray5)};
  border: 1px solid
    ${(props) => (props.isSelect ? Colors.lightKhaki : Colors.gray5)};
  background-color: ${(props) =>
    props.isSelect ? Colors.lightKhaki : Colors.backgroundGray4};
  display: inline-block;
  @media (max-width: 576px) {
    font-size: 14px;
  }
  :active {
    transform: scale(0.99);
  }
`;

const SwitchersContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const NoRecord = styled.span`
  display: inline-block;
  padding: 4px 20px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGray4};
  margin: auto;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.ivory};
`;

const Link = styled.a`
  color: ${Colors.lightKhaki};
`;
