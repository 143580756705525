import { useWeb3React } from "@web3-react/core";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";

const useJazziIcon = () => {
  const { account } = useWeb3React();

  return account ? (
    <Jazzicon seed={jsNumberForAddress(account)} diameter={60} />
  ) : null;
};

export default useJazziIcon;
