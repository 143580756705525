import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import LabelWithHint from "../../components/LabelWithHint";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useFactoryPairs from "../../hooks/useFactoryPairs";
import Colors from "../../styles/Colors";
import { H1, H4 } from "../../styles/Fonts";
import {
  CHAIN_IDS_TO_URL_IDS,
  CHAIN_URL_IDS_TO_IDS,
  isSupportedChain,
  SupportedChainId,
} from "../../utils/constant/chains";
import { networkOptions } from "../../utils/constant/networks";
import t from "../../utils/content";
import FactoryRow from "./FactoryRow";
import { updateSelectedChainId } from "../../redux/accountSlice";

const FactoriesPage = () => {
  const params = useParams();
  const { pairId, chainName } = params;
  const navigate = useNavigate();
  const { account, chainId } = useWeb3React();
  const pairs = useFactoryPairs();
  const { search } = useLocation();

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const dispatch = useAppDispatch();

  const navigateToDefaultFactories = useCallback(() => {
    const defaultChainId = SupportedChainId.ARBITRUM_ONE;
    const defaultChainName = CHAIN_IDS_TO_URL_IDS[defaultChainId];
    navigate(`/factory/${defaultChainName}` + search);
  }, [navigate, search]);

  useEffect(() => {
    const urlChainId = chainName ? CHAIN_URL_IDS_TO_IDS[chainName] : 0;

    // 有連接錢包，直接導到 factory 頁面
    if (account) {
      // 有連接錢包，但是指定的 network 與錢包連接的 network 不同，且指定的 network 是支援的 network，則導向指定的 network factory 頁面
      if (chainId && urlChainId !== chainId && isSupportedChain(chainId)) {
        const matchedChainName = CHAIN_IDS_TO_URL_IDS[chainId];
        navigate(`/factory/${matchedChainName}` + search);
      }
    } else {
      // 沒有連接錢包，但有指定 network，且是支援的 network，則更新 selectedChainId
      if (
        !!urlChainId &&
        isSupportedChain(urlChainId) &&
        urlChainId !== selectedChainId
      ) {
        dispatch(updateSelectedChainId({ chainId: urlChainId }));
        // 沒有連接錢包，且沒有指定 network，則導向預設的 network
      } else if (!urlChainId || !isSupportedChain(urlChainId)) {
        navigateToDefaultFactories();
      }
    }
  }, [
    dispatch,
    account,
    chainId,
    chainName,
    pairId,
    navigate,
    navigateToDefaultFactories,
    selectedChainId,
    search,
  ]);

  return (
    <Container>
      {pairs && (
        <MainContainer>
          <TitleRow>
            <NetworkIcon src={networkOptions[selectedChainId].icon} />
            <Title>{t.factory.allFactories}</Title>
          </TitleRow>
          <div>
            <HeaderRow>
              <LabelWithHint
                labelComponent={<HeadText>{t.factory.pair}</HeadText>}
                hintText=""
              />
              <LabelWithHint
                labelComponent={<HeadText>{t.factory.feeApr}</HeadText>}
                hintText={selectedChainId === 56? t.factory.uniFeeAprDescBnB: t.factory.uniFeeAprDesc}
              />
              <LabelWithHint
                labelComponent={<HeadText>{t.factory.tvl}</HeadText>}
                hintText={t.factory.tvlDesc}
              />
              <LabelWithHint
                labelComponent={<HeadText>{t.factory.uniVolume}</HeadText>}
                hintText={t.factory.uniVolumeDesc}
              />
            </HeaderRow>
            <FactoryItemsContainer>
              {pairs.map((pair, i) => (
                <FactoryRow pair={pair} key={i}/>
              ))}
            </FactoryItemsContainer>
          </div>
        </MainContainer>
      )}
    </Container>
  );
};

export default FactoriesPage;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  padding: 24px 24px 60px 24px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    gap: 20px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 0 12px 0;
`;

const NetworkIcon = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 20px;
  @media (max-width: 576px) {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }
`;

const Title = styled(H1)`
  font-size: 36px;
  line-height: 44px;
  text-align: left;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  @media (max-width: 576px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

const HeadText = styled(H4)`
  display: block;
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
    white-space: nowrap;
  }
`;

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(3, 150px);
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const HeaderRow = styled(RowContainer)`
  padding: 12px 24px;
  @media (max-width: 992px) {
    display: none;
  }
`;

const FactoryItemsContainer = styled.div`
  > div:not(:first-child) {
    margin-top: 16px;
  }
`;
