import usdc from "../../images/usdc.png";
import eth from "../../images/eth-token.png";
import dai from "../../images/dai.png";
import uni from "../../images/uni.png";
import gmx from "../../images/gmx.png";
import matic from "../../images/matic.png";
import derc20 from "../../images/derc20.png";
import defaultToken from "../../images/defaultToken.png";
import arb from "../../images/arb.png";
import usdt from "../../images/usdt.png";
import wbnb from "../../images/wbnb.png";
import cake from "../../images/cake.png";
import busd from "../../images/busd.png";
import bnb from "../../images/bnb.png";
import ldo from "../../images/ldo.png";

export const uniToken = {
  tokenSymbol: "UNI",
  tokenFullName: "UNI",
  tokenDecimal: 18,
  tokenIcon: uni,
};

export const wethToken = {
  tokenSymbol: "WETH",
  tokenFullName: "WETH",
  tokenDecimal: 18,
  tokenIcon: eth,
};

export const usdcToken = {
  tokenSymbol: "USDC",
  tokenFullName: "USDC",
  tokenDecimal: 6,
  tokenIcon: usdc,
};

export const daiToken = {
  tokenSymbol: "DAI",
  tokenFullName: "DAI",
  tokenDecimal: 18,
  tokenIcon: dai,
};

export const gmxToken = {
  tokenSymbol: "GMX",
  tokenFullName: "GMX",
  tokenDecimal: 18,
  tokenIcon: gmx,
};

export const maticToken = {
  tokenSymbol: "WMATIC",
  tokenFullName: "WMATIC",
  tokenDecimal: 18,
  tokenIcon: matic,
};

export const derc20Token = {
  tokenSymbol: "DERC20",
  tokenFullName: "DERC20",
  tokenDecimal: 18,
  tokenIcon: derc20,
};

export const dusdcoken = {
  tokenSymbol: "DUSDC",
  tokenFullName: "DUSDC",
  tokenDecimal: 6,
  tokenIcon: defaultToken,
};

export const arbToken = {
  tokenSymbol: "ARB",
  tokenFullName: "ARB",
  tokenDecimal: 18,
  tokenIcon: arb,
};

export const usdtToken = {
  tokenSymbol: "USDT",
  tokenFullName: "USDT",
  tokenDecimal: 18,
  tokenIcon: usdt,
};

export const wbnbToken = {
  tokenSymbol: "WBNB",
  tokenFullName: "WBNB",
  tokenDecimal: 18,
  tokenIcon: wbnb,
};

export const cake2Token = {
  tokenSymbol: "CAKE2",
  tokenFullName: "CAKE2",
  tokenDecimal: 18,
  tokenIcon: cake,
};

export const busdToken = {
  tokenSymbol: "BUSD",
  tokenFullName: "BUSD",
  tokenDecimal: 18,
  tokenIcon: busd,
};

export const cakeToken = {
  tokenSymbol: "CAKE",
  tokenFullName: "CAKE",
  tokenDecimal: 18,
  tokenIcon: cake,
};

export const ldoToken = {
  tokenSymbol: "LDO",
  tokenFullName: "LDO",
  tokenDecimal: 18,
  tokenIcon: ldo,
}

