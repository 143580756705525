import React, { useState } from "react";
import styled from "styled-components";
import LabelWithHint from "../../../components/LabelWithHint";
import { trimAmountInput } from "../../../utils/common";
import { PairProps } from "../../../utils/constant/pairs";
import t from "../../../utils/content";
import { Input, InputContainer, Label, Unit } from "./CommonParts";

type Props = {
  minAmount: string;
  maxAmount: string;
  pair: PairProps;
  investAmount: number;
  setInvestAmount: (v: number) => void;
};

const Amount: React.FC<Props> = (props) => {
  const {
    minAmount,
    maxAmount,
    pair: {
      wantToken: { tokenSymbol: wTokenSymbol },
    },
    investAmount,
    setInvestAmount,
  } = props;

  const [isFocus, setIsFocus] = useState(false);

  return (
    <Container>
      <LabelWithHint
        labelComponent={<Label>{t.factory.amount}</Label>}
        hintText={<span>{`${t.factory.amountDesc} ${wTokenSymbol}.`}</span>}
      />
      <InputContainer $isFocus={isFocus}>
        <Input
          type="number"
          placeholder={
            minAmount && maxAmount && `${minAmount} ~ ${maxAmount}`
          }
          onChange={(e) => {
            setInvestAmount(+trimAmountInput(e.currentTarget.value));
          }}
          onWheel={(e) => e.currentTarget.blur()}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        />
        <Unit>{wTokenSymbol}</Unit>
      </InputContainer>
    </Container>
  );
};

export default Amount;

const Container = styled.div`
  margin-top: 12px;
`;
