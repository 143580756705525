import React from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import Colors from "../styles/Colors";
import { openNewTab } from "../utils/common";
import { useNavigate } from "react-router-dom";
import lifi from "../images/lifi.png";
import t from "../utils/content";
import { SupportedChainId } from "../utils/constant/chains";
import { networkOptions } from "../utils/constant/networks";
import { ARBITRUM_BRIDGE, POLYGON_BRIDGE } from "../utils/constant/urls";
import useTrackEvent from "../hooks/useTrackEvent";

const bridges: { [key: number]: string } = {
  [SupportedChainId.ARBITRUM_ONE]: ARBITRUM_BRIDGE,
  [SupportedChainId.GOERLI]: "",
  [SupportedChainId.POLYGON]: POLYGON_BRIDGE,
  [SupportedChainId.OPTIMISM_GOERLI]: "",
  [SupportedChainId.POLYGON_MUMBAI]: "",
};

const SelectBridgeModal: React.FC<{
  targetTokenAddress: string;
  deployedChainId: number;
  show: boolean;
  onHide: () => void;
  tokenSymbol: string;
}> = ({ targetTokenAddress, deployedChainId, show, onHide, tokenSymbol }) => {
  const navigate = useNavigate();
  const trackEvent = useTrackEvent();

  const handleOfficialBridgeOnClick = () => {
    onHide();
    openNewTab(bridges[deployedChainId]);

    trackEvent(`${t.amp.clickOfficialBridgeBtn}`, {
      token: tokenSymbol,
      tokenAddress: targetTokenAddress,
    });
  };

  const handleLiFiBridgeOnClick = () => {
    onHide();
    navigate(
      `/swap?chainId=${deployedChainId}&tokenAddress=${targetTokenAddress}`
    );

    trackEvent(`${t.amp.clickLiFiBridgeBtn}`, {
      token: tokenSymbol,
      tokenAddress: targetTokenAddress,
    });
  };

  return (
    <WalletModal show={show} onHide={onHide}>
      <ModalHeader closeButton closeVariant="white">
        <ModalTitle>{t.common.bridgeOptions}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <MethodContainer>
          <WalletBtnContainer onClick={handleOfficialBridgeOnClick}>
            <LeftContainer>
              <WalletBtnText>{t.common.officialBridge}</WalletBtnText>
            </LeftContainer>
            <WalletBtnIcon src={networkOptions[deployedChainId].icon} />
          </WalletBtnContainer>
          <WalletBtnContainer onClick={handleLiFiBridgeOnClick}>
            <LeftContainer>
              <WalletBtnText>{t.common.lifi}</WalletBtnText>
            </LeftContainer>
            <WalletBtnIcon src={lifi} />
          </WalletBtnContainer>
        </MethodContainer>
      </ModalBody>
    </WalletModal>
  );
};

export default SelectBridgeModal;

const WalletModal = styled(Modal)`
  .modal-content {
    margin: 20vh auto auto auto;
    width: 90%;
    max-width: 350px;
    border-radius: 20px;
    background-color: ${Colors.backgroundGray1};
    color: ${Colors.ivory};
    padding: 20px 12px 0px 12px;
  }
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.3);
`;

const ModalHeader = styled(Modal.Header)`
  border-width: 0;
  color: ${Colors.ivory};
`;

const ModalTitle = styled(Modal.Title)`
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
`;

const ModalBody = styled(Modal.Body)``;

const MethodContainer = styled.div`
  margin-bottom: 30px;
`;

const WalletBtnContainer = styled.div`
  padding: 16px 18px;
  border: 1px solid ${Colors.midNightGreen};
  border-radius: 10px;
  border: 1px solid ${Colors.ivory};
  color: ${Colors.ivory};
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  :hover {
    border: 1px solid ${Colors.lightKhaki};
    color: ${Colors.lightKhaki};
  }
`;

const WalletBtnText = styled.span`
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const WalletBtnIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;
