import { ethers } from "ethers";
import rewardVestingAbi from "../../abi/rewardVesting.json";
import { ClaimableRewardParams } from "../../hooks/useUserAirdropRewards";
import { ALL_AIRDROP } from "../constant/internalAddresses";
import t from "../content";
import { showErrorAlert, showPendingAlert } from "../showAlert";

/**
 * Claim airdrop rewards
 * @param userAddress user address
 * @param chainId chain id
 * @param provider provider
 * @param claimParams claim weeks
 * @returns transaction result
 */
export const claimAirdropRewards = async (
  userAddress: string,
  chainId: number,
  provider: any,
  claimParams: ClaimableRewardParams
) => {
  let res;

  const signer = provider.getSigner(userAddress);
  const rewardVesting = new ethers.Contract(
    ALL_AIRDROP[chainId],
    rewardVestingAbi,
    signer
  );

  try {
    res = await rewardVesting.claimWeeks(userAddress, claimParams);
    await showPendingAlert(res.hash, t.succeed.claimRewardSucceed, provider);
  } catch (error: any) {
    console.error("error from airdrop api", error);
    if (error.code === "ACTION_REJECTED") {
      showErrorAlert(t.error.deniedTransaction);
    } else if (error.message.includes("Invalid claim")) {
      showErrorAlert(t.error.invalidClaim);
    } else {
      showErrorAlert(t.error.claimRewardFailed);
    }
  }

  return res;
};