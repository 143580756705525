const networks: Record<string, string> = {
  goerli: "Goerli",
  arbitrum: "Arbitrum",
  mainnet: "Mainnet",
  polygon: "Polygon",
  ethereum: "Ethereum",
  arbitrumOne: "Arbitrum One",
  optimism: "Optimism",
  optimismGoerli: "Optimism Goerli",
  polygonMumbai: "Polygon Mumbai",
  binanceSmartChain: "BNB Chain",
  avalanche: "Avalanche",
  fantomOpera: "Fantom Opera",
  celo: "Celo",
  binanceTestnet: "BNB Testnet",
};

export default networks;
