import React from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { SmallRegular } from "../../../../styles/Fonts";
import PoolBalanceItem from "./PoolBalanceItem";
import useLendingPools from "../../../../hooks/useLendingPool";
import t from "../../../../utils/content";

const PoolBalances: React.FC = () => {
  const pools = useLendingPools();

  return (
    <Container>
      {pools && (
        <>
          <PortfolioHeaderRow>
            <HeaderText>{t.pool.asset}</HeaderText>
            <HeaderText>{t.pool.roi}</HeaderText>
            <HeaderText>{t.pool.balance}</HeaderText>
            <HeaderText>{t.pool.earned}</HeaderText>
          </PortfolioHeaderRow>
          <PoolBalancesContainer>
            {pools.map((pool) => (
              <PoolBalanceItem pool={pool} key={pool.lendingPoolAddress} />
            ))}
          </PoolBalancesContainer>
        </>
      )}
    </Container>
  );
};

export default PoolBalances;

const Container = styled.div``;

const PortfolioRow = styled.div`
  display: grid;
  grid-template-columns: 100px 0.5fr 1fr 1fr 40px;
  align-items: center;
`;

const PortfolioHeaderRow = styled(PortfolioRow)`
  padding: 0 24px;
  margin: 12px 0 20px 0;

  > span:not(:first-child) {
    text-align: right;
  }

  @media (max-width: 768px) {
    padding: 20px;
    display: none;
  }
`;

const HeaderText = styled(SmallRegular)`
  color: ${Colors.gray4};
`;

const PoolBalancesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
