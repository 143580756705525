import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import Button from "../../../components/Button";
import { H4 } from "../../../styles/Fonts";
import {
  getAccountNativeTokenBalance,
  wrapToken,
} from "../../../utils/api/lendingPoolApi";
import CustomModal from "../../../components/Modal";
import { getTruncNum } from "../../../utils/common";
import { useWeb3React } from "@web3-react/core";
import AmountInput from "../../../components/AmountInput";
import t from "../../../utils/content";
import { isSupportedChain } from "../../../utils/constant/chains";
import useTrackEvent from "../../../hooks/useTrackEvent";

const WrapEthModal: React.FC<{
  isShowModal: boolean;
  onHideModal: () => void;
  setIsWrapping: (v: boolean) => void;
  tokenBalance: string;
  updateTokenBalance: () => void;
  tokenAddress: string;
  tokenSymbol: string;
}> = (props) => {
  const {
    isShowModal,
    onHideModal,
    setIsWrapping,
    tokenBalance,
    updateTokenBalance,
    tokenAddress,
    tokenSymbol,
  } = props;

  const [ethAmount, setEthAmount] = useState<string>("0");
  const [ethBalance, setEthBalance] = useState<number>(0);

  const { account, chainId, provider } = useWeb3React();
  const trackEvent = useTrackEvent();

  const getAccountBalance = useCallback(
    async (_address: string) => {
      if (!chainId) {
        return;
      }
      const _ethBalance = await getAccountNativeTokenBalance(
        _address,
        chainId,
        provider
      );
      setEthBalance(_ethBalance);
    },
    [chainId, provider]
  );

  useEffect(() => {
    if (account && isSupportedChain(chainId)) {
      getAccountBalance(account);
    }
  }, [account, chainId, getAccountBalance]);

  const handleWrapEth = async (address: string, amount: string) => {
    if (account && isSupportedChain(chainId) && provider) {
      onHideModal();
      setIsWrapping(true);

      trackEvent(`${t.amp.clickWrapTokenBtn} | ${t.amp.poolPage}`, {
        amount: amount,
        token: tokenSymbol,
      });

      try {
        await wrapToken(
          address,
          tokenAddress,
          tokenSymbol,
          amount,
          chainId,
          provider,
          (txHash: string) => {
            trackEvent(`${t.amp.wrapToken} | ${t.amp.poolPage}`, {
              txHash: txHash,
              amount: amount,
              token: tokenSymbol,
            });
          }
        );
        getAccountBalance(address);
        updateTokenBalance();
      } catch (e) {
        // console.log(e);
      }

      setIsWrapping(false);
      setEthAmount("0");
    }
  };

  return (
    <CustomModal
      show={isShowModal}
      onHide={onHideModal}
      title={`${t.pool.wrapYour} ${tokenSymbol.substring(1)} ${
        t.pool.to
      } ${tokenSymbol}`}
    >
      <AmountInput
        setAmount={setEthAmount}
        tokenSymbol={tokenSymbol.substring(1)}
        tokenDecimal={18}
        showMaxBtn={false}
      />
      <InfoContainer>
        <BalanceRow>
          <Label>{t.pool.walletBalance}</Label>
        </BalanceRow>
        <BalanceRow>
          <Label>{tokenSymbol.substring(1)}</Label>
          <Value>{`${getTruncNum(+ethBalance, 18)} ${tokenSymbol.substring(
            1
          )}`}</Value>
        </BalanceRow>
        <BalanceRow>
          <Label>{tokenSymbol}</Label>
          <Value>{`${getTruncNum(+tokenBalance, 18)} ${tokenSymbol}`}</Value>
        </BalanceRow>
      </InfoContainer>
      <BtnContainer>
        <Button
          btnType="primary"
          btnText={t.pool.wrap}
          onClick={() => (account ? handleWrapEth(account, ethAmount) : null)}
          disabled={!ethAmount}
          isLoading={false}
        />
      </BtnContainer>
    </CustomModal>
  );
};

export default WrapEthModal;

const InfoContainer = styled.div`
  margin-top: 20px;
`;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const Value = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  color: ${Colors.ivory};
  text-align: right;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12px;
`;

const BtnContainer = styled.div`
  margin-top: 20px;
  button {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;
