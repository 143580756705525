import account from "./account";
import amplitude from "./amplitude";
import common from "./common";
import errorMsg from "./errorMsg";
import factory from "./factory";
import landing from "./landing";
import leaderboard from "./leaderboard";
import networks from "./networks";
import pool from "./pool";
import referral from "./referral";
import stats from "./stats";
import succeedMsg from "./succeedMsg";

const t = {
  common,
  networks,
  factory,
  error: errorMsg,
  leaderboard,
  pool,
  stats,
  succeed: succeedMsg,
  amp: amplitude,
  referral,
  landing,
  account,
};

export default t;