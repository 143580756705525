import React, { Fragment, useMemo } from "react";
import styled from "styled-components";
import PairTokenIcon from "../../components/PairTokenIcon";
import Colors from "../../styles/Colors";
import { H4 } from "../../styles/Fonts";
import { getTruncNum } from "../../utils/common";
import { AMOUNT_DECIMAL } from "../../utils/constant/decimals";
import t from "../../utils/content";
import NumberWithHint from "../../components/NumberWithHint";
import { formatUnits } from "ethers/lib/utils";

const ClosedPosition: React.FC<{ pos: { [key: string]: any } }> = ({ pos }) => {
  const {
    CloseEvent: eventName,
    AmountAfterRepaid: closeAmount,
    WantAmount: startAmount,
    PositionId,
    pair,
  } = pos;

  const {
    WantTokenFee: wantTokenFee,
    BorrowTokenFee: borrowTokenFee,
    MeasuredBorrowTokenFeeInWant: borrowTokenFeeInWant,
    RewardTokenValueInWant: rewardAmountInWant,
    UserReward: rewardAmount,
  } = pos.PositionInfo;

  const {
    wantToken: {
      tokenDecimal: wantTokenDecimal,
      tokenSymbol: wantTokenSymbol,
      tokenIcon: wantTokenIcon,
    },
    borrowToken: {
      tokenDecimal: borrowTokenDecimal,
      tokenSymbol: borrowTokenSymbol,
      tokenIcon: borrowTokenIcon,
    },
    label: pairLabel,
  } = pair;

  const earnedFeeInWant = useMemo(() => {
    let sum = 0;
    if (wantTokenDecimal) {
      const wantFee = Math.trunc(wantTokenFee) / 10 ** wantTokenDecimal;
      const borrowFee =
        Math.trunc(+borrowTokenFeeInWant) / 10 ** wantTokenDecimal;
      sum = +wantFee + +borrowFee;
    }
    return sum;
  }, [borrowTokenFeeInWant, wantTokenFee, wantTokenDecimal]);

  return (
    <ClosePositionRow key={pos.positionId}>
      <PositionDetail>
        <PositionHeaderM>{t.stats.pool}</PositionHeaderM>
        <Row>
          {borrowTokenIcon && wantTokenIcon && (
            <PairTokenIcon
              borrowTokenIcon={borrowTokenIcon}
              wantTokenIcon={wantTokenIcon}
            />
          )}
          <PositionValue>{pairLabel}</PositionValue>
        </Row>
      </PositionDetail>
      <PositionDetail>
        <PositionHeaderM>{t.stats.positionId}</PositionHeaderM>
        <PositionValue>{PositionId || "-"}</PositionValue>
      </PositionDetail>
      <PositionDetail>
        <PositionHeaderM>{t.stats.initialInvestmentAmount}</PositionHeaderM>
        <PositionValue>
          {`${getTruncNum(
            +startAmount / 10 ** wantTokenDecimal,
            AMOUNT_DECIMAL
          )} ${wantTokenSymbol}` || "-"}
        </PositionValue>
      </PositionDetail>
      <PositionDetail>
        <PositionHeaderM>{t.stats.endingAmount}</PositionHeaderM>
        <NumberWithHint
          labelComponent={
            <PositionValue>
              {`${getTruncNum(
                +formatUnits(closeAmount, wantTokenDecimal) +
                +formatUnits(rewardAmountInWant || "0", wantTokenDecimal),
                AMOUNT_DECIMAL
              )} ${wantTokenSymbol}` || "-"}
            </PositionValue>
          }
          hints={
            !!rewardAmount
              ? [
                  `• LP PnL: ${getTruncNum(
                    +formatUnits(closeAmount, wantTokenDecimal),
                    AMOUNT_DECIMAL
                  )} ${wantTokenSymbol}`,
                  `• CAKE Reward: ${getTruncNum(
                    rewardAmount ? +formatUnits(rewardAmount || "0", 18) : 0,
                    AMOUNT_DECIMAL
                  )} CAKE`,
                  `(≈ ${getTruncNum(
                    rewardAmountInWant
                      ? +formatUnits(
                          rewardAmountInWant || "0",
                          wantTokenDecimal
                        )
                      : 0,
                    AMOUNT_DECIMAL
                  )} ${wantTokenSymbol})`,
                ]
              : []
          }
        />
      </PositionDetail>
      <PositionDetail>
        <PositionHeaderM>{t.stats.earnedFee}</PositionHeaderM>
        <FeeContainer>
          {wantTokenDecimal && borrowTokenDecimal ? (
            <Fragment>
              <PositionValueSmall>
                {`${getTruncNum(
                  earnedFeeInWant,
                  AMOUNT_DECIMAL
                )} ${wantTokenSymbol}`}
              </PositionValueSmall>
              <PositionValueTiny>
                {`${getTruncNum(
                  wantTokenFee / 10 ** wantTokenDecimal,
                  AMOUNT_DECIMAL
                )} ${wantTokenSymbol}`}
              </PositionValueTiny>
              <PositionValueTiny>
                {`+ ${getTruncNum(
                  borrowTokenFee / 10 ** borrowTokenDecimal,
                  AMOUNT_DECIMAL
                )} ${borrowTokenSymbol}`}
              </PositionValueTiny>
            </Fragment>
          ) : (
            <PositionValueSmall>{"-"}</PositionValueSmall>
          )}
        </FeeContainer>
      </PositionDetail>
      <PositionDetail>
        <PositionHeaderM>{t.stats.type}</PositionHeaderM>
        <PositionValue>{eventName}</PositionValue>
      </PositionDetail>
    </ClosePositionRow>
  );
};

export default ClosedPosition;

const PositionRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 80px;
  align-items: center;
  background-color: ${Colors.backgroundGray4};
  border-radius: 8px;
  padding: 20px;
  margin-top: 12px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  div:not(:first-child) {
    justify-content: flex-end;
    text-align: right;
    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }
  h4:not(:first-child) {
    text-align: right;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const PositionHeader = styled(H4)`
  color: ${Colors.gray4};
  font-size: 14px;
  line-height: 22px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const PositionValue = styled(H4)`
  color: ${Colors.gray1};
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const PositionValueSmall = styled(H4)`
  color: ${Colors.gray1};
  font-size: 14px;
  line-height: 22px;
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const ClosePositionRow = styled(PositionRow)`
  grid-template-columns: 0.8fr 0.6fr repeat(3, 1fr) 0.8fr;
  gap: 12px;
`;

const PositionDetail = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
    padding: 4px 0;
  }
`;

const PositionHeaderM = styled(PositionHeader)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const FeeContainer = styled.div`
  @media (max-width: 768px) {
    text-align: right;
  }
`;

const PositionValueTiny = styled(H4)`
  color: ${Colors.gray4};
  font-size: 12px;
  line-height: 16px;
`;
