const succeedMsg = {
  approvedToUseVaultAsset: "Approved factory to use asset in your vault.",
  openPositionSucceed: "Open Position Succeed",
  addCollateralSucceed: "Add Collateral Succeed",
  decreaseCollateralSucceed: "Decrease Collateral Succeed",
  collectFeeSucceed: "Collect Fee Succeed",
  updateExitPricesSucceed: "Update Exit Prices Succeed",
  permissionGranted: "Permission Granted",
  deposit: "Deposit",
  succeed: "Succeed",
  withdraw: "Withdraw",
  wrapEthSucceed: "Wrap ETH to WETH Succeed",
  unwrapWethSucceed: "Unwrap WETH to ETH Succeed",
  approveTokenSucceed: "Approve Token Succeed",
  depositSucceed: "Deposit Succeed",
  withdrawSucceed: "Withdraw Succeed",
  approve: "Approve",
  registerDepositSucceed: "Register Deposit Succeed",
  registerWithdraw: "Register Withdraw",
  closePositionSucceed: "Close Position Succeed",
  createReferralCodeSucceed: "Create Referral Code Succeed",
  setReferralCodeSucceed: "Set Referral Code Succeed",
  claimRewardSucceed: "Claim Rewards Succeed",
  linkTwitterSucceed: "Your twitter account has been linked successfully!",
  stake: "Stake",
  unstake: "Unstake",
  claim: "Claim",
};

export default succeedMsg;
