import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../styles/Colors";
import Header from "./Header";
import { useQuery } from "../hooks";
import SetReferralCodeModal from "./SetReferralCodeModal";
import { showErrorAlert } from "../utils/showAlert";
import useTwitterAccount from "../hooks/useTwitterAccount";
import { getTwitterAccountInfo } from "../utils/api/twitterApi";
import { useWeb3React } from "@web3-react/core";
import t from "../utils/content";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  let query = useQuery();

  const referralCode = query.get("referralCode");

  const twitterAuthCode = query.get("code");
  const twitterAuthError = query.get("error");

  const { account } = useWeb3React();
  const { setTwitterId, twitterId } = useTwitterAccount();

  useEffect(() => {
    if (referralCode) {
      setModalIsOpen(true);
    }
  }, [referralCode]);

  // Get twitter id if twitter auth code is provided
  useEffect(() => {
    const getTwitterId = async () => {
      if (!account || !twitterAuthCode) return;
      const { twitterId: _twitterId } = await getTwitterAccountInfo(
        account,
        twitterAuthCode
      );
      setTwitterId(_twitterId);
    };

    if (account && twitterAuthCode && !twitterId) {
      getTwitterId();
    }
  }, [account, setTwitterId, twitterAuthCode, twitterId]);

  // Show error alert if twitter auth failed
  useEffect(() => {
    if (twitterAuthError) {
      showErrorAlert(t.error.twitterLinkageFailed);
    }
  }, [twitterAuthError]);

  return (
    <Container>
      <Header />
      <MainContainer>{children}</MainContainer>
      {referralCode && (
        <SetReferralCodeModal
          isShowModal={modalIsOpen}
          setShowModal={setModalIsOpen}
          code={referralCode}
        />
      )}
    </Container>
  );
};

export default Layout;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Colors.backgroundGray3};
`;

const MainContainer = styled.div`
  width: 100%;
  padding-top: 100px;
  background-color: ${Colors.backgroundGray3};
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
`;
