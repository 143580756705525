import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { H1, H3, H4 } from "../../../styles/Fonts";
import { getSlicedAddress } from "../../../utils/api/common";
import { useWeb3React } from "@web3-react/core";
import SetReferralCodeModal from "./SetReferralCodeModal";
import Button from "../../../components/Button";
import useReferralIsExisted from "../../../hooks/useReferralIsExisted";
import useJazziIcon from "../../../hooks/useJazziIcon";
import { NFTProps } from "../../../redux/accountSlice";
import Nfts from "./Nfts";
import { getTwitterAuthorizationUrl } from "../../../utils/api/twitterApi";
import Assets from "./Assets";

type Props = {
  referrerCode: string;
  updateReferralCode: () => void;
  nfts: NFTProps[];
  nftIsFetching: boolean;
  twitterUserName: string;
};

const Profile: React.FC<Props> = (props) => {
  const {
    referrerCode,
    updateReferralCode,
    nfts,
    nftIsFetching,
    twitterUserName,
  } = props;

  const { account } = useWeb3React();
  const referralIsExisted = useReferralIsExisted();
  const userIcon = useJazziIcon();

  const [referralModalIsShow, setReferralModalIsShow] =
    useState<boolean>(false);
  const [isSetting, setIsSetting] = useState<boolean>(false);

  const redirectToTwitterAuthPage = async () => {
    if (!account) return;
    const url = await getTwitterAuthorizationUrl(account);
    if (url) {
      window.location.replace(`${url}&redirect_uri=${window.location.origin}`);
    }
  };

  return (
    <Container>
      <div>
        <Title>Profile</Title>
        <RowContainer>
          <LeftContainer>
            <UserIconContainer>{userIcon}</UserIconContainer>
            <AccountAddress>
              {account && getSlicedAddress(account)}
            </AccountAddress>
          </LeftContainer>
          <RightContainer>
            {referralIsExisted && (
              <>
                {referrerCode ? (
                  <DataRow>
                    <Label>Referral Code Entered:</Label>
                    <CodeText> {referrerCode}</CodeText>
                  </DataRow>
                ) : (
                  <Button
                    btnText="Set Referral Code"
                    btnType="primary"
                    onClick={() => setReferralModalIsShow(true)}
                    isLoading={isSetting}
                    disabled={false}
                  />
                )}
              </>
            )}
            {twitterUserName ? (
              <DataRow>
                <Label>Twitter Account Linked:</Label>
                <CodeText> {twitterUserName}</CodeText>
              </DataRow>
            ) : (
              <Button
                btnText="Link Twitter Account"
                btnType="primary"
                onClick={() => {
                  redirectToTwitterAuthPage();
                }}
                isLoading={false}
                disabled={false}
              />
            )}
          </RightContainer>
        </RowContainer>
      </div>
      <div>
        <Title>Assets</Title>
        <Assets />
      </div>
      <div>
        <Title>NFTs</Title>
        <Nfts nfts={nfts} isFetching={nftIsFetching} />
      </div>
      <SetReferralCodeModal
        isShowModal={referralModalIsShow}
        setShowModal={setReferralModalIsShow}
        setIsSetting={setIsSetting}
        updateReferralCode={updateReferralCode}
      />
    </Container>
  );
};

export default Profile;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled(H1)`
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  color: ${Colors.ivory};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }

  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  background: ${Colors.backgroundGray1};
  gap: 20px;
  padding: 24px 32px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 28px;
    padding: 24px 20px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-right: 24px;
`;

const AccountAddress = styled(H3)`
  font-size: 18px;
  line-height: 22px;
  color: ${Colors.ivory};
  margin-right: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

// const EditBtn = styled.button`
//   border-radius: 8px;
//   border: 1px solid ${Colors.gray3};
//   color: ${Colors.gray3};
//   padding: 4px 12px;
// `;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 576px) {
    width: 100%;
    border-top: 0.5px solid ${Colors.gray3};
    padding-top: 24px;
  }
`;

const DataRow = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled(H4)`
  color: ${Colors.ivory};
  font-size: 16px;
  line-height: 24px;
  margin-right: 8px;
  min-width: 180px;

  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
    min-width: 160px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
    min-width: 140px;
  }
`;

const CodeText = styled(Label)`
  min-width: auto;

  padding: 4px 12px;
  border-radius: 8px;
  background: ${Colors.backgroundGray4};
`;
