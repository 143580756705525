import React from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { H4 } from "../../../styles/Fonts";
import LabelWithHint from "../../../components/LabelWithHint";
import MarketItem from "./MarketItem";
import { PoolProps } from "../../../utils/constant/pools";
import t from "../../../utils/content";

const Markets: React.FC<{ pools: PoolProps[] }> = (props) => {
  const { pools } = props;

  return (
    <Container>
      <HeaderRow>
        <LabelWithHint
          labelComponent={<HeadText>{t.pool.assets}</HeadText>}
          hintText=""
        />
        <LabelWithHint
          labelComponent={<HeadText>{t.pool.yieldApy}</HeadText>}
          hintText={t.pool.yieldApyDesc}
        />
        <LabelWithHint
          labelComponent={<HeadText>{t.pool.totalSupply}</HeadText>}
          hintText={t.pool.totalSupplyDesc}
        />
        <LabelWithHint
          labelComponent={<HeadText>{t.pool.totalBorrowed}</HeadText>}
          hintText={t.pool.totalBorrowedDesc}
        />
        <LabelWithHint
          labelComponent={<HeadText>{t.pool.utilization}</HeadText>}
          hintText={t.pool.utilizationDesc}
        />
        <LabelWithHint
          labelComponent={<HeadText>{t.pool.yourSupply}</HeadText>}
          hintText={""}
        />
        <BtnContainer />
      </HeaderRow>
      <MarketItemsContainer>
        {pools.map((pool) => (
          <MarketItem key={pool.lendingPoolAddress} pool={pool} />
        ))}
      </MarketItemsContainer>
    </Container>
  );
};

export default Markets;

const Container = styled.div``;

const HeadText = styled(H4)`
  display: block;
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
    white-space: nowrap;
  }
`;

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr) 130px;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const HeaderRow = styled(RowContainer)`
  padding: 12px 24px;
  @media (max-width: 992px) {
    display: none;
  }
`;

const BtnContainer = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  button {
    font-size: 14px;
    line-height: 18px;
  }
  @media (max-width: 992px) {
    width: 100%;
    margin-top: 12px;
    flex-direction: row;
  }
`;

const MarketItemsContainer = styled.div`
  > div:not(:first-child) {
    margin-top: 16px;
  }
`;
