import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";
import Colors from "../styles/Colors";

const NumberWithHint: React.FC<{
  labelComponent: any;
  hints?: string[];
  hintPlace?: "auto" | "top" | "right" | "left" | "bottom";
}> = ({ labelComponent, hintPlace, hints = [] }) => {
  return hints.length > 0 ? (
    <OverlayTrigger
      placement={hintPlace || "bottom-start"}
      delay={{ show: 0, hide: 100 }}
      overlay={(props: any) => (
        <StyledTooltip multiline={"true"} {...props}>
          {hints.length > 0 &&
            hints.map((hint, i) => <Text key={i}>{`${hint}`}</Text>)}
        </StyledTooltip>
      )}
    >
      {<BorderContainer>{labelComponent}</BorderContainer>}
    </OverlayTrigger>
  ) : (
    labelComponent
  );
};

export default NumberWithHint;

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    font-size: 12px;
    text-align: left;
    background-color: ${Colors.gray7};
    max-width: 240px;
  }
  .tooltip-arrow::before {
    border-bottom-color: ${Colors.gray7};
  }
  @media (max-width: 576px) {
    .tooltip-inner {
      font-size: 12px;
      max-width: 150px;
    }
  }
`;

const Text = styled.span`
  display: block;
  color: ${Colors.gray3};
`;

const BorderContainer = styled.div`
  border-bottom: 1px dashed ${Colors.gray4};
`;
