import { ethers, BigNumber, utils } from "ethers";
import lendingPoolAbi from "../../abi/lendingPool.json";
import wethAbi from "../../abi/weth.json";
import erc20Abi from "../../abi/erc20.json";
import stakingAbi from "../../abi/staking.json";
import { showErrorAlert as showError, showPendingAlert } from "../showAlert";
import { isSupportedChain, SupportedChainId } from "../constant/chains";
import * as Sentry from "@sentry/react";
import getProvider from "../getProvider";
import { formatEther, formatUnits, parseEther } from "ethers/lib/utils";
import { getAllLogsOfAddress, getTokenPriceFromBinance } from "./common";
import {
  getGinzaLatestCachedBlockNumber,
  getUserLendingPoolDepositedRecordFromGinza,
  getUserLendingPoolWithdrawnRecordFromGinza,
} from "./ginzaApi";
import t from "../content";

/**
 * Deposit token into Lending Pool
 * @param accountAddress user address
 * @param lendingPoolAddress lending pool address
 * @param tokenAddress token address
 * @param tokenDecimal token decimal
 * @param tokenSymbol token symbol
 * @param amount deposit amount
 * @param chainId chain id
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 */
export const depositTokenIntoPool = async (
  accountAddress: string,
  lendingPoolAddress: string,
  tokenAddress: string,
  tokenDecimal: number,
  tokenSymbol: string,
  amount: string,
  chainId: number,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  const depositAmount = utils.parseUnits(amount.toString(), tokenDecimal);

  const signer = provider.getSigner(accountAddress);
  const token = new ethers.Contract(tokenAddress, erc20Abi, signer);
  const pool = new ethers.Contract(lendingPoolAddress, lendingPoolAbi, signer);
  const tokenBalance = await token.balanceOf(accountAddress);
  const allowance = await token.allowance(accountAddress, lendingPoolAddress);

  if (tokenBalance.lt(depositAmount)) {
    showError(
      `${t.error.tokenBalanceNotEnough1} ${tokenSymbol} ${t.error.tokenBalanceNotEnough2}`
    );
  } else {
    try {
      if (allowance.lt(depositAmount)) {
        const approveRes = await token.approve(
          lendingPoolAddress,
          depositAmount
        );
        await showPendingAlert(
          approveRes.hash,
          t.succeed.permissionGranted,
          provider
        );
      }

      const res = await pool.deposit(depositAmount);
      trackTxEvent(res.hash);
      await showPendingAlert(
        res.hash,
        `${t.succeed.deposit} ${tokenSymbol} ${t.succeed.succeed}`,
        provider
      );
    } catch (error: any) {
      if (error.message.includes("User denied transaction signature")) {
        showError(t.error.deniedTransaction);
      } else {
        showError(`${t.error.deposit} ${tokenSymbol} ${t.error.failed}`);
        Sentry.captureException(error);
      }
    }
  }
};

/**
 * Withdraw token from Lending Pool
 * @param accountAddress user address
 * @param lendingPoolAddress lending pool address
 * @param tokenDecimal token decimal
 * @param tokenSymbol token symbol
 * @param amount withdraw amount
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 */
export const withdrawTokenFromPool = async (
  accountAddress: string,
  lendingPoolAddress: string,
  tokenDecimal: number,
  tokenSymbol: string,
  amount: string,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  const signer = provider.getSigner(accountAddress);
  const pool = new ethers.Contract(lendingPoolAddress, lendingPoolAbi, signer);
  const exchangeRate = await pool.getEstimatedExchangeRate();
  const withdrawShares = utils
    .parseUnits(amount, tokenDecimal)
    .mul(BigNumber.from(10).pow(18))
    .div(exchangeRate);

  try {
    const res = await pool.withdraw(withdrawShares);
    trackTxEvent(res.hash);
    await showPendingAlert(
      res.hash,
      `${t.succeed.withdraw} ${tokenSymbol} ${t.succeed.succeed}`,
      provider
    );
  } catch (e: any) {
    if (e.message.includes("burn amount exceeds balance")) {
      showError(t.error.balanceNotEnough);
    } else if (e.message.includes("Insufficient Balance")) {
      showError(t.error.lendingPoolNotEnough);
    } else if (e.message.includes("User denied transaction signature")) {
      showError(t.error.deniedTransaction);
    } else {
      showError(`${t.error.withdraw} ${tokenSymbol} ${t.error.failed}`);
      Sentry.captureException(e);
    }
  }
};

/**
 * Get available amount of token in Lending Pool
 * @param lendingPoolAddress lending pool address
 * @param chainId chain id
 * @param tokenDecimal token decimal
 * @param provider provider
 * @returns token amount
 */
export const getPoolCash = async (
  lendingPoolAddress: string,
  chainId: number,
  tokenDecimal: number,
  provider: any
) => {
  let cashAmount = 0;

  if (isSupportedChain(chainId)) {
    try {
      const p = await getProvider(provider, chainId);
      const pool = new ethers.Contract(lendingPoolAddress, lendingPoolAbi, p);
      const cash = await pool.getCash();
      cashAmount = +formatUnits(cash, tokenDecimal);
    } catch (e) {}
  }

  return cashAmount;
};

/**
 * Get total borrow amount of token in Lending Pool
 * @param lendingPoolAddress lending pool address
 * @param chainId chain id
 * @param tokenDecimal token decimal
 * @param provider provider
 * @returns token amount
 */
export const getTotalBorrowAmount = async (
  lendingPoolAddress: string,
  chainId: number,
  tokenDecimal: number,
  provider: any
) => {
  let borrowAmountInEth = 0;

  if (isSupportedChain(chainId)) {
    try {
      const p = await getProvider(provider, chainId);
      const pool = new ethers.Contract(lendingPoolAddress, lendingPoolAbi, p);
      const borrowAmount = await pool.totalBorrows();
      borrowAmountInEth = +formatUnits(borrowAmount, tokenDecimal);
    } catch (e) {}
  }

  return borrowAmountInEth;
};

/**
 * Get total supply amount of token in Lending Pool
 * @param lendingPoolAddress lending pool address
 * @param chainId chain id
 * @param tokenDecimal token decimal
 * @param provider provider
 * @returns token amount
 */
export const getTotalSupplyAmount = async (
  lendingPoolAddress: string,
  chainId: number,
  tokenDecimal: number,
  provider: any
) => {
  let amountInEth = 0;

  if (isSupportedChain(chainId)) {
    try {
      const p = await getProvider(provider, chainId);
      const pool = new ethers.Contract(lendingPoolAddress, lendingPoolAbi, p);
      const amount = await pool.totalSupply();
      amountInEth = +formatUnits(amount, tokenDecimal);
    } catch (e) {}
  }

  return amountInEth;
};

/**
 * Get exchange rate of token in Lending Pool
 * @param lendingPoolAddress lending pool address
 * @param chainId chain id
 * @param provider provider
 * @returns exchange rate
 */
export const getExchangeRate = async (
  lendingPoolAddress: string,
  chainId: number,
  provider: any
) => {
  let exchangeRate = 0;

  if (isSupportedChain(chainId)) {
    try {
      const p = await getProvider(provider, chainId);
      const pool = new ethers.Contract(lendingPoolAddress, lendingPoolAbi, p);
      const num = await pool.getEstimatedExchangeRate();
      exchangeRate = +formatEther(num);
    } catch (e) {}
  }

  return exchangeRate;
};

/**
 * Get user's share of token in Lending Pool
 * @param accountAddress user address
 * @param lendingPoolAddress lending pool address
 * @param tokenDecimal token decimal
 * @param provider provider
 * @returns shares amount
 */
export const getUserPoolShares = async (
  accountAddress: string,
  lendingPoolAddress: string,
  tokenDecimal: number,
  provider: any
) => {
  let shares = "0";

  try {
    const pool = new ethers.Contract(
      lendingPoolAddress,
      lendingPoolAbi,
      provider
    );
    const num = await pool.balanceOf(accountAddress);
    shares = formatUnits(num, tokenDecimal);
  } catch (e) {}

  return shares;
};

/**
 * Get user's total deposit amount of token in Lending Pool from Ginza & blockchain
 * @param accountAddress user address
 * @param lendingPoolAddress lending pool address
 * @param chainId chain id
 * @param tokenDecimal token decimal
 * @param provider provider
 * @returns deposit amount
 */
export const getUserLendingPoolDepositedAmount = async (
  accountAddress: string,
  lendingPoolAddress: string,
  chainId: SupportedChainId,
  tokenDecimal: number,
  provider: any
) => {
  let sumOfDeposit = 0;
  let recordsObj: { [key: string]: number } = {};
  let lastCachedBlockNumber: number = 0;

  try {
    lastCachedBlockNumber = await getGinzaLatestCachedBlockNumber(chainId);
    const recordsObjFromGinza =
      await getUserLendingPoolDepositedRecordFromGinza(
        accountAddress,
        lendingPoolAddress,
        chainId,
        tokenDecimal
      );
    recordsObj = { ...recordsObjFromGinza };
  } catch {
    return null
  }

  try {
    const currentBlockNumber = await provider.getBlockNumber();
    const res = await getAllLogsOfAddress(
      provider,
      lendingPoolAddress,
      lastCachedBlockNumber,
      currentBlockNumber
    );
    const iface = new ethers.utils.Interface(lendingPoolAbi);

    const parsedEvents = res.map((log: any) => {
      return { ...iface.parseLog(log), txHash: log.transactionHash };
    });

    const userEvents = parsedEvents.filter(
      (e: any) =>
        e.args?.account?.toUpperCase() === accountAddress.toUpperCase()
    );

    userEvents
      .filter((e: any) => e.name.toUpperCase() === "Deposit".toUpperCase())
      .forEach((e: any) => {
        recordsObj[e.txHash] = +formatUnits(
          e.args.amountDeposited,
          tokenDecimal
        );
      });
  } catch (e) {
    return null
  }

  sumOfDeposit += Object.values(recordsObj).reduce(
    (pre, curr) => pre + +curr,
    0
  );

  return sumOfDeposit;
};

/**
 * Get user's total withdrawal amount of token in Lending Pool from Ginza & blockchain
 * @param accountAddress user address
 * @param lendingPoolAddress lending pool address
 * @param chainId chain id
 * @param tokenDecimal token decimal
 * @param provider provider
 * @returns withdrawal amount
 */
export const getUserLendingPoolWithdrawnAmount = async (
  accountAddress: string,
  lendingPoolAddress: string,
  chainId: SupportedChainId,
  tokenDecimal: number,
  provider: any
) => {
  let sumOfWithdrawn = 0;
  let recordsObj: { [key: string]: number } = {};
  let lastCachedBlockNumber: number = 0;

  try {
    lastCachedBlockNumber = await getGinzaLatestCachedBlockNumber(chainId);
    const recordsObjFromGinza =
      await getUserLendingPoolWithdrawnRecordFromGinza(
        accountAddress,
        lendingPoolAddress,
        chainId,
        tokenDecimal
      );
    recordsObj = { ...recordsObjFromGinza };
  } catch {}

  try {
    const currentBlockNumber = await provider.getBlockNumber();
    const res = await getAllLogsOfAddress(
      provider,
      lendingPoolAddress,
      lastCachedBlockNumber,
      currentBlockNumber
    );
    const iface = new ethers.utils.Interface(lendingPoolAbi);

    const parsedEvents = res.map((log: any) => {
      return { ...iface.parseLog(log), txHash: log.transactionHash };
    });

    const userEvents = parsedEvents.filter(
      (e: any) =>
        e.args?.account?.toUpperCase() === accountAddress.toUpperCase()
    );

    userEvents
      .filter((e: any) => e.name.toUpperCase() === "Withdraw".toUpperCase())
      .forEach((e: any) => {
        recordsObj[e.txHash] = +formatUnits(
          e.args.amountWithdrawn,
          tokenDecimal
        );
      });
  } catch (e) {}

  sumOfWithdrawn += Object.values(recordsObj).reduce(
    (pre, curr) => pre + +curr,
    0
  );

  return sumOfWithdrawn;
};

/**
 * Get lending rate of token in Lending Pool
 * @param lendingPoolAddress lending pool address
 * @param chainId chain id
 * @param provider provider
 * @returns lending rate
 */
export const getLendingPoolLendingRate = async (
  lendingPoolAddress: string,
  chainId: number,
  provider: any
) => {
  let rate = 0;

  if (isSupportedChain(chainId)) {
    try {
      const p = await getProvider(provider, chainId);
      const pool = new ethers.Contract(lendingPoolAddress, lendingPoolAbi, p);
      const lendingRate = await pool.getLendingRate();
      const numInEth = formatEther(lendingRate);
      const performanceFee = (await pool.performanceFee()).toString();
      const bps = (await pool.BPS()).toString();
      rate = +numInEth * 60 * 60 * 24 * 365 * (1 - performanceFee / bps);
    } catch (e) {}
  }

  return rate;
};

/**
 * Get borrow rate of token in Lending Pool
 * @param lendingPoolAddress lending pool address
 * @param chainId chain id
 * @param provider provider
 * @returns borrow rate
 */
export const getLendingPoolBorrowRate = async (
  lendingPoolAddress: string,
  chainId: number,
  provider: any
) => {
  let rate = 0;
  if (isSupportedChain(chainId)) {
    try {
      const p = await getProvider(provider, chainId);
      const pool = new ethers.Contract(lendingPoolAddress, lendingPoolAbi, p);
      const lendingRate = await pool.getBorrowRate();
      const numInEth = formatEther(lendingRate);
      rate = +numInEth * 60 * 60 * 24 * 365;
    } catch (e) {}
  }
  return rate;
};

/**
 * Wrap token
 * @param accountAddress user address
 * @param tokenAddress token address
 * @param tokenSymbol token symbol
 * @param amount wrap amount
 * @param chainId chain id
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 */
export const wrapToken = async (
  accountAddress: string,
  tokenAddress: string,
  tokenSymbol: string,
  amount: string,
  chainId: number,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  const wrapAmount = parseEther(amount.toString());
  const signer = provider.getSigner(accountAddress);
  const wToken = new ethers.Contract(tokenAddress, wethAbi, signer);
  const nativeTokenBalance = await provider.getBalance(accountAddress);

  if (nativeTokenBalance.lt(wrapAmount)) {
    showError(`Your ${tokenSymbol.substring(1)} balance is not enough.`);
  } else {
    try {
      const res = await wToken.deposit({ value: wrapAmount });
      trackTxEvent(res.hash);
      await showPendingAlert(
        res.hash,
        `Wrap ${tokenSymbol.substring(1)} to ${tokenSymbol} Succeed`,
        provider
      );
    } catch (e: any) {
      if (e.message.includes("User denied transaction signature")) {
        showError(t.error.deniedTransaction);
      } else {
        showError(
          `${t.error.wrap} ${tokenSymbol.substring(1)} ${t.error.failed}`
        );
        Sentry.captureException(e);
      }
    }
  }
};

/**
 * Get user's native token balance
 * @param accountAddress user address
 * @param chainId chain id
 * @param provider provider
 * @returns token amount
 */
export const getAccountNativeTokenBalance = async (
  accountAddress: string,
  chainId: number,
  provider: any
) => {
  let balance = 0;

  try {
    const eth = await provider.getBalance(accountAddress);
    balance = +formatEther(eth);
  } catch (e) {}

  return balance;
};

/**
 * Get user's borrow amount by borrow id
 * @param lendingPoolAddress lending pool address
 * @param borrowId borrow id
 * @param borrowTokenDecimal borrow token decimal
 * @param provider provider
 * @returns borrow amount
 */
export const getUserBorrowAmountFromLogs = async (
  lendingPoolAddress: string,
  borrowId: number,
  borrowTokenDecimal: number,
  provider: any
) => {
  let borrowAmount = 0;

  try {
    const currentBlockNumber = await provider.getBlockNumber();
    const res = await getAllLogsOfAddress(
      provider,
      lendingPoolAddress,
      0,
      currentBlockNumber
    );
    const iface = new ethers.utils.Interface(lendingPoolAbi);

    const parsedEvents = res.map((log: any) => {
      return { ...iface.parseLog(log) };
    });

    const borrowEvents = parsedEvents.filter(
      (e: any) =>
        e.name.toUpperCase() === "BORROW" &&
        +e.args.borrowId.toString() === borrowId
    );

    borrowAmount = +formatUnits(
      borrowEvents[0].args.borrowAmount,
      borrowTokenDecimal
    );
  } catch (e) {}

  return borrowAmount;
};

/**
 * Unwrap token
 * @param accountAddress user address
 * @param tokenAddress token address
 * @param tokenSymbol token symbol
 * @param amount unwrap amount
 * @param chainId chain id
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 */
export const unwrapWrappedToken = async (
  accountAddress: string,
  tokenAddress: string,
  tokenSymbol: string,
  amount: string,
  chainId: number,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  const unwrapAmount = parseEther(amount.toString());
  const signer = provider.getSigner(accountAddress);
  const wToken = new ethers.Contract(tokenAddress, wethAbi, signer);
  const wTokenBalance = await wToken.balanceOf(accountAddress);

  if (wTokenBalance.lt(unwrapAmount)) {
    showError(`Your ${tokenSymbol} balance is not enough.`);
  } else {
    try {
      const res = await wToken.withdraw(unwrapAmount);
      trackTxEvent(res.hash);
      await showPendingAlert(
        res.hash,
        `Unwrap ${tokenSymbol} to ${tokenSymbol.substring(1)} Succeed`,
        provider
      );
    } catch (e: any) {
      if (e.message.includes("User denied transaction signature")) {
        showError(t.error.deniedTransaction);
      } else {
        showError(`${t.error.unwrap} ${tokenSymbol} ${t.error.failed}`);
        Sentry.captureException(e);
      }
    }
  }
};

/**
 * Stake token into PCS
 * @param accountAddress user address
 * @param stakingAddress staking contract address
 * @param tokenDecimal token decimal
 * @param amount stake amount
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 */
export const stakeTokenIntoPCS = async (
  accountAddress: string,
  stakingAddress: string,
  tokenDecimal: number,
  amount: string,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  const signer = provider.getSigner(accountAddress);
  const stakeShares = utils.parseUnits(amount, tokenDecimal);
  const stakingContract = new ethers.Contract(
    stakingAddress,
    stakingAbi,
    signer
  );

  try {
    const res = await stakingContract.stake(stakeShares);
    trackTxEvent(res.hash);
    await showPendingAlert(
      res.hash,
      `${t.succeed.stake} ${t.succeed.succeed}`,
      provider
    );
  } catch (e: any) {
    if (e.message.includes("User denied transaction signature")) {
      showError(t.error.deniedTransaction);
    } else {
      showError(`${t.error.stake} ${t.error.failed}`);
    }
  }
};

/**
 * Get user's staked amount
 * @param accountAddress user address
 * @param stakingAddress staking contract address
 * @param tokenDecimal token decimal
 * @param provider provider
 * @returns staked amount
 */
export const getUserStakedAmount = async (
  accountAddress: string,
  stakingAddress: string,
  tokenDecimal: number,
  provider: any
) => {
  let stakedAmount = "0";

  try {
    const signer = provider.getSigner(accountAddress);
    const stakingContract = new ethers.Contract(
      stakingAddress,
      stakingAbi,
      signer
    );
    const num = await stakingContract.balanceOf(accountAddress);
    stakedAmount = formatUnits(num, tokenDecimal);
  } catch (e) {}

  return stakedAmount;
};

/**
 * Get user's CAKE reward amount for staking
 * @param accountAddress user address
 * @param stakingAddress staking contract address
 * @param tokenDecimal token decimal
 * @param provider provider
 * @returns reward amount
 */
export const getUserStakeRewardAmount = async (
  accountAddress: string,
  stakingAddress: string,
  tokenDecimal: number,
  provider: any
) => {
  let rewardAmount = "0";

  try {
    const signer = provider.getSigner(accountAddress);
    const stakingContract = new ethers.Contract(
      stakingAddress,
      stakingAbi,
      signer
    );
    const num = await stakingContract.earned(accountAddress);
    rewardAmount = formatUnits(num, tokenDecimal);
  } catch (e) {}

  return rewardAmount;
};

/**
 * Unstake token from PCS
 * @param accountAddress user address
 * @param stakingAddress staking contract address
 * @param tokenDecimal token decimal
 * @param amount unstake amount
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 */
export const unstakeTokenFromPCS = async (
  accountAddress: string,
  stakingAddress: string,
  tokenDecimal: number,
  amount: string,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  const signer = provider.getSigner(accountAddress);
  const unstakeShares = utils.parseUnits(amount, tokenDecimal);
  const stakingContract = new ethers.Contract(
    stakingAddress,
    stakingAbi,
    signer
  );

  try {
    const res = await stakingContract.withdraw(unstakeShares);
    trackTxEvent(res.hash);
    await showPendingAlert(
      res.hash,
      `${t.succeed.unstake} ${t.succeed.succeed}`,
      provider
    );
  } catch (e: any) {
    if (e.message.includes("User denied transaction signature")) {
      showError(t.error.deniedTransaction);
    } else {
      showError(`${t.error.unstake} ${t.error.failed}`);
    }
  }
};

/**
 * Claim CAKE reward from PCS
 * @param accountAddress user address
 * @param stakingAddress staking contract address
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 */
export const claimStakeReward = async (
  accountAddress: string,
  stakingAddress: string,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  const signer = provider.getSigner(accountAddress);

  const stakingContract = new ethers.Contract(
    stakingAddress,
    stakingAbi,
    signer
  );

  try {
    const res = await stakingContract.getReward();
    trackTxEvent(res.hash);
    await showPendingAlert(
      res.hash,
      `${t.succeed.claim} CAKE ${t.succeed.succeed}`,
      provider
    );
  } catch (e: any) {
    if (e.message.includes("User denied transaction signature")) {
      showError(t.error.deniedTransaction);
    } else {
      showError(`${t.error.claim} CAKE ${t.error.failed}`);
    }
  }
};

/**
 * Get PCS staking APR
 * @param stakingAddress staking contract address
 * @param lendingPoolAddress lending pool address
 * @param tokenSymbol token symbol
 * @param chainId chain id
 * @param provider provider
 * @returns APR
 */
export const getPCSStakingAPR = async (
  stakingAddress: string,
  lendingPoolAddress: string,
  tokenSymbol: string,
  chainId: SupportedChainId,
  provider: any
) => {
  let apr = 0;
  try {
    const p = await getProvider(provider, chainId);
    const staking = new ethers.Contract(stakingAddress, stakingAbi, p);
    const pool = new ethers.Contract(lendingPoolAddress, lendingPoolAbi, p);
    const rewardRate = await staking.rewardRate();
    const cakePrice = await getTokenPriceFromBinance("CAKE");
    const tokenPrice = await getTokenPriceFromBinance(tokenSymbol);
    const exchangeRate = await pool.getEstimatedExchangeRate();
    const totalSupply = await staking.totalSupply();
    const totalReward =
      +formatUnits(rewardRate, 18) * 60 * 60 * 24 * 365 * +cakePrice;
    const TVL =
      +formatUnits(totalSupply, 18) *
      +formatUnits(exchangeRate, 18) *
      +tokenPrice;
    apr = totalReward / TVL;
  } catch (e) {}
  return apr;
};

export const getContractTokenSymbol = async (
  lendingPoolAddress: string,
  chainId: SupportedChainId,
  provider: any
) => {
  let symbol = "";
  try {
    const p = await getProvider(provider, chainId);
    const pool = new ethers.Contract(lendingPoolAddress, lendingPoolAbi, p);
    symbol = await pool.symbol();
  } catch (e) {}
  return symbol;
};
