import React, { useRef } from "react";
import styled from "styled-components";
import Colors from "../styles/Colors";
import { H4 } from "../styles/Fonts";
import { trimAmountInput } from "../utils/common";
import t from "../utils/content";

type Props = {
  setAmount: (v: string) => void;
  tokenSymbol: string;
  tokenDecimal: number;
  showMaxBtn: boolean;
  maxAmount?: string;
};

const AmountInput: React.FC<Props> = (props) => {
  const { setAmount, tokenSymbol, showMaxBtn, maxAmount } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSetMax = () => {
    if (maxAmount && parseFloat(maxAmount)) {
      if (inputRef.current) {
        inputRef.current.value = maxAmount;
        setAmount(trimAmountInput(maxAmount));
      }
    }
  };

  return (
    <Container>
      <LabelContainer>
        <Label>{t.common.amount}</Label>
      </LabelContainer>
      <Input
        ref={inputRef}
        type="number"
        placeholder="100"
        onChange={(e) => setAmount(trimAmountInput(e.currentTarget.value))}
        onWheel={(e) => e.currentTarget.blur()}
      />
      <Unit>{tokenSymbol}</Unit>
      {showMaxBtn && <MaxBtn onClick={handleSetMax}>{t.common.max}</MaxBtn>}
    </Container>
  );
};

export default AmountInput;

const Container = styled.div`
  width: 100%;
  padding: 6px 12px 6px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid ${Colors.gray4};
  margin-top: 12px;
`;

const Unit = styled.span`
  font-size: 16px;
  line-height: 28px;
  color: ${Colors.ivory};
  margin: 0 8px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const LabelContainer = styled.div`
  position: absolute;
  padding: 0 4px;
  top: -14px;
  left: 8px;
  background-color: ${Colors.backgroundGray1};
`;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const Input = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: ${Colors.ivory};
  :focus {
    border: none;
    outline: none;
  }
`;

const MaxBtn = styled.button`
  padding: 2px 8px;
  border: 1px solid ${Colors.lightKhaki};
  border-radius: 100px;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.lightKhaki};
  margin-left: 4px;
  :active {
    transform: scale(0.99);
  }
`;
