import React, { useMemo } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { SmallRegular } from "../../../../styles/Fonts";
import { PairProps } from "../../../../utils/constant/pairs";
import { UserActivePositionProps } from "../../../../redux/factorySlice";
import { getTruncNum } from "../../../../utils/common";
import useUniswapPoolState from "../../../../hooks/useUniswapPoolState";
import PairTokenIcon from "../../../../components/PairTokenIcon";
import { AMOUNT_DECIMAL } from "../../../../utils/constant/decimals";

const FactoryPositionItem: React.FC<{
  pos: {
    position: UserActivePositionProps;
    pair: PairProps;
  };
}> = ({ pos }) => {
  const { pair, position } = pos;
  const { wantTokenFee, borrowTokenFee, wantAmount, positionCreateTimestamp } =
    position;

  const { borrowTokenPrice } = useUniswapPoolState(
    pair.uniPoolAddress,
    pair.borrowToken,
    pair.wantToken,
    pair.deployedChainId,
    pair.wantTokenIsToken0
  );

  const currentTimestamp = useMemo(
    () => Math.floor(new Date().getTime() / 1000),
    []
  );

  const feeApr = useMemo(
    () =>
      borrowTokenPrice &&
      currentTimestamp &&
      positionCreateTimestamp &&
      wantAmount
        ? (+(+wantTokenFee + +borrowTokenFee * +borrowTokenPrice) /
            +wantAmount) *
          ((60 * 60 * 24) / (+currentTimestamp - +positionCreateTimestamp)) *
          365 *
          100
        : 0,
    [
      currentTimestamp,
      positionCreateTimestamp,
      wantAmount,
      wantTokenFee,
      borrowTokenFee,
      borrowTokenPrice,
    ]
  );

  return (
    <PositionItemRow>
      <TokenRow>
        <PairTokenIcon
          wantTokenIcon={pair.wantToken.tokenIcon}
          borrowTokenIcon={pair.borrowToken.tokenIcon}
        />
        <ValueText>{pair.label}</ValueText>
      </TokenRow>
      <Row>
        <MLabel>Position Value</MLabel>
        <ValueText>{`${getTruncNum(
          +position.positionValueInWantToken,
          AMOUNT_DECIMAL
        )} ${pair.wantToken.tokenSymbol}`}</ValueText>
      </Row>
      <Row>
        <MLabel>Earned Fee</MLabel>
        <div>
          <ValueText>{`${getTruncNum(+position.wantTokenFee, AMOUNT_DECIMAL)} ${
            pair.wantToken.tokenSymbol
          }`}</ValueText>
          <ValueText>{`${getTruncNum(
            +position.borrowTokenFee,
            AMOUNT_DECIMAL
          )} ${pair.borrowToken.tokenSymbol}`}</ValueText>
        </div>
      </Row>
      <Row>
        <MLabel>Fee APR</MLabel>
        <ValueText>{getTruncNum(feeApr, 2)} %</ValueText>
      </Row>
      <Row>
        <MLabel>Leverage</MLabel>
        <ValueText>{`${+position.borrowRatio + 1}x`}</ValueText>
      </Row>
    </PositionItemRow>
  );
};

export default FactoryPositionItem;

const TokenRow = styled.div`
  flex: 0 0 200px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex: inherit;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 12px;
  }
`;

const ValueText = styled(SmallRegular)`
  display: block;
  color: ${Colors.ivory};
  font-size: 14px;
  text-align: right;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 18px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 4px 0;
  }
`;

const MLabel = styled(ValueText)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const PositionRow = styled.div`
  display: grid;
  grid-template-columns: 100px repeat(4, 1fr);
  align-items: center;
  > span:not(:first-child) {
    text-align: right;
  }
  @media (max-width: 768px) {
    display: block;
  }
`;

const PositionItemRow = styled(PositionRow)`
  padding: 24px 40px 24px 24px;
  background-color: ${Colors.backgroundGray4};
  border-radius: 8px;
  margin-top: 16px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;
