import React, { forwardRef, useState } from "react";
import styled from "styled-components";

import twitter from "../images/twitter.png";
import twitterHover from "../images/twitter_hover.png";
import discord from "../images/discord.png";
import discordHover from "../images/discord_hover.png";
import { DISCORD_URL, TWITTER_URL } from "../utils/constant/urls";
import useTrackEvent from "../hooks/useTrackEvent";
import t from "../utils/content";

const ContactLink = forwardRef(
  (
    {
      children,
      href,
      passHref,
      ...rest
    }: { children: JSX.Element; href: string; passHref: boolean },
    ref: any
  ) => (
    <a {...rest} href={href} ref={ref} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
);

const Contacts = () => {
  const trackEvent = useTrackEvent();

  const [isHoveringTwitter, setIsHoveredTwitter] = useState(false);
  const onMouseEnterTitter = () => setIsHoveredTwitter(true);
  const onMouseLeaveTwitter = () => setIsHoveredTwitter(false);

  const [isHoveringDiscord, setIsHoveredDiscord] = useState(false);
  const onMouseEnterDiscord = () => setIsHoveredDiscord(true);
  const onMouseLeaveDiscord = () => setIsHoveredDiscord(false);

  return (
    <ContactsContainer>
      <Twitter
        onMouseEnter={onMouseEnterTitter}
        onMouseLeave={onMouseLeaveTwitter}
        onClick={() => trackEvent(`${t.amp.clickTwitterBtn}`)}
      >
        <ContactLink href={TWITTER_URL} passHref>
          {isHoveringTwitter ? (
            <Image
              src={twitterHover}
              alt="twitter logo"
              width="40"
              height="40"
            />
          ) : (
            <Image src={twitter} alt="twitter logo" width="40" height="40" />
          )}
        </ContactLink>
      </Twitter>
      <Discord
        onMouseEnter={onMouseEnterDiscord}
        onMouseLeave={onMouseLeaveDiscord}
        onClick={() => trackEvent(`${t.amp.clickDiscordBtn}`)}
      >
        <ContactLink href={DISCORD_URL} passHref>
          {isHoveringDiscord ? (
            <Image
              src={discordHover}
              alt="discord logo"
              width="40"
              height="40"
            />
          ) : (
            <Image src={discord} alt="discord logo" width="40" height="40" />
          )}
        </ContactLink>
      </Discord>
    </ContactsContainer>
  );
};

export default Contacts;

const ContactsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Twitter = styled.div`
  width: 36px;
`;

const Discord = styled(Twitter)`
  margin-left: 12px;
`;

const Image = styled.img`
  width: 36px;
  height: 36px;
`;
