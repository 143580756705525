import { BigNumber, ethers, utils } from "ethers";
import factoryV3Abi from "../../abi/factoryV3.json";
import vaultAbi from "../../abi/balanceVault.json";
import erc20Abi from "../../abi/erc20.json";
import aggregatorAbi from "../../abi/aggregator.json";
import {
  convertTickToPrice,
  getProof,
  convertPriceToTick,
  getAllLogsOfAddress,
  getTokenPriceFromBinance,
} from "./common";
import { showErrorAlert as showError, showPendingAlert } from "../showAlert";
import { Pool } from "@uniswap/v3-sdk";
import { Token } from "@uniswap/sdk-core";
import uniV3PoolAbi from "../../abi/uniV3Pool.json";
import pancakeV3PoolAbi from "../../abi/pancakeV3Pool.json";
import helperAbi from "../../abi/helper.json";
import { V3_POSITION_MANAGERS } from "../constant/externalAddresses";
import {
  isPanCakeSwap,
  isSupportedChain,
  SupportedChainId,
} from "../constant/chains";
import { ALL_HELPERS, ALL_VAULTS } from "../constant/internalAddresses";
import * as Sentry from "@sentry/react";
import controllerAbi from "../../abi/factoryController.json";
import { ALL_CONTROLLERS } from "../constant/internalAddresses";
import getProvider from "../getProvider";
import { formatUnits } from "ethers/lib/utils";
import {
  getGinzaLatestCachedBlockNumber,
  getUserClosedPositionsFromGinza,
  getUserPositionByIdFromGinza,
} from "./ginzaApi";
import t from "../content";
import { getUserBorrowAmountFromLogs } from "./lendingPoolApi";
import {
  UserActivePositionProps,
  UserClosedPositionProps,
} from "../../redux/factorySlice";
import { ALL_AGGREGATORS } from "../constant/internalAddresses";
import uniV3Abi from "../../abi/uniV3.json";
import { SUBGRAPH_URLS } from "../constant/subgraphs";

interface Immutables {
  factory: string;
  token0: string;
  token1: string;
  fee: number;
  tickSpacing: number;
  maxLiquidityPerTick: ethers.BigNumber;
}

interface State {
  liquidity: ethers.BigNumber;
  sqrtPriceX96: ethers.BigNumber;
  tick: number;
  observationIndex: number;
  observationCardinality: number;
  observationCardinalityNext: number;
  feeProtocol: number;
  unlocked: boolean;
}

interface TokenProps {
  tokenSymbol: string;
  tokenFullName: string;
  tokenDecimal: number;
}

const getV3PoolAbi = (chainId: SupportedChainId) => {
  const isPancakeSwap = isPanCakeSwap(chainId);
  if (!isPancakeSwap) {
    return uniV3PoolAbi;
  } else {
    return pancakeV3PoolAbi;
  }
};

/**
 * Get target tick from range and tick spacing
 * @param range range, e.g. 15% => 0.15
 * @param tickSpacing tick spacing
 * @returns target tick
 */
export const getTargetTick = (range: number, tickSpacing: number) => {
  const targetRange = 1 + range;
  const tickSpacingMultiplier = 1.0001 ** tickSpacing;
  const targetTick =
    tickSpacing *
    Math.ceil(Math.log(targetRange) / Math.log(tickSpacingMultiplier));
  return targetTick;
};

/**
 * Get token prices and tick of Uniswap V3 liquidity pool
 * @param poolAddress pool address
 * @param tokenA token A
 * @param tokenB token B
 * @param chainId chain id
 * @param provider provider
 * @returns token prices and tick
 */
export const getUniPoolTokenPricesAndTick = async (
  poolAddress: string,
  tokenA: TokenProps,
  tokenB: TokenProps,
  chainId: number,
  provider: any
) => {
  try {
    const p = await getProvider(provider, chainId);

    const poolContract = new ethers.Contract(
      poolAddress,
      getV3PoolAbi(chainId),
      p
    );

    const getPoolImmutables = async () => {
      const [factory, token0, token1, fee, tickSpacing, maxLiquidityPerTick] =
        await Promise.all([
          poolContract.factory(),
          poolContract.token0(),
          poolContract.token1(),
          poolContract.fee(),
          poolContract.tickSpacing(),
          poolContract.maxLiquidityPerTick(),
        ]);

      const immutables: Immutables = {
        factory,
        token0,
        token1,
        fee,
        tickSpacing,
        maxLiquidityPerTick,
      };
      return immutables;
    };

    const getPoolState = async () => {
      const [liquidity, slot] = await Promise.all([
        poolContract.liquidity(),
        poolContract.slot0(),
      ]);

      const PoolState: State = {
        liquidity,
        sqrtPriceX96: slot[0],
        tick: slot[1],
        observationIndex: slot[2],
        observationCardinality: slot[3],
        observationCardinalityNext: slot[4],
        feeProtocol: slot[5],
        unlocked: slot[6],
      };

      return PoolState;
    };

    const [immutables, state] = await Promise.all([
      getPoolImmutables(),
      getPoolState(),
    ]);

    const TokenA = new Token(
      3,
      immutables.token0,
      tokenA.tokenDecimal,
      tokenA.tokenSymbol,
      tokenA.tokenFullName
    );

    const TokenB = new Token(
      3,
      immutables.token1,
      tokenB.tokenDecimal,
      tokenB.tokenSymbol,
      tokenB.tokenFullName
    );

    const TOKENA_TOKENB_POOL = new Pool(
      TokenA,
      TokenB,
      immutables.fee,
      state.sqrtPriceX96.toString(),
      state.liquidity.toString(),
      state.tick
    );

    const token0Price = TOKENA_TOKENB_POOL.token0Price;
    const token1Price = TOKENA_TOKENB_POOL.token1Price;
    const tick = state.tick;

    return {
      token0Price: +token0Price.toSignificant(18),
      token1Price: +token1Price.toSignificant(18),
      tickSpacing: immutables.tickSpacing,
      tick: tick,
    };
  } catch (e) {
    // reached end
    // console.error("Fetch all liquidity error", e);
  }
};

/**
 * Open a factory position
 * @param userAddress user address
 * @param factoryAddress factory address
 * @param amount amount to open position
 * @param tokenAddress token address
 * @param tokenSymbol token symbol
 * @param tokenDecimal token decimal
 * @param stopLossUpperPrice stop loss upper price
 * @param stopLossLowerPrice stop loss lower price
 * @param borrowRatio borrow ratio, e.g. 2x => 1, 1.5x => 0.5
 * @param range range, e.g. 15% => 0.15
 * @param chainId chain id
 * @param wantTokenIsToken0 wantToken is token 0
 * @param borrowTokenDecimal borrowToken decimal
 * @param tickSpacing tick spacing
 * @param spotPriceTick spot price tick (current price tick)
 * @param slippage slippage
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 */
export const openFactoryPosition = async (
  userAddress: string,
  factoryAddress: string,
  amount: number,
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimal: number,
  stopLossUpperPrice: number,
  stopLossLowerPrice: number,
  borrowRatio: number,
  range: number,
  chainId: number,
  wantTokenIsToken0: boolean,
  borrowTokenDecimal: number,
  tickSpacing: number,
  spotPriceTick: number,
  slippage: number,
  provider: any,
  trackTxEvent: (hash: string) => void
) => {
  const openAmount = utils.parseUnits(amount.toString(), tokenDecimal);

  const signer = provider.getSigner(userAddress);
  const factory = new ethers.Contract(factoryAddress, factoryV3Abi, signer);
  const balanceVault = new ethers.Contract(
    ALL_VAULTS[chainId],
    vaultAbi,
    signer
  );
  const controller = new ethers.Contract(
    ALL_CONTROLLERS[chainId],
    controllerAbi,
    signer
  );

  const accountBalance = await balanceVault.getAccountBalance(
    userAddress,
    tokenAddress
  );

  const openPositionMaxAmount = await factory.openPositionMaximumAmount();
  const maxTokenAmount = formatUnits(openPositionMaxAmount, tokenDecimal);

  const openPositionMinAmount = await factory.openPositionMinimumAmount();
  const minTokenAmount = formatUnits(openPositionMinAmount, tokenDecimal);

  const maxPositionNum = await controller.maxPositionNumber();
  const userPositionCount = (await factory.getAccountPositionIds(userAddress))
    .length;
  const reachMaxPosition = userPositionCount >= maxPositionNum;

  const stopLossLowerTick = convertPriceToTick(
    stopLossLowerPrice,
    borrowTokenDecimal,
    tokenDecimal,
    "floor"
  );

  const stopLossUpperTick = convertPriceToTick(
    stopLossUpperPrice,
    borrowTokenDecimal,
    tokenDecimal,
    "ceil"
  );

  const isApproved = await balanceVault.userApproveFactories(
    userAddress,
    factoryAddress
  );

  if (!isApproved) {
    const approveRes = await balanceVault.approve(factoryAddress, tokenAddress);
    await showPendingAlert(
      approveRes.hash,
      t.succeed.approvedToUseVaultAsset,
      provider
    );
  }

  if (openAmount.gt(openPositionMaxAmount)) {
    showError(
      `${t.error.exceedMaxAmount1}${+maxTokenAmount} ${tokenSymbol}${
        t.error.exceedMaxAmount2
      }`
    );
  } else if (openAmount.lt(openPositionMinAmount)) {
    showError(
      `${t.error.lowerThanMinAmount1}${+minTokenAmount} ${tokenSymbol}${
        t.error.lowerThanMinAmount2
      }`
    );
  } else if (accountBalance.lt(openAmount)) {
    showError(t.error.accountBalanceNotEnough);
  } else if (reachMaxPosition) {
    showError(t.error.exceedPositionsLimit);
  } else {
    const targetTick = getTargetTick(range, tickSpacing);
    try {
      const proof = await getProof(userAddress, chainId);
      const openPositionParams = {
        wantTokenAmount: BigNumber.from(openAmount),
        borrowRatio: BigNumber.from(Math.round(borrowRatio * 10000)),
        spotPriceTick: BigNumber.from(
          wantTokenIsToken0 ? spotPriceTick : -spotPriceTick
        ),
        slippage: BigNumber.from(Math.round(slippage * 10000)),
        reserveRatio: BigNumber.from(0),
        stopLossUpperPriceTick: BigNumber.from(
          wantTokenIsToken0 ? stopLossUpperTick : -stopLossLowerTick
        ),
        stopLossLowerPriceTick: BigNumber.from(
          wantTokenIsToken0 ? stopLossLowerTick : -stopLossUpperTick
        ),
        tickRange: BigNumber.from(targetTick),
        amount0Min: 0,
        amount1Min: 0,
      };
      const estimatedGas = await factory.estimateGas.openPosition(
        openPositionParams,
        proof
      );
      const increasedGasLimit = estimatedGas.mul(120).div(100);
      const res = await factory.openPosition(openPositionParams, proof, {
        gasLimit: increasedGasLimit,
      });
      trackTxEvent(res.hash);
      await showPendingAlert(res.hash, t.succeed.openPositionSucceed, provider);
    } catch (e: any) {
      if (e.code === "ACTION_REJECTED") {
        showError(t.error.deniedTransaction);
      } else if (e.message.includes("execution reverted: 04")) {
        showError(t.error.lendingPoolNotEnough);
      } else if (e.message.includes("execution reverted: 08")) {
        showError(t.error.openPositionSlippageProtect);
      } else if (e.message.includes("execution reverted: 03")) {
        showError(t.error.invalidParameters);
      } else if (e.message.includes("Not in whitelist")) {
        showError(t.error.notInWhitelist);
      } else {
        // console.log(e);
        showError(t.error.openPositionFailed);
        Sentry.captureException(e);
      }
    }
  }
};

/**
 * Get user's closed factory positions
 * @param accountAddress user address
 * @param factoryAddress factory address
 * @param lendingPoolAddress lending pool address
 * @param wantTokenIsToken0 wantToken is token 0
 * @param wantTokenDecimal wantToken decimal
 * @param borrowTokenDecimal borrowToken decimal
 * @param chainId chain id
 * @param provider provider
 * @returns user's closed factory positions
 */
export const getUserClosedFactoryPositions = async (
  accountAddress: string,
  factoryAddress: string,
  lendingPoolAddress: string,
  wantTokenIsToken0: boolean,
  wantTokenDecimal: number,
  borrowTokenDecimal: number,
  chainId: number,
  provider: any
) => {
  const diffTokenDecimal = borrowTokenDecimal - wantTokenDecimal;

  let cachedBlockNumber: number = 0;
  let closedPositionsFromGinza: UserClosedPositionProps[] = [];

  try {
    cachedBlockNumber = await getGinzaLatestCachedBlockNumber(chainId);
    closedPositionsFromGinza = await getUserClosedPositionsFromGinza(
      accountAddress,
      factoryAddress,
      chainId,
      borrowTokenDecimal,
      wantTokenDecimal,
      wantTokenIsToken0,
      provider
    );
  } catch (e) {}

  const existClosedPositionObj: { [key: string]: boolean } = {};
  closedPositionsFromGinza.forEach((p) => {
    existClosedPositionObj[p.positionId] = true;
  });

  const currentBlockNumber = await provider.getBlockNumber();
  const res = await getAllLogsOfAddress(
    provider,
    factoryAddress,
    cachedBlockNumber,
    currentBlockNumber
  );
  const iface = new utils.Interface(factoryV3Abi);

  const parsedEvents: any[] = [];

  res.forEach((log: any) => {
    try {
      const parseLog = iface.parseLog(log);
      if (parseLog) {
        parsedEvents.push({
          blockNumber: log.blockNumber,
          ...iface.parseLog(log),
        });
      }
    } catch (e) {
      // console.log(e);
    }
  });

  const userEvents = parsedEvents.filter(
    (e: any) => e.args?.account?.toUpperCase() === accountAddress.toUpperCase()
  );

  const closePositionEvents = userEvents.filter(
    (e: any) =>
      e.name.toUpperCase() === "ClosePosition".toUpperCase() ||
      e.name.toUpperCase() === "Liquidate".toUpperCase() ||
      e.name.toUpperCase() === "StopLoss".toUpperCase()
  );

  const restClosePositionEvent = closePositionEvents.filter(
    (e: any) => !existClosedPositionObj[e.args.positionId]
  );

  const openPositionEvents = userEvents.filter(
    (e: any) => e.name.toUpperCase() === "OpenPosition".toUpperCase()
  );

  const positionInfos = await Promise.all(
    restClosePositionEvent.map(async (history: any) => {
      const closeTimestamp = (await provider.getBlock(history.blockNumber))
        .timestamp;
      const positionId = history.args.positionId.toString();
      const {
        closePriceTick,
        endWantAmount: _endWantAmount,
        reserveWantAmount: _reserveAmountAtEnd,
      } = history?.args;

      // data which need to get from OpenPosition event log
      let borrowId;
      let _reserveWantAmountAtStart;
      let startPriceTick;
      let _startWantAmount;
      let startTimestamp;

      const ginzaPositionInfo = await getUserPositionByIdFromGinza(
        accountAddress,
        factoryAddress,
        chainId,
        positionId
      );

      // use ginza data if it has already cached OpenPosition event
      if (!!ginzaPositionInfo) {
        borrowId = +ginzaPositionInfo.BorrowId;
        _reserveWantAmountAtStart =
          ginzaPositionInfo.PositionInfo.ReserveAmountAtStart;
        startPriceTick = +ginzaPositionInfo.OpenTicker;
        _startWantAmount = ginzaPositionInfo.WantAmount;
        startTimestamp = +ginzaPositionInfo.OpenTime;
      } // use OpenPosition event data on-chain
      else {
        const matchedOpenPositionLog = openPositionEvents.find(
          (e: any) => e.args.positionId.toString() === positionId
        );
        ({
          _borrowId: borrowId,
          reserveWantAmount: _reserveWantAmountAtStart,
          startPriceTick,
          startWantAmount: _startWantAmount,
        } = matchedOpenPositionLog?.args);
        startTimestamp = (
          await provider.getBlock(matchedOpenPositionLog.blockNumber)
        ).timestamp;
      }

      const startPrice = convertTickToPrice(
        wantTokenIsToken0 ? +startPriceTick : -startPriceTick,
        diffTokenDecimal
      );

      const closePrice = convertTickToPrice(
        wantTokenIsToken0 ? +closePriceTick : -closePriceTick,
        diffTokenDecimal
      );

      const borrowedEth = await getUserBorrowAmountFromLogs(
        lendingPoolAddress,
        +borrowId,
        borrowTokenDecimal,
        provider
      );

      const reserveWantAmount = formatUnits(
        _reserveWantAmountAtStart,
        wantTokenDecimal
      );

      const startWantAmount = +formatUnits(_startWantAmount, wantTokenDecimal);

      // get ranges from v3
      let upperPrice = 0;
      let lowerPrice = 0;
      try {
        const { tickLower, tickUpper } = await getPositionRangesFromV3(
          +positionId,
          chainId,
          provider
        );
        if (tickLower && tickUpper) {
          upperPrice = convertTickToPrice(
            wantTokenIsToken0 ? +tickUpper : -tickLower,
            diffTokenDecimal
          );
          lowerPrice = convertTickToPrice(
            wantTokenIsToken0 ? +tickLower : -tickUpper,
            diffTokenDecimal
          );
        }
      } catch (error) {
        // console.log(error);
      }

      const position: UserClosedPositionProps = {
        positionId: positionId,
        startWantAmount: startWantAmount.toString(),
        endWantAmount: formatUnits(_endWantAmount, wantTokenDecimal),
        reserveAmount: formatUnits(_reserveAmountAtEnd, wantTokenDecimal),
        upperTickPrice: upperPrice,
        lowerTickPrice: lowerPrice,
        borrowRatio: +((borrowedEth * +startPrice) / startWantAmount).toFixed(
          1
        ),
        startTime: startTimestamp,
        closeTime: closeTimestamp,
        startPrice: startPrice,
        closePrice: closePrice,
        eventName: history.name,
        reserveWantAmount: reserveWantAmount,
        wantTokenFee: "",
        borrowTokenFee: "",
        cakeReward: "",
        rewardTokenValueInWantToken: "",
      };

      return position;
    })
  );

  return [...positionInfos, ...closedPositionsFromGinza].sort(
    (a, b) => b.closeTime - a.closeTime
  );
};

/**
 * Add collateral to a factory position
 * @param userAddress user address
 * @param factoryAddress factory address
 * @param tokenAddress token address
 * @param tokenDecimal token decimal
 * @param amount amount to add collateral
 * @param positionId position id
 * @param chainId chain id
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 */
export const addFactoryCollateral = async (
  userAddress: string,
  factoryAddress: string,
  tokenAddress: string,
  tokenDecimal: number,
  amount: string,
  positionId: string,
  chainId: number,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  const tokenAmount = ethers.utils.parseUnits(amount.toString(), tokenDecimal);

  const signer = provider.getSigner(userAddress);
  const factory = new ethers.Contract(factoryAddress, factoryV3Abi, signer);
  const accountBalance = await getUserTokenBalanceInBalanceVault(
    userAddress,
    tokenAddress,
    chainId,
    provider
  );

  if (accountBalance.lt(tokenAmount)) {
    showError(t.error.accountBalanceNotEnough);
  } else {
    try {
      const proof = await getProof(userAddress, chainId);
      const res = await factory.addCollateral(tokenAmount, positionId, proof);
      trackTxEvent(res.hash);
      await showPendingAlert(
        res.hash,
        t.succeed.addCollateralSucceed,
        provider
      );
    } catch (e: any) {
      if (e.code === "ACTION_REJECTED") {
        showError(t.error.deniedTransaction);
      } else if (e.message.includes("Not in whitelist")) {
        showError(t.error.notInWhitelist);
      } else {
        showError(t.error.addCollateralFailed);
        Sentry.captureException(e);
      }
    }
  }
};

/**
 * Close a factory position
 * @param userAddress user address
 * @param factoryAddress factory address
 * @param positionId position id
 * @param spotPriceTick spot price tick (current price tick)
 * @param slippage slippage
 * @param chainId chain id
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 */
export const closeFactoryPosition = async (
  userAddress: string,
  factoryAddress: string,
  positionId: string,
  spotPriceTick: number,
  slippage: number,
  chainId: number,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  const signer = provider.getSigner(userAddress);
  const factory = new ethers.Contract(factoryAddress, factoryV3Abi, signer);

  try {
    const proof = await getProof(userAddress, chainId);
    const res = await factory.closePosition(
      positionId,
      BigNumber.from(spotPriceTick),
      BigNumber.from(Math.round(slippage)),
      proof
    );
    trackTxEvent(res.hash);
    await showPendingAlert(res.hash, t.succeed.closePositionSucceed, provider);
  } catch (e: any) {
    if (e.code === "ACTION_REJECTED") {
      showError(t.error.deniedTransaction);
    } else if (e.message.includes("execution reverted: 08")) {
      showError(t.error.closePositionSlippageProtect);
    } else if (e.message.includes("Not in whitelist")) {
      showError(t.error.notInWhitelist);
    } else {
      showError(t.error.closePositionFailed);
      Sentry.captureException(e);
    }
  }
};

/**
 * Get user's token balance
 * @param userAddress user address
 * @param chainId chain id
 * @param tokenAddress token address
 * @param tokenDecimal token decimal
 * @param provider provider
 * @returns user's token balance
 */
export const getAccountTokenBalance = async (
  userAddress: string,
  chainId: number,
  tokenAddress: string,
  tokenDecimal: number,
  provider: any
) => {
  let tokenBalance = "0";

  if (isSupportedChain(chainId)) {
    try {
      const p = await getProvider(provider, chainId);
      const signer = p.getSigner(userAddress);
      const token = new ethers.Contract(tokenAddress, erc20Abi, signer);
      tokenBalance = formatUnits(
        await token.balanceOf(userAddress),
        tokenDecimal
      );
    } catch (e) {
      // console.log(e);
    }
  }
  return tokenBalance;
};

/**
 * Get position ranges from Uniswap/PancakeSwap V3
 * @param positionId position id
 * @param chainId chain id
 * @param provider provider
 * @returns position ranges in tick
 */
export const getPositionRangesFromV3 = async (
  positionId: number,
  chainId: SupportedChainId,
  provider: any
) => {
  let ticks;

  if (isSupportedChain(chainId)) {
    const p = await getProvider(provider, chainId);
    const v3 = new ethers.Contract(V3_POSITION_MANAGERS[chainId], uniV3Abi, p);
    ticks = await v3.positions(positionId);
  }

  return ticks as { tickLower: number; tickUpper: number };
};

/**
 * Decrease collateral of a factory position
 * @param userAddress user address
 * @param factoryAddress factory address
 * @param amount amount to decrease collateral
 * @param tokenDecimal token decimal
 * @param positionId position id
 * @param chainId chain id
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 */
export const decreaseFactoryCollateral = async (
  userAddress: string,
  factoryAddress: string,
  amount: string,
  tokenDecimal: number,
  positionId: string,
  chainId: number,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  const decreaseAmount = utils.parseUnits(amount.toString(), tokenDecimal);

  const signer = provider.getSigner(userAddress);
  const factory = new ethers.Contract(factoryAddress, factoryV3Abi, signer);

  try {
    const proof = await getProof(userAddress, chainId);
    const res = await factory.decreaseCollateral(
      decreaseAmount,
      positionId,
      proof
    );
    trackTxEvent(res.hash);
    await showPendingAlert(
      res.hash,
      t.succeed.decreaseCollateralSucceed,
      provider
    );
  } catch (e: any) {
    if (e.code === "ACTION_REJECTED") {
      showError(t.error.deniedTransaction);
    } else if (e.message.includes("Not in whitelist")) {
      showError(t.error.notInWhitelist);
    } else if (e.message.includes("execution reverted: 11")) {
      showError(t.error.collateralNotEnough);
    } else if (e.message.includes("execution reverted: 12")) {
      showError(t.error.healthFactorTooLow);
    } else {
      showError(t.error.decreaseCollateralFailed);
    }
  }
};

/**
 * Collect earned fee of a factory position
 * @param userAddress user address
 * @param factoryAddress factory address
 * @param positionId position id
 * @param spotPriceTick spot price tick (current price tick)
 * @param slippage slippage
 * @param chainId chain id
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 */
export const collectFactoryFee = async (
  userAddress: string,
  factoryAddress: string,
  positionId: string,
  spotPriceTick: number,
  slippage: number,
  chainId: number,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  const signer = provider.getSigner(userAddress);
  const factory = new ethers.Contract(factoryAddress, factoryV3Abi, signer);

  try {
    const res = await factory.collectFee(
      positionId,
      BigNumber.from(spotPriceTick),
      BigNumber.from(Math.round(slippage))
    );
    trackTxEvent(res.hash);
    await showPendingAlert(res.hash, t.succeed.collectFeeSucceed, provider);
  } catch (e: any) {
    if (e.code === "ACTION_REJECTED") {
      showError(t.error.deniedTransaction);
    } else if (e.message.includes("execution reverted: 07")) {
      showError(t.error.positionHasNoFee);
    } else {
      showError(t.error.collectFeeFailed);
    }
  }
};

/**
 * Get user's token balance in BalanceVault
 * @param userAddress user address
 * @param tokenAddress token address
 * @param chainId chain id
 * @param provider provider
 * @returns user's token balance in BalanceVault
 */
export const getUserTokenBalanceInBalanceVault = async (
  userAddress: string,
  tokenAddress: string,
  chainId: number,
  provider: any
) => {
  let accountBalance;

  if (isSupportedChain(chainId)) {
    try {
      const p = await getProvider(provider, chainId);

      const signer = p.getSigner(userAddress);
      const balanceVault = new ethers.Contract(
        ALL_VAULTS[chainId],
        vaultAbi,
        signer
      );

      accountBalance = await balanceVault.getAccountBalance(
        userAddress,
        tokenAddress
      );
    } catch (e) {}
  }

  return accountBalance;
};

/**
 * Update position's stop loss prices
 * @param userAddress user address
 * @param provider provider
 * @param factoryAddress factory address
 * @param chainId chain id
 * @param positionId position id
 * @param wantTokenIsToken0 wantToken is token 0
 * @param wantTokenDecimal wantToken decimal
 * @param borrowTokenDecimal borrowToken decimal
 * @param upperPrice stop loss upper price
 * @param lowerPrice stop loss lower price
 * @param trackTxEvent function used to track tx event
 */
export const updateFactoryStopLoss = async (
  userAddress: string,
  provider: any,
  factoryAddress: string,
  chainId: number,
  positionId: string,
  wantTokenIsToken0: boolean,
  wantTokenDecimal: number,
  borrowTokenDecimal: number,
  upperPrice: number,
  lowerPrice: number,
  trackTxEvent: (txHash: string) => void
) => {
  const upperTick = convertPriceToTick(
    upperPrice,
    borrowTokenDecimal,
    wantTokenDecimal,
    "ceil"
  );

  const lowerTick = convertPriceToTick(
    lowerPrice,
    borrowTokenDecimal,
    wantTokenDecimal,
    "floor"
  );

  const signer = provider.getSigner(userAddress);
  const factory = new ethers.Contract(factoryAddress, factoryV3Abi, signer);

  try {
    const proof = await getProof(userAddress, chainId);
    const res = await factory.updateStopLossPrice(
      positionId,
      wantTokenIsToken0 ? upperTick : -lowerTick,
      wantTokenIsToken0 ? lowerTick : -upperTick,
      proof
    );
    trackTxEvent(res.hash);
    await showPendingAlert(
      res.hash,
      t.succeed.updateExitPricesSucceed,
      provider
    );
  } catch (e: any) {
    if (e.code === "ACTION_REJECTED") {
      showError(t.error.deniedTransaction);
    } else {
      showError(t.error.updateExitPricesFailed);
    }
    return;
  }

  return;
};

/**
 * Get TVL, 24h volume and 24h fee of the Uniswap V3 liquidity pool
 * @param uniPoolAddress uniswap v3 pool address
 * @param chainId chain id
 * @returns TVL amount
 */
export const getUniV3PoolData = async (
  uniPoolAddress: string,
  chainId: number,
) => {
  let tvl = 0;
  let volume24H = 0;
  let fee24H = 0;

  try {
    const query = `
      {
        pools(where: {
          id: "${uniPoolAddress.toLowerCase()}"
        }) {
          feesUSD
          totalValueLockedUSD
          poolDayData(first: 2, orderBy: date, orderDirection: desc) {
            volumeUSD
            feesUSD
          }
        }
      }
    `
    const data = await fetch(SUBGRAPH_URLS[chainId], {
      body: JSON.stringify({
        query,
        variables: null,
      }),
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    });
    let result = (await data.json()).data.pools[0];
    // uniswap didn't fix the bug that they will consider fee as TVL,
    // so we have to do it manually.
    tvl = parseInt(result.totalValueLockedUSD) - parseInt(result.feesUSD)
    // the first day data is today, which is still in progress,
    // we should use yesterday's data.
    volume24H = parseInt(result.poolDayData[1].volumeUSD)
    fee24H = parseInt(result.poolDayData[1].feesUSD)
  } catch (e) {
    // console.log(e);
  }

  return {
    tvl,
    volume24H,
    fee24H
  };
};

/**
 * Get token amount of the Uniswap V3 liquidity pool
 * @param uniPoolAddress uniswap v3 pool address
 * @param tokenAddress token address
 * @param tokenDecimal token decimal
 * @param chainId chain id
 * @param provider provider
 * @returns token amount
 */
export const getV3PoolTokenAmount = async (
  uniPoolAddress: string,
  tokenAddress: string,
  tokenDecimal: number,
  chainId: number,
  provider: any
) => {
  let tokenAmount;

  const p = await getProvider(provider, chainId);
  const token = new ethers.Contract(tokenAddress, erc20Abi, p);
  const amount = await token.balanceOf(uniPoolAddress);
  tokenAmount = +formatUnits(amount, tokenDecimal);

  return tokenAmount;
};

/**
 * Get CAKE reward amount of the position
 * @param positionId position id
 * @param provider provider
 * @param chainId chain id
 * @param helperAddress helper address
 * @returns CAKE reward amount
 */
export const getPositionRewardAmount = async (
  positionId: string,
  provider: any,
  chainId: SupportedChainId,
  helperAddress: string
) => {
  const p = await getProvider(provider, chainId);
  const helper = new ethers.Contract(helperAddress, helperAbi, p);
  const rewardAmount = await helper.getPendingRewardTokenAmount(positionId);
  return rewardAmount;
};

/**
 * Get user's active positions
 * @param userAddress user address
 * @param factoryAddress factory address
 * @param wantTokenDecimal wantToken decimal
 * @param borrowTokenDecimal borrowToken decimal
 * @param dmoWantTokenIsToken0 wantToken is token 0
 * @param chainId chain id
 * @param provider provider
 * @returns user's active positions
 */
export const getUserActiveFactoryPositionsWithAggregator = async (
  userAddress: string,
  factoryAddress: string,
  wantTokenDecimal: number,
  borrowTokenDecimal: number,
  wantTokenSymbol: string,
  dmoWantTokenIsToken0: boolean,
  chainId: SupportedChainId,
  provider: any
) => {
  let positionIds;
  let positionInfos;

  const helperExist = !!ALL_HELPERS[chainId];

  if (userAddress) {
    const signer = provider.getSigner(userAddress);
    const factory = new ethers.Contract(factoryAddress, factoryV3Abi, signer);
    const aggregator = new ethers.Contract(
      ALL_AGGREGATORS[chainId],
      aggregatorAbi,
      signer
    );

    try {
      positionIds = await factory.getAccountPositionIds(userAddress);
    } catch (error) {
      // console.log(error);
    }

    if (positionIds?.length > 0) {
      positionInfos = await Promise.all(
        positionIds.map(async (pId: string) => {
          let rewardAmount = 0;
          let rewardAmountInWantToken = 0;

          // Get Position Information
          const {
            position: {
              positionId,
              borrowId,
              wantTokenAmountAtStart,
              reserveAmountAtStart,
              positionCreateTimestamp,
              startPriceTick,
              borrowRatio,
              reserveAmount,
              stopLossUpperPriceTick,
              stopLossLowerPriceTick,
            },
            healthFactor,
            debtValueMeasuredInWantToken: debtValue,
            positionTokenAmount: {
              borrowTokenAmount,
              wantTokenFee,
              borrowTokenFee,
            },
            tickLower,
            tickUpper,
            positionValueMeasuredInWantToken: positionValueInWantToken,
          } = await aggregator.getAllPositionInfo(factoryAddress, pId, 1);

          if (helperExist && isPanCakeSwap(chainId)) {
            rewardAmount = await getPositionRewardAmount(
              pId,
              provider,
              chainId,
              ALL_HELPERS[chainId] || ""
            );
            const rewardTokenPrice = await getTokenPriceFromBinance("CAKE");
            const wantTokenPrice = await getTokenPriceFromBinance(
              wantTokenSymbol
            );
            rewardAmountInWantToken =
              (+formatUnits(rewardAmount, 18) * rewardTokenPrice) /
              wantTokenPrice;
          }

          const entryPrice = convertTickToPrice(
            dmoWantTokenIsToken0 ? startPriceTick : -startPriceTick,
            borrowTokenDecimal - wantTokenDecimal
          );

          return {
            borrowId: borrowId.toString(),
            positionId: positionId.toString(),
            stopLossLowerPrice: convertTickToPrice(
              dmoWantTokenIsToken0
                ? stopLossLowerPriceTick
                : -stopLossUpperPriceTick,
              borrowTokenDecimal - wantTokenDecimal
            ).toString(),
            stopLossUpperPrice: convertTickToPrice(
              dmoWantTokenIsToken0
                ? stopLossUpperPriceTick
                : -stopLossLowerPriceTick,
              borrowTokenDecimal - wantTokenDecimal
            ).toString(),
            wantAmount: formatUnits(wantTokenAmountAtStart, wantTokenDecimal),
            reserveAmount: formatUnits(reserveAmount, wantTokenDecimal),
            debtValue: formatUnits(debtValue, wantTokenDecimal),
            upperTick: tickUpper.toString(),
            lowerTick: tickLower.toString(),
            upperTickPrice: convertTickToPrice(
              dmoWantTokenIsToken0 ? +tickUpper : -tickLower,
              borrowTokenDecimal - wantTokenDecimal
            ).toString(),
            lowerTickPrice: convertTickToPrice(
              dmoWantTokenIsToken0 ? +tickLower : -tickUpper,
              borrowTokenDecimal - wantTokenDecimal
            ).toString(),
            entryPrice: entryPrice.toString(),
            positionCreateTimestamp: +positionCreateTimestamp.toString(),
            healthFactor: formatUnits(healthFactor, 18),
            principal: formatUnits(borrowTokenAmount, borrowTokenDecimal),
            borrowRatio: +borrowRatio / 10000,

            // NEW
            reserveAmountAtStart: formatUnits(
              reserveAmountAtStart,
              wantTokenDecimal
            ),
            wantTokenFee: formatUnits(wantTokenFee, wantTokenDecimal),
            borrowTokenFee: formatUnits(borrowTokenFee, borrowTokenDecimal),
            stopLossUpperPriceTick: stopLossUpperPriceTick.toString(),
            stopLossLowerPriceTick: stopLossLowerPriceTick.toString(),

            // NOTICE: positionValueInWantToken includes CAKE reward
            positionValueInWantToken: (
              +formatUnits(positionValueInWantToken, wantTokenDecimal) +
              +rewardAmountInWantToken
            ).toString(),

            // Only in PCS - CAKE reward
            rewardAmount: formatUnits(rewardAmount, 18),
            rewardAmountInWantToken: rewardAmountInWantToken,
          } as UserActivePositionProps;
        })
      );
    }
  }

  return positionInfos?.sort(
    (a, b) => +b.positionCreateTimestamp - +a.positionCreateTimestamp
  );
};
