const referral = {
  totalUserReferred: "Total Users Referred",
  lastWeekTradingVolume: "Last Week’s Trading Volume",
  lastWeekReward: "Last Week’s Reward",
  createReferralCode: "Create Referral Code",
  copyReferralLink: "Copy Referral Link",
  myCode: "My Code : ",
  noReferralCodeYet: "Don’t have a referral code yet?",
  generateAndEarn:
    "Generate your referral code and earn 30% commission rebate + discount",
  referralCode: "Referral Code",
  create: "Create",
  claimed: "Claimed",
  unclaimed: "Unclaimed",
  claimReward: "Claim Rewards",
  weekNo: "Week No.",
  reward: "Reward",
  status: "Status",
  noRecord: "No Record",
  records: "Records",
  referralRebates: "Referrer Rebates",
  refereeDiscounts: "Referee Discounts",
};

export default referral;
