/**
 * Use this function to calculate the total profit of a position
 * @param wantTokenAmount the amount of wantToken
 * @param hedgeRatio the hedge ratio, e.g. 1.5x => 0.5, 2x => 1
 * @param duration the duration of the position in days
 * @param range the range of the position, e.g. 15% => 15
 * @param k the interval used to calculate the price change,
 *          e.g. k = 1.1 => 10% price change, k = 0.9 => -10% price change
 * @param marketPrice the current price of the borrowToken
 * @param feeApy the fee apy of this pair (from ginza api)
 * @param borrowingRate the borrowing rate of the borrowToken
 * @returns the total profit of the position
 */
const getTotalProfit = (
  wantTokenAmount: number,
  hedgeRatio: number,
  duration: number,
  range: number,
  k: number,
  marketPrice: number,
  feeApy: number,
  borrowingRate: number
) => {
  const entryPrice = marketPrice;
  const price = entryPrice * k;
  const max_price = entryPrice * (1 + range / 100);
  const min_price = entryPrice / (1 + range / 100);
  const max_k = max_price / entryPrice;
  const min_k = min_price / entryPrice;

  const hodl_a_amount = (wantTokenAmount * (1 + hedgeRatio)) / 2 / entryPrice;
  const hodl_b_amount = (wantTokenAmount * (1 + hedgeRatio)) / 2;

  const Lx =
    entryPrice > max_price
      ? hodl_b_amount / (entryPrice ** 0.5 - min_price ** 0.5)
      : (hodl_a_amount * (entryPrice ** 0.5 * max_price ** 0.5)) /
        (max_price ** 0.5 - entryPrice ** 0.5);

  const entry_total = hodl_a_amount * entryPrice + hodl_b_amount;

  const get_v3_a_amount = () => {
    let amount;
    if (k < min_k) {
      amount =
        (Lx * (max_price ** 0.5 - min_price ** 0.5)) /
        (max_price ** 0.5 * min_price ** 0.5);
    } else if (k > max_k) {
      amount =
        (Lx * (max_price ** 0.5 - max_price ** 0.5)) /
        (max_price ** 0.5 * max_price ** 0.5);
    } else {
      amount =
        (Lx * (max_price ** 0.5 - price ** 0.5)) /
        (max_price ** 0.5 * price ** 0.5);
    }
    return amount;
  };

  const get_v3_b_amount = () => {
    let amount;
    if (k < min_k) {
      amount = Lx * (min_price ** 0.5 - min_price ** 0.5);
    } else if (k > max_k) {
      amount = Lx * (max_price ** 0.5 - min_price ** 0.5);
    } else {
      amount = Lx * (price ** 0.5 - min_price ** 0.5);
    }
    return amount;
  };

  const v3_a_amount = get_v3_a_amount();
  const v3_a_value = v3_a_amount * price;
  const v3_b_amount = get_v3_b_amount();
  const v3_total = v3_a_value + v3_b_amount;

  const eth_borrow_amount = (wantTokenAmount * hedgeRatio) / entryPrice;
  const fee_income =
    ((hodl_a_amount * entryPrice + hodl_b_amount) * feeApy * duration) / 365;
  const lending_position = eth_borrow_amount * (entryPrice - price);
  const lending_interest_cost =
    (-(eth_borrow_amount * borrowingRate * duration) / 365) * price;

  const total_profit =
    v3_total -
    entry_total +
    fee_income +
    lending_position +
    lending_interest_cost;

  return total_profit;
};

/**
 *
 * @param wantTokenAmount the amount of wantToken
 * @param hedgeRatio the hedge ratio, e.g. 1.5x => 0.5, 2x => 1
 * @param duration the duration of the position in days
 * @param range the range of the position, e.g. 15% => 15
 * @param k the interval used to calculate the price change,
 *         e.g. k = 1.1 => 10% price change, k = 0.9 => -10% price change
 * @param marketPrice the current price of the borrowToken
 * @param feeApy the fee apy of this pair (from ginza api)
 * @param borrowingRate the borrowing rate of the borrowToken
 * @param stopLossLowerPrice the lower stop loss price
 * @param stopLossUpperPrice the upper stop loss price
 * @returns the total profit of the position
 */
const getTotalProfitWithStopLossPrice = (
  wantTokenAmount: number,
  hedgeRatio: number,
  duration: number,
  range: number,
  k: number,
  marketPrice: number,
  feeApy: number,
  borrowingRate: number,
  stopLossLowerPrice: number,
  stopLossUpperPrice: number
) => {
  const estiProfit = getTotalProfit(
    wantTokenAmount,
    hedgeRatio,
    duration,
    range,
    k,
    marketPrice,
    feeApy,
    borrowingRate
  );

  const stopLossUpperPriceProfit = getTotalProfit(
    wantTokenAmount,
    hedgeRatio,
    duration,
    range,
    stopLossUpperPrice / marketPrice, // k
    marketPrice,
    feeApy,
    borrowingRate
  );

  const stopLossLowerPriceProfit = getTotalProfit(
    wantTokenAmount,
    hedgeRatio,
    duration,
    range,
    stopLossLowerPrice / marketPrice, // k
    marketPrice,
    feeApy,
    borrowingRate
  );

  if (k < 1) {
    return !stopLossLowerPrice
      ? estiProfit
      : Math.max(estiProfit, stopLossLowerPriceProfit);
  } else if (k > 1) {
    return !stopLossUpperPrice
      ? estiProfit
      : Math.max(estiProfit, stopLossUpperPriceProfit);
  } else {
    return estiProfit;
  }
};

export default getTotalProfitWithStopLossPrice;
