import { toast } from "react-toastify";
import { waitForTransaction } from "./api/common";
import { alertText } from "./Toast";

export const showPendingAlert = async (
  hash: string,
  successText: string,
  provider: any
) => {
  const id = toast.loading(
    alertText(`Transaction hash ${hash} is processing...`)
  );
  try {
    const res = await waitForTransaction(provider, hash);
    if (res.status === 1) {
      toast.update(id, {
        render: alertText(successText),
        type: "success",
        isLoading: false,
        autoClose: 5000,
        delay: 100,
      });
      return true;
    } else {
      toast.update(id, {
        render: alertText("Transaction Reverted"),
        type: "error",
        isLoading: false,
        autoClose: 5000,
        delay: 100,
      });
      return false;
    }
  } catch (e: any) {
    // console.log(e.messages);
  }
};

export const showErrorAlert = async (errorMsg: string) => {
  toast.warn(errorMsg, {
    isLoading: false,
    autoClose: 5000,
    position: "top-center",
    theme: "dark",
  });
};

export const showSuccessAlert = async (successMsg: string) => {
  toast.success(successMsg, {
    isLoading: false,
    autoClose: 5000,
    position: "top-center",
    theme: "dark",
  });
};
