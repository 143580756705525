import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LabelWithHint from "../../../components/LabelWithHint";
import Colors from "../../../styles/Colors";
import { convertTickToPrice } from "../../../utils/api/common";
import { getTargetTick } from "../../../utils/api/factoryV3Api";
import { PairProps } from "../../../utils/constant/pairs";
import t from "../../../utils/content";
import { InfoDiv, Input, InputContainer, Label, PanelBtn, Unit } from "./CommonParts";

type Props = {
  isLiteMode: boolean;
  pair: PairProps;
  minRange: number;
  range: number;
  setRange: (v: number) => void;
  tick: number;
  tickSpacing: number;
  inputRef: React.RefObject<HTMLInputElement>;
  lowerPrice: number | null;
  setLowerPrice: (v: number | null) => void;
  upperPrice: number | null;
  setUpperPrice: (v: number | null) => void;
};

const Range: React.FC<Props> = (props) => {
  const {
    isLiteMode,
    pair,
    range,
    minRange,
    setRange,
    tick,
    tickSpacing,
    inputRef,
    lowerPrice,
    setLowerPrice,
    upperPrice,
    setUpperPrice,
  } = props;
  const {
    liteModeRanges,
    wantToken: { tokenSymbol: wTokenSymbol, tokenDecimal: wTokenDecimal },
    borrowToken: { tokenSymbol: bTokenSymbol, tokenDecimal: bTokenDecimal },
  } = pair;

  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    const getLowerPrice = (_range: number) => {
      let _lowerPrice = null;
      if (_range && tickSpacing && tick) {
        const targetTick = getTargetTick(_range / 100, tickSpacing);
        const _tick = tick - targetTick;
        const _decimal = bTokenDecimal - wTokenDecimal;
        _lowerPrice = convertTickToPrice(_tick, _decimal);
      }
      return _lowerPrice;
    };

    if (range) {
      const _lowerPrice = getLowerPrice(range);
      setLowerPrice(_lowerPrice);
    }
  }, [range, bTokenDecimal, wTokenDecimal, tickSpacing, tick, setLowerPrice]);

  useEffect(() => {
    const getUpperPrice = (_range: number) => {
      let _upperPrice = null;
      if (_range && tickSpacing && tick) {
        const targetTick = getTargetTick(_range / 100, tickSpacing);
        const _tick = tick + targetTick;
        const _decimal = bTokenDecimal - wTokenDecimal;
        _upperPrice = convertTickToPrice(_tick, _decimal);
      }
      return _upperPrice;
    };

    if (range) {
      const _upperPrice = getUpperPrice(range);
      setUpperPrice(_upperPrice);
    }
  }, [range, bTokenDecimal, wTokenDecimal, tickSpacing, tick, setUpperPrice]);

  const handleCheckRangeInput = () => {
    const rangeMeetsTickSpacing = (range * 100) % tickSpacing === 0;
    if (rangeMeetsTickSpacing) {
      return;
    }

    const upperRange =
      (Math.ceil((range * 100) / tickSpacing) * tickSpacing) / 100;
    const lowerRange =
      (Math.floor((range * 100) / tickSpacing) * tickSpacing) / 100;

    const upperRangeDiff = upperRange - range;
    const lowerRangeDiff = range - lowerRange;

    if (upperRangeDiff >= lowerRangeDiff) {
      setRange(lowerRange);
      if (inputRef.current) {
        inputRef.current.value = lowerRange.toString();
      }
    } else {
      setRange(upperRange);
      if (inputRef.current) {
        inputRef.current.value = upperRange.toString();
      }
    }
  };

  return (
    <div>
      {isLiteMode ? (
        <>
          <LabelWithHint
            labelComponent={<Label>{t.factory.range}</Label>}
            hintText={
              <span>
                {t.factory.rangeDesc}
                {isLiteMode && (
                  <>
                    <br />
                    {t.factory.rangeLiteModeHint}
                  </>
                )}
                <br />
                {t.factory.rangeNotice}
              </span>
            }
          />
          <LeverageButtonsContainer>
            {liteModeRanges
              .map((r) => r.range)
              .map((r) => (
                <PanelBtn
                  key={r}
                  isSelect={range === r}
                  onClick={() => setRange(r)}
                >
                  {r + "%"}
                </PanelBtn>
              ))}
          </LeverageButtonsContainer>
        </>
      ) : (
        <>
          <LabelWithHint
            labelComponent={<Label>{t.factory.range}</Label>}
            hintText={
              <span>
                {t.factory.rangeDesc}
                {isLiteMode && (
                  <>
                    <br />
                    {t.factory.rangeLiteModeHint}
                  </>
                )}
                <br />
                {t.factory.rangeNotice}
              </span>
            }
          />
          <InputContainer $isFocus={isFocus}>
            <Input
              value={range === 0 ? "" : range}
              ref={inputRef}
              type="number"
              placeholder={`${minRange} ~ 50`}
              onChange={(e) => {
                setRange(+e.currentTarget.value);
              }}
              onWheel={(e) => e.currentTarget.blur()}
              onBlur={() => { handleCheckRangeInput(); setIsFocus(false) }}
              onFocus={() => setIsFocus(true)}
            />
            <Unit>%</Unit>
          </InputContainer>
        </>
      )}
      <PricesContainer>
        <PriceContainer>
          <PriceTag>{t.factory.minPrice}</PriceTag>
          <PriceValue>
            {lowerPrice ? `${lowerPrice.toString().slice(0, 8)}` : "-"}
          </PriceValue>
          <PriceTag>{`${wTokenSymbol} ${t.factory.per} ${bTokenSymbol}`}</PriceTag>
        </PriceContainer>
        <PriceContainer>
          <PriceTag>{t.factory.maxPrice}</PriceTag>
          <PriceValue>
            {upperPrice ? `${upperPrice.toString().slice(0, 8)}` : "-"}
          </PriceValue>
          <PriceTag>{`${wTokenSymbol} ${t.factory.per} ${bTokenSymbol}`}</PriceTag>
        </PriceContainer>
      </PricesContainer>
    </div>
  );
};

export default Range;

const LeverageButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 8px;
`;

const PricesContainer = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 8px;
`;

const PriceContainer = styled(InfoDiv)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 12px;
`;

const PriceTag = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: ${Colors.gray3};
  text-align: center;
`;

const PriceValue = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: ${Colors.ivory};
`;
