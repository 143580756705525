import {
  wethToken,
  usdcToken,
  uniToken,
  daiToken,
  gmxToken,
  derc20Token,
  dusdcoken,
  arbToken,
  usdtToken,
  busdToken,
  cakeToken,
  wbnbToken,
  ldoToken
} from "./tokens";
import { ALL_TOKENS } from "./externalAddresses";
import { SupportedChainId } from "./chains";

const ARBITRUM_VAULTS = [
  {
    ...usdcToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].USDC,
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
  },
  {
    ...wethToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].WETH,
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
  },
  {
    ...gmxToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].GMX,
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
  },
  {
    ...arbToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].ARB,
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
  },
  {
    ...ldoToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].LDO,
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
  }
];

const GOERLI_VAULTS = [
  {
    ...uniToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.GOERLI].UNI,
    deployedChainId: SupportedChainId.GOERLI,
  },
  {
    ...daiToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.GOERLI].DAI,
    deployedChainId: SupportedChainId.GOERLI,
  },
  {
    ...wethToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.GOERLI].WETH,
    deployedChainId: SupportedChainId.GOERLI,
  },
];

const POLYGON_MUMBAI_VAULTS = [
  {
    ...derc20Token,
    tokenAddress: ALL_TOKENS[SupportedChainId.POLYGON_MUMBAI].DERC20,
    deployedChainId: SupportedChainId.POLYGON_MUMBAI,
  },
];

const OPTIMISM_GOERLI_VAULTS = [
  {
    ...dusdcoken,
    tokenAddress: ALL_TOKENS[SupportedChainId.OPTIMISM_GOERLI].DUSDC,
    deployedChainId: SupportedChainId.OPTIMISM_GOERLI,
  },
];

const POLYGON_VAULTS = [
  {
    ...usdcToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.POLYGON].USDC,
    deployedChainId: SupportedChainId.POLYGON,
  },
  {
    ...wethToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.POLYGON].WETH,
    deployedChainId: SupportedChainId.POLYGON,
  },
];

const BNB_VAULTS = [
  {
    ...cakeToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_SMART_CHAIN].CAKE,
    deployedChainId: SupportedChainId.BINANCE_SMART_CHAIN,
  },
  {
    ...usdtToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_SMART_CHAIN].USDT,
    deployedChainId: SupportedChainId.BINANCE_SMART_CHAIN,
  },
  {
    ...wbnbToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_SMART_CHAIN].WBNB,
    deployedChainId: SupportedChainId.BINANCE_SMART_CHAIN,
    isWrapToken: true,
  },
];

const BNB_TESTNET_VAULTS = [
  {
    ...busdToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_TESTNET].BUSD,
    deployedChainId: SupportedChainId.BINANCE_TESTNET,
  },
  {
    ...wbnbToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_TESTNET].WBNB,
    deployedChainId: SupportedChainId.BINANCE_TESTNET,
    isWrapToken: true,
  },
];

export const ALL_VAULT_TOKENS: { [key: number]: VaultProps[] } = {
  [SupportedChainId.ARBITRUM_ONE]: ARBITRUM_VAULTS,
  [SupportedChainId.GOERLI]: GOERLI_VAULTS,
  [SupportedChainId.POLYGON_MUMBAI]: POLYGON_MUMBAI_VAULTS,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_VAULTS,
  [SupportedChainId.POLYGON]: POLYGON_VAULTS,
  [SupportedChainId.BINANCE_SMART_CHAIN]: BNB_VAULTS,
  [SupportedChainId.BINANCE_TESTNET]: BNB_TESTNET_VAULTS,
};

export type VaultProps = {
  tokenSymbol: string;
  tokenFullName: string;
  tokenDecimal: number;
  tokenAddress: string;
  tokenIcon: string;
  deployedChainId: number;
  isWrapToken?: boolean;
};
