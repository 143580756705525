import React, { useEffect } from "react";
import styled from "styled-components";
import "./App.css";
import { GlobalStyle, ResetStyle } from "./styles/globalStyle";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Colors from "./styles/Colors";

import PrivateRoute from "./components/PrivateRoute";

import Layout from "./components/Layout";
import NoMatchPage from "./pages/NoMatchPage";
import PoolPage from "./pages/PoolPage";
import FactoryPage from "./pages/FactoryPage";
import EvieVaultPage from "./pages/EvieVaultPage";
import StatsPage from "./pages/StatsPage";
import AccountPage from "./pages/AccountPage";
import LiFiPage from "./pages/LiFiPage";
import LeaderboardPage from "./pages/LeaderboardPage";
import FactoriesPage from "./pages/FactoriesPage";
import HomePage from "./pages/HomePage";
import { identify, Identify } from "@amplitude/analytics-node";
import { useWeb3React } from "@web3-react/core";
import { v4 as uuid } from "uuid";

const App = () => {
  const { account } = useWeb3React();

  useEffect(() => {
    const deviceId = localStorage.getItem("deviceId") || uuid();
    localStorage.setItem("deviceId", deviceId);

    const identifyObj = new Identify();
    identify(identifyObj, {
      user_id: account || undefined,
      device_id: deviceId,
    });
  }, [account]);

  return (
    <Container>
      <ResetStyle />
      <GlobalStyle />
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/factory" element={<FactoriesPage />} />
            <Route
              path="/factory/:chainName/:pairId"
              element={<FactoryPage />}
            />
            <Route path="/factory/:chainName" element={<FactoriesPage />} />

            <Route path="/pool" element={<PoolPage />} />
            <Route path="/pool/:chainName" element={<PoolPage />} />

            <Route path="/statistics" element={<StatsPage />} />
            <Route path="/statistics/:chainName" element={<StatsPage />} />
            <Route path="/vault/:chainName" element={<EvieVaultPage />} />

            <Route
              path="/account"
              element={
                <PrivateRoute redirectPath="/">
                  <AccountPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/account/:accTab"
              element={
                <PrivateRoute redirectPath="/">
                  <AccountPage />
                </PrivateRoute>
              }
            />

            <Route path="/swap/*" element={<LiFiPage />} />

            <Route path="/leaderboard" element={<LeaderboardPage />} />
            <Route
              path="/leaderboard/:chainName"
              element={<LeaderboardPage />}
            />

            <Route path="/" element={<HomePage />} />
            <Route path="/:chainName" element={<HomePage />} />

            <Route path="*" element={<NoMatchPage />} />
            {/* <Route path="/" element={<NavigateWithQuery to="/factory" />} /> */}
          </Routes>
        </Layout>
        <StyledContainer
          position="bottom-left"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
          transition={Flip}
        />
      </BrowserRouter>
    </Container>
  );
};

export default App;

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
`;

const StyledContainer = styled(ToastContainer).attrs({})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    background-color: ${Colors.gray6};
    color: ${Colors.ivory};
    border: 1px solid ${Colors.gray5};
  }
  .Toastify__toast-body {
    font-size: 16px;
    line-height: 22px;
  }
  @media (min-width: 768px) {
    width: 70%;
    max-width: 400px;
  }
`;
