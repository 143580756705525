import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import {
  getExchangeRate,
  getUserPoolShares,
} from "../utils/api/lendingPoolApi";
import { SupportedChainId, isSupportedChain } from "../utils/constant/chains";
import { getTokenPriceFromBinance } from "../utils/api/common";
import { ALL_POOLS } from "../utils/constant/pools";
import { ALL_PAIRS } from "../utils/constant/pairs";
import { getUserActiveFactoryPositionsWithAggregator } from "../utils/api/factoryV3Api";
import { ALL_VAULT_TOKENS } from "../utils/constant/vaults";
import { getAccountBalanceFromBalanceVault } from "../utils/api/balanceVaultApi";
import { formatUnits } from "ethers/lib/utils";

const useUserAssets = () => {
  const [lendingPoolBalance, setLendingPoolBalance] = useState<number | null>(
    null
  );
  const [factoryBalance, setFactoryBalance] = useState<number | null>(null);
  const [balanceVaultBalance, setBalanceVaultBalance] = useState<number | null>(
    null
  );

  const isAllFetched =
    lendingPoolBalance !== null &&
    factoryBalance !== null &&
    balanceVaultBalance !== null;

  const { account, chainId, provider } = useWeb3React();

  const getLendingPoolBalance = useCallback(
    async (
      _account: string,
      _chainId: SupportedChainId,
      _lendingPool: string,
      _decimal: number,
      _symbol: string
    ) => {
      const shares = await getUserPoolShares(
        _account,
        _lendingPool,
        _decimal,
        provider
      );
      const rate = await getExchangeRate(_lendingPool, _chainId, provider);
      const price = await getTokenPriceFromBinance(_symbol);
      const balance = +shares * +rate * +price
      return balance;
    },
    [provider]
  );

  const getAllLendingPoolBalance = useCallback(
    async (_account: string, _chainId: SupportedChainId) => {
      let _lendingPoolBalance = 0;
      await Promise.all(
        ALL_POOLS[_chainId].map(async (pool) => {
          const _balance = await getLendingPoolBalance(
            _account,
            _chainId,
            pool.lendingPoolAddress,
            pool.tokenDecimal,
            pool.tokenSymbol
          );
          _lendingPoolBalance += Number(_balance);
        })
      );
      setLendingPoolBalance(+_lendingPoolBalance.toFixed(2));
    },
    [getLendingPoolBalance]
  );

  useEffect(() => {
    if (!account || !isSupportedChain(chainId)) return;
    getAllLendingPoolBalance(account, chainId);
  }, [account, chainId, getAllLendingPoolBalance]);

  const getPositionBalance = useCallback(
    async (
      _account: string,
      _chainId: SupportedChainId,
      _factory: string,
      _wDecimal: number,
      _bDecimal: number,
      _wTIsToken0: boolean,
      _wSymbol: string
    ) => {
      let _positionValueInWantToken = 0;
      const _positions = await getUserActiveFactoryPositionsWithAggregator(
        _account,
        _factory,
        _wDecimal,
        _bDecimal,
        _wSymbol,
        _wTIsToken0,
        _chainId,
        provider
      );
      if (!!_positions && _positions.length > 0) {
        _positions.forEach((p) => {
          _positionValueInWantToken += +p.positionValueInWantToken;
        });
      }
      const price = await getTokenPriceFromBinance(_wSymbol);
      return _positionValueInWantToken * price;
    },
    [provider]
  );

  const getAllFactoryPositionsBalance = useCallback(async () => {
    if (!account || !isSupportedChain(chainId) || !ALL_PAIRS[chainId]) return;
    let _factoryBalance = 0;

    await Promise.all(
      ALL_PAIRS[chainId].map(async (pair) => {
        const { factoryAddress, wantToken, borrowToken, wantTokenIsToken0 } =
          pair;
        const _balance = await getPositionBalance(
          account,
          chainId,
          factoryAddress,
          wantToken.tokenDecimal,
          borrowToken.tokenDecimal,
          wantTokenIsToken0,
          wantToken.tokenSymbol
        );
        _factoryBalance += Number(_balance);
      })
    );
    setFactoryBalance(+_factoryBalance.toFixed(2));
  }, [account, chainId, getPositionBalance]);

  useEffect(() => {
    if (!account || !isSupportedChain(chainId) || !ALL_PAIRS[chainId]) return;
    getAllFactoryPositionsBalance();
  }, [account, chainId, getAllFactoryPositionsBalance]);

  const getBalanceVaultBalance = useCallback(
    async (
      _account: string,
      _chainId: SupportedChainId,
      _tokenAddress: string,
      _tokenDecimal: number,
      _tokenSymbol: string
    ) => {
      const _balance = await getAccountBalanceFromBalanceVault(
        _account,
        _tokenAddress,
        _chainId,
        provider
      );
      const price = await getTokenPriceFromBinance(_tokenSymbol);
      return +formatUnits(_balance, _tokenDecimal) * price;
    },
    [provider]
  );

  const getAllBalanceVaultBalance = useCallback(async () => {
    if (!account || !isSupportedChain(chainId) || !ALL_PAIRS[chainId]) return;
    let _balanceVaultBalance = 0;

    await Promise.all(
      ALL_VAULT_TOKENS[chainId].map(async (token) => {
        const _balance = await getBalanceVaultBalance(
          account,
          chainId,
          token.tokenAddress,
          token.tokenDecimal,
          token.tokenSymbol
        );
        _balanceVaultBalance += Number(_balance);
      })
    );
    setBalanceVaultBalance(+_balanceVaultBalance.toFixed(2));
  }, [account, chainId, getBalanceVaultBalance]);

  useEffect(() => {
    if (!account || !isSupportedChain(chainId) || !ALL_PAIRS[chainId]) return;
    getAllBalanceVaultBalance();
  }, [account, chainId, getAllBalanceVaultBalance]);

  return {
    lendingPoolBalance,
    factoryBalance,
    balanceVaultBalance,
    isAllFetched,
  };
};

export default useUserAssets;
