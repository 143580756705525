const amplitude = {
  // Page
  accountPage: "Account Page",
  factoryPage: "Factory Page",
  leaderboardPage: "Leaderboard Page",
  poolPage: "Pool Page",
  statsPage: "Stats Page",

  // Action
  clickOpenPositionBtn: "Click Open Position Button",
  clickSimulateBtn: "Click Simulate Button",
  clickClosePositionBtn: "Click Close Position Button",
  clickUpdateExitPricesBtn: "Click Update Exit Prices Button",
  clickAddCollateralBtn: "Click Add Collateral Button",
  clickRemoveCollateralBtn: "Click Remove Collateral Button",
  clickWithdrawBtn: "Click Withdraw Button",
  clickDepositBtn: "Click Deposit Button",
  clickApproveTokenBtn: "Click Approve Token Button",
  clickApproveFactoryBtn: "Click Approve Factory Button",
  clickCollectFeeBtn: "Click Collect Fee Button",
  clickWrapTokenBtn: "Click Wrap Token Button",
  clickUnwrapTokenBtn: "Click Unwrap Token Button",
  clickOfficialBridgeBtn: "Click Official Bridge Button",
  clickLiFiBridgeBtn: "Click LiFi Bridge Button",
  clickDisconnectWalletBtn: "Click Disconnect Wallet Button",
  clickNavBtn: "Click Nav Button",
  clickSwitchPairBtn: "Click Switch Pair Button",
  clickTwitterBtn: "Click Twitter Button",
  clickDiscordBtn: "Click Discord Button",
  clickStakeBtn: "Click Stake Button",
  stakeToken: "Stake Token",
  clickUnstakeBtn: "Click Unstake Button",
  unstakeToken: "Unstake Token",
  clickClaimRewardBtn: "Click Claim Reward Button",
  claimReward: "Claim Reward",

  // Tx Event
  openPosition: "Open Position",
  depositToken: "Deposit Token",
  withdrawToken: "Withdraw Token",
  addCollateral: "Add Collateral",
  removeCollateral: "Remove Collateral",
  updateExitPrices: "Update Exit Prices",
  approveFactory: "Approve Factory",
  closePosition: "Close Position",
  unwrapToken: "Unwrap Token",
  wrapToken: "Wrap Token",
  connectWallet: "Connect Wallet",
  collectFee: "Collect Fee",

  // Connect Wallet
  clickConnectWalletBtn: "Click Connect Wallet Button",
  selectMetaMask: "Select MetaMask",
  selectWalletConnect: "Select WalletConnect",

  liteMode: "Lite Mode",
  proMode: "Pro Mode",
  metamask: "MetaMask",
  walletConnect: "WalletConnect",
};

export default amplitude;
