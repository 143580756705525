import React, { Fragment } from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import bear from "../../images/bear.png";
import bull from "../../images/bull.png";
import LabelWithHint from "../../components/LabelWithHint";
import t from "../../utils/content";

export const Leverage20: React.FC<{
  borrowTokenSymbol: string;
  wantTokenSymbol: string;
  wantTokenIsToken0: boolean;
}> = ({ borrowTokenSymbol, wantTokenSymbol, wantTokenIsToken0 }) => {
  const isStableCoin = borrowTokenSymbol.toUpperCase() === "USDC";

  return (
    <Fragment>
      <Image src={isStableCoin ? bull : bear} />
      <LabelWithHint
        labelComponent={
          <Unit>
            {isStableCoin
              ? `${t.factory.bullishOn} ${wantTokenSymbol}`
              : `${t.factory.bearishOn} ${borrowTokenSymbol}`}
          </Unit>
        }
        hintText={
          <span>
            {`${t.factory.leverage20Deposit} ${wantTokenSymbol} ${t.factory.leverage20OpenPosition}`}
            <br />
            <Ol>
              <li>{`${t.factory.leverage20Borrow} ${wantTokenSymbol} ${t.factory.worthOf} ${borrowTokenSymbol} ${t.factory.fromDiamondProtocol}`}</li>
              <li>{`${t.factory.leverage20Sell} ${wantTokenSymbol} ${t.factory.worthOf} ${borrowTokenSymbol} ${t.factory.for} ${wantTokenSymbol} ${t.factory.onUniswap}`}</li>
              <li>
                {`${t.factory.leverage20ProvideWant} ${wantTokenSymbol} ${
                  t.factory.leverage20ProvideBorrow
                } ${wantTokenSymbol} ${
                  t.factory.worthOf
                } ${borrowTokenSymbol} ${t.factory.toUniswap} ${
                  wantTokenIsToken0 ? borrowTokenSymbol : wantTokenSymbol
                }/${wantTokenIsToken0 ? wantTokenSymbol : borrowTokenSymbol}
                ${t.factory.pool}`}
              </li>
            </Ol>
          </span>
        }
      />
    </Fragment>
  );
};

export const Leverage15: React.FC<{
  borrowTokenSymbol: string;
  wantTokenSymbol: string;
  wantTokenIsToken0: boolean;
}> = ({ borrowTokenSymbol, wantTokenSymbol, wantTokenIsToken0 }) => {
  const isStableCoin = borrowTokenSymbol.toUpperCase() === "USDC";

  return (
    <Fragment>
      <Image src={isStableCoin ? bull : bear} />
      <LabelWithHint
        labelComponent={
          <Unit>
            {isStableCoin
              ? `${t.factory.bullishOn} ${wantTokenSymbol}`
              : `${t.factory.bearishOn} ${borrowTokenSymbol}`}
          </Unit>
        }
        hintText={
          <span>
            {`${t.factory.leverage15Deposit} ${wantTokenSymbol} ${t.factory.leverage15OpenPosition}`}
            <br />
            <Ol>
              <li>{`${t.factory.leverage15Borrow} ${wantTokenSymbol} ${t.factory.worthOf} ${borrowTokenSymbol} ${t.factory.fromDiamondProtocol}`}</li>
              <li>
                {`${t.factory.leverage15Sell} ${wantTokenSymbol} ${t.factory.worthOf} ${borrowTokenSymbol} ${t.factory.for} ${wantTokenSymbol} ${t.factory.onUniswap}`}
              </li>
              <li>
                {`${t.factory.leverage15ProvideWant} ${wantTokenSymbol} ${
                  t.factory.leverage15ProvideBorrow
                } ${wantTokenSymbol} ${
                  t.factory.worthOf
                } ${borrowTokenSymbol} ${t.factory.toUniswap} ${
                  wantTokenIsToken0 ? borrowTokenSymbol : wantTokenSymbol
                }/${wantTokenIsToken0 ? wantTokenSymbol : borrowTokenSymbol} ${
                  t.factory.pool
                }`}
              </li>
            </Ol>
          </span>
        }
      />
    </Fragment>
  );
};

export const Leverage10: React.FC<{
  borrowTokenSymbol: string;
  wantTokenSymbol: string;
  wantTokenIsToken0: boolean;
}> = ({ borrowTokenSymbol, wantTokenSymbol, wantTokenIsToken0 }) => {
  const isStableCoin = borrowTokenSymbol.toUpperCase() === "USDC";

  return (
    <Fragment>
      <Image src={bull} />
      <Unit>/</Unit>
      <Image src={bear} />
      <LabelWithHint
        labelComponent={
          <Unit>{`${t.factory.neutralOn} ${
            isStableCoin ? wantTokenSymbol : borrowTokenSymbol
          }`}</Unit>
        }
        hintText={
          <span>
            {`${t.factory.leverage10Deposit} ${wantTokenSymbol} ${t.factory.leverage10OpenPosition}`}
            <br />
            <Ol>
              <li>{`${t.factory.leverage10Borrow} ${wantTokenSymbol} ${t.factory.worthOf} ${borrowTokenSymbol} ${t.factory.fromDiamondProtocol}`}</li>
              <li>
                {`${t.factory.leverage10ProvideWant} ${wantTokenSymbol} ${
                  t.factory.leverage10ProvideBorrow
                } ${wantTokenSymbol} ${
                  t.factory.worthOf
                } ${borrowTokenSymbol} ${t.factory.toUniswap} ${
                  wantTokenIsToken0 ? borrowTokenSymbol : wantTokenSymbol
                }/${wantTokenIsToken0 ? wantTokenSymbol : borrowTokenSymbol}
                ${t.factory.pool}`}
              </li>
            </Ol>
          </span>
        }
      />
    </Fragment>
  );
};

export const Leverage05: React.FC<{
  borrowTokenSymbol: string;
  wantTokenSymbol: string;
  wantTokenIsToken0: boolean;
}> = ({ borrowTokenSymbol, wantTokenSymbol, wantTokenIsToken0 }) => {
  const isStableCoin = borrowTokenSymbol.toUpperCase() === "USDC";

  return (
    <Fragment>
      <Image src={isStableCoin ? bear : bull} />
      <LabelWithHint
        labelComponent={
          <Unit>
            {isStableCoin
              ? `${t.factory.bearishOn} ${wantTokenSymbol}`
              : `${t.factory.bullishOn} ${borrowTokenSymbol}`}
          </Unit>
        }
        hintText={
          <span>
            {`${t.factory.leverage05Deposit} ${wantTokenSymbol} ${t.factory.leverage05OpenPosition}`}
            <br />
            <Ol>
              <li>{`${t.factory.leverage05Borrow} ${wantTokenSymbol} ${t.factory.worthOf} ${borrowTokenSymbol} ${t.factory.fromDiamondProtocol}`}</li>
              <li>{`${t.factory.leverage05Sell} ${wantTokenSymbol} ${t.factory.for} ${borrowTokenSymbol} ${t.factory.onUniswap}`}</li>
              <li>
                {`${t.factory.leverage05ProvideWant} ${wantTokenSymbol} ${
                  t.factory.leverage05ProvideBorrow
                } ${wantTokenSymbol} ${
                  t.factory.worthOf
                } ${borrowTokenSymbol} ${t.factory.toUniswap} ${
                  wantTokenIsToken0 ? borrowTokenSymbol : wantTokenSymbol
                }/${wantTokenIsToken0 ? wantTokenSymbol : borrowTokenSymbol} ${
                  t.factory.pool
                }`}
              </li>
            </Ol>
          </span>
        }
      />
    </Fragment>
  );
};

const Unit = styled.span`
  font-size: 13px;
  line-height: 22px;
  color: ${Colors.ivory};
  margin: 0 8px;
`;

const Image = styled.img`
  width: 16px;
  height: 16px;
`;

const Ol = styled.ol`
  list-style-type: decimal;
  padding-left: 12px;
`;
