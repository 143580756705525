import { createSlice } from "@reduxjs/toolkit";

export type UserActivePositionProps = {
  positionId: string;
  wantAmount: string;
  reserveAmount: string;
  stopLossLowerPrice: string;
  stopLossUpperPrice: string;
  debtValue: string;
  lowerTick: string;
  upperTick: string;
  healthFactor: string;
  upperTickPrice: string;
  lowerTickPrice: string;
  entryPrice: string;
  borrowId: string;
  principal: string;
  borrowRatio: number;
  reserveAmountAtStart: string;
  positionCreateTimestamp: number;
  wantTokenFee: string;
  borrowTokenFee: string;
  positionValueInWantToken: string;
  rewardAmount: string;
  rewardAmountInWantToken: number;
};

export type UserClosedPositionProps = {
  positionId: number;
  startWantAmount: string;
  endWantAmount: string;
  reserveAmount: string;
  upperTickPrice: number;
  lowerTickPrice: number;
  borrowRatio: number;
  startTime: number;
  closeTime: number;
  startPrice: number;
  closePrice: number;
  eventName: string;
  reserveWantAmount: string;
  wantTokenFee: string;
  borrowTokenFee: string;
  cakeReward: string;
  rewardTokenValueInWantToken: string;
};

type FactoryProps = {
  // used in Factory page
  userActivePositions: {
    [key: number]: { [key: string]: UserActivePositionProps[] };
  };
  userClosedPositions: {
    [key: number]: {
      [key: string]: UserClosedPositionProps[];
    };
  };
  userActivePositionIsFetched: boolean;
  userClosedPositionIsFetched: boolean;
  userPositionEntryTicks: { [key: string]: number };

  // used in Stats page
  allClosedPositions: {
    [key: number]: {
      records: [];
      totalRecord: number;
    };
  };
  closedPositionIsFetched: boolean;
  allActivePositions: {
    [key: number]: {
      records: [];
      totalRecord: number;
    };
  };
  activePositionIsFetched: boolean;
  totalUsdcInFactory: number;
  unibotTvl: { [key: number]: number };
  unibotCumulativeFee: { [key: number]: number };

  // used in Factory page - uniswap pool info
  uniPoolStats: {
    [key: string]: {
      volumeIn24H: number;
      feeIn24H: number;
      tvl: number;
    };
  };

  // used in Leaderboard page
  topUsersByPnL: {
    [key: string]: {
      [key: string]: { leftValue: string; rightValue: number; url?: string }[];
    };
  };
  topUserByPnLIsFetched: boolean;
  topOpenedByEarnedFee: {
    [key: string]: {
      [key: string]: { leftValue: string; rightValue: number; url?: string }[];
    };
  };
  topOpenedByEarnedFeeIsFetched: boolean;
  topOpenedByFeeApr: {
    [key: string]: {
      [key: string]: { leftValue: string; rightValue: number; url?: string }[];
    };
  };
  topOpenedByAprIsFetched: boolean;
  topClosedByPnL: {
    [key: string]: {
      [key: string]: { leftValue: string; rightValue: number; url?: string }[];
    };
  };
  topClosedByPnLIsFetched: boolean;
  topClosedByPercentage: {
    [key: string]: {
      [key: string]: { leftValue: string; rightValue: number; url?: string }[];
    };
  };
  topClosedByPercentageIsFetched: boolean;
};

const initialState: FactoryProps = {
  userActivePositions: {},
  userClosedPositions: {},
  userActivePositionIsFetched: false,
  userClosedPositionIsFetched: false,
  allClosedPositions: {},
  closedPositionIsFetched: false,
  allActivePositions: {},
  activePositionIsFetched: false,
  totalUsdcInFactory: 0,
  uniPoolStats: {},
  unibotTvl: {},
  unibotCumulativeFee: {},
  topUsersByPnL: {},
  topUserByPnLIsFetched: false,
  topOpenedByEarnedFee: {},
  topOpenedByEarnedFeeIsFetched: false,
  topOpenedByFeeApr: {},
  topOpenedByAprIsFetched: false,
  topClosedByPnL: {},
  topClosedByPnLIsFetched: false,
  topClosedByPercentage: {},
  topClosedByPercentageIsFetched: false,
  userPositionEntryTicks: {},
};

const factorySlice = createSlice({
  name: "factory",
  initialState: initialState,
  reducers: {
    // User Related
    setUserActivePositions(state, action) {
      const { factoryAddress, positionInfos, chainId } = action.payload;
      state.userActivePositions = {
        ...state.userActivePositions,
        [chainId]: {
          ...state.userActivePositions[chainId],
          [factoryAddress]: positionInfos,
        },
      };
    },
    setUserActivePositionIsFetched(state, action) {
      state.userActivePositionIsFetched = action.payload;
    },
    setUserClosedPositions(state, action) {
      const { factoryAddress, positions, chainId } = action.payload;
      state.userClosedPositions = {
        ...state.userClosedPositions,
        [chainId]: {
          ...state.userClosedPositions[chainId],
          [factoryAddress]: positions,
        },
      };
    },
    setUserClosedPositionIsFetched(state, action) {
      state.userClosedPositionIsFetched = action.payload;
    },
    clearUserPositions(state) {
      state.userClosedPositions = {};
      state.userActivePositions = {};
    },

    // Stats Page
    setUnibotTvl(state, { payload }) {
      const { chainId, tvl } = payload;
      state.unibotTvl = {
        ...state.unibotTvl,
        [chainId]: tvl,
      };
    },
    setUnibotCumulativeFee(state, { payload }) {
      const { chainId, cumulativeFee } = payload;
      state.unibotCumulativeFee = {
        ...state.unibotCumulativeFee,
        [chainId]: cumulativeFee,
      };
    },
    setAllActivePositions(state, action) {
      const { chainId, records, totalRecord } = action.payload;
      state.allActivePositions = {
        ...state.allActivePositions,
        [chainId]: {
          records: records,
          totalRecord: totalRecord,
        },
      };
    },
    setActivePositionIsFetched(state, { payload }) {
      state.activePositionIsFetched = payload;
    },
    setAllClosedPositions(state, action) {
      const { chainId, records, totalRecord } = action.payload;
      state.allClosedPositions = {
        ...state.allClosedPositions,
        [chainId]: {
          records: records,
          totalRecord: totalRecord,
        },
      };
    },
    setClosedPositionIsFetched(state, { payload }) {
      state.closedPositionIsFetched = payload;
    },
    setUserPositionEntryTick(state, { payload }) {
      state.userPositionEntryTicks = payload;
    },

    // Uniswap Pool Related
    setUniswapPool24HVolume(state, action) {
      const { poolAddress, volume } = action.payload;
      state.uniPoolStats = {
        ...state.uniPoolStats,
        [poolAddress]: {
          ...state.uniPoolStats[poolAddress],
          volumeIn24H: volume,
        },
      };
    },
    setUniswapPool24HFee(state, action) {
      const { poolAddress, fee } = action.payload;
      state.uniPoolStats = {
        ...state.uniPoolStats,
        [poolAddress]: {
          ...state.uniPoolStats[poolAddress],
          feeIn24H: fee,
        },
      };
    },
    setUniswapPoolTvl(state, action) {
      const { poolAddress, tvl } = action.payload;
      state.uniPoolStats = {
        ...state.uniPoolStats,
        [poolAddress]: {
          ...state.uniPoolStats[poolAddress],
          tvl: tvl,
        },
      };
    },

    // Leaderboard Related
    setTopUsersByPnLIsFetching(state) {
      state.topUserByPnLIsFetched = false;
    },
    setTopUsersByPnLIsFetched(state, action) {
      const { tokenSymbol, timeRange, records } = action.payload;
      state.topUsersByPnL = {
        ...state.topUsersByPnL,
        [tokenSymbol]: {
          ...state.topUsersByPnL[tokenSymbol],
          [timeRange]: records,
        },
      };
      state.topUserByPnLIsFetched = true;
    },
    setTopOpenedByEarnedFeeIsFetching(state) {
      state.topOpenedByEarnedFeeIsFetched = false;
    },
    setTopOpenedByEarnedFeeIsFetched(state, action) {
      const { tokenSymbol, timeRange, records } = action.payload;
      state.topOpenedByEarnedFee = {
        ...state.topOpenedByEarnedFee,
        [tokenSymbol]: {
          ...state.topOpenedByEarnedFee[tokenSymbol],
          [timeRange]: records,
        },
      };
      state.topOpenedByEarnedFeeIsFetched = true;
    },
    setTopOpenedByFeeAprIsFetching(state) {
      state.topOpenedByAprIsFetched = false;
    },
    setTopOpenedByFeeAprIsFetched(state, action) {
      const { tokenSymbol, timeRange, records } = action.payload;
      state.topOpenedByFeeApr = {
        ...state.topOpenedByFeeApr,
        [tokenSymbol]: {
          ...state.topOpenedByFeeApr[tokenSymbol],
          [timeRange]: records,
        },
      };
      state.topOpenedByAprIsFetched = true;
    },
    setTopClosedByPnLIsFetching(state) {
      state.topClosedByPnLIsFetched = false;
    },
    setTopClosedByPnLIsFetched(state, action) {
      const { tokenSymbol, timeRange, records } = action.payload;
      state.topClosedByPnL = {
        ...state.topClosedByPnL,
        [tokenSymbol]: {
          ...state.topClosedByPnL[tokenSymbol],
          [timeRange]: records,
        },
      };
      state.topClosedByPnLIsFetched = true;
    },
    setTopClosedByPercentageIsFetching(state) {
      state.topClosedByPercentageIsFetched = false;
    },
    setTopClosedByPercentageIsFetched(state, action) {
      const { tokenSymbol, timeRange, records } = action.payload;
      state.topClosedByPercentage = {
        ...state.topClosedByPercentage,
        [tokenSymbol]: {
          ...state.topClosedByPercentage[tokenSymbol],
          [timeRange]: records,
        },
      };
      state.topClosedByPercentageIsFetched = true;
    },
    setInitLeaderboardState(state) {
      state.topUserByPnLIsFetched = false;
      state.topOpenedByEarnedFeeIsFetched = false;
      state.topOpenedByAprIsFetched = false;
      state.topClosedByPnLIsFetched = false;
      state.topClosedByPercentageIsFetched = false;
    },
  },
});

export const {
  setUserActivePositions,
  setUserActivePositionIsFetched,
  setUserClosedPositions,
  setUserClosedPositionIsFetched,
  clearUserPositions,
  setUserPositionEntryTick,

  setAllActivePositions,
  setActivePositionIsFetched,
  setAllClosedPositions,
  setClosedPositionIsFetched,
  setUnibotTvl,
  setUnibotCumulativeFee,

  setUniswapPool24HVolume,
  setUniswapPool24HFee,
  setUniswapPoolTvl,

  setTopUsersByPnLIsFetching,
  setTopUsersByPnLIsFetched,
  setTopOpenedByEarnedFeeIsFetching,
  setTopOpenedByEarnedFeeIsFetched,
  setTopOpenedByFeeAprIsFetching,
  setTopOpenedByFeeAprIsFetched,
  setTopClosedByPnLIsFetching,
  setTopClosedByPnLIsFetched,
  setTopClosedByPercentageIsFetching,
  setTopClosedByPercentageIsFetched,
} = factorySlice.actions;

export default factorySlice.reducer;
