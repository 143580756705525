import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../styles/Colors";
import { trimAmountInput } from "../utils/common";
import LabelWithHint from "./LabelWithHint";

type PropTypes = {
  value: number;
  setValue: (v: number) => void;
  labelText: string;
  prefix: string;
  suffix: string;
  placeholder: string;
};

const InputWithFloatingLabel: React.FC<PropTypes> = (props) => {
  const { value, setValue, labelText, prefix, suffix, placeholder } = props;

  const [isFloating, setIsFloating] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  return (
    <InputContainer $isFocus={isFocus}>
      <LabelContainer $isFloating={isFloating}>
        <LabelWithHint
          labelComponent={<Label $isFloating={isFloating}>{labelText}</Label>}
          hintText=""
        />
      </LabelContainer>
      {isFloating && <Prefix>{prefix}</Prefix>}
      <Input
        type="number"
        onChange={(e) => setValue(+trimAmountInput(e.currentTarget.value))}
        onFocus={() => {
          setIsFloating(true);
          setIsFocus(true);
        }}
        onBlur={() => {
          !value && setIsFloating(false);
          setIsFocus(false);
        }}
        onWheel={(event) => event.currentTarget.blur()}
        placeholder={isFloating ? placeholder : ""}
      />
      {isFloating && <Suffix>{suffix}</Suffix>}
    </InputContainer>
  );
};

export default InputWithFloatingLabel;

const InputContainer = styled.div<{ $isFocus: boolean }>`
  flex: 1 1 60px;
  padding: 6px 12px 6px 12px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: 1px solid ${Colors.lightKhaki};
  box-shadow: ${(props) => props.$isFocus && `0 0 4px ${Colors.lightKhaki}`};
`;

const LabelContainer = styled.div<{ $isFloating: boolean }>`
  position: absolute;
  padding: 0 4px;
  top: -14px;
  left: 8px;
  background-color: ${Colors.backgroundGray1};
  top: ${(props) => (props.$isFloating ? "-14px" : "50%")};
  transform: ${(props) => (props.$isFloating ? "" : "translate(0%,-50%)")};
  transition: all 200ms ease;
  color: ${(props) => (props.$isFloating ? Colors.gray3 : Colors.gray5)};
  pointer-events: none;
`;

const Label = styled.span<{ $isFloating: boolean }>`
  font-size: 13px;
  line-height: 22px;
`;

const Input = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  color: ${Colors.ivory};
  :focus {
    border: none;
    outline: none;
  }

  ::placeholder {
    font-weight: normal;
    color: ${Colors.gray5};
  }
`;

const Prefix = styled.span`
  margin: 0 4px 0 0;
  font-size: 13px;
  line-height: 22px;
  color: ${Colors.ivory};
`;

const Suffix = styled.span`
  margin: 0 0 0 4px;
  font-size: 13px;
  line-height: 22px;
  color: ${Colors.ivory};
`;
