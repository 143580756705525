import t from "../content";

export const navs = [
  // { label: t.common.factory, path: "/factory" },
  { label: t.common.pool, path: "/pool" },
  // { label: t.common.vault, path: "/vault" },
  // { label: "Vault", path: "/automated-vault" },
  // { label: "Simulator", path: "/simulator" },
  // { label: t.common.stats, path: "/statistics" },
  // { label: t.common.leaderboard, path: "/leaderboard" },
];
