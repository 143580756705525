import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { PairProps } from "../utils/constant/pairs";
import {
  getTopClosedPositionsByPnl,
  getTopClosedPositionsByPnlPercentage,
  getTopOpenedPositionsByEarnedFee,
  getTopOpenedPositionsByFeeApr,
  getTopUsersByRealizedProfit,
} from "../redux/factoryAction";
import { TimeRangeProps } from "../pages/LeaderboardPage";

const useLeaderboardPositions = (
  selectedToken: PairProps["wantToken"] | null,
  timeRange: TimeRangeProps
) => {
  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );
  const {
    topUsersByPnL,
    topUserByPnLIsFetched,
    topOpenedByEarnedFee,
    topOpenedByEarnedFeeIsFetched,
    topOpenedByFeeApr,
    topOpenedByAprIsFetched,
    topClosedByPnL,
    topClosedByPnLIsFetched,
    topClosedByPercentage,
    topClosedByPercentageIsFetched,
  } = useAppSelector((state) => state.factory);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedToken && selectedChainId) {
      const tokenSymbol = selectedToken.tokenSymbol;
      dispatch(
        getTopUsersByRealizedProfit(tokenSymbol, selectedChainId, timeRange)
      );
      dispatch(
        getTopOpenedPositionsByEarnedFee(
          tokenSymbol,
          selectedChainId,
          timeRange
        )
      );
      dispatch(
        getTopOpenedPositionsByFeeApr(tokenSymbol, selectedChainId, timeRange)
      );
      dispatch(
        getTopClosedPositionsByPnl(tokenSymbol, selectedChainId, timeRange)
      );
      dispatch(
        getTopClosedPositionsByPnlPercentage(
          tokenSymbol,
          selectedChainId,
          timeRange
        )
      );
    }
  }, [dispatch, selectedToken, selectedChainId, timeRange]);

  const timeRangeTag = `${timeRange.startTime}-${timeRange.endTime}`;

  return {
    topUsersByPnL: selectedToken
      ? topUsersByPnL[selectedToken?.tokenSymbol]?.[timeRangeTag]
      : [],
    topUserByPnLIsFetched: topUserByPnLIsFetched,
    topOpenedByEarnedFee: selectedToken
      ? topOpenedByEarnedFee[selectedToken?.tokenSymbol]?.[timeRangeTag]
      : [],
    topOpenedByEarnedFeeIsFetched,
    topOpenedByApr: selectedToken
      ? topOpenedByFeeApr[selectedToken?.tokenSymbol]?.[timeRangeTag]
      : [],
    topOpenedByAprIsFetched,
    topClosedByPnL: selectedToken
      ? topClosedByPnL[selectedToken?.tokenSymbol]?.[timeRangeTag]
      : [],
    topClosedByPnLIsFetched,
    topClosedByPercentage: selectedToken
      ? topClosedByPercentage[selectedToken?.tokenSymbol]?.[timeRangeTag]
      : [],
    topClosedByPercentageIsFetched,
  };
};

export default useLeaderboardPositions;
