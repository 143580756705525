import { useWeb3React } from "@web3-react/core";
import { useMemo } from "react";
import { useAppSelector } from "../hooks";

const useIsUnderCorrectNetwork = () => {
  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const { chainId } = useWeb3React();

  const isUnderCorrectNetwork = useMemo(
    () => selectedChainId === chainId,
    [selectedChainId, chainId]
  );

  return isUnderCorrectNetwork;
};

export default useIsUnderCorrectNetwork;
