import { useWeb3React } from "@web3-react/core";
import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import Button from "../../../components/Button";
import useIsAirdropExisted from "../../../hooks/useIsAirdropExisted";
import useUserAirdropRewards from "../../../hooks/useUserAirdropRewards";
import { ReactComponent as airdrop } from "../../../images/airdrop_khaki.svg";
import { ReactComponent as unvested } from "../../../images/unvested.svg";
import { ReactComponent as vested } from "../../../images/vested.svg";
import Colors from "../../../styles/Colors";
import { H2 } from "../../../styles/Fonts";
import { claimAirdropRewards } from "../../../utils/api/airdropApi";
import {
  CHAIN_IDS_TO_NAMES,
  CHAIN_IDS_TO_URL_IDS,
  SupportedChainId,
} from "../../../utils/constant/chains";
import { ALL_AIRDROP } from "../../../utils/constant/internalAddresses";
import t from "../../../utils/content";
import { showErrorAlert } from "../../../utils/showAlert";

const Airdrop = () => {
  const unit = "DMO";
  const [isClaiming, setIsClaiming] = useState<boolean>(false);

  const { account, chainId, provider } = useWeb3React();

  const {
    claimedReward,
    claimableReward,
    unvestedReward,
    nextVestingDate,
    claimableRewardParams,
    updateRewards,
  } = useUserAirdropRewards();

  const isAirdropExisted = useIsAirdropExisted();

  const handleClaimReward = async () => {
    if (+claimableReward === 0 || !claimableRewardParams) {
      showErrorAlert(t.error.noRewardToClaim);
      return;
    }

    if (account && chainId && provider) {
      setIsClaiming(true);

      try {
        await claimAirdropRewards(
          account,
          chainId,
          provider,
          claimableRewardParams
        );
      } catch (e) {
        console.log("claim reward error", e);
      }

      setIsClaiming(false);
      updateRewards();
    }
  };

  const findAirdropChainId = (object: { [key: number]: string }) => {
    const chainIds = [];
    for (const [key, value] of Object.entries(object)) {
      if (value) {
        chainIds.push(key);
      }
    }
    return chainIds;
  };

  const airdropChainIds = findAirdropChainId(ALL_AIRDROP).filter(
    (id) => +id !== SupportedChainId.BINANCE_TESTNET
  );

  const generateAirdropNames = (
    ids: String[],
    idToNames: { [key: number]: string }
  ) => {
    if (ids.length === 0) {
      return "other chains";
    } else if (ids.length === 1) {
      return idToNames[+ids[0]];
    } else {
      const names = ids.map((id) => idToNames[+id]);
      return names.join(" or ");
    }
  };

  if (!isAirdropExisted) {
    return (
      <NoRecordContainer>
        <NoRecord>
          {t.account.airdrop.switchToClaim1}
          {generateAirdropNames(airdropChainIds, CHAIN_IDS_TO_NAMES)}
          {t.account.airdrop.switchToClaim2}
        </NoRecord>
      </NoRecordContainer>
    );
  }

  return (
    <Container>
      <div>
        <OverallSec>
          <AirdropIcon />
          <OverallInfo>
            <Label>{t.account.airdrop.totalClaimed}</Label>
            <Value>
              {claimedReward} <Unit>{unit}</Unit>
            </Value>
          </OverallInfo>
        </OverallSec>
        <Rows>
          <Row $highlight={!!+claimableReward && !isNaN(+claimableReward)}>
            <LabelDiv>
              <VestedIcon />
              <OverallInfo>
                <Label>{t.account.airdrop.claimable}</Label>
                <Value>
                  {claimableReward} <Unit>{unit}</Unit>
                </Value>
              </OverallInfo>
            </LabelDiv>
            <RowEndContainer>
              <BtnContainer>
                <Button
                  btnText={t.account.airdrop.claim}
                  btnType="primary"
                  onClick={handleClaimReward}
                  isLoading={isClaiming}
                  disabled={
                    isClaiming ||
                    claimableReward === "-" ||
                    +claimableReward === 0
                  }
                />
              </BtnContainer>
              {/* <BtnContainer>
                <Button
                  btnText={t.account.airdrop.deposit}
                  btnType="secondary"
                  onClick={() => {
                    //navigate to Pool page and open deposit modal
                    chainId &&
                      navigate(`/pool/${CHAIN_IDS_TO_URL_IDS[chainId]}`);
                  }}
                  isLoading={false}
                  disabled={true}
                />
              </BtnContainer> */}
            </RowEndContainer>
          </Row>
          <Row>
            <LabelDiv>
              <UnvestedIcon />
              <OverallInfo>
                <Label>{t.account.airdrop.unvested}</Label>
                <Value>
                  {unvestedReward} <Unit>{unit}</Unit>
                </Value>
              </OverallInfo>
            </LabelDiv>
            <RowEndContainer>
              {/* <NextDate>{t.account.airdrop.nextDate}</NextDate>
              <NextDate>{nextVestingDate}</NextDate> */}
            </RowEndContainer>
          </Row>
        </Rows>
      </div>
    </Container>
  );
};

export default Airdrop;

const swing = keyframes`
  0% { transform: rotate(3deg) }
  100% { transform: rotate(-6deg) }
`;

const AirdropIcon = styled(airdrop)`
  width: 60px;
  height: 60px;
  transition: all 300ms;
  animation: ${swing} ease-in-out 1.5s infinite alternate;

  &:hover {
    animation: none;
  }

  @media (max-width: 768px) {
    width: 35px;
    min-width: 35px;
  }
`;

const NextDate = styled.span`
  color: ${Colors.ivory};
  font-size: 16px;
  text-align: center;

  font-family: Fira Sans, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const NoRecordContainer = styled.div`
  text-align: center;
  padding: 40px 0;
`;

const NoRecord = styled.span`
  display: inline-block;
  padding: 4px 20px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGray4};
  margin: auto;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.ivory};
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const OverallSec = styled.div`
  display: flex;
  justify-content: start;
  gap: 20px;
  margin: 0 0 40px 20px;

  @media (max-width: 768px) {
    justify-content: start;
  }

  @media (max-width: 400px) {
    gap: 10px;
  }
`;

const VestedIcon = styled(vested)`
  width: 60px;
  height: 60px;
  transition: all 300ms;

  @media (max-width: 768px) {
    height: 40px;
    min-width: 40px;
  }
`;

const UnvestedIcon = styled(unvested)`
  width: 60px;
  height: 50px;
  margin-left: 5px;
  transition: all 300ms;

  @media (max-width: 768px) {
    height: 40px;
    min-width: 40px;
  }
`;

const OverallInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const Value = styled(H2)`
  color: ${Colors.lightKhaki};
  line-height: 1;
  transition: all 300ms;
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 400px) {
    font-size: 20px;
  }
`;

const Unit = styled.span`
  color: ${Colors.ivory};
  font-size: smaller;
  font-weight: normal;
`;

const Label = styled.span`
  color: ${Colors.ivory};
  font-weight: 600;
  transition: all 300ms;
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Row = styled.div<{ $highlight?: boolean }>`
  background: ${Colors.backgroundGray1};
  border-radius: 8px;
  height: 128px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px 24px 24px;
  transition: all 300ms;
  gap: 16px;
  outline: ${(props) => props.$highlight && `2px solid ${Colors.lightKhaki}`};

  @media (max-width: 768px) {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 6px;
    padding-right: 24px;
  }

  @media (max-width: 420px) {
    padding: 20px;
    gap: 16px;
    flex-direction: column;
    height: auto;
  }
`;

const LabelDiv = styled.div`
  transition: all 300ms;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const RowEndContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: start;
  flex-shrink: 2;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 6px;
  }

  @media (max-width: 420px) {
    width: 100%;
    align-items: center;
    flex-wrap: nowrap;
  }

  @media (max-width: 340px) {
    flex-wrap: wrap;
  }
`;

const BtnContainer = styled.div`
  width: max-content;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
