import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { ALL_VAULT_TOKENS, VaultProps } from "../utils/constant/vaults";
import { SupportedChainId, isSupportedChain } from "../utils/constant/chains";

const defaultChainId = SupportedChainId.ARBITRUM_ONE;

const useVaultTokens = () => {
  const { chainId } = useWeb3React();

  const [vaultTokens, setVaultTokens] = useState<VaultProps[] | null>(null);

  // Set Pair Info Based On Params (or Redirect)
  useEffect(() => {
    if (isSupportedChain(chainId)) {
      const pairsUnderChainId = ALL_VAULT_TOKENS[chainId];
      setVaultTokens(pairsUnderChainId);
    } else {
      const pairsUnderChainId = ALL_VAULT_TOKENS[defaultChainId];
      setVaultTokens(pairsUnderChainId);
    }
  }, [chainId]);

  return vaultTokens;
};

export default useVaultTokens;
