import {
  diamondArbitrumAddresses as arbDmo,
  diamondGoerliAddresses as goerDmo,
  diamondPolygonMumbaiAddresses as polygonMumbaiDmo,
  diamondOptimismGoerliAddresses as optimismGoerliDmo,
  diamondPolygonAddresses as polygonDmo,
} from "./internalAddresses";
import { SupportedChainId } from "./chains";

export const ALL_REFERRALS: { [key: number]: string } = {
  [SupportedChainId.ARBITRUM_ONE]: arbDmo.referral,
  [SupportedChainId.GOERLI]: goerDmo.referral,
  [SupportedChainId.POLYGON_MUMBAI]: polygonMumbaiDmo.referral,
  [SupportedChainId.OPTIMISM_GOERLI]: optimismGoerliDmo.referral,
  [SupportedChainId.POLYGON]: polygonDmo.referral,
};

export const ALL_REWARD_VESTING: { [key: number]: string } = {
  [SupportedChainId.ARBITRUM_ONE]: arbDmo.rewardVesting,
  [SupportedChainId.GOERLI]: goerDmo.rewardVesting,
  [SupportedChainId.POLYGON_MUMBAI]: polygonMumbaiDmo.rewardVesting,
  [SupportedChainId.OPTIMISM_GOERLI]: optimismGoerliDmo.rewardVesting,
  [SupportedChainId.POLYGON]: polygonDmo.rewardVesting,
};
