import { initializeConnector } from "@web3-react/core";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import { MetaMask } from "@web3-react/metamask";
import { ALL_SUPPORTED_CHAIN_IDS, TRUST_UNSUPPORTED_CHAIN_IDS, TrustUnsupportedChainId } from "./chains";

const metaMask = initializeConnector((actions) => new MetaMask({ actions }));

const walletConnectV2 =
  initializeConnector(
    (actions) =>
      new WalletConnectV2({
        actions,
        options: {
          projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || '',
          chains: ALL_SUPPORTED_CHAIN_IDS.filter((id) => !TRUST_UNSUPPORTED_CHAIN_IDS.includes(id as unknown as TrustUnsupportedChainId)),
          optionalChains: ALL_SUPPORTED_CHAIN_IDS,
          showQrModal: true,
        },
      })
  )

export const connectors = {
  injected: metaMask,
  walletConnect: walletConnectV2,
};

export enum ConnectionType {
  INJECTED = "INJECTED",
  // COINBASE_WALLET = "COINBASE_WALLET",
  WALLET_CONNECT = "WALLET_CONNECT",
  // NETWORK = "NETWORK",
  // GNOSIS_SAFE = "GNOSIS_SAFE",
}

// const CONNECTIONS = [injected, walletconnect];

export function getConnection(c: ConnectionType) {
  //   if (c instanceof ConnectionType) {
  //     const connection = CONNECTIONS.find(
  //       (connection) => connection.connector === c
  //     );
  //     if (!connection) {
  //       throw Error("unsupported connector");
  //     }
  //     return connection;
  //   } else {
  switch (c) {
    case ConnectionType.INJECTED:
      return metaMask;
    // case ConnectionType.COINBASE_WALLET:
    //   return coinbaseWalletConnection;
    case ConnectionType.WALLET_CONNECT:
      return walletConnectV2;
    // case ConnectionType.NETWORK:
    //   return networkConnection;
    // case ConnectionType.GNOSIS_SAFE:
    //   return gnosisSafeConnection;
  }
  // }
}
