export const UNIBOT_V2_APP_URL = "https://dmo.finance";

// Social Media
// export const TWITTER_URL = "https://twitter.com/DiamondProtocol";
export const TWITTER_URL = "https://twitter.com/dmoprotocol";
export const DISCORD_URL = "https://discord.gg/5WszxeZvXT";

// Doc
export const UNIBOT_DOC_URL = "https://dmo-protocol.gitbook.io/diamond/white-paper/what-is-diamond-protocol";
export const UNIBOT_POOL_DOC_URL = "https://dmo-protocol.gitbook.io/diamond/white-paper/pool";
export const RISK_PARAMETERS_DOC_URL = "https://dmo-protocol.gitbook.io/diamond/unibot/pool#risk-parameters"; // outdated
export const AUDIT_REPORTS_URL = "https://dmo-protocol.gitbook.io/diamond/security/audit-reports";

// Bridges
export const ARBITRUM_BRIDGE = "https://bridge.arbitrum.io/";
export const POLYGON_BRIDGE = "https://wallet.polygon.technology/";

// Uniswap V3
export const UNISWAP_V3_INFO_URL = "https://info.uniswap.org/";
export const UNISWAP_V3_APP_URL = "https://app.uniswap.org/";

export const PCS_V3_APP_URL = "https://pancakeswap.finance/";

// External
export const ARBITRUM_URL = "https://arbitrum.io/";
export const BNB_URL = "https://www.bnbchain.org";
export const POLYGON_URL = "https://polygon.technology/";

export const QUANTSTAMP_URL = "https://quantstamp.com/"

export const TOKOCRYPTO_URL = "https://www.tokocrypto.com/";
export const RAZER_URL = "https://www.razer.com/";
export const IVCRYPTO_URL = "https://www.ivcrypto.io/";
export const RBCAP_URL = "https://www.rbcap.io/";
export const SPARTAN_URL = "https://www.spartangroup.io/";
export const VERTEX_VENTURES_URL = "https://www.vertexventures.sg/";
export const PERPETUAL_URL = "https://perp.com/";
