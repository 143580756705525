import { ethers, utils } from "ethers";
import vaultAbi from "../../abi/balanceVault.json";
import erc20Abi from "../../abi/erc20.json";
import { showErrorAlert as showError, showPendingAlert } from "../showAlert";
import { ALL_VAULTS } from "../constant/internalAddresses";
import * as Sentry from "@sentry/react";
import { waitForTransaction } from "./common";
import t from "../content";

/**
 * Deposit token into Balance Vault
 * @param userAddress user address
 * @param tokenAddress token address
 * @param tokenDecimal token decimal
 * @param amount deposit amount
 * @param chainId chain id
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 * @returns transaction result
 */
export const depositTokenIntoBalanceVault = async (
  userAddress: string,
  tokenAddress: string,
  tokenDecimal: number,
  amount: string,
  chainId: number,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  let res;

  const signer = provider.getSigner(userAddress);
  const vault = new ethers.Contract(ALL_VAULTS[chainId], vaultAbi, signer);

  const depositAmount = utils.parseUnits(amount.toString(), tokenDecimal);

  const token = new ethers.Contract(tokenAddress, erc20Abi, signer);
  const tokenBalance = await token.balanceOf(userAddress);
  const allowance = await token.allowance(userAddress, ALL_VAULTS[chainId]);

  if (tokenBalance.lt(depositAmount)) {
    showError(t.error.balanceNotEnough);
  } else {
    if (allowance.lt(depositAmount)) {
      try {
        const approveRes = await token.approve(
          ALL_VAULTS[chainId],
          depositAmount
        );
        await showPendingAlert(
          approveRes.hash,
          t.succeed.approveTokenSucceed,
          provider
        );
      } catch (error: any) {
        if (error.message.includes("User denied transaction signature")) {
          showError(t.error.deniedTransaction);
        } else {
          showError(t.error.approveTokenFailed);
        }
        return;
      }
    }
    try {
      res = await vault.deposit(tokenAddress, depositAmount);
      trackTxEvent(res.hash);
      await showPendingAlert(res.hash, t.succeed.depositSucceed, provider);
    } catch (error: any) {
      if (error.message.includes("User denied transaction signature")) {
        showError(t.error.deniedTransaction);
      } else {
        showError(t.error.depositFailed);
      }
    }
  }

  return res;
};

/**
 * Withdraw token from Balance Vault
 * @param userAddress user address
 * @param tokenAddress token address
 * @param tokenDecimal token decimal
 * @param amount withdraw amount
 * @param chainId chain id
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 * @returns transaction result
 */
export const withdrawTokenFromBalanceVault = async (
  userAddress: string,
  tokenAddress: string,
  tokenDecimal: number,
  amount: string,
  chainId: number,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  let res;

  const withdrawAmount = utils.parseUnits(amount.toString(), tokenDecimal);

  const signer = provider.getSigner(userAddress);
  const vault = new ethers.Contract(ALL_VAULTS[chainId], vaultAbi, signer);

  const accountBalance = await vault.getAccountBalance(
    userAddress,
    tokenAddress
  );

  if (accountBalance.lt(withdrawAmount)) {
    showError(t.error.balanceNotEnough);
  } else {
    try {
      res = await vault.withdraw(tokenAddress, withdrawAmount);
      trackTxEvent(res.hash);
      await showPendingAlert(res.hash, t.succeed.withdrawSucceed, provider);
    } catch (error: any) {
      if (error.message.includes("User denied transaction signature")) {
        showError(t.error.deniedTransaction);
      } else {
        showError(t.error.withdrawFailed);
      }
    }
  }

  return res;
};

/**
 * Get account balance from Balance Vault
 * @param userAddress user address
 * @param tokenAddress token address
 * @param chainId chain id
 * @param provider provider
 * @returns account balance in BigNumber
 */
export const getAccountBalanceFromBalanceVault = async (
  userAddress: string,
  tokenAddress: string,
  chainId: number,
  provider: any
) => {
  let amount;

  const signer = provider.getSigner(userAddress);
  const vault = new ethers.Contract(ALL_VAULTS[chainId], vaultAbi, signer);

  try {
    amount = await vault.getAccountBalance(userAddress, tokenAddress);
  } catch (error) {
    // console.log(error);
  }

  return amount;
};

/**
 * Approve Factory to use the token in Balance Vault
 * @param userAddress user address
 * @param factoryAddress factory address
 * @param tokenAddress token address
 * @param chainId chain id
 * @param provider provider
 * @param trackTxEvent function used to track tx event
 * @returns transaction result
 */
export const approveFactoryToUseBalanceVault = async (
  userAddress: string,
  factoryAddress: string,
  tokenAddress: string,
  chainId: number,
  provider: any,
  trackTxEvent: (txHash: string) => void
) => {
  const signer = provider.getSigner(userAddress);
  const balanceVault = new ethers.Contract(
    ALL_VAULTS[chainId],
    vaultAbi,
    signer
  );

  let isSucceed = false;

  try {
    const approveRes = await balanceVault.approve(factoryAddress, tokenAddress);
    trackTxEvent(approveRes.hash);
    const txRes = await waitForTransaction(provider, approveRes.hash);
    if (txRes.status === 1) {
      isSucceed = true;
    }
  } catch (e: any) {
    if (e.code === "ACTION_REJECTED") {
      showError(t.error.deniedTransaction);
    } else {
      showError(t.error.approveFactoryFailed);
      Sentry.captureException(e);
    }
  }

  return isSucceed;
};

/**
 * Check if user has approved Factory to use the token in Balance Vault
 * @param userAddress user address
 * @param factoryAddress factory address
 * @param chainId chain id
 * @param provider provider
 * @returns is approved or not
 */
export const getIsUserApprovedFactory = async (
  userAddress: string,
  factoryAddress: string,
  chainId: number,
  provider: any
) => {
  let isApproved = false;

  try {
    const signer = provider.getSigner(userAddress);
    const balanceVault = new ethers.Contract(
      ALL_VAULTS[chainId],
      vaultAbi,
      signer
    );

    isApproved = await balanceVault.userApproveFactories(
      userAddress,
      factoryAddress
    );
  } catch (e) {}

  return isApproved;
};
