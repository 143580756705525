import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { H2, H4 } from "../../styles/Fonts";
import { getTruncNum } from "../../utils/common";
import {FarmProps} from "../../utils/constant/evieVault";
import { getSlicedAddress } from "../../utils/api/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { networkParams } from "../../utils/constant/networks";
import Pagination from "../AutoVaultPage/Pagination";

const ITEM_PER_PAGE = 5;
const DECIMAL = 2;

const PositionRecords: React.FC<{
  pastPositions: any[],
  wantToken: FarmProps["wantToken"],
  selectedChainId: number}> = ({
  pastPositions,
  wantToken,
  selectedChainId
}) => {
  const [maxPage, setMaxPage] = useState<number>(0);
  const [currPage, setCurrPage] = useState<number>(1);
  const [displayRecords, setDisplayRecords] = useState<
    { amount: number; timestamp: number; event: string }[]
  >([]);

  useEffect(() => {
    const total = pastPositions?.length;
    setMaxPage(Math.ceil(total / ITEM_PER_PAGE));
  }, [pastPositions]);

  useEffect(() => {
    if (pastPositions?.length > 0) {
      setDisplayRecords(
        pastPositions.slice(
          (currPage - 1) * ITEM_PER_PAGE,
          currPage * ITEM_PER_PAGE
        )
      );
    }
  }, [currPage, pastPositions]);

  const _renderNoRecord = () => {
    return (
      <NoRecordContainer>
        <NoRecord>No Record</NoRecord>
      </NoRecordContainer>
    );
  };

  const onClickLink = (tx: string) => {
    window.open(
      `${networkParams[selectedChainId].blockExplorerUrls[0]}tx/${tx}`,
      "_blank"
    );
  };

  return (
    <BackgroundContainer>
      <Subtitle>Vault's Position Records </Subtitle>
      <ClosePositionRowH>
        <PositionHeader>Tx Type</PositionHeader>
        <PositionHeader>Time</PositionHeader>
        <PositionHeader>Amount</PositionHeader>
        <PositionHeader>Tx Hash</PositionHeader>
      </ClosePositionRowH>
      {displayRecords.length > 0 ? (
        <Fragment>
          {displayRecords.map((pos: any) => (
            <ClosePositionRow key={pos.txHash}>
              <PositionDetail>
                <PositionHeaderM>Tx Type</PositionHeaderM>
                  <PositionValueSmall>{`${pos.type} ${pos.positionId}`}</PositionValueSmall>
              </PositionDetail>
              <PositionDetail>
                <PositionHeaderM>Time</PositionHeaderM>
                <FeeContainer>
                  <TimeText>
                    {`${new Date(pos.time * 1000).toLocaleDateString(
                      "en-US"
                    )} ${new Date(pos.time * 1000).toLocaleTimeString(
                      "en-US"
                    )}`}
                  </TimeText>
                </FeeContainer>
              </PositionDetail>
              <PositionDetail>
                <PositionHeaderM>Amount</PositionHeaderM>
                <PositionValueSmall>{`${
                  wantToken.tokenDecimal &&
                  getTruncNum(
                    +pos.wantAmount / 10 ** wantToken.tokenDecimal,
                    DECIMAL
                  )
                } ${wantToken.tokenSymbol}`}</PositionValueSmall>
              </PositionDetail>
              <PositionDetail>
                <PositionHeaderM>Tx Hash</PositionHeaderM>
                <PositionValueSmall>{getSlicedAddress(pos.txHash)}</PositionValueSmall>
              </PositionDetail>
              <PositionDetail>
                <PositionHeaderM>Tx Hash</PositionHeaderM>
                <LinkIcon
                  icon={faArrowUpRightFromSquare}
                  onClick={() =>
                    onClickLink(pos.txHash)
                  }
                />
              </PositionDetail>
            </ClosePositionRow>
          ))}
          <Pagination
            currPage={currPage}
            maxPage={maxPage}
            setPage={setCurrPage}
          />
        </Fragment>
      ) : (
        _renderNoRecord()
      )}
    </BackgroundContainer>
  );
};

export default PositionRecords;

const VaultTitle = styled(H2)`
  font-size: 24px;
  line-height: 36px;
  text-align: left;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

const BackgroundContainer = styled.div`
  margin-top: 20px;
  background-color: ${Colors.backgroundGray2};
  padding: 24px;
  border-radius: 8px;
`;

const Subtitle = styled(VaultTitle)`
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 24px;
`;

const ClosePositionRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 60px;
  align-items: center;
  background-color: ${Colors.backgroundGray4};
  border-radius: 8px;
  padding: 20px;
  margin-top: 12px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 12px 20px;
  }
  > div:not(:first-child) {
    justify-content: flex-end;
    text-align: right;
    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }
  h4:not(:first-child) {
    text-align: right;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 768px) {
    justify-content: flex-end;
  }
`;

const PositionHeader = styled(H4)`
  color: ${Colors.gray4};
  font-size: 13px;
  line-height: 22px;
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const PositionValueSmall = styled(H4)`
  color: ${Colors.gray1};
  font-size: 14px;
  line-height: 22px;
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const PositionDetail = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
    padding: 4px 0;
  }
`;

const PositionHeaderM = styled(PositionHeader)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const FeeContainer = styled.div`
  @media (max-width: 768px) {
    text-align: left;
  }
`;

const ClosePositionRowH = styled(ClosePositionRow)`
  border-top: none;
  background-color: transparent;
  padding: 12px 20px;
  margin-top: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

const NoRecordContainer = styled.div`
  text-align: center;
  padding: 60px 0;
`;

const NoRecord = styled.span`
  display: inline-block;
  padding: 4px 20px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGray4};
  margin: auto;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.ivory};
`;

const TimeText = styled(H4)`
  font-size: 12px;
  line-height: 18px;
  color: ${Colors.ivory};
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const LinkIcon = styled(FontAwesomeIcon)`
  color: ${Colors.gray1};
  font-size: 14px;
  padding: 4px;
  :hover {
    cursor: pointer;
  }
  :active {
    transform: scale(0.95);
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
