import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import Button from "../../../components/Button";
import { H4 } from "../../../styles/Fonts";
import CustomModal from "../../../components/Modal";
import { useWeb3React } from "@web3-react/core";
import { createReferralCode } from "../../../utils/api/referralApi";

const CreateReferralCodeModal: React.FC<{
  isShowModal: boolean;
  setShowModal: (v: boolean) => void;
  setIsCreating: (v: boolean) => void;
  updateReferralCode: () => void;
}> = (props) => {
  const { isShowModal, setShowModal, setIsCreating, updateReferralCode } =
    props;

  const { account, chainId, provider } = useWeb3React();

  const [code, setCode] = useState<string>("");

  const handleCreate = async () => {
    if (!account || !code || !chainId || !provider) return;

    setShowModal(false);
    setIsCreating(true);

    await createReferralCode(account, chainId, provider, code);

    setIsCreating(false);
    updateReferralCode();
  };

  return (
    <CustomModal
      show={isShowModal}
      onHide={() => setShowModal(false)}
      title="Create Referral Code"
    >
      <InfoContainer>
        <Description>Don’t have a referral code yet?</Description>
        <Description>
          Generate your referral code and earn 30% commission rebate + discount
        </Description>
      </InfoContainer>
      <InputContainer>
        <Input
          type="text"
          placeholder="Referral Code"
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
      </InputContainer>
      <BtnContainer>
        <Button
          btnType="primary"
          btnText="Create"
          onClick={handleCreate}
          disabled={!code}
          isLoading={false}
        />
      </BtnContainer>
    </CustomModal>
  );
};

export default CreateReferralCodeModal;

const BtnContainer = styled.div`
  margin-top: 24px;
  button {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;

const Description = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const InputContainer = styled.div`
  flex: 1 1 60px;
  padding: 6px 12px 6px 12px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: 1px solid ${Colors.gray4};
  margin-top: 20px;
`;

const Input = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: ${Colors.ivory};
  :focus {
    border: none;
    outline: none;
  }
`;

const InfoContainer = styled.div`
  h4:not(:first-child) {
    margin-top: 4px;
  }
`;
