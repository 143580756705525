import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import {
  getTotalBorrowAmount,
  getTotalSupplyAmount,
  getExchangeRate,
  getPoolCash,
  getLendingPoolBorrowRate,
  getLendingPoolLendingRate,
  getPCSStakingAPR,
  getContractTokenSymbol,
} from "../utils/api/lendingPoolApi";

export interface ILendingPoolState {
  totalBorrowed: number | null;
  totalSupply: number | null;
  estiExchangeRate: number;
  cash: number;
  borrowRate: number;
  lendingRate: number | null;
  updateLendingPoolState: () => void;
  rewardApr: number | null;
  lpTokenSymbol: string;
}

const useLendingPoolState = (
  lendingPoolAddress: string,
  tokenSymbol: string,
  tokenDecimal: number,
  deployedChainId: number,
  stakingAddress?: string
) => {
  const [totalBorrowed, setTotalBorrowed] = useState<number | null>(null);
  const [totalSupply, setTotalSupply] = useState<number | null>(null);
  const [estiExchangeRate, setEstiExchangeRate] = useState<number>(0);
  const [cash, setCash] = useState<number>(0);
  const [borrowRate, setBorrowRate] = useState<number>(0);
  const [lendingRate, setLendingRate] = useState<number | null>(null);
  const [rewardApr, setRewardApr] = useState<number | null>(null);
  const [lpTokenSymbol, setLpTokenSymbol] = useState<string>("");

  const { provider } = useWeb3React();

  const getTotalBorrowed = useCallback(async () => {
    const amount = await getTotalBorrowAmount(
      lendingPoolAddress,
      deployedChainId,
      tokenDecimal,
      provider
    );
    setTotalBorrowed(amount);
  }, [lendingPoolAddress, deployedChainId, provider, tokenDecimal]);

  const getTotalSupply = useCallback(async () => {
    const amount = await getTotalSupplyAmount(
      lendingPoolAddress,
      deployedChainId,
      tokenDecimal,
      provider
    );
    setTotalSupply(amount);
  }, [lendingPoolAddress, deployedChainId, provider, tokenDecimal]);

  const getEstiExchangeRate = useCallback(async () => {
    const rate = await getExchangeRate(
      lendingPoolAddress,
      deployedChainId,
      provider
    );
    setEstiExchangeRate(rate);
  }, [lendingPoolAddress, deployedChainId, provider]);

  const getCash = useCallback(async () => {
    const amount = await getPoolCash(
      lendingPoolAddress,
      deployedChainId,
      tokenDecimal,
      provider
    );
    setCash(amount);
  }, [lendingPoolAddress, deployedChainId, provider, tokenDecimal]);

  const getBorrowRate = useCallback(async () => {
    const rate = await getLendingPoolBorrowRate(
      lendingPoolAddress,
      deployedChainId,
      provider
    );
    setBorrowRate(rate);
  }, [lendingPoolAddress, deployedChainId, provider]);

  const getLendingRate = useCallback(async () => {
    const rate = await getLendingPoolLendingRate(
      lendingPoolAddress,
      deployedChainId,
      provider
    );
    setLendingRate(rate);
  }, [lendingPoolAddress, deployedChainId, provider]);

  const getRewardApr = useCallback(async () => {
    if (!!stakingAddress) {
      const rate = await getPCSStakingAPR(
        stakingAddress,
        lendingPoolAddress,
        tokenSymbol,
        deployedChainId,
        provider
      );
      setRewardApr(rate * 100);
    } else {
      setRewardApr(0);
    }
  }, [
    lendingPoolAddress,
    tokenSymbol,
    deployedChainId,
    provider,
    stakingAddress,
  ]);

  const getTokenSymbol = useCallback(async () => {
    const symbol = await getContractTokenSymbol(
      lendingPoolAddress,
      deployedChainId,
      provider
    );
    setLpTokenSymbol(symbol);
  }, [lendingPoolAddress, deployedChainId, provider]);

  const updateLendingPoolState = useCallback(async () => {
    getTotalBorrowed();
    getTotalSupply();
    getEstiExchangeRate();
    getCash();
    getBorrowRate();
    getLendingRate();
    getTokenSymbol();

    getRewardApr();
  }, [
    getTotalBorrowed,
    getTotalSupply,
    getEstiExchangeRate,
    getCash,
    getBorrowRate,
    getLendingRate,
    getTokenSymbol,
    getRewardApr,
  ]);

  useEffect(() => {
    if (!lendingPoolAddress || !deployedChainId) return;
    updateLendingPoolState();
  }, [lendingPoolAddress, deployedChainId, updateLendingPoolState]);

  return {
    totalBorrowed,
    totalSupply,
    estiExchangeRate,
    cash,
    borrowRate,
    lendingRate,
    updateLendingPoolState,
    rewardApr,
    lpTokenSymbol,
  } as ILendingPoolState;
};

export default useLendingPoolState;
