import { ethers } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { useCallback, useState } from "react";
import factoryV3Abi from "../abi/factoryV3.json";
import getProvider from "../utils/getProvider";

const useOpenPositionMinMaxAmount = (
  factoryAddress: string,
  tokenDecimal: number,
  chainId: number,
  provider: any
) => {
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");

  const getMinMaxAmount = useCallback(async () => {
    if (!!!factoryAddress) return;
    try {
      const p = await getProvider(provider, chainId);
      const factory = new ethers.Contract(factoryAddress, factoryV3Abi, p);
      const [openPositionMinAmount, openPositionMaxAmount] = await Promise.all([
        factory.openPositionMinimumAmount(),
        factory.openPositionMaximumAmount(),
      ]);
      const minTokenAmount = formatUnits(openPositionMinAmount, tokenDecimal);
      const maxTokenAmount = formatUnits(openPositionMaxAmount, tokenDecimal);
      setMinAmount(minTokenAmount);
      setMaxAmount(maxTokenAmount);
    } catch (e) {
      console.error(e);
    }
  }, [chainId, factoryAddress, provider, tokenDecimal]);

  getMinMaxAmount();

  return { minAmount, maxAmount };
};

export default useOpenPositionMinMaxAmount;
