import React from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { H4 } from "../../../styles/Fonts";
import LabelWithHint from "../../../components/LabelWithHint";
import Button from "../../../components/Button";
import { getTruncNum } from "../../../utils/common";
import { PoolProps } from "../../../utils/constant/pools";
import { Dropdown } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import t from "../../../utils/content";
import AprWithHint from "../../../components/AprWithHint";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalProps } from "./MarketItem";
import { IUserPoolSupply } from "../../../hooks/useUserPoolSupply";
import { ILendingPoolState } from "../../../hooks/useLendingPoolState";
import { AMOUNT_DECIMAL } from "../../../utils/constant/decimals";

interface Props {
  setShowModal: (v: ModalProps | null) => void;
  pool: PoolProps;
  isDepositing: boolean;
  isWithdrawing: boolean;
  isWrapping: boolean;
  isUnwrapping: boolean;
  stakingIsShow: boolean;
  setStakingIsShow: (v: boolean) => void;
  poolState: ILendingPoolState;
  userPoolState: IUserPoolSupply;
  isConnected: boolean;
}

const TokenSection: React.FC<Props> = (props) => {
  const {
    pool,
    setShowModal,
    isDepositing,
    isWithdrawing,
    isWrapping,
    isUnwrapping,
    stakingIsShow,
    setStakingIsShow,
    poolState,
    userPoolState,
    isConnected,
  } = props;
  const { tokenSymbol, tokenIcon, stakingAddress } = pool;
  const { totalBorrowed, totalSupply, lendingRate, rewardApr, estiExchangeRate } = poolState;
  const { balance, earned, stakedAmountInPoolToken } = userPoolState;
  const calculateUtilization = (
    totalSupply: number | null,
    totalBorrowed: number | null,
    estiExchangeRate: number
  ) => {
    if (totalSupply !== null && totalSupply > 0 && totalBorrowed !== null) {
      const percentage = (totalBorrowed / totalSupply / estiExchangeRate * 100).toFixed(2)
      return percentage;
    } else {
      return "-";
    }
  };

  return (
    <TopContainer>
      <Row>
        <TokenIcon src={tokenIcon} />
        <TokenName>{tokenSymbol}</TokenName>
      </Row>
      <Col>
        <LabelWithHint
          labelComponent={<HeadText>{t.pool.lendingInterestRate}</HeadText>}
          hintText={t.pool.lendingInterestRateDesc}
        />
        <AprWithHint
          labelComponent={
            <GreenValueText cakeRewardExist={!!stakingAddress && !!rewardApr}>
              {lendingRate !== null && rewardApr !== null
                ? `${(lendingRate * 100 + rewardApr).toFixed(2)} %`
                : "-"}
            </GreenValueText>
          }
          liquidityProvidingApr={lendingRate !== null ? lendingRate * 100 : 0}
          pcsRewardApr={rewardApr}
          type="pool"
        />
      </Col>
      <Col>
        <LabelWithHint
          labelComponent={<HeadText>{t.pool.totalSupply}</HeadText>}
          hintText={t.pool.totalSupplyDesc}
        />
        <ValueText>
          {totalSupply !== null ? `${getTruncNum(totalSupply * estiExchangeRate, 2)}` : "-"}
          {` ${tokenSymbol}`}
        </ValueText>
      </Col>
      <Col>
        <LabelWithHint
          labelComponent={<HeadText>{t.pool.totalBorrowed}</HeadText>}
          hintText={t.pool.totalBorrowedDesc}
        />
        <ValueText>
          {totalBorrowed !== null ? `${getTruncNum(totalBorrowed, 2)}` : "-"}
          {` ${tokenSymbol}`}
        </ValueText>
      </Col>
      <Col>
        <LabelWithHint
          labelComponent={<HeadText>{t.pool.utilization}</HeadText>}
          hintText={t.pool.utilizationDesc}
        />
        <ValueText>{calculateUtilization(totalSupply, totalBorrowed, estiExchangeRate)} %</ValueText>
      </Col>
      <Col className="pool-s-2">
        <LabelWithHint
          labelComponent={<HeadText>{t.pool.yourSupply}</HeadText>}
          hintText=""
        />
        <SupplyContainer>
          <SupplyLabel> {t.pool.balance}</SupplyLabel>
          <SupplyText>
            {isConnected && balance !== null && stakedAmountInPoolToken !== null
              ? `${getTruncNum(
                +balance.replace(/,/g, '') + +stakedAmountInPoolToken,
                  2
                )} ${tokenSymbol}`
              : "-"}
          </SupplyText>
          <SupplyLabel style={{ marginTop: "8px" }}>
            {t.pool.earned}
          </SupplyLabel>
          <SupplyText>
            {isConnected && earned !== null
              ? `${getTruncNum(+earned, 2)} ${tokenSymbol}`
              : "-"}
          </SupplyText>
        </SupplyContainer>
      </Col>
      <BtnContainer>
        <ActionBtnContainer>
          <div className="pool-s-1">
            {/* <Button
              btnText={t.pool.deposit}
              btnType="primary"
              onClick={() => setShowModal("Deposit")}
              disabled={!isConnected || isDepositing}
              isLoading={isDepositing}
            /> */}
          </div>
          <div className="pool-s-3">
            <Button
              btnText={t.pool.withdraw}
              btnType="primary"
              onClick={() => setShowModal("Withdraw")}
              disabled={!isConnected || isWithdrawing}
              isLoading={isWithdrawing}
            />
          </div>
          {isConnected && (pool.isWrapToken) && (
            <ManageDropDown>
              <ManageToggle id="dropdown-basic">
                {isWrapping || isUnwrapping ? (
                  <BeatLoader color={Colors.gray6} size={10} />
                ) : (
                  t.pool.more
                )}
              </ManageToggle>
              <ManageMenu>
                <ManageItem
                  onClick={() => setShowModal("Wrap")}
                  disabled={false}
                >
                  {`${t.pool.wrap} ${tokenSymbol.substring(1)}`}
                </ManageItem>
                <ManageItem
                  onClick={() => setShowModal("Unwrap")}
                  disabled={false}
                >
                  {`${t.pool.unwrap} ${tokenSymbol}`}
                </ManageItem>
              </ManageMenu>
            </ManageDropDown>
          )}
        </ActionBtnContainer>
        {!!stakingAddress && (
          <ExpandBtn onClick={() => setStakingIsShow(!stakingIsShow)}>
            <ArrowIcon icon={faAngleDown} isShow={stakingIsShow} />
          </ExpandBtn>
        )}
      </BtnContainer>
    </TopContainer>
  );
};

export default TokenSection;

const HeadText = styled(H4)`
  display: block;
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
    white-space: nowrap;
  }
`;

const ValueText = styled(HeadText)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${Colors.gray1};
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    text-align: right;
  }
`;

const SupplyText = styled(HeadText)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: ${Colors.gray1};
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    text-align: right;
  }
`;

const SupplyLabel = styled.span`
  display: block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: ${Colors.gray3};
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    text-align: right;
  }
`;

const GreenValueText = styled(ValueText)<{ cakeRewardExist: boolean }>`
  font-family: Poppins, Open Sans, Goldman-Sans, Helvetica Neue, Helvetica,
    Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  color: ${Colors.green};
  font-weight: bold;
  border-bottom: ${(props) =>
    props.cakeRewardExist ? `1px dashed ${Colors.gray4}` : "none"};
  display: inline;
`;

const TopContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr) 130px;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  padding: 18px 24px;
  border-radius: 8px;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  @media (max-width: 768px) {
    padding: 18px 18px;
  }
`;

const Col = styled.div`
  div:first-child {
    display: none;
  }
  @media (max-width: 992px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4px;
    div:first-child {
      display: flex;
    }
  }
`;

const BtnContainer = styled.div`
  display: flex;
  gap: 12px;
  div:not(:first-child) {
    margin-top: 0px;
  }
  button {
    font-size: 14px;
    line-height: 18px;
  }
  @media (max-width: 992px) {
    width: 100%;
    margin-top: 12px;
    flex-direction: row;
    div {
      flex: 1;
    }
  }
`;

const ActionBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 992px) {
    flex-direction: row;
  }
`;

const TokenIcon = styled.img`
  height: 24px;
  margin-right: 8px;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 992px) {
    width: 100%;
    padding-bottom: 12px;
    margin-bottom: 12px;
    ::after {
      content: "";
      border-bottom: 0.5px solid ${Colors.gray4};
      width: calc(100% + 48px);
      position: absolute;
      top: 100%;
      left: -24px;
    }
  }
  @media (max-width: 768px) {
    ::after {
      content: "";
      border-bottom: 0.5px solid ${Colors.gray4};
      width: calc(100% + 36px);
      position: absolute;
      top: 100%;
      left: -18px;
    }
  }
`;

const TokenName = styled(ValueText)`
  @media (max-width: 992px) {
    font-size: 15px;
    font-weight: 700;
  }
  @media (max-width: 768px) {
    text-align: left;
  }
`;

const SupplyContainer = styled.div`
  @media (max-width: 992px) {
    text-align: right;
  }
`;

const ManageDropDown = styled(Dropdown)`
  button {
    padding: 0 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-primary {
    color: inherit;
    background-color: ${Colors.lightKhaki};
    border-color: ${Colors.lightKhaki};
    :hover {
      color: inherit;
      background-color: ${Colors.lightKhaki};
      border-color: ${Colors.lightKhaki};
    }
    :focus {
      box-shadow: none;
    }
    :active {
      transform: scale(0.95);
    }
    transition: all ease 200ms;
  }
  @media (max-width: 992px) {
    button {
      padding: 0 12px;
      width: 100%;
      min-height: 28px;
    }
  }
`;

const ManageToggle = styled(Dropdown.Toggle)`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  min-height: 32px;
  ::after {
    margin-left: 8px;
    vertical-align: 1px;
  }
`;

const ManageMenu = styled(Dropdown.Menu)`
  background-color: ${Colors.gray6};
  box-shadow: 2px 2px 10px ${Colors.backgroundGray3};
`;

const ManageItem = styled(Dropdown.Item)`
  font-size: 13px;
  line-height: 22px;
  color: ${Colors.ivory};
  font-family: Open Sans, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  :focus {
    background-color: inherit;
  }
`;

const ExpandBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  transition: transform 0.1s ease-in-out;
  color: ${Colors.ivory};
  :hover {
    transform: scale(1.1);
    color: ${Colors.lightKhaki};
  }
`;

const ArrowIcon = styled(FontAwesomeIcon)<{ isShow: boolean }>`
  font-size: 16px;
  transform: ${(props) => (props.isShow ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.1s ease-in-out;
  color: ${Colors.gray3};
`;
