import { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  getTokenAllowanceIsEnough,
  grantTokenApproval,
} from "../utils/api/common";
import { showErrorAlert } from "../utils/showAlert";
import t from "../utils/content";

type Props = {
  tokenAmount: string;
  tokenAddress: string;
  tokenDecimal: number;
  contractAddress: string;
};

const useTokenApproval = (props: Props) => {
  const { tokenAmount, tokenAddress, tokenDecimal, contractAddress } = props;

  const { account, chainId, provider } = useWeb3React();

  const [tokenAmountIsApproved, setTokenAmountIsApproved] =
    useState<boolean>(false);
  const [isGranting, setIsGranting] = useState<boolean>(false);
  const [isGranted, setIsGranted] = useState<boolean>(false);

  const initTokenApprovalStates = () => {
    setIsGranting(false);
    setIsGranted(false);
  };

  const checkTokenAllowance = useCallback(async () => {
    if (!(account && chainId && tokenAddress)) {
      return;
    }

    initTokenApprovalStates();

    const isApproved = await getTokenAllowanceIsEnough(
      account,
      tokenAmount,
      tokenAddress,
      tokenDecimal,
      contractAddress,
      chainId,
      provider
    );
    setTokenAmountIsApproved(isApproved);
  }, [
    account,
    tokenAmount,
    tokenAddress,
    tokenDecimal,
    contractAddress,
    chainId,
    provider,
  ]);

  const grantTokenAllowance = async () => {
    if (!account || !chainId) {
      return;
    }

    if (!isNaN(parseFloat(tokenAmount)) && parseFloat(tokenAmount) <= 0) {
      showErrorAlert(t.error.inputDepositAmount);
      return;
    }

    setIsGranting(true);

    const isApproved = await grantTokenApproval(
      account,
      tokenAmount,
      tokenAddress,
      tokenDecimal,
      contractAddress,
      chainId,
      provider
    );

    if (isApproved) {
      checkTokenAllowance();
      setIsGranted(true);
    }

    setIsGranting(false);
  };

  useEffect(() => {
    if (!account) {
      initTokenApprovalStates();
    }
  }, [account]);

  useEffect(() => {}, []);

  return {
    tokenAmountIsApproved,
    checkTokenAllowance,
    grantTokenAllowance,
    isGranting,
    isGranted,
    initTokenApprovalStates,
  };
};

export default useTokenApproval;
