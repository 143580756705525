import { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { getIsUserApprovedFactory } from "../utils/api/balanceVaultApi";

const useIsApprovedFactory = (
  factoryAddress: string,
  deployedChainId: number
) => {
  const [isApprovedFactory, setIsApprovedFactory] = useState<boolean>(false);

  const { account, chainId, provider } = useWeb3React();

  const updateIsApprovedState = useCallback(async () => {
    if (account && chainId === deployedChainId) {
      const _isApproved = await getIsUserApprovedFactory(
        account,
        factoryAddress,
        deployedChainId,
        provider
      );
      setIsApprovedFactory(_isApproved);
    }
  }, [account, chainId, deployedChainId, factoryAddress, provider]);

  useEffect(() => {
    updateIsApprovedState();
  }, [updateIsApprovedState]);

  return { isApprovedFactory, updateIsApprovedState };
};

export default useIsApprovedFactory;
