import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import Button from "../../../components/Button";
import { H4 } from "../../../styles/Fonts";
import CustomModal from "../../../components/Modal";
import { PairProps } from "../../../utils/constant/pairs";
import { useWeb3React } from "@web3-react/core";
import { addFactoryCollateral } from "../../../utils/api/factoryV3Api";
import AmountInput from "../../../components/AmountInput";
import t from "../../../utils/content";
import useTrackEvent from "../../../hooks/useTrackEvent";

const AddCollateral: React.FC<{
  isShowModal: boolean;
  setShowModal: (v: boolean) => void;
  positionId: string;
  setIsExecuting: (v: boolean) => void;
  updateUserActivePositions: () => void;
  updateVaultBalance: () => void;
  pair: PairProps;
  vaultBalance: string;
}> = (props) => {
  const {
    isShowModal,
    setShowModal,
    positionId,
    setIsExecuting,
    updateUserActivePositions,
    updateVaultBalance,
    vaultBalance,
    pair,
  } = props;
  const { tokenSymbol, tokenAddress, tokenDecimal } = pair.wantToken;

  const { account, chainId, provider } = useWeb3React();
  const trackEvent = useTrackEvent();

  const [amount, setAmount] = useState<string>("0");

  const handleAddCollateral = async () => {
    if (account && amount && positionId && chainId && provider) {
      trackEvent(`${t.amp.clickAddCollateralBtn} | ${t.amp.factoryPage}`, {
        positionId: positionId,
        factoryAddress: pair.factoryAddress,
        factoryLabel: `${pair.label} ${pair.rate}%`,
        wantToken: pair.wantToken.tokenSymbol,
        borrowToken: pair.borrowToken.tokenSymbol,
        amount: amount,
      });

      setShowModal(false);
      setIsExecuting(true);

      await addFactoryCollateral(
        account,
        pair.factoryAddress,
        tokenAddress,
        tokenDecimal,
        amount,
        positionId,
        chainId,
        provider,
        (txHash: string) => {
          trackEvent(`${t.amp.addCollateral} | ${t.amp.factoryPage}`, {
            txHash: txHash,
            positionId: positionId,
            factoryAddress: pair.factoryAddress,
            factoryLabel: `${pair.label} ${pair.rate}%`,
            wantToken: pair.wantToken.tokenSymbol,
            borrowToken: pair.borrowToken.tokenSymbol,
            amount: amount,
          });
        }
      );

      setIsExecuting(false);
      updateUserActivePositions();
      updateVaultBalance();
    }
  };

  return (
    <CustomModal
      show={isShowModal}
      onHide={() => setShowModal(false)}
      title={t.factory.addCollateral}
    >
      <AmountInput
        setAmount={setAmount}
        tokenSymbol={tokenSymbol}
        tokenDecimal={tokenDecimal}
        showMaxBtn={true}
        maxAmount={vaultBalance}
      />
      <InfoContainer>
        <BalanceRow>
          <Label>{t.factory.factoryBalance}</Label>
          <Value>
            {vaultBalance} {tokenSymbol}
          </Value>
        </BalanceRow>
      </InfoContainer>
      <BtnContainer>
        <Button
          btnType="primary"
          btnText={t.factory.addCollateral}
          onClick={handleAddCollateral}
          disabled={!amount}
          isLoading={false}
        />
      </BtnContainer>
    </CustomModal>
  );
};

export default AddCollateral;

const InfoContainer = styled.div`
  margin-top: 20px;
`;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const Value = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
  color: ${Colors.ivory};
  text-align: right;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  gap: 20px;
`;

const BtnContainer = styled.div`
  margin-top: 20px;
  button {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;
