import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import {
  getAutoVaultState,
} from "../utils/api/autoFarmApi";
import {
  getSharePriceOfFarm,
  getActionRolloverTime,
  getVaultCurrentRoundStartTime,
  getTotalSupplyOfEvieVault
} from "../utils/api/evieVaultApi"

const useEvieVaultState = (
  farmAddress: string,
  actionAddress: string,
  wantTokenDecimal: number,
  deployedChainId: number
) => {
  const [totalSupply, setTotalSupply] = useState<number>(0);
  const [prevRoundSharePrice, setPrevRoundSharePrice] = useState<number>(0);
  const [vaultState, setVaultState] = useState<number>(0); // 0:Locked, 1:Unlocked, 2:Emergency
  const [rolloverTime, setRolloverTime] = useState<number>(0)
  const [currentRoundStartTimestamp, setCurrentRoundStartTimestamp] = useState<number>(0)

  const { provider } = useWeb3React();

  const refreshState = () => {
    setTotalSupply(0);
    setPrevRoundSharePrice(0);
    setVaultState(0);
  };

  const getTotalSupply = useCallback(async () => {
    if (farmAddress && deployedChainId) {
      const amount = await getTotalSupplyOfEvieVault(
        farmAddress,
        wantTokenDecimal,
        deployedChainId,
        provider
      );
      setTotalSupply(+amount);
    }
  }, [farmAddress, wantTokenDecimal, deployedChainId, provider]);

  const getVaultState = useCallback(async () => {
    if (farmAddress && deployedChainId) {
      const state = await getAutoVaultState(
        farmAddress,
        deployedChainId,
        provider
      );
      setVaultState(state);
    }
  }, [farmAddress, deployedChainId, provider]);

  const getSharePrice = useCallback(async () => {
    if (farmAddress && deployedChainId) {
      const sharePrice = await getSharePriceOfFarm(
        farmAddress,
        wantTokenDecimal,
        deployedChainId,
        provider
      );
      setPrevRoundSharePrice(+sharePrice);
    }
  }, [farmAddress, wantTokenDecimal, deployedChainId, provider]);

  const getRolloverTime = useCallback(async () => {
    if (actionAddress && deployedChainId) {
      const rolloverTime = await getActionRolloverTime(
        actionAddress,
        deployedChainId,
        provider
      );
      setRolloverTime(rolloverTime);
    }
  }, [actionAddress, deployedChainId, provider]);

  const getCurrentRoundStartTime = useCallback(async () => {
    if (farmAddress && deployedChainId) {
      const time = await getVaultCurrentRoundStartTime(
        farmAddress,
        deployedChainId,
        provider
      );
      setCurrentRoundStartTimestamp(time);
    }
  }, [farmAddress, deployedChainId, provider]);

  const updateAutoVaultState = useCallback(() => {
    getTotalSupply();
  }, [getTotalSupply]);

  useEffect(() => {
    refreshState();
  }, [farmAddress]);

  useEffect(() => {
    getSharePrice();
    getVaultState();
    getRolloverTime();
    getCurrentRoundStartTime()

    // States that will change if users deposit/withdraw
    updateAutoVaultState();
  }, [
    getSharePrice,
    getVaultState,
    updateAutoVaultState,
    getRolloverTime,
    getCurrentRoundStartTime,
  ]);

  return {
    updateAutoVaultState,
    totalSupply,
    prevRoundSharePrice,
    vaultState,
    rolloverTime,
    currentRoundStartTimestamp
  };
};

export default useEvieVaultState;
