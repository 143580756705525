import { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { getAccountBalanceFromBalanceVault } from "../utils/api/balanceVaultApi";
import { utils } from "ethers";
import { ALL_VAULTS } from "../utils/constant/internalAddresses";

const useAccountVaultBalance = (
  tokenAddress: string,
  tokenDecimal: number,
  deployedChainId: number
) => {
  const { account, provider } = useWeb3React();

  const [balance, setBalance] = useState<string>("0");

  const getBalance = useCallback(async () => {
    if (!(account && deployedChainId && tokenAddress && provider)) return;
    if (!ALL_VAULTS[deployedChainId]) return;

    const _balance = await getAccountBalanceFromBalanceVault(
      account,
      tokenAddress,
      deployedChainId,
      provider
    );
    if (_balance) {
      setBalance(utils.formatUnits(_balance, tokenDecimal));
    }
  }, [account, tokenAddress, tokenDecimal, deployedChainId, provider]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  return {
    accountVaultBalance: balance,
    updateAccountVaultBalance: getBalance,
  };
};

export default useAccountVaultBalance;
