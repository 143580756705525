import { useWeb3React } from "@web3-react/core";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useCallback, useEffect, useState } from "react";
import { getBalanceVaultRecords } from "../redux/accountAction";
import { RecordProps } from "../redux/accountSlice";

const useUserBalanceVaultRecord = (tokenAddress: string, tokenDecimal: number) => {
  const balanceVaultRecords = useAppSelector(
    (state) => state.account.balanceVaultRecords
  );

  const [records, setRecords] = useState<RecordProps[] | null>(null);

  const { account, chainId } = useWeb3React();

  const dispatch = useAppDispatch();

  const updateRecords = useCallback(() => {
    if (!account || !chainId || !tokenAddress) return;
    dispatch(getBalanceVaultRecords(account, tokenAddress, tokenDecimal, chainId));
  }, [dispatch, account, chainId, tokenAddress]);

  useEffect(() => {
    updateRecords();
  }, [updateRecords]);

  useEffect(() => {
    if (!chainId || !tokenAddress) return;
    if (
      !balanceVaultRecords[chainId] ||
      !balanceVaultRecords[chainId][tokenAddress]
    )
      return;
    setRecords(balanceVaultRecords[chainId][tokenAddress]);
  }, [balanceVaultRecords, chainId, tokenAddress]);

  return { records, updateRecords };
};

export default useUserBalanceVaultRecord;
