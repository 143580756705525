import { useWeb3React } from "@web3-react/core";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useEffect, useState } from "react";
import { getStakingRecords } from "../redux/accountAction";
import { RecordProps } from "../redux/accountSlice";

const useUserStakingRecord = (stakingAddress: string | undefined) => {
  const stakingRecords = useAppSelector(
    (state) => state.account.stakingRecords
  );

  const [records, setRecords] = useState<RecordProps[] | null>(null);

  const { account, chainId } = useWeb3React();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!account || !chainId || !stakingAddress) return;
    dispatch(getStakingRecords(account, stakingAddress, chainId));
  }, [dispatch, account, chainId, stakingAddress]);

  useEffect(() => {
    if (!chainId || !stakingAddress) return;
    if (!stakingRecords[chainId] || !stakingRecords[chainId][stakingAddress])
      return;
    setRecords(stakingRecords[chainId][stakingAddress]);
  }, [stakingRecords, chainId, stakingAddress]);

  return records;
};

export default useUserStakingRecord;
