const toolHints = {
  poolSteps: [
    {
      selector: ".pool-s-1",
      content: "Deposit ETH into Lending Pool",
    },
    {
      selector: ".pool-s-2",
      content: "View your supplies and interest",
    },
    {
      selector: ".pool-s-3",
      content: "Withdraw your ETH back from Lending Pool",
    },
  ],
  factorySteps: [
    {
      selector: ".fac-s-1",
      content: "Deposit USDC into your factory account",
    },
    {
      selector: ".fac-s-2",
      content: "Set parameters and open your position",
    },
    {
      selector: ".fac-s-3",
      content: "View your opened positions and histories",
    },
  ],
};

export default toolHints;
