import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import styled from "styled-components";
import LabelWithHint from "../../components/LabelWithHint";
import PairTokenIcon from "../../components/PairTokenIcon";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useLiquidityPoolApr from "../../hooks/useLiquidityPoolApr";
import { setUniPoolData } from "../../redux/factoryAction";
import Colors from "../../styles/Colors";
import { H4 } from "../../styles/Fonts";
import { getTruncNum, nFormatter } from "../../utils/common";
import { PairProps } from "../../utils/constant/pairs";
import t from "../../utils/content";
import AprWithHint from "../../components/AprWithHint";

const FactoryRow: React.FC<{ pair: PairProps }> = ({ pair }) => {
  const {
    uniPoolAddress,
    wantToken,
    borrowToken,
    deployedChainId,
    pairId,
    label,
    rate,
  } = pair;

  const { apr, pcsRewardApr } = useLiquidityPoolApr(pair.uniPoolAddress);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedChainId = useAppSelector((state) => state.account.selectedChainId);
  const uniPoolStats = useAppSelector((state) => state.factory.uniPoolStats);
  const uniPoolState = useMemo(
    () => uniPoolStats?.[uniPoolAddress],
    [uniPoolStats, uniPoolAddress]
  );

  // Get Uniswap Pool Tvl and 24H Fee & Volume
  useEffect(() => {
    if (!uniPoolAddress || !deployedChainId) return;

    dispatch(
      setUniPoolData(
        uniPoolAddress,
        deployedChainId,
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    uniPoolAddress,
    borrowToken,
    wantToken,
    deployedChainId,
    // library,
    borrowToken.tokenAddress,
    borrowToken.tokenSymbol,
    borrowToken.tokenDecimal,
    wantToken.tokenAddress,
    wantToken.tokenSymbol,
    wantToken.tokenDecimal,
  ]);

  const handleOnClick = () => {
    navigate(`./${pairId}`);
  };

  return (
    <Container onClick={handleOnClick}>
      <PairRow>
        <PairTokenIcon
          borrowTokenIcon={borrowToken.tokenIcon}
          wantTokenIcon={wantToken.tokenIcon}
        />
        <PairName>{`${label} ${rate}%`}</PairName>
      </PairRow>
      <Col>
        <LabelWithHint
          labelComponent={<HeadText>{t.factory.feeApr}</HeadText>}
          hintText={selectedChainId === 56? t.factory.uniFeeAprDescBnB: t.factory.uniFeeAprDesc}
        />

        <AprWithHint
          labelComponent={
            <HighlightedText cakeRewardExist={!!(pcsRewardApr && apr)}>
              {apr !== null && pcsRewardApr !== null
                ? `${getTruncNum(apr / 15 + pcsRewardApr, 2)} %`
                : "-"}
            </HighlightedText>
          }
          liquidityProvidingApr={apr !== null ? apr / 15 : 0}
          pcsRewardApr={pcsRewardApr}
          type="factory"
        />
      </Col>
      <Col>
        <LabelWithHint
          labelComponent={<HeadText>{t.factory.tvl}</HeadText>}
          hintText={t.factory.tvlDesc}
        />
        {uniPoolState?.tvl ? (
          <Text>{`$${nFormatter(uniPoolState?.tvl, 2)}`}</Text>
        ) : (
          <BarLoaderContainer>
            <BarLoader color={Colors.ivory} width={32} />
          </BarLoaderContainer>
        )}
      </Col>
      <Col>
        <LabelWithHint
          labelComponent={<HeadText>{t.factory.uniVolume}</HeadText>}
          hintText={t.factory.uniVolumeDesc}
        />
        {uniPoolState?.volumeIn24H ? (
          <Text>{`$${nFormatter(uniPoolState?.volumeIn24H, 2)}`}</Text>
        ) : (
          <BarLoaderContainer>
            <BarLoader color={Colors.ivory} width={32} />
          </BarLoaderContainer>
        )}
      </Col>
    </Container>
  );
};

export default FactoryRow;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(3, 150px);
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  transition: all 300ms ease;
  :hover {
    transform: scale(1.03);
  }
  background-color: ${Colors.backgroundGray4};
  padding: 24px;
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  @media (max-width: 768px) {
    padding: 18px;
  }
`;

const PairRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 992px) {
    width: 100%;
    padding-bottom: 12px;
    margin-bottom: 12px;
    ::after {
      content: "";
      border-bottom: 0.5px solid ${Colors.gray4};
      width: calc(100% + 48px);
      position: absolute;
      top: 100%;
      left: -24px;
    }
  }
  @media (max-width: 768px) {
    ::after {
      content: "";
      border-bottom: 0.5px solid ${Colors.gray4};
      width: calc(100% + 36px);
      position: absolute;
      top: 100%;
      left: -18px;
    }
  }
`;

const HeadText = styled(H4)`
  display: block;
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
    white-space: nowrap;
  }
`;

const Text = styled(HeadText)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${Colors.gray1};
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    text-align: right;
  }
`;

const PairName = styled(Text)`
  @media (max-width: 992px) {
    font-size: 15px;
    font-weight: 700;
  }
  @media (max-width: 768px) {
    text-align: left;
  }
`;

const HighlightedText = styled(Text)<{ cakeRewardExist: boolean }>`
  display: inline;
  color: ${Colors.green};
  font-weight: bold;
  border-bottom: ${(props) =>
    props.cakeRewardExist ? `1px dashed ${Colors.gray4}` : "none"};
`;

const Col = styled.div`
  > div:first-child {
    display: none;
  }
  @media (max-width: 992px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    > div:first-child {
      display: flex;
    }
  }
`;

const BarLoaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 992px) {
    justify-content: flex-end;
  }
`;
