import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LiFiWidget from "../../components/LiFiWidget";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Colors from "../../styles/Colors";
import Button from "../../components/Button";
import { H4 } from "../../styles/Fonts";
import { updateSelectedChainId } from "../../redux/accountSlice";
import { networkParams } from "../../utils/constant/networks";
import { isSupportedChain } from "../../utils/constant/chains";
import CustomModal from "../../components/Modal";

const LiFiPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState<boolean>(false);

  const isDappBrowser = Boolean(window.ethereum);

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const handleBackToPrePage = () => {
    navigate(-2);
    setTimeout(() => connectMetaMask(selectedChainId), 1000);
  };

  const connectMetaMask = async (targetChainId: number) => {
    if (isSupportedChain(targetChainId)) {
      const chainIdInHex = networkParams[targetChainId].chainId;

      if (isDappBrowser) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: chainIdInHex }],
          });
          await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          dispatch(updateSelectedChainId(+window.ethereum.networkVersion));
        } catch (error: any) {
          if (error.code === 4902) {
            try {
              await window?.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [networkParams[targetChainId]],
              });
            } catch (error) {
              // console.log(error);
            }
          }
          console.error(error);
        }
      }
    }
  };

  return (
    <Container>
      <LiFiWidget setShowModal={setShowModal} />
      <CustomModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={"Bridge Token Succeed"}
      >
        <Text>
          Please go to the previous page to see your balance on Arbitrum.
        </Text>
        <BtnContainer>
          <Button
            btnType="primary"
            btnText="Back To Previous Page"
            onClick={handleBackToPrePage}
            disabled={false}
            isLoading={false}
          />
        </BtnContainer>
      </CustomModal>
    </Container>
  );
};

export default LiFiPage;

const Container = styled.div`
  padding: 40px 0 120px 0;
  background-color: rgba(0, 0, 0, 0.4);
  min-height: 100vh;

  > .MuiBox-root {
    height: auto;
  }
`;

const BtnContainer = styled.div`
  margin-top: 20px;
  button {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;

const Text = styled(H4)`
  color: ${Colors.ivory};
  font-size: 14px;
  line-height: 22px;
  @media (max-width: 768px) {
  }
`;
