import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { getGinzaRewardRecords } from "../utils/api/ginzaApi";
import { getRewardClaimStatus } from "../utils/api/referralApi";
import { utils } from "ethers";

type ClaimableWeekParams = [number, number, string[]][]; // [weekNumber, rewardTokenAmount, merkleProof][]

interface RewardProps {
  ID: number;
  MerkleProof: string[];
  Discount: number;
  Rebate: number;
  RewardSession: {
    WeekNumber: number;
    RewardToken: string;
    RewardTokenDecimal: number;
  };
  isClaimed: boolean;
}

const useUserRewards = () => {
  const [unclaimedReward, setUnclaimedReward] = useState<number>(0);
  const [claimedReward, setClaimedReward] = useState<number>(0);
  const [claimableWeekParams, setClaimableWeekParams] =
    useState<ClaimableWeekParams>([]);

  const { account, chainId, provider } = useWeb3React();

  const updateRewards = useCallback(async () => {
    if (!account || !chainId) return;

    const { data: _rewards, claimed_rewards } = await getGinzaRewardRecords(
      account,
      chainId
    );
    setClaimedReward(+utils.formatUnits(claimed_rewards, 6));

    let _unclaimedRewards = 0;
    let _claimableWeekParams: ClaimableWeekParams = [];

    await Promise.all(
      await _rewards.map(async (r: RewardProps) => {
        const weeNum = r.RewardSession.WeekNumber;
        try {
          const isClaimed = await getRewardClaimStatus(
            account,
            chainId,
            provider,
            weeNum
          );
          if (!isClaimed) {
            _unclaimedRewards += +r.Discount + +r.Rebate;
            _claimableWeekParams.push([
              weeNum,
              +r.Discount + +r.Rebate,
              r.MerkleProof,
            ]);
          }
        } catch (e) {}
      })
    );

    setUnclaimedReward(+utils.formatUnits(_unclaimedRewards, 6));
    setClaimableWeekParams(_claimableWeekParams);
  }, [account, chainId, provider]);

  useEffect(() => {
    updateRewards();
  }, [updateRewards]);

  return { claimedReward, unclaimedReward, claimableWeekParams, updateRewards };
};

export default useUserRewards;
