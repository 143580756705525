import { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { getAccountTokenBalance } from "../utils/api/factoryV3Api";

type Props = {
  tokenAddress: string;
  tokenDecimal: number;
};

const useTokenBalance = (props: Props) => {
  const { tokenAddress, tokenDecimal } = props;

  const { account, chainId, provider } = useWeb3React();

  const [balance, setBalance] = useState<string>("0");

  const getBalance = useCallback(async () => {
    if (!(account && chainId && tokenAddress && provider)) {
      return;
    }
    const _balance = await getAccountTokenBalance(
      account,
      chainId,
      tokenAddress,
      tokenDecimal,
      provider
    );
    setBalance(_balance);
  }, [account, chainId, tokenAddress, tokenDecimal, provider]);

  useEffect(() => {
    getBalance();
  }, [account, chainId, tokenAddress, tokenDecimal, getBalance]);

  return { tokenBalance: balance, updateTokenBalance: getBalance };
};

export default useTokenBalance;
