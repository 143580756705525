import { SupportedChainId } from "./chains";

type DiamondAddressesProps = {
  lendingPools: {
    [key: string]: string;
  };
  factories: {
    [key: string]: string;
  };
  balanceVault: string;
  referral: string;
  rewardVesting: string;
  factoryController: string;
  farm: string;
  farmAction: string;
  aggregator: string;
  helper: string;
  staking: {
    [key: string]: string;
  } | null;
  liquidityMining: string;
  airdrop: string;
};

export const diamondArbitrumAddresses: DiamondAddressesProps = {
  lendingPools: {
    WETH: "0xEdD1efA76fe59e9106067D824b89B59157C5223C",
    USDC: "0x65fE5785dE94c1a87903C1739b1173C10D9b8F33",
    ARB: "0x4d5043d90f13ac2E6318B3aF9C3423A5224b920C",
  },
  factories: {
    WETH_USDC: "0xcad7daf7517fe80b93c8938eadf3156a68873c53",
    WETH_GMX: "0xBb0Af4563b940F01ACc528ECd4635b85e188e6f1",
    USDC_WETH_500: "0xfd0B26dA4ED57Fb5F58d60D9d2a92d42dab81111",
    // ARB_USDC_10000: "0x495D7C77049b6185c021B7cf11CfD6e3d04E28D5",
    WETH_ARB_3000: "0xCbD73Fb7B6645573c3b388A03bFEEed80035375d",
    ARB_USDC_3000: "0xD54079Ef4c6B8cf0A3b8a14322cf08a20A14bb28",
    WETH_ARB_500: "0x177b7d394a9b28dA8ccC32A7Ce79CB3D7fAD836A",
    USDC_ARB_3000: "0x247B33BADbB44633522C00772384038816675B3c",
    ARB_USDC_500: "0xf9D526B85AFe8de8cf2183DC0a4A746256E4EEAB",
    WETH_LDO_10000: "0x65366b229C17DAEA8E886e2C5F35A8e5C1e7aF32",
    WETH_LDO_3000: "0x881aec3876fe6ffca2526aea0cfbbddfa959ee54",
  },
  balanceVault: "0x8610D60f5329B0560c8F0CEb80175F342fe943F3",
  referral: "0xaD48A8261b0690c71b70115035Eb14afD9a43242",
  rewardVesting: "",
  factoryController: "0x44F23a4E5e25Da042F7B3d44E6320a7dbdc40f7f",
  farm: "",
  farmAction: "0xBE9245C83BBcad5D521CbF84F304f157F8A53198",
  aggregator: "0x69F3924340f374aC8c38f1e0271397064Ca61202",
  liquidityMining: "",
  helper: "",
  staking: null,
  airdrop: "0x0000000000000000000000000000000000000001",
};

export const diamondGoerliAddresses: DiamondAddressesProps = {
  lendingPools: {
    WETH: "0xeC3F3A082791058da933780c514C289F0757370f",
    UNI: "0x214DB0538672e9DE60ec7F356FCaf15A4c9216d5",
  },
  factories: {
    WETH_UNI: "0xFd30c8Ff0847E6220319CfdAEbb7FEAaF5787df6",
    WETH_DAI: "0xe22eAcD9fFf32d12c42CF7287A69881a7F291bc2",
    UNI_WETH: "0x2a87EAb1A402B8624f96e197b04603CECa1D7376",
  },
  balanceVault: "0xd5656fA31631035cFaD3B1670e3e159a896ffE6B",
  referral: "0xf5910ca5Dd1757cd55E0f8E0fc69840eAF800196",
  rewardVesting: "0x77F60F8B35912Bc4e0a98a3172828e440Bd9BbA7",
  factoryController: "0x66Ef5aF335eBada583E52A26e49d2f72589e3954",
  farm: "0xC1f3c6B8b16fdF135a6a8664E573f97489920595",
  farmAction: "0x51f1a38767FEC263AE1e88B358bD7E8022C0711B",
  aggregator: "0xC4aBe7c528CBC08C14E5E1D7202E3112E82E73a1",
  liquidityMining: "",
  helper: "",
  staking: null,
  airdrop: "",
};

export const diamondPolygonMumbaiAddresses: DiamondAddressesProps = {
  lendingPools: {
    WMATIC: "0x71Cf5a399730E33d19cb30719c0fdFff6C230029",
  },
  factories: {
    WMATIC_DERC20: "0xe0911898D30f469A3d471d2f535eB66579641517",
  },
  balanceVault: "0x600101663A7Ed7d24f2d9ef5589837a1755dA9D3",
  referral: "0x05ea248c901320ecd3ffcb79f958eb60f3674eaf",
  rewardVesting: "0x1918279CaF538e2fE5fC7aEC5029640cEF86B58E",
  factoryController: "0xd197a9D5A1Eceb9A4CD8c8de3bA3a7E491a318E5",
  farm: "",
  farmAction: "",
  aggregator: "0xa18e3b7190AC78c258e5EA339431966297428F6B",
  liquidityMining: "",
  helper: "",
  staking: null,
  airdrop: "",
};

export const diamondOptimismGoerliAddresses: DiamondAddressesProps = {
  lendingPools: {
    WETH: "0xEE43f47F2b13030a987F7c6Ba98785326AE206d6",
  },
  factories: {
    WETH_DUSDC: "0xa639B4De19d9f559e07718CE590e9A2328Bf4E45",
  },
  balanceVault: "0xae930E1294679199Db70828d16ADB29E7eD86A8C",
  referral: "",
  rewardVesting: "",
  factoryController: "0x38D8604a140DE81fc2dEB91c54913c5f4b2F61D9",
  farm: "",
  farmAction: "",
  aggregator: "",
  liquidityMining: "",
  helper: "",
  staking: null,
  airdrop: "",
};

export const diamondPolygonAddresses: DiamondAddressesProps = {
  lendingPools: {
    WETH: "0x8610D60f5329B0560c8F0CEb80175F342fe943F3",
    WMATIC: "0xEdD1efA76fe59e9106067D824b89B59157C5223C",
  },
  factories: {
    WETH_USDC: "0x46E65545f4A527a7e2090Bab9E1AC21D019BEE38",
    MATIC_WETH: "0xa2f14e5bFc78f67eB0eF0551b8421E59850A316B",
  },
  balanceVault: "0xD576bE0d3CC1c0184d1ea3F1778A4A9Dec523859",
  referral: "",
  rewardVesting: "",
  factoryController: "0xD8006420c1b2901849505eb3517cc36a169AE0A7",
  farm: "",
  farmAction: "",
  aggregator: "0xc8d0d30654592C63f5bB1E04bD1e97B53C9770f3",
  liquidityMining: "",
  helper: "",
  staking: null,
  airdrop: "",
};

export const diamondBnbChainAddresses: DiamondAddressesProps = {
  lendingPools: {
    CAKE: "0x8610D60f5329B0560c8F0CEb80175F342fe943F3",
  },
  factories: {
    CAKE_USDT_2500: "0x61De30d8367e852eF13494e19fde9F97198E2Fc9",
    CAKE_WBNB_2500: "0x3C3FCa7035dA581Ea2F47F121fDE71f1de5C0cfe",
  },
  balanceVault: "0xD576bE0d3CC1c0184d1ea3F1778A4A9Dec523859",
  referral: "",
  rewardVesting: "",
  factoryController: "0xcc669455feFF896fEA8bFbb5D9A97385EbF0c1C9",
  farm: "",
  farmAction: "",
  aggregator: "0x8FCF6179A89a13E06092C41b63bFe4d5dDf7274e",
  helper: "0x5dfee62C78A0E607CCE6A5d4458c328A03275ba2",
  staking: {
    CAKE: "",
  },
  liquidityMining: "",
  airdrop: "",
};

export const diamondBnbTestnetAddresses: DiamondAddressesProps = {
  lendingPools: {
    CAKE2: "0x766c3777AE757568E8462851c737Ef33dbbb9218",
  },
  factories: {
    CAKE2_BUSD: "0xD6b7527f02416F62032Fd1CF9dd869537344Abe8",
  },
  balanceVault: "0x6CfdD871245EB83dAa464CdB690dCb36c9ec5c74",
  referral: "",
  rewardVesting: "",
  factoryController: "0x44099a501F44cf376Ca8E7AC2e6C6dd99AECF137",
  farm: "",
  farmAction: "",
  aggregator: "0xCB0aae4D496D8Cb2E5561Bd6FC183949F4784E83",
  helper: "0x41fAD86EF9360fC5E85F99E4F6Fe50849f0Bf8C7",
  staking: { CAKE2: "0x128da4d379f155656F5cD0F84C3101FD6a03678f" },
  liquidityMining: "0x02403647cc4a75E2318988938E4aF0dEFaA935f2",
  airdrop: "0x86e47D9b839bc088fa673Bd05507de1322Bc26f0",
};

export const diamondAddresses: {
  [key: number]: DiamondAddressesProps;
} = {
  [SupportedChainId.ARBITRUM_ONE]: diamondArbitrumAddresses,
  [SupportedChainId.GOERLI]: diamondGoerliAddresses,
  // [SupportedChainId.POLYGON_MUMBAI]: diamondPolygonMumbaiAddresses,
  [SupportedChainId.OPTIMISM_GOERLI]: diamondOptimismGoerliAddresses,
  // [SupportedChainId.POLYGON]: diamondPolygonAddresses,
  [SupportedChainId.BINANCE_SMART_CHAIN]: diamondBnbChainAddresses,
  [SupportedChainId.BINANCE_TESTNET]: diamondBnbTestnetAddresses,
};

const generateAllAddressTypes = (_allChainsAddresses: {
  [key: number]: DiamondAddressesProps;
}) => {
  const ALL_LIQUIDITY_MINING: { [key: number]: string } = {};
  const ALL_VAULTS: { [key: number]: string } = {};
  const ALL_AGGREGATORS: { [key: number]: string } = {};
  const ALL_CONTROLLERS: { [key: number]: string } = {};
  const ALL_HELPERS: { [key: number]: string } = {};
  const ALL_STAKINGS: {
    [key: number]: { [key: string]: string } | null;
  } = {};
  const ALL_AIRDROP: { [key: number]: string } = {};

  for (const _chainId in _allChainsAddresses) {
    const chainAddresses = _allChainsAddresses[_chainId];

    ALL_LIQUIDITY_MINING[_chainId] = chainAddresses.liquidityMining;
    ALL_VAULTS[_chainId] = chainAddresses.balanceVault;
    ALL_AGGREGATORS[_chainId] = chainAddresses.aggregator;
    ALL_CONTROLLERS[_chainId] = chainAddresses.factoryController;
    ALL_HELPERS[_chainId] = chainAddresses.helper;
    ALL_STAKINGS[_chainId] = chainAddresses.staking;
    ALL_AIRDROP[_chainId] = chainAddresses.airdrop;
  }

  return {
    ALL_LIQUIDITY_MINING,
    ALL_VAULTS,
    ALL_AGGREGATORS,
    ALL_CONTROLLERS,
    ALL_HELPERS,
    ALL_STAKINGS,
    ALL_AIRDROP,
  };
};

export const {
  ALL_LIQUIDITY_MINING,
  ALL_VAULTS,
  ALL_AGGREGATORS,
  ALL_CONTROLLERS,
  ALL_HELPERS,
  ALL_STAKINGS,
  ALL_AIRDROP,
} = generateAllAddressTypes(diamondAddresses);
