import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./fonts/fonts.css";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { TourProvider } from "@reactour/tour";
import Colors from "./styles/Colors";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import { Web3ReactProvider } from "@web3-react/core";
import { init } from "@amplitude/analytics-node";
import { connectors } from "./utils/constant/connectors";

const disableBody = (target: null | Element) =>
  disableBodyScroll(target as Element);

const enableBody = (target: null | Element) =>
  enableBodyScroll(target as Element);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Sentry.init({
//   dsn: "https://4af9757e69cc4b11b2820b009015690d@o4503979716050944.ingest.sentry.io/4503979716902912",
//   integrations: [new BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

init(process.env.REACT_APP_AMPLITUDE_KEY || "");

root.render(
  <Provider store={store}>
    <TourProvider
      steps={[]}
      afterOpen={disableBody}
      beforeClose={enableBody}
      styles={{
        popover: (base) => ({
          ...base,
          "--reactour-accent": Colors.primary.dark,
          borderRadius: 8,
          fontSize: 14,
          lineHeight: 1.5,
        }),
        maskArea: (base) => ({ ...base, rx: 10 }),
        maskWrapper: (base) => ({ ...base, opacity: "0.5" }),
        badge: (base) => ({ ...base, right: "auto", left: "-0.8125em" }),
        controls: (base) => ({ ...base, marginTop: 50 }),
        close: (base) => ({ ...base, left: "auto", right: 12, top: 12 }),
      }}
    >
      <Web3ReactProvider connectors={Object.values(connectors)}>
        <App />
      </Web3ReactProvider>
    </TourProvider>
  </Provider>
);
