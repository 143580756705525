import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { H1, H3 } from "../../styles/Fonts";
import {
  getTruncNum,
  handleOpenV3PoolLink,
  nFormatter,
} from "../../utils/common";
import {
  faArrowUpRightFromSquare,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "../../hooks";
import { ClipLoader, BarLoader } from "react-spinners";
import LabelWithHint from "../../components/LabelWithHint";
import { PairProps } from "../../utils/constant/pairs";
import { isPanCakeSwap } from "../../utils/constant/chains";
import PairTokenIcon from "../../components/PairTokenIcon";
import SetSlippage from "./Modals/SetSlippage";
import t from "../../utils/content";
import { getPriceDecimal } from "../../utils/constant/decimals";
import AprWithHint from "../../components/AprWithHint";

type PairSectionProps = {
  pair: PairProps;
  openModal: () => void;
  liquidityPoolApr: number;
  tokenPrice: number;
  slippage: number;
  setSlippage: (v: number) => void;
  defaultSlippage: number;
  pcsRewardApr: null | number;
};

const PairSection: React.FC<PairSectionProps> = (props) => {
  const {
    pair,
    openModal,
    liquidityPoolApr,
    tokenPrice,
    slippage,
    setSlippage,
    defaultSlippage,
    pcsRewardApr,
  } = props;
  const {
    label: pairName,
    rate,
    wantToken,
    borrowToken,
    deployedChainId,
    uniPoolAddress,
  } = pair;
  const { tokenSymbol: bTokenSymbol } = borrowToken;
  const { tokenSymbol: wTokenSymbol } = wantToken;

  const selectedChainId = useAppSelector((state) => state.account.selectedChainId);
  const uniPoolStats = useAppSelector((state) => state.factory.uniPoolStats);
  const uniPoolState = useMemo(
    () => uniPoolStats?.[uniPoolAddress],
    [uniPoolStats, uniPoolAddress]
  );

  const [slippageIsShow, setSlippageIsShow] = useState<boolean>(false);

  const isPancakeSwap = isPanCakeSwap(deployedChainId);

  return (
    <Container>
      <TopContainer>
        <TitleRow>
          <TopRow>
            <PairRow>
              <PairTokenIcon
                borrowTokenIcon={pair.borrowToken.tokenIcon}
                wantTokenIcon={pair.wantToken.tokenIcon}
                size="lg"
              />
              <Title>{pairName}</Title>
              <Ratio>{`${rate}%`}</Ratio>
            </PairRow>
            <SettingBtn onClick={() => setSlippageIsShow(true)}>
              <SettingIcon icon={faGear} />
            </SettingBtn>
          </TopRow>
          <PairSwitcher onClick={openModal}>Switch pair</PairSwitcher>
        </TitleRow>
        {tokenPrice ? (
          <DataRows>
            <PriceCol>
              <LabelRow
                onClick={() =>
                  handleOpenV3PoolLink(
                    uniPoolAddress,
                    deployedChainId,
                    isPancakeSwap
                  )
                }
              >
                <Label>{`${
                  isPancakeSwap ? t.factory.pancakeSwap : t.factory.uniswap
                } V3 ${pairName}`}</Label>
                <Icon icon={faArrowUpRightFromSquare} />
              </LabelRow>
              <PriceRow>
                <PriceText>
                  {tokenPrice
                    ? `${getTruncNum(tokenPrice, getPriceDecimal(tokenPrice))}`
                    : "-"}
                </PriceText>
                <PriceTextUnit>{`${wTokenSymbol} per ${bTokenSymbol}`}</PriceTextUnit>
              </PriceRow>
            </PriceCol>
            <DataCol>
              <LabelWithHint
                labelComponent={<Label>{t.factory.tvl}</Label>}
                hintText={t.factory.tvlDesc}
              />
              <ValueText>
                {uniPoolState?.tvl ? (
                  `$${nFormatter(uniPoolState?.tvl, 2)}`
                ) : (
                  <BarLoader color={Colors.ivory} width={32} />
                )}
              </ValueText>
            </DataCol>
            <DataCol>
              <LabelWithHint
                labelComponent={<Label>{t.factory.uniVolume}</Label>}
                hintText={t.factory.uniVolumeDesc}
              />
              <ValueText>
                {uniPoolState?.volumeIn24H ? (
                  "$" + nFormatter(uniPoolState?.volumeIn24H, 2)
                ) : (
                  <BarLoader color={Colors.ivory} width={32} />
                )}
              </ValueText>
            </DataCol>
            <DataCol>
              <LabelWithHint
                labelComponent={<Label>{t.factory.uniFee}</Label>}
                hintText={t.factory.uniFeeDesc}
              />
              <ValueText>
                {uniPoolState?.feeIn24H ? (
                  "$" + nFormatter(uniPoolState?.feeIn24H, 2)
                ) : (
                  <BarLoader color={Colors.ivory} width={32} />
                )}
              </ValueText>
            </DataCol>
            <DataCol>
              <LabelWithHint
                labelComponent={<Label>{t.factory.feeApr}</Label>}
                hintText={selectedChainId === 56? t.factory.uniFeeAprDescBnB: t.factory.uniFeeAprDesc}
              />
              <AprWithHint
                labelComponent={
                  <HighlightText
                    cakeRewardExist={!!(liquidityPoolApr && pcsRewardApr)}
                  >
                    {liquidityPoolApr !== null && pcsRewardApr !== null
                      ? (liquidityPoolApr / 15 + pcsRewardApr).toFixed(2)
                      : "- "}
                    %
                  </HighlightText>
                }
                liquidityProvidingApr={liquidityPoolApr / 15}
                pcsRewardApr={pcsRewardApr}
                type="factory"
              />
            </DataCol>
          </DataRows>
        ) : (
          <LoaderContainer>
            <ClipLoader color={Colors.ivory} size={24} />
          </LoaderContainer>
        )}
      </TopContainer>
      <SetSlippage
        isShowModal={slippageIsShow}
        setShowModal={setSlippageIsShow}
        slippage={slippage}
        setSlippage={setSlippage}
        defaultSlippage={defaultSlippage}
      />
    </Container>
  );
};

export default PairSection;

const Container = styled.div``;

const Title = styled(H1)`
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  color: ${Colors.ivory};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const DataRows = styled.div`
  display: flex;
  flex-direction: row;
  gap: 44px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const DataCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    padding: 2px 0;
  }
`;

const PriceCol= styled(DataCol)`
  flex-wrap: wrap;
`;

const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
  cursor: pointer;
`;

const Label = styled.span`
  color: #9199a7;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  @media (max-width: 976px) {
    padding: 0;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-size: 13px;
  color: ${Colors.gray3};
  :hover {
    color: ${Colors.ivory};
  }
`;

const PriceText = styled(H3)`
  color: ${Colors.ivory};
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin: auto auto auto 0;
  @media (max-width: 768px) {
    padding: 0;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
  }
`;

const PriceTextUnit = styled(Label)`
  color: ${Colors.ivory};
`;

const HighlightText = styled(H3)<{ cakeRewardExist: boolean }>`
  color: ${Colors.green};
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin: auto auto auto 0;
  border-bottom: ${(props) =>
    props.cakeRewardExist ? `1px dashed ${Colors.gray4}` : "none"};
  @media (max-width: 768px) {
    padding: 0;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
  }
`;

const ValueText = styled(H3)`
  min-height: 40px;
  color: ${Colors.ivory};
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    min-height: 0px;
    padding: 0;
    font-size: 14px;
    line-height: 24px;
  }
`;

const TopContainer = styled.div`
  padding: 0 20px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const LoaderContainer = styled.div`
  padding: 12px 0 36px 0;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 12px;
  flex-wrap: wrap;
`;

const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-grow: 2;
`;

const PairRow = styled.div`
  display: flex;
  align-items: center;
`;

const PairSwitcher = styled.button`
  display: none;
  @media (max-width: 976px) {
    white-space: nowrap;
    font-family: Fira Sans, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
      PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    display: block;
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    color: ${Colors.gray6};
    padding: 4px 16px;
    background-color: ${Colors.lightKhaki};
    border-radius: 100px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    padding: 2px 12px;
  }
`;

const Ratio = styled(Title)`
  background-color: ${Colors.backgroundGray4};
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 576px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const PriceRow = styled.div`
  display: flex;
  align-items: flex-end;
  margin: auto auto auto 0;
  gap: 8px;
  @media (max-width: 768px) {
    align-items: center;
    justify-content: flex-end;
    margin: 0;
  }
`;

const SettingBtn = styled.button`
  width: 40px;
  height: 36px;
  background-color: ${Colors.backgroundGray4};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    transform: scale(0.95);
  }
  @media (max-width: 976px) {
    width: 32px;
    height: 28px;
  }
`;

const SettingIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${Colors.gray3};
  transform: rotate(-30deg);
  @media (max-width: 976px) {
    font-size: 14px;
  }
`;
