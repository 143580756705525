const pool = {
  pool: "Pool",
  poolDesc1:
    "With Diamond Protocol’s Pool, you can offer liquidity on Uniswap V3 without worrying about any potential Impermanent Loss (IL). The Pool acts as the lending counter-party for all Diamond Factory positions, and any IL risks are taken and contained within Factory positions. As a user of the Diamond Pool, you can earn a stable yield based on asset utilization without IL risks. For more detailed information about Diamond Protocol Pool, visit this page ",
  poolDesc2: "here",
  markets: "Markets",
  assets: "Assets",
  yieldApy: "Yield APY",
  yieldApyDesc:
    "The current yield APY based on the utilization rate of Diamond Pool",
  totalSupply: "Total Supply",
  totalSupplyDesc: "The amount of total supplied asset",
  totalBorrowed: "Total Borrowed",
  totalBorrowedDesc: "The amount of total borrowed asset",
  utilization: "Utilization",
  utilizationDesc:
    "The ratio of asset that are being borrowed by Diamond users",
  yourSupply: "Your Supply",
  yourSupplyDesc: "",
  lendingInterestRate: "Lending Interest Rate",
  lendingInterestRateDesc:
    "The current interest rate based on the utilization rate of Diamond Pool",
  balance: "Balance",
  earned: "Earned",
  deposit: "Deposit",
  withdraw: "Withdraw",
  more: "More",
  wrap: "Wrap",
  unwrap: "Unwrap",
  walletBalance: "Wallet Balance",
  approveUseOf: "Approve use of",
  canDeposit: "You can now deposit",
  bridgeYourAsset: "Bridge Your Asset To",
  unwrapYour: "Unwrap Your",
  poolBalance: "Pool Balance",
  wrapYour: "Wrap Your",
  to: "to",
  allPools: "All Pools",
  roi: "ROI",
  asset: "Asset",
  stake: "Stake",
  unstakedAsset: "Unstaked Asset",
  canStake: "You can now stake",
  stakedAsset: "Staked Asset",
  unstake: "Unstake",
};

export default pool;
