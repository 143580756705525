import { createSlice } from "@reduxjs/toolkit";
import { isSupportedChain, SupportedChainId } from "../utils/constant/chains";
import { ConnectionType } from "../utils/constant/connectors";

export interface NFTProps {
  id: string;
  name: string;
  imageUrl: string;
  permalink: string;
}

export interface RecordProps {
  timestamp: number;
  txHash: string;
  actionType: string;
  amount: string;
}

type AccountProps = {
  selectedWallet?: ConnectionType;
  selectedChainId: number;
  nfts: NFTProps[];
  nftIsFetching: boolean;
  balanceVaultRecords: { [key: number]: { [key: string]: RecordProps[] } };
  lendingPoolRecords: { [key: number]: { [key: string]: RecordProps[] } };
  stakingRecords: { [key: number]: { [key: string]: RecordProps[] } };
};

const initialState: AccountProps = {
  selectedWallet: undefined,
  selectedChainId: SupportedChainId.ARBITRUM_ONE,
  nfts: [],
  nftIsFetching: false,
  balanceVaultRecords: {},
  lendingPoolRecords: {},
  stakingRecords: {},
};

const accountSlice = createSlice({
  name: "account",
  initialState: initialState,
  reducers: {
    updateSelectedWallet(state, { payload: { wallet } }) {
      state.selectedWallet = wallet;
    },
    updateSelectedChainId(state, { payload: { chainId } }) {
      if (isSupportedChain(chainId)) {
        state.selectedChainId = chainId;
      }
    },
    updateAccountNfts(state, action) {
      state.nfts = action.payload;
    },
    setNftIsFetching(state, action) {
      state.nftIsFetching = action.payload;
    },
    updateUserLendingPoolRecords(state, action) {
      const { records, lendingPoolAddress, chainId } = action.payload;
      state.lendingPoolRecords = {
        ...state.lendingPoolRecords,
        [chainId]: {
          ...state.lendingPoolRecords[chainId],
          [lendingPoolAddress]: records,
        },
      };
    },
    updateUserBalanceVaultRecords(state, action) {
      const { records, tokenAddress, chainId } = action.payload;
      state.balanceVaultRecords = {
        ...state.balanceVaultRecords,
        [chainId]: {
          ...state.balanceVaultRecords[chainId],
          [tokenAddress]: records,
        },
      };
    },
    updateUserStakingRecords(state, action) {
      const { records, stakingAddress, chainId } = action.payload;
      state.stakingRecords = {
        ...state.stakingRecords,
        [chainId]: {
          ...state.stakingRecords[chainId],
          [stakingAddress]: records,
        },
      };
    },
  },
});

export const {
  updateSelectedWallet,
  updateSelectedChainId,
  updateAccountNfts,
  setNftIsFetching,
  updateUserLendingPoolRecords,
  updateUserBalanceVaultRecords,
  updateUserStakingRecords,
} = accountSlice.actions;

export default accountSlice.reducer;
