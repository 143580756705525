import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Colors from "../styles/Colors";
import { useTour } from "@reactour/tour";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { useWeb3React } from "@web3-react/core";
import toolHints from "../utils/constant/toolHints";
import t from "../utils/content";

type typeProps = "poolSteps" | "factorySteps";

const TourHint: React.FC<{ type: typeProps }> = ({ type }) => {
  const { setIsOpen, setCurrentStep, setSteps } = useTour();
  const { account } = useWeb3React();

  useEffect(() => {
    const walletStep = {
      selector: ".connect-wallet",
      content: t.common.connectYourWallet,
    };

    const restSteps = toolHints[type];

    const allSteps = !!account ? restSteps : [walletStep, ...restSteps];

    setSteps(allSteps);
  }, [account, setSteps, type]);

  return (
    <TourContainer
      onClick={() => {
        setIsOpen(true);
        setCurrentStep(0);
      }}
    >
      <Icon icon={faQuestion} />
    </TourContainer>
  );
};

export default TourHint;

const move = keyframes`
  0% {
      bottom: 20px;
      right: 20px;
      rotate: 10deg;
    }
    50% {
      bottom: 24px;
      right: 20px;
      rotate: -10deg;
    }
    100% {
      bottom: 20px;
      right: 20px;
      rotate: 10deg;
    }
`;

const TourContainer = styled.div`
  width: 32px;
  height: 32px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* background-color: ${Colors.lightKhaki}; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 2px solid ${Colors.lightKhaki};
  cursor: pointer;
  animation-name: ${move};
  animation-duration: 1s;
  animation-iteration-count: 10;
  @media (max-width: 480px) {
    display: none;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${Colors.lightKhaki};
`;
