import { createSlice } from "@reduxjs/toolkit";

type ModalProps = {
  selectWalletModalIsOpen: boolean;
};

const initialState: ModalProps = {
  selectWalletModalIsOpen: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    setSelectWalletModal(state, action) {
      state.selectWalletModalIsOpen = action.payload;
    },
  },
});

export const { setSelectWalletModal } = modalSlice.actions;

export default modalSlice.reducer;
