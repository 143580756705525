const errorMsg = {
  connectWallet: "Please connect your wallet",
  switchNetwork: "Please switch to correct network",
  inputParameters: "Please input the ",
  inputAllParameters: "Please input all required parameters",
  inputRangeTooSmall: "The input range is too small. The minimal range is",
  inputRangeNotValid: "The input range is not valid. The range should be between ",
  inputRangeNotValid2: " and ",
  lowerExitPriceNotValid:
    "The lower exit price should be lower than the current price",
  upperExitPriceNotValid:
    "The upper exit price should be higher than the current price",
  slippageTooLow: "Your slippage is lower than the minimal slippage",
  slippageNotValid: "Your slippage is invalid",
  exceedMaxAmount1: "Your amount has exceeded the maximum position amount (",
  exceedMaxAmount2: ").",
  lowerThanMinAmount1:
    "Your amount is lower than the minimum position amount (",
  lowerThanMinAmount2: ").",
  accountBalanceNotEnough: "Your account balance is not enough.",
  exceedPositionsLimit: "Your positions have exceeded the position limit.",
  deniedTransaction: "You denied the transaction.",
  lendingPoolNotEnough: "Lending Pool Balance Is Not Enough.",
  openPositionSlippageProtect: "Open position failed due to slippage protect.",
  invalidParameters: "Invalid Parameters",
  notInWhitelist: "Your account address is not in the whitelist.",
  openPositionFailed: "Open Position Failed.",
  addCollateralFailed: "Add Collateral Failed",
  closePositionSlippageProtect:
    "Close position failed due to slippage protect.",
  closePositionFailed: "Close Position Failed",
  collateralNotEnough: "Your collateral is not enough",
  healthFactorTooLow: "Health factor will be too low",
  decreaseCollateralFailed: "Decrease Collateral Failed",
  positionHasNoFee: "This position does not have fee yet.",
  collectFeeFailed: "Collect Fee Failed",
  updateExitPricesFailed: "Update Exit Prices Failed",
  tokenBalanceNotEnough1: "Your",
  tokenBalanceNotEnough2: "balance is not enough.",
  deposit: "Deposit",
  failed: "Failed",
  withdraw: "Withdraw",
  balanceNotEnough: "Your balance is not enough.",
  ethBalanceNotEnough: "Your ETH balance is not enough.",
  wrapEthFailed: "Wrap ETH Failed",
  wethBalanceNotEnough: "Your WETH balance is not enough.",
  unwrapWethFailed: "Unwrap WETH Failed",
  approveTokenFailed: "Approve Token Failed",
  depositFailed: "Deposit Failed",
  withdrawFailed: "Withdraw Failed",
  approveFactoryFailed: "Approve Factory Failed.",
  approve: "Approve",
  registerDepositFailed: "Register Deposit Failed",
  exceedWithdrawableBalance:
    "Your amount has exceeded your withdrawable balance",
  registerWithdrawFailed: "Registered Withdraw Failed",
  unwrap: "Unwrap",
  wrap: "Wrap",
  liteModeAmountLimit: "The maximum amount under Lite version is ",
  useProAmount: ", please use Pro version to unlock higher amount",
  inputDepositAmount: "Please input the deposit amount",
  inputWithdrawAmount: "Please input the withdraw amount",
  referralCodeAssignedAlready:
    "This referral code has already been assigned to an address.",
  referralCodeCreatedAlready: "You have already created a referral code.",
  createReferralCodeFailed: "Create Referral Code Failed",
  cantUseOwnReferralCode: "You cannot use your own referral code.",
  referralCodeSetAlready: "You have already set the referral code.",
  referralCodeNotExist: "The referral code you entered does not exist",
  setReferralCodeFailed: "Set Referral Code Failed",
  invalidClaim: "The claim reward request is invalid",
  claimRewardFailed: "Claim Reward Failed",
  duplicateTwitterAccount:
    "This twitter account has already been linked to another address.",
  twitterLinkageFailed:
    "Linking your twitter account failed. Please try again.",
  stake: "Stake",
  inputStakeAmount: "Please input the stake amount",
  unstake: "Unstake",
  claim: "Claim",
  noRewardToClaim: "There is no reward to claim",
};

export default errorMsg;
