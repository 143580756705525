import styled from "styled-components";
import Colors from "../styles/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H4, SmallRegular } from "../styles/Fonts";
import {
  faAngleLeft,
  faAngleRight,
  faArrowUpRightFromSquare,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { RecordProps } from "../redux/accountSlice";
import { useEffect, useState } from "react";
import t from "../utils/content";
import { networkParams } from "../utils/constant/networks";
import { useWeb3React } from "@web3-react/core";
import { getTimeString, slicedTxHash, onCopy } from "../utils/common";
import { ClipLoader } from "react-spinners";

interface Props {
  records: RecordProps[] | null;
  tokenSymbol: string;
  tokenDecimal: number;
}

const TransactionRecord: React.FC<Props> = (props) => {
  const { records, tokenSymbol, tokenDecimal } = props;

  const [currPage, setCurrPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);
  const [displayRecords, setDisplayRecords] = useState<RecordProps[]>([]);

  const { chainId } = useWeb3React();

  useEffect(() => {
    if (records === null) return;
    setMaxPage(Math.ceil(records.length / 5));
  }, [records]);

  useEffect(() => {
    if (records === null) return;
    if (records.length > 0 && currPage) {
      setDisplayRecords(records.slice((currPage - 1) * 5, currPage * 5));
    }
  }, [records, currPage]);

  const actions: { [key: string]: string } = {
    deposit: t.common.deposit,
    withdraw: t.common.withdraw,
    claimReward: t.common.claimReward,
    stake: t.common.stake,
    unstake: t.common.unstake,
  };

  const onClickLink = (tx: string) => {
    if (!chainId) return;
    window.open(
      `${networkParams[chainId].blockExplorerUrls[0]}tx/${tx}`,
      "_blank"
    );
  };

  const _renderNoRecord = () => {
    return (
      <NoRecordContainer>
        <NoRecord>{t.common.noRecord}</NoRecord>
      </NoRecordContainer>
    );
  };

  const _renderLoader = () => {
    return (
      <NoRecordContainer>
        <ClipLoader color={Colors.ivory} loading={records === null} />
      </NoRecordContainer>
    );
  };

  const showLoader = records === null;
  const showNoRecord = records !== null && records.length === 0;

  return (
    <Padding>
      {showLoader && _renderLoader()}
      {showNoRecord && _renderNoRecord()}
      {displayRecords.length > 0 && (
        <RecordsContainer>
          {displayRecords.map((record) => (
            <RecordContainer key={record.txHash}>
              <RecordValueRow>
                <RecordLabel>{t.common.time}</RecordLabel>
                <RecordValue>{getTimeString(record.timestamp)}</RecordValue>
              </RecordValueRow>
              <RecordValueRow>
                <RecordLabel>{t.common.action}</RecordLabel>
                <RecordValue>{actions[record.actionType]}</RecordValue>
              </RecordValueRow>
              <RecordValueRow>
                <RecordLabel>{t.common.amount}</RecordLabel>
                <RecordValue>
                  {`${record.amount} ${
                    record.actionType === "claimReward" ? "CAKE" : tokenSymbol
                  }`}
                </RecordValue>
              </RecordValueRow>
              <RecordValueRow>
                <RecordLabel>{t.common.TxID}</RecordLabel>
                <TxHashContainer>
                  <RecordValue>{slicedTxHash(record.txHash)}</RecordValue>
                  <TxHashIcon
                    icon={faCopy}
                    onClick={() => onCopy(record.txHash)}
                  />
                  <TxHashIcon
                    icon={faArrowUpRightFromSquare}
                    onClick={() => onClickLink(record.txHash)}
                  />
                </TxHashContainer>
              </RecordValueRow>
            </RecordContainer>
          ))}
        </RecordsContainer>
      )}
      {!!records && records.length > 0 && (
        <PaginationContainer>
          <PaginationIcon
            icon={faAngleLeft}
            disabled={!(currPage > 1)}
            onClick={() => {
              if (currPage > 1) {
                setCurrPage((pre) => pre - 1);
              }
            }}
          />
          <PaginationLabel>{`${currPage} / ${maxPage}`}</PaginationLabel>
          <PaginationIcon
            icon={faAngleRight}
            disabled={!(currPage < maxPage)}
            onClick={() => {
              if (currPage < maxPage) {
                setCurrPage((pre) => pre + 1);
              }
            }}
          />
        </PaginationContainer>
      )}
    </Padding>
  );
};

export default TransactionRecord;

const Padding = styled.div`
  padding: 6px 24px 24px 24px;
`;

const RecordsContainer = styled.div`
  margin-top: 12px;

  @media (max-width: 768px) {
    margin-top: 0px;
    > div:not(:first-child) {
      border-top: 0.5px solid ${Colors.gray5};
    }
  }
`;

const RecordContainer = styled.div`
  display: grid;
  grid-template-columns: 150px 100px 1fr 1fr;
  padding: 4px 0;
  > div:not(:first-child) {
    justify-content: flex-end;

    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: none;
    padding: 12px 0;
  }
`;

const RecordValueRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const RecordValue = styled(SmallRegular)`
  color: ${Colors.gray3};
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const RecordLabel = styled(RecordValue)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const TxHashContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  @media (max-width: 768px) {
    gap: 4px;
  }
`;

const TxHashIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${Colors.gray3};
  padding: 4px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  :active {
    transform: scale(0.9);
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const NoRecordContainer = styled.div`
  text-align: center;
  padding: 60px 0 40px 0;
`;

const NoRecord = styled.span`
  display: inline-block;
  padding: 4px 20px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGray4};
  margin: auto;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.ivory};
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  @media (max-width: 768px) {
    gap: 6px;
  }
`;

const PaginationLabel = styled(H4)`
  color: ${Colors.gray1};
  font-size: 14px;
  line-height: 22px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const PaginationIcon = styled(FontAwesomeIcon)<{ disabled: boolean }>`
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.gray1};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  padding: 8px 12px;
  background-color: ${Colors.backgroundGray4};
  border-radius: 100px;
  :hover {
    cursor: ${(props) => (props.disabled ? "inherit" : "pointer")};
  }
  :active {
    transform: scale(0.9);
  }

  @media (max-width: 768px) {
    padding: 6px 8px;
  }
`;
