const factory = {
  uniswap: "Uniswap",
  pancakeSwap: "PancakeSwap",
  tvl: "TVL",
  tvlDesc: "Total value locked in this liquidity pool",
  uniVolume: "24H Vol",
  uniVolumeDesc:
    "The trading volume of this liquidity pool in the last 24 hours",
  uniFee: "24H Fee",
  uniFeeDesc:
    "The fee income of this liquidity pool in the last 24 hours",
  feeApr: "Fee APR",
  uniFeeAprDesc:
    "The estimated annualized return from fee income based on the range at +-15% in the last 3 days",
  uniFeeAprDescBnB: "The estimated annualized return from fee income & Cake rewards provided by PancakeSwap",
  markets: "Markets",
  openUnibotPosition: "Open Diamond Position",
  openUnibotPositionDesc1:
    "Diamond is an Uniswap V3 Automated Leveraged Liquidity Provision Platform. You can open your automated V3 liquidity provision with",
  openUnibotPositionDesc2: "and borrow",
  openUnibotPositionDesc3: "to hedge and increase your transaction fee return.",
  range: "Range",
  rangeDesc:
    "How wide the provided liquidity will be distributed with the liquidity centers at the entry price.",
  rangeNotice:
    "Notice: A wider range might cause a larger difference between the actual range and the range you set.",
  rangePlaceHolder: "Enter the range",
  minPrice: "Min Price",
  per: "per",
  maxPrice: "Max Price",
  leverage: "Leverage",
  leverageDesc1:
    "The ratio of the total position value divided by your initial investment in",
  leverageDesc2:
    ". With leverage > 2x, users get to create bearish strategies with max profit occurs when price drops, while with leverage <2x, the strategies become are relatively bullish with max profit occurs when price rises.",
  estFeeApr: "Est. Fee APR",
  estFeeAprDesc:
    "The estimated annualized return from fee income based on the liquidity distribution and fee income in the last 3 days",
  borrowingRate: "Borrowing Rate",
  borrowingRateDesc: "The expected cost of borrowing",
  exitPrices: "Exit Prices",
  exitPricesDesc:
    "When the oracle price reaches the exit prices, our keeper will close your position. Please note that the exit prices will be measured in tick, so the actual recorded prices might have slight differences from the input value",
  lowerPrice: "Lower Price",
  upperPrice: "Upper Price",
  amount: "Amount",
  amountDesc: "The investment in",
  approveFactory: "Enable Factory",
  canOpenPosition: "You can now open the position",
  openPosition: "Open Position",
  simulatePosition: "Simulate Your Position",
  bullishOn: "Bullish on",
  bearishOn: "Bearish on",
  worthOf: "worth of",
  leverage20Deposit: "Assume that a user deposited 1,000",
  leverage20OpenPosition:
    "and opened a position with 3x leverage, then Diamond Factory will:",
  leverage20Borrow: "Borrows 2,000",
  leverage20Sell: "Sell 500",
  leverage20ProvideWant: "Provide 1,500",
  leverage20ProvideBorrow: "+ 1,500",
  fromDiamondProtocol: "from Diamond Pool",
  onUniswap: "on Uniswap",
  for: "for",
  toUniswap: "to Uniswap",
  pool: "pool",
  leverage15Deposit: "Assume that a user deposited 1,000",
  leverage15OpenPosition:
    "and opened a position with 2.5x leverage, then Diamond Factory will:",
  leverage15Borrow: "Borrows 1,500",
  leverage15Sell: "Sell 250",
  leverage15ProvideWant: "Provide 1,250",
  leverage15ProvideBorrow: "+ 1,250",
  neutralOn: "Neutral on",
  leverage10Deposit: "Assume that a user deposited 1,000",
  leverage10OpenPosition:
    "and opened a position with 2x leverage, then Diamond Factory will:",
  leverage10Borrow: "Borrows 1,000",
  leverage10ProvideWant: "Provide 1,000",
  leverage10ProvideBorrow: "+ 1,000",
  leverage05Deposit: "Assume that a user deposited 1,000",
  leverage05OpenPosition:
    "and opened a position with 1.5x leverage, then Diamond Factory will:",
  leverage05Borrow: "Borrows 500",
  leverage05Sell: "Sell 250",
  leverage05ProvideWant: "Provide 750",
  leverage05ProvideBorrow: "+ 750",
  yourFactoryAccount: "Your Factory Account",
  balance: "Balance",
  goDeposit: "Go Deposit",

  pair: "Pair",
  positionValue: "Position Value",
  positionValueDesc: "The value of your V3 liquidity position",
  feeAprDesc: "The annualized return of your fee income",
  event: "Event",
  openAmount: "Open Amount",
  openAmountDesc: "The initial investment you put in this position",
  closeAmount: "Close Amount",
  closeAmountDesc: "The closed value of this position",
  yourPositions: "Your Diamond Positions",
  current: "Current",
  history: "History",
  manage: "Manage",
  addCollateral: "Add Collateral",
  removeCollateral: "Remove Collateral",
  collectFee: "Collect Fee",
  updateExitPrices: "Update Exit Prices",
  closePosition: "Close Position",
  positionId: "Position Id",
  positionIdDesc: "The NFT id of your position",
  positionAmount: "Amount",
  positionAmountDesc: "The principal of your position",
  totalDebt: "Total Debt",
  totalDebtDesc1: "The amount of",
  totalDebtDesc2:
    "that you borrowed plus the accrued interest in this position",
  extraCollateral: "Extra Collateral",
  extraCollateralDesc: "The extra collateral for this position",
  pnl: "PnL",
  pnlDesc: "The profit and loss in this position",
  positionRangeDesc: "The liquidity providing range for this position",
  uniswapFee: "Fee Earned",
  uniswapFeeDesc: "The accrued fee income for this position",
  positionCreatedTime: "Position Created Time",
  entryPrice: "Entry Price",
  entryPriceDesc:
    "After opening the position, the entry price will be displayed in 3 to 5 minutes. Please refresh the page to see the update",
  positionLeverageDesc:
    "The ratio of total position value divided by you initial investment when you open the position",
  estLiquidationPrice: "Est. Liquidation Price",
  estLiquidationPriceDesc1: "The rough estimated liquidation price of",
  estLiquidationPriceDesc2:
    "for this position. The liquidated price is estimated based on your range, leverage and the current price.",
  healthFactor: "Health Factor",
  healthFactorDesc:
    "Health Factor is a risk level indicator of the position. When the Health Factor falls below 1, the position will be liquidated by liquidators. You can check our document for more information about Health Factor",
  closedPositionPnlDesc: "Close Amount - (Open Amount + Extra Collateral)",
  startPrice: "Start Price",
  closePrice: "Close Price",
  startTime: "Start Time",
  closedTime: "Closed Time",
  factoryBalance: "Factory Balance",
  collateralAmount: "Collateral Amount",
  setting: "Setting",
  slippageTolerance: "Slippage Tolerance",
  auto: "Auto",
  save: "Save",
  shareYourPerformance: "Share Your Performance",
  earnedFee: "Earned Fee",
  duration: "Duration",
  longPressToDownload: "Long press on the image to download",
  download: "Download",
  simulator: "Simulator",
  simulatorDesc:
    "It is advisable not to re-balance your position too often. Our back-test results are based on a 10 day re-balance period",
  priceChanges: "Price Changes",
  priceChange: "Price Change:",
  currentPrice: "Current Price",
  update: "Update",
  polygonHint1:
    "Diamond on Polygon is currently in closed beta. You are advised to use at your own risk and apply for the whitelist in our ",
  polygonHint2: " channel.",
  discord: "Discord",
  rangeLiteModeHint: "Use Pro version to unlock more range options.",
  leverageLiteModeHint: "Use Pro version to unlock more leverages.",
  amountLiteModeHint: "Use Pro version to unlock higher amount.",
  allFactories: "All Factories",
  betaHint: "Diamond is currently in beta. You are advised to use at your own risk.",
};

export default factory;
