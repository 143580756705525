import React, { Fragment, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { H4 } from "../../styles/Fonts";
import Button from "../../components/Button";
import { formatBigNumber, getTruncNum } from "../../utils/common";
import {
  depositIntoFarm,
} from "../../utils/api/autoFarmApi";
import { withdraw } from "../../utils/api/evieVaultApi";
import useTokenBalance from "../../hooks/useTokenBalance";
import { FarmProps } from "../../utils/constant/evieVault";
import { useWeb3React } from "@web3-react/core";
import useTokenApproval from "../../hooks/useTokenApproval";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { showErrorAlert } from "../../utils/showAlert";
import { CHAIN_IDS_TO_NAMES } from "../../utils/constant/chains";

type actionTypeProps = "Deposit" | "Withdraw";

type Props = {
  updateAccountFarmStatus: () => void;
  withdrawableAmount: number;
  updateAutoVaultState: () => void;
  vaultState: number;
  vault: FarmProps;
  rolloverTime: number;
  currentRoundStartTimestamp: number;
};

const ActionCol: React.FC<Props> = (props) => {
  const {
    updateAccountFarmStatus,
    withdrawableAmount,
    updateAutoVaultState,
    vaultState,
    vault,
    rolloverTime,
    currentRoundStartTimestamp
  } = props;
  const { vaultAddress, deployedChainId } = vault;
  const { tokenSymbol, tokenAddress, tokenDecimal } = vault.wantToken;

  const { account, chainId, provider } = useWeb3React();
  const { tokenBalance: usdcBalance, updateTokenBalance: updateUsdcBalance } =
    useTokenBalance({
      tokenAddress,
      tokenDecimal,
    });

  const [actionType, setActionType] = useState<actionTypeProps>("Deposit");
  const [amount, setAmount] = useState<string>("0");
  const [isDepositing, setIsDepositing] = useState<boolean>(false);
  const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false);

  const {
    tokenAmountIsApproved,
    checkTokenAllowance,
    isGranting,
    isGranted,
    grantTokenAllowance,
    initTokenApprovalStates,
  } = useTokenApproval({
    tokenAmount: amount,
    tokenAddress,
    tokenDecimal,
    contractAddress: vaultAddress,
  });

  useEffect(() => {
    if (parseFloat(amount) > 0 && actionType === "Deposit") {
      checkTokenAllowance();
    }
  }, [amount, actionType, checkTokenAllowance]);

  const formRef = useRef<HTMLFormElement>(null);

  const handleSwitch = (type: actionTypeProps) => {
    setActionType(type);
    initSetting();
  };

  const updateStates = (_account: string) => {
    updateAccountFarmStatus();
    updateAutoVaultState();

    if (!_account) return;
    updateUsdcBalance();
  };

  const initSetting = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    setAmount("0");
    initTokenApprovalStates();
  };

  const handleDeposit = async () => {
    // Check network
    if (!account) {
      showErrorAlert("Please connect your wallet");
      return;
    }

    // Check network
    if (deployedChainId !== chainId) {
      showErrorAlert(
        `Please switch to correct network (${CHAIN_IDS_TO_NAMES[deployedChainId]})`
      );
      return;
    }

    setIsDepositing(true);

    if (vaultState === 0) {
    } else if (vaultState === 1) {
      await depositIntoFarm(
        account,
        vaultAddress,
        amount,
        tokenAddress,
        tokenDecimal,
        tokenSymbol,
        chainId,
        provider
      );
    }

    setIsDepositing(false);
    initSetting();

    updateStates(account);
  };

  const handleWithdraw = async () => {
    if (account && amount && chainId) {
      setIsWithdrawing(true);
      await withdraw(
        account,
        vaultAddress,
        amount,
        tokenDecimal,
        tokenSymbol,
        chainId,
        provider
      );
      setIsWithdrawing(false);
      initSetting();

      updateStates(account);
    }
  };

  const getTimeDiff = () => {
    let currentTime = Math.floor(Date.now() / 1000)
    let day = 86400
    let hourSec = 3600
    let minuteSec = 60
    let countingTime = 0;
    if (vaultState === 1) {
      if (currentRoundStartTimestamp == 0) return ''
      countingTime = currentRoundStartTimestamp + day * 2
    } else {
      countingTime = rolloverTime + day * 28
    }
    let diff = countingTime - currentTime
    if (diff < 0) return ''
    let dd = Math.floor(diff / day);
    diff -= dd * day
    let hh = Math.floor(diff / hourSec);
    diff -= hh * hourSec;
    let mm = Math.floor(diff / minuteSec);
    return `${dd}d ${hh}h ${mm}m`
  }

  return (
    <OuterContainer>
      <Container>
        <TabsContainer>
          <Tab
            isSelect={actionType === "Deposit"}
            onClick={() => handleSwitch("Deposit")}
          >
            Deposit
          </Tab>
          <Tab
            isSelect={actionType !== "Deposit"}
            onClick={() => handleSwitch("Withdraw")}
          >
            Withdraw
          </Tab>
        </TabsContainer>
        <MainContainer>
          <RoundTimeContainer>
            <BalanceRow>
              <BLabel>{vaultState === 0 ? "Round will end in" : "Round will start"}</BLabel>
              <BValue>
                {getTimeDiff()}
              </BValue>
            </BalanceRow>
          </RoundTimeContainer>
          <InputContainer ref={formRef}>
            <LabelContainer>
              <Label>Amount</Label>
            </LabelContainer>
            <Input
              type="number"
              min="0"
              placeholder={usdcBalance}
              onChange={(e) => setAmount(e.currentTarget.value)}
              onWheel={(event) => event.currentTarget.blur()}
            />
            <Unit>{actionType === "Deposit" ? tokenSymbol : `d${tokenSymbol}`}</Unit>
          </InputContainer>
          {actionType === "Deposit" ? (
            <BalanceContainer>
              <BalanceRow>
                <BLabel>Your Vault Balance</BLabel>
                <BValue>
                  {account ? getTruncNum(withdrawableAmount, 6) : "-"}{" d"}
                  {tokenSymbol}
                </BValue>
              </BalanceRow>
            </BalanceContainer>
          ) : (
            <BalanceContainer>
              <BalanceRow>
                <BLabel>Can Withdraw Amount</BLabel>
                <BValue>{`${
                  account ? getTruncNum(withdrawableAmount, 6) : "-"
                } d${tokenSymbol}`}</BValue>
              </BalanceRow>
            </BalanceContainer>
          )}
          <BtnContainer>
            {actionType === "Deposit" ? (
              <BtnContainer>
                {account && (
                  <Fragment>
                    {!!amount && !tokenAmountIsApproved && !isGranted && (
                      <Button
                        btnType="primary"
                        btnText={`Approve use of ${tokenSymbol}`}
                        onClick={!isGranting ? grantTokenAllowance : () => {}}
                        disabled={!amount || vaultState === 0}
                        isLoading={isGranting}
                      />
                    )}
                    {isGranted && (
                      <Reminder>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color={Colors.green}
                        />
                        <ReminderText>You can now deposit</ReminderText>
                      </Reminder>
                    )}
                  </Fragment>
                )}
                <Button
                  btnText={"Deposit"}
                  btnType="primary"
                  onClick={handleDeposit}
                  disabled={!amount || (!!account && !tokenAmountIsApproved) || vaultState === 0}
                  isLoading={isDepositing}
                />
              </BtnContainer>
            ) : (
              <BtnContainer>
                <Button
                  btnText={"Withdraw"}
                  btnType="primary"
                  onClick={handleWithdraw}
                  disabled={!(account && amount && !isWithdrawing) || vaultState === 0}
                  isLoading={isWithdrawing}
                />
              </BtnContainer>
            )}
          </BtnContainer>
        </MainContainer>
      </Container>
    </OuterContainer>
  );
};

export default ActionCol;

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Container = styled.div`
  width: 100%;
  background-color: ${Colors.backgroundGray2};
  border-radius: 8px;
`;

const PositionContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: ${Colors.backgroundGray4};
`;

const PositionLabel = styled(H4)`
  color: ${Colors.ivory};
  font-size: 14px;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 22px;
  }
`;

const PositionValue = styled(H4)`
  color: ${Colors.ivory};
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 22px;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  border-bottom: 2px solid ${Colors.backgroundGray4};
  background-color: ${Colors.backgroundGray4};
  border-radius: 8px 8px 0 0;
`;

const Tab = styled.button<{ isSelect: boolean }>`
  flex: 1;
  padding: 16px 0;
  background-color: ${(props) =>
    props.isSelect ? Colors.backgroundGray2 : `transparent`};
  color: ${(props) => (props.isSelect ? Colors.ivory : Colors.gray5)};
  border: ${(props) =>
    `1px solid ${props.isSelect ? Colors.backgroundGray2 : "inherit"}`};
  border-bottom-width: 0px;
  font-weight: ${(props) => (props.isSelect ? "bold" : "normal")};
  border-radius: 8px 8px 0 0;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const MainContainer = styled.div`
  padding: 32px 20px;
`;

const InputContainer = styled.form`
  flex: 1 1 60px;
  padding: 6px 12px 6px 12px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: 1px solid ${Colors.gray4};
`;

const Input = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: ${Colors.ivory};
  :focus {
    border: none;
    outline: none;
  }
`;

const Unit = styled.span`
  font-size: 13px;
  line-height: 22px;
  color: ${Colors.ivory};
  margin: 0 8px;
`;

const LabelContainer = styled.div`
  position: absolute;
  padding: 0 4px;
  top: -14px;
  left: 8px;
  background-color: ${Colors.backgroundGray2};
`;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
  @media (max-width: 768px) {
  }
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  button {
    border-radius: 4px;
    padding: 4px 12px;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 18px 0;
`;

const RoundTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 0 18px 0;
`;

const BalanceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
`;

const BLabel = styled(Label)`
  flex: 50%;
  margin-bottom: 0px;
`;

const BValue = styled(Unit)`
  flex: 50%;
  text-align: right;
  margin: 0;
`;

const Reminder = styled.div`
  width: 100%;
  min-height: 32px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.ivory};
  background-color: ${Colors.backgroundGray4};
  border: 1px solid ${Colors.gray5};
  padding: 4px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (max-width: 768px) {
    min-height: 28px;
    min-width: 80px;
  }
`;

const ReminderText = styled(H4)`
  font-size: 16px;
  line-height: 24px;
  font-family: Fira Sans, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  /* font-weight: 600; */
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
  }
`;
