import eth from "../../images/eth.png";
import arb from "../../images/arbitrum.png";
import polygon from "../../images/polygon.png";
import op from "../../images/optimism.png";
import bnb from "../../images/bnb.png";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "./chains";
import { toHex } from "../api/common";
import t from "../content";

export const networkOptions: {
  [key: number]: { label: string; icon: string; hex: string };
} = {
  [SupportedChainId.GOERLI]: {
    label: CHAIN_IDS_TO_NAMES[SupportedChainId.GOERLI],
    icon: eth,
    hex: toHex(SupportedChainId.GOERLI),
  },
  [SupportedChainId.ARBITRUM_ONE]: {
    label: CHAIN_IDS_TO_NAMES[SupportedChainId.ARBITRUM_ONE],
    icon: arb,
    hex: toHex(SupportedChainId.ARBITRUM_ONE),
  },
  // [SupportedChainId.POLYGON_MUMBAI]: {
  //   label: CHAIN_IDS_TO_NAMES[SupportedChainId.POLYGON_MUMBAI],
  //   icon: polygon,
  //   hex: toHex(SupportedChainId.POLYGON_MUMBAI),
  // },
  [SupportedChainId.OPTIMISM_GOERLI]: {
    label: CHAIN_IDS_TO_NAMES[SupportedChainId.OPTIMISM_GOERLI],
    icon: op,
    hex: toHex(SupportedChainId.OPTIMISM_GOERLI),
  },
  // [SupportedChainId.POLYGON]: {
  //   label: CHAIN_IDS_TO_NAMES[SupportedChainId.POLYGON],
  //   icon: polygon,
  //   hex: toHex(SupportedChainId.POLYGON),
  // },
  [SupportedChainId.BINANCE_SMART_CHAIN]: {
    label: CHAIN_IDS_TO_NAMES[SupportedChainId.BINANCE_SMART_CHAIN],
    icon: bnb,
    hex: toHex(SupportedChainId.BINANCE_SMART_CHAIN),
  },
  [SupportedChainId.BINANCE_TESTNET]: {
    label: CHAIN_IDS_TO_NAMES[SupportedChainId.BINANCE_TESTNET],
    icon: bnb,
    hex: toHex(SupportedChainId.BINANCE_TESTNET),
  },
};

/**
 * Network parameters used to add a new network,
 * should be the same as the official parameters of the network
 */
export const networkParams: {
  [key: number]: {
    chainId: string;
    rpcUrls: string[];
    chainName: string;
    nativeCurrency: { name: string; decimals: number; symbol: string };
    blockExplorerUrls: string[];
    iconUrls: string[];
  };
} = {
  [SupportedChainId.GOERLI]: {
    chainId: toHex(SupportedChainId.GOERLI),
    rpcUrls: ["https://goerli.infura.io/v3/"],
    chainName: t.networks.goerli,
    nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
    blockExplorerUrls: ["https://goerli.etherscan.io/"],
    iconUrls: [eth],
  },
  [SupportedChainId.ARBITRUM_ONE]: {
    chainId: toHex(SupportedChainId.ARBITRUM_ONE),
    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
    chainName: t.networks.arbitrumOne,
    nativeCurrency: { name: "AETH", decimals: 18, symbol: "AETH" },
    blockExplorerUrls: ["https://arbiscan.io/"],
    iconUrls: [arb],
  },
  // [SupportedChainId.POLYGON_MUMBAI]: {
  //   chainId: toHex(SupportedChainId.POLYGON_MUMBAI),
  //   rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
  //   chainName: "Mumbai",
  //   nativeCurrency: { name: "MATIC", decimals: 18, symbol: "MATIC" },
  //   blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  //   iconUrls: [polygon],
  // },
  [SupportedChainId.OPTIMISM_GOERLI]: {
    chainId: toHex(SupportedChainId.OPTIMISM_GOERLI),
    rpcUrls: ["https://goerli.optimism.io"],
    chainName: "Optimism Goerli",
    nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
    blockExplorerUrls: ["https://goerli-explorer.optimism.io/"],
    iconUrls: [op],
  },
  // [SupportedChainId.POLYGON]: {
  //   chainId: toHex(SupportedChainId.POLYGON),
  //   rpcUrls: ["https://polygon-rpc.com/"],
  //   chainName: "Polygon Mainnet",
  //   nativeCurrency: { name: "MATIC", decimals: 18, symbol: "MATIC" },
  //   blockExplorerUrls: ["https://polygonscan.com/"],
  //   iconUrls: [polygon],
  // },
  [SupportedChainId.BINANCE_SMART_CHAIN]: {
    chainId: toHex(SupportedChainId.BINANCE_SMART_CHAIN),
    rpcUrls: ["https://bsc-dataseed1.binance.org/"],
    chainName: "BNB Chain",
    nativeCurrency: { name: "BNB", decimals: 18, symbol: "BNB" },
    blockExplorerUrls: ["https://bscscan.com//"],
    iconUrls: [bnb],
  },
  [SupportedChainId.BINANCE_TESTNET]: {
    chainId: toHex(SupportedChainId.BINANCE_TESTNET),
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    chainName: "BNB Testnet",
    nativeCurrency: { name: "tBNB", decimals: 18, symbol: "tBNB" },
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
    iconUrls: [bnb],
  },
};
