import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import Button from "../../../../components/Button";
import { H4 } from "../../../../styles/Fonts";
import CustomModal from "../../../../components/Modal";
import { useWeb3React } from "@web3-react/core";
import { withdrawTokenFromBalanceVault } from "../../../../utils/api/balanceVaultApi";
import AmountInput from "../../../../components/AmountInput";
import { showErrorAlert } from "../../../../utils/showAlert";
import t from "../../../../utils/content";
import useTrackEvent from "../../../../hooks/useTrackEvent";

type Props = {
  isShowModal: boolean;
  setShowModal: (v: boolean) => void;
  setIsWithdrawing: (v: boolean) => void;
  updateBalances: () => void;
  currency: string;
  tokenAddress: string;
  tokenDecimal: number;
  accountVaultBalance: string;
  updateRecords: () => void;
};

const WithdrawModal: React.FC<Props> = (props) => {
  const {
    isShowModal,
    setShowModal,
    setIsWithdrawing,
    updateBalances,
    currency,
    tokenAddress,
    tokenDecimal,
    accountVaultBalance,
    updateRecords,
  } = props;

  const { account, chainId, provider } = useWeb3React();
  const trackEvent = useTrackEvent();

  const [amount, setAmount] = useState<string>("0");

  const handleOnWithdraw = async () => {
    if (!isNaN(parseFloat(amount)) && parseFloat(amount) <= 0) {
      showErrorAlert(t.error.inputWithdrawAmount);
      return;
    }

    if (account && amount && chainId && tokenAddress && tokenDecimal) {
      trackEvent(`${t.amp.clickWithdrawBtn} | ${t.amp.accountPage}`, {
        amount: amount,
        tokenSymbol: currency,
      });
      setShowModal(false);
      setAmount("0");
      setIsWithdrawing(true);
      await withdrawTokenFromBalanceVault(
        account,
        tokenAddress,
        tokenDecimal,
        amount,
        chainId,
        provider,
        (txHash: string) => {
          trackEvent(`${t.amp.withdrawToken} | ${t.amp.accountPage}`, {
            txHash: txHash,
            amount: amount,
            tokenSymbol: currency,
          });
        }
      );
      setIsWithdrawing(false);
      updateBalances();
      updateRecords();
    }
  };

  return (
    <CustomModal
      show={isShowModal}
      onHide={() => setShowModal(false)}
      title={`Withdraw ${currency}`}
    >
      <AmountInput
        setAmount={setAmount}
        tokenSymbol={currency}
        tokenDecimal={tokenDecimal}
        showMaxBtn={true}
        maxAmount={accountVaultBalance}
      />
      <InfoContainer>
        <BalanceRow>
          <Label>Factory Balance</Label>
          <Value>
            {accountVaultBalance} {currency}
          </Value>
        </BalanceRow>
      </InfoContainer>
      <BtnContainer>
        <Button
          btnType="primary"
          btnText="Withdraw"
          onClick={handleOnWithdraw}
          disabled={!amount}
          isLoading={false}
        />
      </BtnContainer>
    </CustomModal>
  );
};

export default WithdrawModal;

const InfoContainer = styled.div`
  margin-top: 20px;
`;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const Value = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
  color: ${Colors.ivory};
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

const BtnContainer = styled.div`
  margin-top: 20px;
  button {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;
