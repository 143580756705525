import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import Colors from "../../../styles/Colors";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getUserActivePositions } from "../../../redux/factoryAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import SimulatorModal from "../Modals/SimulatorModal";
import LabelWithHint from "../../../components/LabelWithHint";
import { H3, H4 } from "../../../styles/Fonts";
import { PairProps } from "../../../utils/constant/pairs";
import { useWeb3React } from "@web3-react/core";
import useLendingPoolState from "../../../hooks/useLendingPoolState";
import { StateProps } from "../../../hooks/useUniswapPoolState";
import { openFactoryPosition } from "../../../utils/api/factoryV3Api";
import useIsUnderCorrectNetwork from "../../../hooks/useIsUnderCorrectNetwork";
import { approveFactoryToUseBalanceVault } from "../../../utils/api/balanceVaultApi";
import { showErrorAlert } from "../../../utils/showAlert";
import { CHAIN_IDS_TO_NAMES } from "../../../utils/constant/chains";
import useIsApprovedFactory from "../../../hooks/useIsApprovedFactory";
import t from "../../../utils/content";
import Range from "./Range";
import Leverage from "./Leverage";
import ExitPrices from "./ExitPrices";
import Amount from "./Amount";
import useTrackEvent from "../../../hooks/useTrackEvent";
import { getLiquidationPrice } from "../../../utils/calculation/getLiquidationPrice";
import { Label } from "./CommonParts";
import useOpenPositionMinMaxAmount from "../../../hooks/useOpenPositionMinMaxAmount";

const ALL_LEVERAGES = [0.5, 1, 1.5, 2];
const LITE_MODE_LEVERAGES = [0.5, 1.5];

const REBALANCE_PERIOD = 10;

type Props = {
  pair: PairProps;
  updateAccountBalance: () => void;
  uniPoolState: StateProps;
  liquidityPoolApr: number;
  slippage: number;
  pcsRewardApr: number | null;
};

const SettingColumn: React.FC<Props> = (props) => {
  const {
    pair,
    updateAccountBalance,
    uniPoolState,
    liquidityPoolApr,
    slippage,
    pcsRewardApr,
  } = props;
  const {
    wantToken,
    borrowToken,
    factoryAddress,
    lendingPoolAddress,
    deployedChainId,
    wantTokenIsToken0,
    defaultRange,
    liteModeRanges,
  } = pair;
  const liteModeRangeOptions = liteModeRanges.map((r) => r.range);
  const { tokenSymbol, tokenAddress, tokenDecimal } = wantToken;
  const { tokenDecimal: bTokenDecimal } = borrowToken;
  const { tick, tickSpacing, borrowTokenPrice } = uniPoolState;

  const { account, chainId, provider } = useWeb3React();

  const { borrowRate, updateLendingPoolState } = useLendingPoolState(
    lendingPoolAddress,
    tokenSymbol,
    tokenDecimal,
    deployedChainId
  );
  const isUnderCorrectNetwork = useIsUnderCorrectNetwork();
  const { isApprovedFactory, updateIsApprovedState } = useIsApprovedFactory(
    pair.factoryAddress,
    pair.deployedChainId
  );

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const { minAmount, maxAmount } = useOpenPositionMinMaxAmount(
    factoryAddress,
    tokenDecimal,
    chainId ?? selectedChainId,
    provider
  );

  // Open Position Parameters
  const [range, setRange] = useState<number>(0);
  const [stopLossLowerPrice, setStopLossLowerPrice] = useState<number>(0);
  const [stopLossUpperPrice, setStopLossUpperPrice] = useState<number>(0);
  const [leverage, setLeverage] = useState<number>(1);
  const [investAmount, setInvestAmount] = useState<number>(0);
  const [liquidationPrice, setLiquidationPrice] = useState<number | null>(null);
  const [lowerPrice, setLowerPrice] = useState<number | null>(null);
  const [upperPrice, setUpperPrice] = useState<number | null>(null);

  // Modal
  const [showSimulator, setShowSimulator] = useState<boolean>(false);

  const [estimatedFeeApy, setEstimatedFeeApy] = useState<number>(0);
  const [minRange, setMinRange] = useState<number>(0);
  const [isOpening, setIsOpening] = useState<boolean>(false);
  const [isApproving, setIsApproving] = useState<boolean>(false);
  const [completeApproval, setCompleteApproval] = useState<boolean>(false);

  const [isLiteMode, setIsLiteMode] = useState<boolean>(true);

  const requiredInputs = {
    [t.factory.range]: range,
    [t.factory.lowerPrice]: stopLossLowerPrice,
    [t.factory.upperPrice]: stopLossUpperPrice,
    [t.factory.amount]: investAmount,
  };

  const rangeIsValid = range <= 100 && range >= minRange;

  const stopLossIsValid =
    stopLossUpperPrice > borrowTokenPrice &&
    stopLossLowerPrice > 0 &&
    stopLossLowerPrice < borrowTokenPrice;

  const approveBtnIsDisabled =
    isApproving || !account || !isUnderCorrectNetwork;

  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();
  const trackEvent = useTrackEvent();

  useEffect(() => {
    setRange(defaultRange);
    if (inputRef.current) {
      inputRef.current.value = defaultRange.toString();
    }
  }, [defaultRange]);

  useEffect(() => {
    if (pcsRewardApr === null) return;
    if (range) {
      // assume pcsRewardApr is in 15% range
      const _estimatedFeeApy = (liquidityPoolApr + pcsRewardApr * 15) / range;
      setEstimatedFeeApy(_estimatedFeeApy);
    }
  }, [range, liquidityPoolApr, pcsRewardApr]);

  useEffect(() => {
    if (
      borrowTokenPrice &&
      leverage &&
      range &&
      borrowTokenPrice &&
      lowerPrice &&
      upperPrice
    ) {
      const _liquidationPrice = getLiquidationPrice(
        100, // wantTokenAmount
        leverage,
        100 * leverage, // debtValue
        borrowTokenPrice, // entryPrice
        lowerPrice,
        upperPrice,
        0, // earnedFee
        borrowTokenPrice, // currentPrice
        0 // extraCollateral
      );
      setLiquidationPrice(_liquidationPrice);
    } else {
      setLiquidationPrice(null);
    }
  }, [borrowTokenPrice, leverage, range, lowerPrice, upperPrice]);

  const initSetting = useCallback(
    (_isLiteMode: boolean) => {
      if (formRef.current) {
        formRef.current.reset();
      }
      if (!_isLiteMode) {
        setRange(0);
        setLeverage(ALL_LEVERAGES[1]);
        setInvestAmount(0);
        setStopLossUpperPrice(0);
        setStopLossLowerPrice(0);
      } else {
        setRange(liteModeRangeOptions[1]);
        setLeverage(LITE_MODE_LEVERAGES[0]);
        setInvestAmount(0);
        setStopLossUpperPrice(0);
        setStopLossLowerPrice(0);
      }
    },
    [liteModeRanges]
  );

  useEffect(() => {
    const getMinRange = (_tickSpacing: number) => {
      const _minRange = ((_tickSpacing * 5) / 10000) * 100;
      setMinRange(_minRange);
    };

    if (tickSpacing) {
      getMinRange(tickSpacing);
    }
  }, [tickSpacing]);

  useEffect(() => {
    initSetting(isLiteMode);
  }, [initSetting]);

  useEffect(() => {
    if (isOpening) return;
    if (isLiteMode) {
      const exitPriceRange = liteModeRanges.find(
        (r) => r.range === range
      )?.exitPriceRange;
      if (exitPriceRange) {
        setStopLossLowerPrice(borrowTokenPrice * (1 - exitPriceRange));
        setStopLossUpperPrice(borrowTokenPrice * (1 + exitPriceRange));
      }
    }
  }, [isLiteMode, range, borrowTokenPrice, liteModeRanges, isOpening]);

  const positionParamsIsValid = useMemo(
    () =>
      !!(
        investAmount &&
        // stop loss should be valid in Pro mode
        (isLiteMode || stopLossIsValid) &&
        leverage &&
        rangeIsValid &&
        slippage
      ),
    [
      investAmount,
      stopLossIsValid,
      leverage,
      rangeIsValid,
      slippage,
      isLiteMode,
    ]
  );

  const handleSwitchMode = (_isLiteMode: boolean) => {
    if (_isLiteMode) {
      !liteModeRangeOptions.includes(+range) &&
        setRange(liteModeRangeOptions[1]);
      !LITE_MODE_LEVERAGES.includes(leverage) &&
        setLeverage(LITE_MODE_LEVERAGES[0]);
    } else {
      setRange(0);
      !ALL_LEVERAGES.includes(leverage) && setLeverage(ALL_LEVERAGES[1]);
      setStopLossUpperPrice(0);
      setStopLossLowerPrice(0);
    }
  };

  const createRequiredInputsAlertMsg = () => {
    const zeroInputs = [];

    for (const input of Object.entries(requiredInputs)) {
      if (input[1] === 0) {
        zeroInputs.push(input[0]);
      }
    }

    let errorMessage = t.error.inputParameters;

    if (zeroInputs.length === 1) {
      errorMessage += zeroInputs[0];
    } else if (zeroInputs.length === 2) {
      errorMessage += `${zeroInputs[0]} and ${zeroInputs[1]}`;
    } else {
      const lastInputName = zeroInputs.pop();
      errorMessage += `${zeroInputs.join(", ")}, and ${lastInputName}`;
    }

    return errorMessage;
  };

  const checkAccount = () => {
    if (!account) {
      showErrorAlert(t.error.connectWallet);
      return false;
    }
    return true;
  };

  const checkNetwork = () => {
    if (deployedChainId !== chainId) {
      showErrorAlert(
        `${t.error.switchNetwork} (${CHAIN_IDS_TO_NAMES[deployedChainId]})`
      );
      return false;
    }
    return true;
  };

  const checkRequiredInputs = () => {
    if (Object.values(requiredInputs).some((input) => input === 0)) {
      showErrorAlert(createRequiredInputsAlertMsg());
      return false;
    }
    return true;
  };

  const checkRange = () => {
    if (!rangeIsValid) {
      showErrorAlert(
        `${t.error.inputRangeNotValid}${minRange}%${t.error.inputRangeNotValid2}100%.`
      );
      return false;
    }
    return true;
  };

  const checkExitPrices = () => {
    if (stopLossLowerPrice > borrowTokenPrice) {
      showErrorAlert(t.error.lowerExitPriceNotValid);
      return false;
    } else if (stopLossUpperPrice < borrowTokenPrice) {
      showErrorAlert(t.error.upperExitPriceNotValid);
      return false;
    }
    return true;
  };

  const checkAmount = () => {
    if (investAmount < +minAmount) {
      showErrorAlert(
        `${t.error.lowerThanMinAmount1}${minAmount}${t.error.lowerThanMinAmount2}`
      );
      return false;
    }
    if (investAmount > +maxAmount) {
      showErrorAlert(
        `${t.error.exceedMaxAmount1}${maxAmount}${t.error.exceedMaxAmount2}`
      );
      return false;
    }
    return true;
  };

  const handleOpenPosition = async () => {
    if (
      !checkAccount() ||
      !checkNetwork() ||
      !checkRequiredInputs() ||
      !checkRange() ||
      !checkExitPrices() ||
      !checkAmount()
    ) {
      return;
    }

    if (
      account &&
      factoryAddress &&
      positionParamsIsValid &&
      chainId &&
      provider
    ) {
      trackEvent(`${t.amp.clickOpenPositionBtn} | ${t.amp.factoryPage}`, {
        amount: investAmount,
        leverage: leverage + 1,
        range: range,
        exitUpperPrice: stopLossUpperPrice,
        exitLowerPrice: stopLossLowerPrice,
        factoryAddress: factoryAddress,
        factoryLabel: `${pair.label} ${pair.rate}%`,
        wantToken: wantToken.tokenSymbol,
        borrowToken: borrowToken.tokenSymbol,
        borrowTokenPrice: borrowTokenPrice,
        mode: isLiteMode ? t.amp.liteMode : t.amp.proMode,
      });

      setIsOpening(true);

      await openFactoryPosition(
        account,
        factoryAddress,
        investAmount,
        tokenAddress,
        tokenSymbol,
        tokenDecimal,
        stopLossUpperPrice,
        stopLossLowerPrice,
        leverage,
        range / 100,
        chainId,
        wantTokenIsToken0,
        bTokenDecimal,
        tickSpacing,
        tick, // spotPriceTick,
        slippage / 100, //slippage,
        provider,
        (txHash: string) => {
          trackEvent(`${t.amp.openPosition} | ${t.amp.factoryPage}`, {
            txHash: txHash,
            amount: investAmount,
            leverage: leverage + 1,
            range: range,
            exitUpperPrice: stopLossUpperPrice,
            exitLowerPrice: stopLossLowerPrice,
            factoryAddress: factoryAddress,
            factoryLabel: `${pair.label} ${pair.rate}%`,
            wantToken: wantToken.tokenSymbol,
            borrowToken: borrowToken.tokenSymbol,
            borrowTokenPrice: borrowTokenPrice,
            mode: isLiteMode ? t.amp.liteMode : t.amp.proMode,
          });
        }
      );

      setIsOpening(false);

      initSetting(isLiteMode);
      dispatch(
        getUserActivePositions(
          account,
          pair.factoryAddress,
          wantToken.tokenDecimal,
          borrowToken.tokenDecimal,
          wantToken.tokenSymbol,
          pair.wantTokenIsToken0,
          chainId,
          provider
        )
      );
      updateLendingPoolState();
      updateAccountBalance();
    }
  };

  const handleApproveFactory = async () => {
    if (account && factoryAddress && chainId && provider) {
      trackEvent(`${t.amp.clickApproveFactoryBtn} | ${t.amp.factoryPage}`, {
        factoryAddress: factoryAddress,
        factoryLabel: `${pair.label} ${pair.rate}%`,
        token: wantToken.tokenSymbol,
      });

      setIsApproving(true);

      const isSucceed = await approveFactoryToUseBalanceVault(
        account,
        factoryAddress,
        tokenAddress,
        chainId,
        provider,
        (txHash: string) => {
          trackEvent(`${t.amp.approveFactory} | ${t.amp.factoryPage}`, {
            txHash: txHash,
            factoryAddress: factoryAddress,
            factoryLabel: `${pair.label} ${pair.rate}%`,
            token: wantToken.tokenSymbol,
          });
        }
      );

      if (isSucceed) {
        setCompleteApproval(true);
        updateIsApprovedState();
      }

      setIsApproving(false);
    }
  };

  const handleOnSimulate = () => {
    if (!checkRequiredInputs() || !checkRange() || !checkExitPrices()) {
      return;
    }

    if (borrowTokenPrice) {
      trackEvent(`${t.amp.clickSimulateBtn} | ${t.amp.factoryPage}`, {
        amount: investAmount,
        leverage: leverage + 1,
        range: range,
        exitUpperPrice: stopLossUpperPrice,
        exitLowerPrice: stopLossLowerPrice,
        factoryAddress: factoryAddress,
        factoryLabel: `${pair.label} ${pair.rate}%`,
        wantToken: wantToken.tokenSymbol,
        borrowToken: borrowToken.tokenSymbol,
        borrowTokenPrice: borrowTokenPrice,
      });

      setShowSimulator(true);
    }
  };

  return (
    <OuterContainer>
      <TitleContainer>
        <LabelWithHint
          labelComponent={<Title>{t.factory.openUnibotPosition}</Title>}
          hintText={`${t.factory.openUnibotPositionDesc1} ${wantToken.tokenSymbol}
        ${t.factory.openUnibotPositionDesc2} ${borrowToken.tokenSymbol} ${t.factory.openUnibotPositionDesc3}`}
        />
        <ModeSwitcherContainer>
          <ModeLabel isSelect={isLiteMode}>Lite</ModeLabel>
          <ModeSwitcher
            isToggled={isLiteMode}
            onClick={() => {
              setIsLiteMode(!isLiteMode);
              handleSwitchMode(!isLiteMode);
            }}
          >
            <ModeToggle />
          </ModeSwitcher>
          <ModeLabel isSelect={!isLiteMode}>Pro</ModeLabel>
        </ModeSwitcherContainer>
      </TitleContainer>
      <Container className="fac-s-2">
        <Form ref={formRef}>
          <Range
            isLiteMode={isLiteMode}
            pair={pair}
            minRange={minRange}
            range={range}
            setRange={setRange}
            tick={tick}
            tickSpacing={tickSpacing}
            inputRef={inputRef}
            lowerPrice={lowerPrice}
            setLowerPrice={setLowerPrice}
            upperPrice={upperPrice}
            setUpperPrice={setUpperPrice}
          />
          <Leverage
            isLiteMode={isLiteMode}
            pair={pair}
            leverageOptions={isLiteMode ? LITE_MODE_LEVERAGES : ALL_LEVERAGES}
            leverage={leverage}
            setLeverage={setLeverage}
          />
          <SectionRow>
            <LabelWithHint
              labelComponent={<Label>{t.factory.estLiquidationPrice}</Label>}
              hintText={`${t.factory.estLiquidationPriceDesc1} ${borrowToken.tokenSymbol} ${t.factory.estLiquidationPriceDesc2}`}
            />
            <LiquidationCol>
              <Value>{`${
                liquidationPrice ? liquidationPrice.toString().slice(0, 8) : "-"
              }`}</Value>
              <LiquidationUnit>{`${wantToken.tokenSymbol} per ${borrowToken.tokenSymbol}`}</LiquidationUnit>
            </LiquidationCol>
          </SectionRow>
          <SectionRow>
            <LabelWithHint
              labelComponent={<Label>{t.factory.estFeeApr}</Label>}
              hintText={t.factory.estFeeAprDesc}
            />
            <HighlightValue>{`${
              rangeIsValid && estimatedFeeApy
                ? (estimatedFeeApy * (1 + leverage)).toFixed(2)
                : "-"
            } %`}</HighlightValue>
          </SectionRow>
          <SectionRow>
            <LabelWithHint
              labelComponent={<Label>{t.factory.borrowingRate}</Label>}
              hintText={`${t.factory.borrowingRateDesc} ${borrowToken.tokenSymbol}`}
            />
            <Value>{`${(borrowRate * 100).toFixed(2)} %`}</Value>
          </SectionRow>
          <ExitPrices
            isLiteMode={isLiteMode}
            pair={pair}
            borrowTokenPrice={borrowTokenPrice}
            stopLossLowerPrice={stopLossLowerPrice}
            stopLossUpperPrice={stopLossUpperPrice}
            setStopLossLowerPrice={setStopLossLowerPrice}
            setStopLossUpperPrice={setStopLossUpperPrice}
          />
          <Amount
            minAmount={minAmount}
            maxAmount={maxAmount}
            pair={pair}
            investAmount={investAmount}
            setInvestAmount={setInvestAmount}
          />
        </Form>
        <BtnContainer>
          {account &&
            !isApprovedFactory &&
            positionParamsIsValid &&
            !completeApproval && (
              <Button
                btnText={t.factory.approveFactory}
                btnType="primary"
                onClick={handleApproveFactory}
                disabled={approveBtnIsDisabled}
                isLoading={isApproving}
              />
            )}
          {completeApproval && isApprovedFactory && (
            <Reminder>
              <FontAwesomeIcon icon={faCheckCircle} color={Colors.green} />
              <ReminderText>{t.factory.canOpenPosition}</ReminderText>
            </Reminder>
          )}
          <Button
            btnText={t.factory.openPosition}
            btnType="primary"
            onClick={handleOpenPosition}
            disabled={
              isOpening ||
              (!!account && positionParamsIsValid && !isApprovedFactory)
            }
            isLoading={isOpening}
          />
          <SimulateBtn onClick={handleOnSimulate} disabled={false}>
            <Span>{t.factory.simulatePosition}</Span>
            <Icon icon={faArrowRight} />
          </SimulateBtn>
        </BtnContainer>
        <SimulatorModal
          simulateData={{
            usdcAmount: investAmount,
            borrowingRatio: leverage,
            duration: REBALANCE_PERIOD,
            range: range,
            borrowRate: borrowRate,
            estimatedFeeApy: estimatedFeeApy,
            stopLossLowerPrice: stopLossLowerPrice,
            stopLossUpperPrice: stopLossUpperPrice,
          }}
          marketPrice={borrowTokenPrice}
          setShowModal={setShowSimulator}
          isShowModal={showSimulator}
        />
      </Container>
    </OuterContainer>
  );
};

export default SettingColumn;

const OuterContainer = styled.div`
  padding: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-top: 12px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  button {
    border-radius: 4px;
    padding: 4px 12px;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
`;

const Value = styled.span`
  line-height: 22px;
  color: ${Colors.ivory};
  margin: 0 8px;
  font-size: 14px;
  font-weight: bold;
`;

const HighlightValue = styled(Value)`
  color: ${Colors.green};
`;

const SimulateBtn = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  color: ${Colors.ivory};
  border-radius: 4px;
  border: 1px solid ${Colors.ivory};
  cursor: ${(props) => (props.disabled ? "inherit" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  :hover {
    color: ${(props) => (props.disabled ? Colors.ivory : Colors.lightKhaki)};
    border: 1px solid
      ${(props) => (props.disabled ? Colors.ivory : Colors.lightKhaki)};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 6px;
  font-size: 14px;
  font-weight: bold;
`;

const Span = styled.span`
  font-size: 14px;
  line-height: 24px;
`;

const TitleContainer = styled.div`
  padding-bottom: 12px;
  border-bottom: 0.5px solid gray;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(H3)`
  color: ${Colors.ivory};
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
`;

const ModeSwitcherContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

const ModeLabel = styled.span<{ isSelect: boolean }>`
  color: ${(props) => (props.isSelect ? Colors.lightKhaki : Colors.gray3)};
  font-size: 14px;
  line-height: 18px;
`;

const ModeSwitcher = styled.button<{ isToggled: boolean }>`
  width: 36px;
  height: 20px;
  border-radius: 100px;
  background-color: ${(props) =>
    props.isToggled ? Colors.gray4 : Colors.lightKhaki};
  position: relative;
  border: 3px solid
    ${(props) => (props.isToggled ? Colors.gray4 : Colors.lightKhaki)};
  > div {
    left: ${(props) => (props.isToggled ? "0px" : "none")};
    right: ${(props) => (props.isToggled ? "none" : "0px")};
    transition: all 200ms ease;
  }
`;

const ModeToggle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  background-color: ${Colors.gray1};
`;

const Reminder = styled.div`
  width: 100%;
  min-height: 32px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.ivory};
  background-color: ${Colors.backgroundGray4};
  border: 1px solid ${Colors.gray5};
  padding: 4px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (max-width: 768px) {
    min-height: 28px;
    min-width: 80px;
  }
`;

const ReminderText = styled(H4)`
  font-size: 16px;
  line-height: 24px;
  font-family: Fira Sans, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  /* font-weight: 600; */
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const LiquidationCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const LiquidationUnit = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.gray4};
`;
