/* eslint-disable no-nested-ternary */
import React from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Colors from "../styles/Colors";
import { openNewTab } from "../utils/common";
import { connectors } from "../utils/constant/connectors";
import { useAppDispatch, useAppSelector } from "../hooks";
import { updateSelectedWallet } from "../redux/accountSlice";
import { getIsInjected, getIsMetaMask } from "../utils/api/common";
import { isMobile } from "../utils/userAgent";
import metamask from "../images/metamask.png";
import walletConnect from "../images/walletConnect.png";
import { switchNetwork } from "../utils/switchNetwork";
import t from "../utils/content";
import useTrackEvent from "../hooks/useTrackEvent";
import { showErrorAlert } from "../utils/showAlert";

const METAMASK_URL = process.env.REACT_APP_METAMASK_URL;

const SelectWalletModal: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
}> = (props) => {
  const { isOpen, handleClose } = props;

  const isInjected = getIsInjected();
  const isMetaMask = getIsMetaMask();

  const isMetaMaskBrowser = isMobile && isMetaMask;
  const isInjectedMobileBrowser = isMetaMaskBrowser;

  const dispatch = useAppDispatch();
  const trackEvent = useTrackEvent();

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const handleInjected = async () => {
    trackEvent(`${t.amp.selectMetaMask}`);

    const currentChainId = parseInt(
      await window?.ethereum.request({
        method: "eth_chainId",
      }),
      16
    );

    try {
      if (currentChainId && currentChainId !== selectedChainId) {
        await switchNetwork(selectedChainId);
      }

      await connectors.injected[0].activate(selectedChainId);
      localStorage.setItem("connectedWallet", "INJECTED");
      trackEvent(`${t.amp.connectWallet}`, { type: t.amp.metamask });
      dispatch(updateSelectedWallet({ wallet: "INJECTED" }));
    } catch (e) {
      // console.log(e);
    }
    handleClose();
  };

  const handleWalletConnect = async () => {
    trackEvent(`${t.amp.selectWalletConnect}`);
    handleClose();
    try {
      await connectors.walletConnect[0].activate(selectedChainId);
      localStorage.setItem("connectedWallet", "WALLET_CONNECT");
      trackEvent(`${t.amp.connectWallet}`, { type: t.amp.walletConnect });
      dispatch(updateSelectedWallet({ wallet: "WALLET_CONNECT" }));
    } catch (e) {
      showErrorAlert(`${e}`.replace("Error:", ""));
    }
  };

  return (
    <WalletModal show={isOpen} onHide={handleClose}>
      <ModalHeader closeButton closeVariant="white">
        <ModalTitle>{t.common.connectWallet}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <MethodContainer>
          {!isInjected && !isMobile && (
            <WalletBtnContainer
              onClick={() => openNewTab(METAMASK_URL || "")}
              isSelected={false}
            >
              <LeftContainer>
                <WalletBtnText>{t.common.installMetaMask}</WalletBtnText>
              </LeftContainer>
              <WalletBtnIcon src={metamask} alt="metamask icon" />
            </WalletBtnContainer>
          )}
          {isInjected && (
            <WalletBtnContainer onClick={handleInjected} isSelected={false}>
              <LeftContainer>
                <WalletBtnText>
                  {isMetaMask ? t.common.metaMask : t.common.injected}
                </WalletBtnText>
              </LeftContainer>
              <WalletBtnIcon src={metamask} alt="metamask icon" />
            </WalletBtnContainer>
          )}
          {!isInjectedMobileBrowser && (
            <WalletBtnContainer
              onClick={handleWalletConnect}
              isSelected={false}
            >
              <LeftContainer>
                <WalletBtnText>{t.common.walletConnect}</WalletBtnText>
              </LeftContainer>
              <WalletBtnIcon src={walletConnect} alt="walletConnect icon" />
            </WalletBtnContainer>
          )}
        </MethodContainer>
      </ModalBody>
    </WalletModal>
  );
};

SelectWalletModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

SelectWalletModal.defaultProps = {};

export default SelectWalletModal;

const WalletModal = styled(Modal)`
  .modal-content {
    margin: 20vh auto auto auto;
    width: 90%;
    max-width: 350px;
    border-radius: 20px;
    background-color: ${Colors.backgroundGray1};
    color: ${Colors.ivory};
    padding: 20px 12px 0px 12px;
  }
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.3);
`;

const ModalHeader = styled(Modal.Header)`
  border-width: 0;
  color: ${Colors.ivory};
`;

const ModalTitle = styled(Modal.Title)`
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
`;

const ModalBody = styled(Modal.Body)``;

const MethodContainer = styled.div`
  margin-bottom: 30px;
`;

const WalletBtnContainer = styled.div<{ isSelected: boolean }>`
  padding: 16px 18px;
  border: 1px solid ${Colors.midNightGreen};
  border-radius: 10px;
  border: ${(props) =>
    `1px solid ${props.isSelected ? Colors.lightKhaki : Colors.ivory}`};
  color: ${Colors.ivory};
  box-shadow: ${(props) =>
    props.isSelected ? `0 0 2px ${Colors.lightKhaki}` : "none"};
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  :hover {
    border: 1px solid ${Colors.lightKhaki};
    color: ${Colors.lightKhaki};
  }
`;

const WalletBtnText = styled.span`
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const WalletBtnIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;
