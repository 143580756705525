import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import Colors from "../../../../styles/Colors";
import { H2, H4 } from "../../../../styles/Fonts";
import Button from "../../../../components/Button";
import DepositModal from "./DepositModal";
import WithdrawModal from "./WithdrawModal";
import { VaultProps } from "../../../../utils/constant/vaults";
import { useWeb3React } from "@web3-react/core";
import useTokenBalance from "../../../../hooks/useTokenBalance";
import useAccountVaultBalance from "../../../../hooks/useAccountVaultBalance";
import { useQuery } from "../../../../hooks";
import SelectBridgeModal from "../../../../components/SelectBridgeModal";
import useUserBalanceVaultRecord from "../../../../hooks/useUserBalanceVaultRecord";
import TransactionRecord from "../../../../components/TransactionRecord";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import LabelWithHint from "../../../../components/LabelWithHint";
import t from "../../../../utils/content";
import WrapEthModal from "../../../PoolPage/Modals/WrapEthModal";
import UnwrapWethModal from "../../../PoolPage/Modals/UnwrapWethModal";

const TokenItem: React.FC<{ token: VaultProps }> = ({ token }) => {
  const {
    tokenAddress,
    tokenDecimal,
    tokenSymbol,
    tokenIcon,
    deployedChainId,
    isWrapToken
  } = token;

  const [depositModalIsShow, setDepositModalIsShow] = useState<boolean>(false);
  const [isDepositing, setIsDepositing] = useState<boolean>(false);
  const [withdrawModalIsShow, setWithdrawModalIsShow] =
    useState<boolean>(false);
  const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false);
  const [bridgeModalIsShow, setBridgeModalIsShow] = useState<boolean>(false);

  const [recordIsShow, setRecordIsShow] = useState<boolean>(false);

  const [wrappingModalIsShow, setWrappingModalIsShow] = useState<boolean>(false);
  const [isWrapping, setIsWrapping] = useState<boolean>(false);
  const [unwrappingModalIsShow, setUnwrappingModalIsShow] = useState<boolean>(false);
  const [isUnwrapping, setIsUnwrapping] = useState<boolean>(false);

  const { account, chainId } = useWeb3React();
  const { tokenBalance, updateTokenBalance } = useTokenBalance({
    tokenAddress,
    tokenDecimal,
  });
  const { accountVaultBalance, updateAccountVaultBalance } =
    useAccountVaultBalance(tokenAddress, tokenDecimal, deployedChainId);
  const { records, updateRecords } = useUserBalanceVaultRecord(tokenAddress, tokenDecimal);

  let query = useQuery();

  const queryTokenAddress = query.get("token");

  useEffect(() => {
    if (queryTokenAddress === tokenAddress) {
      setTimeout(() => setDepositModalIsShow(true), 500);
    }
  }, [queryTokenAddress, tokenAddress]);

  const updateBalances = useCallback(() => {
    updateTokenBalance();
    updateAccountVaultBalance();
  }, [updateTokenBalance, updateAccountVaultBalance]);

  useEffect(() => {
    if (!account || !chainId) {
      return;
    }
    updateBalances();
  }, [account, chainId, tokenAddress, tokenDecimal, updateBalances]);

  return (
    <Container>
      <TopContainer>
        <TokenRow>
          <TokenIcon src={tokenIcon} />
          <TokenName>{tokenSymbol}</TokenName>
        </TokenRow>
        <TokenBalance>{`${accountVaultBalance} ${tokenSymbol}`}</TokenBalance>
        <BtnsContainer>
          {/* <Button
            btnText="Deposit"
            onClick={() => setDepositModalIsShow(true)}
            disabled={true}
            isLoading={isDepositing}
            btnType={"secondary"}
          /> */}
          <Button
            btnText="Withdraw"
            onClick={() => setWithdrawModalIsShow(true)}
            disabled={isWithdrawing}
            isLoading={isWithdrawing}
            btnType={"primary"}
          />
          {isWrapToken && (
            <ManageDropDown>
              <ManageToggle id="dropdown-basic">
                {isWrapping || isUnwrapping ? (
                  <BeatLoader color={Colors.gray6} size={10} />
                ) : (
                  t.pool.more
                )}
              </ManageToggle>
              <ManageMenu>
                <ManageItem
                  onClick={() => setWrappingModalIsShow(true)}
                  disabled={false}
                >
                  {`${t.pool.wrap} ${tokenSymbol.substring(1)}`}
                </ManageItem>
                <ManageItem
                  onClick={() => setUnwrappingModalIsShow(true)}
                  disabled={false}
                >
                  {`${t.pool.unwrap} ${tokenSymbol}`}
                </ManageItem>
              </ManageMenu>
            </ManageDropDown>
          )}
          <ExpandBtn onClick={() => setRecordIsShow((pre) => !pre)}>
            <ArrowIcon icon={faAngleDown} isShow={recordIsShow} />
          </ExpandBtn>
        </BtnsContainer>
      </TopContainer>
      <BottomContainer isShow={recordIsShow}>
        <TitleContainer>
          <LabelWithHint
            labelComponent={
              <TitleText>{t.common.depositAndWithdraw}</TitleText>
            }
            hintText={t.common.recordDelayHint}
          />
        </TitleContainer>
        <TransactionRecord
          records={records}
          tokenDecimal={tokenDecimal}
          tokenSymbol={tokenSymbol}
        />
      </BottomContainer>
      <DepositModal
        setShowModal={setDepositModalIsShow}
        isShowModal={depositModalIsShow}
        setIsDepositing={setIsDepositing}
        tokenSymbol={tokenSymbol}
        tokenAddress={tokenAddress}
        tokenDecimal={tokenDecimal}
        tokenBalance={tokenBalance}
        updateBalances={updateBalances}
        deployedChainId={deployedChainId}
        setBridgeModalIsShow={() => {
          setDepositModalIsShow(false);
          setBridgeModalIsShow(true);
        }}
        updateRecords={updateRecords}
      />
      <WithdrawModal
        setShowModal={setWithdrawModalIsShow}
        isShowModal={withdrawModalIsShow}
        setIsWithdrawing={setIsWithdrawing}
        currency={tokenSymbol}
        tokenAddress={tokenAddress}
        tokenDecimal={tokenDecimal}
        accountVaultBalance={accountVaultBalance}
        updateBalances={updateBalances}
        updateRecords={updateRecords}
      />
      <WrapEthModal
        isShowModal={wrappingModalIsShow}
        onHideModal={() => setWrappingModalIsShow(false)}
        setIsWrapping={setIsWrapping}
        tokenBalance={tokenBalance}
        updateTokenBalance={updateTokenBalance}
        tokenAddress={tokenAddress}
        tokenSymbol={tokenSymbol}
      />
      <UnwrapWethModal
        isShowModal={unwrappingModalIsShow}
        onHideModal={() => setUnwrappingModalIsShow(false)}
        setIsUnwrapping={setIsUnwrapping}
        tokenBalance={tokenBalance}
        updateTokenBalance={updateTokenBalance}
        tokenAddress={tokenAddress}
        tokenSymbol={tokenSymbol}
      />
      <SelectBridgeModal
        targetTokenAddress={tokenAddress}
        deployedChainId={deployedChainId}
        show={bridgeModalIsShow}
        onHide={() => {
          setBridgeModalIsShow(false);
          setDepositModalIsShow(true);
        }}
        tokenSymbol={tokenSymbol}
      />
    </Container>
  );
};

export default TokenItem;

const Container = styled.div`
  background-color: ${Colors.backgroundGray1};
  border-radius: 8px;
`;

const GrayContainer = styled.div`
  padding: 24px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const TopContainer = styled(GrayContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const BottomContainer = styled.div<{ isShow: boolean }>`
  max-height: ${(props) => (props.isShow ? "1000px" : "0px")};
  transition: all 200ms ease-in-out;
  overflow-y: hidden;
`;

const TokenRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const TokenIcon = styled.img`
  height: 28px;
  margin-right: 12px;
  @media (max-width: 768px) {
    height: 20px;
    margin-right: 8px;
  }
`;

const TokenName = styled(H2)`
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.ivory};
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const TokenBalance = styled(H2)`
  flex: 1;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.ivory};
  text-align: left;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 576px) {
    text-align: right;
  }
`;

const BtnsContainer = styled.div`
  flex: 0 0 180px;
  display: flex;
  align-items: center;
  gap: 12px;
  @media (max-width: 768px) {
    flex: 0 0 240px;
  }
  @media (max-width: 576px) {
    margin-top: 16px;
    min-width: 100%;
  }
  button {
    font-size: 15px;
    @media (max-width: 768px) {
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

const ExpandBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  transition: transform 0.1s ease-in-out;
  color: ${Colors.ivory};
  :hover {
    transform: scale(1.1);
    color: ${Colors.lightKhaki};
  }
`;

const ArrowIcon = styled(FontAwesomeIcon)<{ isShow: boolean }>`
  font-size: 16px;
  transform: ${(props) => (props.isShow ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.1s ease-in-out;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 24px 12px 24px;
  border-top: 6px solid ${Colors.backgroundGray3};
  border-bottom: 1px solid ${Colors.gray5};
`;

const TitleText = styled(H4)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.ivory};
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.ivory};
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const ManageDropDown = styled(Dropdown)`
  button {
    padding: 0 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-primary {
    color: inherit;
    background-color: ${Colors.lightKhaki};
    border-color: ${Colors.lightKhaki};
    :hover {
      color: inherit;
      background-color: ${Colors.lightKhaki};
      border-color: ${Colors.lightKhaki};
    }
    :focus {
      box-shadow: none;
    }
    :active {
      transform: scale(0.95);
    }
    transition: all ease 200ms;
  }
  @media (max-width: 992px) {
    button {
      padding: 0 12px;
      width: 100%;
      min-height: 28px;
    }
  }
`;

const ManageToggle = styled(Dropdown.Toggle)`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  min-height: 32px;
  ::after {
    margin-left: 8px;
    vertical-align: 1px;
  }
`;

const ManageMenu = styled(Dropdown.Menu)`
  background-color: ${Colors.gray6};
  box-shadow: 2px 2px 10px ${Colors.backgroundGray3};
`;

const ManageItem = styled(Dropdown.Item)`
  font-size: 13px;
  line-height: 22px;
  color: ${Colors.ivory};
  font-family: Open Sans, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  :focus {
    background-color: inherit;
  }
`;
