import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getAllClosePosition,
  getAllOpenPosition,
  getCumulativeFee,
  getTvl,
} from "../../redux/factoryAction";
import Colors from "../../styles/Colors";
import { H2, H4 } from "../../styles/Fonts";
import { ClipLoader } from "react-spinners";
import { motion } from "framer-motion";
import Summary from "./Summary";
import ActivePosition from "./ActivePosition";
import ClosedPosition from "./ClosedPosition";
import Pagination from "../FactoryPage/YourUnibot/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import {
  CHAIN_IDS_TO_URL_IDS,
  CHAIN_URL_IDS_TO_IDS,
  isSupportedChain,
  SupportedChainId,
} from "../../utils/constant/chains";
import { useWeb3React } from "@web3-react/core";
import { updateSelectedChainId } from "../../redux/accountSlice";
import t from "../../utils/content";

const ITEM_LIMIT = 5;

const StatsPage = () => {
  const { chainName } = useParams();
  const { account, chainId } = useWeb3React();
  const navigate = useNavigate();

  const targetChainId = useMemo(
    () => (chainName ? CHAIN_URL_IDS_TO_IDS[chainName] : 0),
    [chainName]
  );

  const {
    allClosedPositions,
    closedPositionIsFetched: cIsFetched,
    allActivePositions,
    activePositionIsFetched: aIsFetched,
    unibotTvl,
    unibotCumulativeFee,
  } = useAppSelector((state) => state.factory);

  const closedPositions = useMemo(
    () => allClosedPositions?.[targetChainId]?.records || [],
    [allClosedPositions, targetChainId]
  );
  const closedTotal = useMemo(
    () => allClosedPositions?.[targetChainId]?.totalRecord || 0,
    [allClosedPositions, targetChainId]
  );
  const activePositions = useMemo(
    () => allActivePositions?.[targetChainId]?.records || [],
    [allActivePositions, targetChainId]
  );
  const activeTotal = useMemo(
    () => allActivePositions?.[targetChainId]?.totalRecord || 0,
    [allActivePositions, targetChainId]
  );
  const tvl = useMemo(
    () => unibotTvl?.[targetChainId] || 0,
    [unibotTvl, targetChainId]
  );
  const cumulativeFee = useMemo(
    () => unibotCumulativeFee?.[targetChainId] || 0,
    [unibotCumulativeFee, targetChainId]
  );

  const [closedPage, setClosedPage] = useState<number>(1);
  const [maxClosedPage, setMaxClosedPage] = useState<number>(1);
  const [closedPos, setClosedPos] = useState<{ [key: string]: any }[]>([]);

  const [activePage, setActivePage] = useState<number>(1);
  const [maxActivePage, setMaxActivePage] = useState<number>(1);
  const [activePos, setActivePos] = useState<{ [key: string]: any }[]>([]);

  const dispatch = useAppDispatch();

  const navigateToDefaultStatPage = useCallback(() => {
    const defaultChainId = SupportedChainId.ARBITRUM_ONE;
    const defaultChainName = CHAIN_IDS_TO_URL_IDS[defaultChainId];
    navigate(`/statistics/${defaultChainName}`);
  }, [navigate]);

  useEffect(() => {
    const urlChainId = chainName ? CHAIN_URL_IDS_TO_IDS[chainName] : 0;

    // 有連接 wallet
    if (account) {
      if (chainId && urlChainId !== chainId && isSupportedChain(chainId)) {
        const matchedChainName = CHAIN_IDS_TO_URL_IDS[chainId];
        navigate(`/statistics/${matchedChainName}`);
      } else {
      }
    } else {
      // 有指定 network
      if (!!urlChainId && isSupportedChain(urlChainId)) {
        dispatch(updateSelectedChainId({ chainId: urlChainId }));
      } else if (!urlChainId || !isSupportedChain(urlChainId)) {
        navigateToDefaultStatPage();
      }
    }
  }, [
    dispatch,
    account,
    chainId,
    chainName,
    navigate,
    navigateToDefaultStatPage,
  ]);

  useEffect(() => {
    dispatch(getAllClosePosition(targetChainId));
    dispatch(getAllOpenPosition(targetChainId));
    dispatch(getTvl(targetChainId));
    dispatch(getCumulativeFee(targetChainId));
  }, [dispatch, targetChainId]);

  useEffect(() => {
    setMaxClosedPage(Math.ceil(closedPositions.length / ITEM_LIMIT));
  }, [closedPositions]);

  useEffect(() => {
    setClosedPos(
      closedPositions.slice(
        (closedPage - 1) * ITEM_LIMIT,
        closedPage * ITEM_LIMIT
      )
    );
  }, [closedPositions, closedPage]);

  useEffect(() => {
    setMaxActivePage(Math.ceil(activePositions.length / ITEM_LIMIT));
  }, [activePositions]);

  useEffect(() => {
    setActivePos(
      activePositions.slice(
        (activePage - 1) * ITEM_LIMIT,
        activePage * ITEM_LIMIT
      )
    );
  }, [activePositions, activePage]);

  const _renderClosedPositions = () => {
    return (
      <Fragment>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key={closedPage}
        >
          {closedPos.map((pos) => (
            <ClosedPosition key={pos.PositionId} pos={pos} />
          ))}
        </motion.div>
        {maxClosedPage > 1 && (
          <Pagination
            currPage={closedPage}
            maxPage={maxClosedPage}
            setPage={setClosedPage}
          />
        )}
      </Fragment>
    );
  };

  const _renderActivePositions = () => {
    return (
      <Fragment>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key={activePage}
        >
          {activePos.map((pos) => (
            <ActivePosition key={pos.PositionId} pos={pos} />
          ))}
        </motion.div>
        {maxActivePage > 1 && (
          <Pagination
            currPage={activePage}
            maxPage={maxActivePage}
            setPage={setActivePage}
          />
        )}
      </Fragment>
    );
  };

  const _renderNoRecord = () => {
    return (
      <NoRecordContainer>
        <NoRecord>{t.common.noRecord}</NoRecord>
      </NoRecordContainer>
    );
  };

  const _renderLoader = () => {
    return (
      <LoaderContainer>
        <ClipLoader color={Colors.ivory} size={24} />
      </LoaderContainer>
    );
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <MainContent>
        <Summary
          totalRecord={closedTotal + activeTotal}
          tvl={tvl}
          cumulativeFee={cumulativeFee}
        />
        {/* All Position Section */}
        <div>
          <Subtitle>{t.stats.openedPositions}</Subtitle>
          <PositionContainer>
            <PositionRowH>
              <PositionHeader>{t.stats.pool}</PositionHeader>
              <PositionHeader>{t.stats.currentPositionValue}</PositionHeader>
              <PositionHeader>{t.stats.earnedFee}</PositionHeader>
              <PositionHeader>{t.stats.feeApr}</PositionHeader>
              <PositionHeader>{t.stats.leverage}</PositionHeader>
              <PositionHeader>{t.stats.startTime}</PositionHeader>
            </PositionRowH>
            {activePos.length > 0
              ? _renderActivePositions()
              : !aIsFetched
              ? _renderLoader()
              : _renderNoRecord()}
          </PositionContainer>
        </div>
        {/* Close Position Section */}
        <div>
          <Subtitle>{t.stats.closedPositions}</Subtitle>
          <PositionContainer>
            <ClosePositionRowH>
              <PositionHeader>{t.stats.pool}</PositionHeader>
              <PositionHeader>{t.stats.positionId}</PositionHeader>
              <PositionHeader>{t.stats.initialInvestmentAmount}</PositionHeader>
              <PositionHeader>{t.stats.endingAmount}</PositionHeader>
              <PositionHeader>{t.stats.earnedFee}</PositionHeader>
              <PositionHeader>{t.stats.type}</PositionHeader>
            </ClosePositionRowH>
            {closedPos.length > 0
              ? _renderClosedPositions()
              : !cIsFetched
              ? _renderLoader()
              : _renderNoRecord()}
          </PositionContainer>
        </div>
      </MainContent>
    </Container>
  );
};

export default StatsPage;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: row;
  padding: 60px 0 160px 0;
  @media (max-width: 576px) {
    align-items: flex-start;
    padding: 60px 0 120px 0;
  }
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1020px;
  margin: auto;
  @media (max-width: 1200px) {
    padding: 0 20px;
  }
`;

const Subtitle = styled(H2)`
  margin-top: 80px;
  font-size: 24px;
  line-height: 36px;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

const PositionContainer = styled.div`
  margin-top: 24px;
  padding: 24px;
  background-color: ${Colors.backgroundGray2};
  border-radius: 8px;
`;

const PositionRow = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 0.8fr 0.6fr 0.6fr 60px;
  background-color: ${Colors.backgroundGray4};
  border-radius: 8px;
  padding: 20px;
  margin-top: 12px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  div:not(:first-child) {
    justify-content: flex-end;
    text-align: right;
    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }
  h4:not(:first-child) {
    text-align: right;
  }
`;

const PositionHeader = styled(H4)`
  color: ${Colors.gray4};
  font-size: 14px;
  line-height: 22px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const ClosePositionRow = styled(PositionRow)`
  grid-template-columns: 0.8fr 0.6fr repeat(3, 1fr) 0.8fr;
`;

const LoaderContainer = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PositionRowH = styled(PositionRow)`
  border-top: none;
  background-color: transparent;
  padding: 12px 20px;
  margin-top: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ClosePositionRowH = styled(ClosePositionRow)`
  border-top: none;
  background-color: transparent;
  padding: 12px 20px;
  margin-top: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

const NoRecordContainer = styled.div`
  text-align: center;
  min-height: 200px;
  display: flex;
  align-items: center;
`;

const NoRecord = styled.span`
  display: inline-block;
  padding: 4px 20px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGray4};
  margin: auto;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.ivory};
`;
