import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import Button from "../../../components/Button";
import { H4 } from "../../../styles/Fonts";
import CustomModal from "../../../components/Modal";
import { PairProps } from "../../../utils/constant/pairs";
import { useWeb3React } from "@web3-react/core";
import { updateFactoryStopLoss } from "../../../utils/api/factoryV3Api";
import useUniswapPoolState from "../../../hooks/useUniswapPoolState";
import t from "../../../utils/content";
import useTrackEvent from "../../../hooks/useTrackEvent";

const UpdateStopLoss: React.FC<{
  isShowModal: boolean;
  setShowModal: (v: boolean) => void;
  positionId: string;
  setIsExecuting: (v: boolean) => void;
  updateUserActivePositions: () => void;
  pair: PairProps;
  currentPrice: number;
  previousStopLoss: { upperPrice: number; lowerPrice: number };
}> = (props) => {
  const {
    isShowModal,
    setShowModal,
    positionId,
    setIsExecuting,
    updateUserActivePositions,
    pair,
    currentPrice,
    previousStopLoss,
  } = props;
  const {
    factoryAddress,
    uniPoolAddress,
    wantToken,
    borrowToken,
    deployedChainId,
    wantTokenIsToken0,
  } = pair;
  const { tokenSymbol: wTokenSymbol, tokenDecimal: wTokenDecimal } = wantToken;
  const { tokenSymbol: bTokenSymbol, tokenDecimal: bTokenDecimal } =
    borrowToken;
  const { upperPrice, lowerPrice } = previousStopLoss;

  const { account, provider, chainId } = useWeb3React();
  const { tickSpacing } = useUniswapPoolState(
    uniPoolAddress,
    borrowToken,
    wantToken,
    deployedChainId,
    wantTokenIsToken0
  );
  const trackEvent = useTrackEvent();

  const [newUpperPrice, setNewUpperPrice] = useState<number>(0);
  const [newLowerPrice, setNewLowerPrice] = useState<number>(0);

  const handleUpdateStopLoss = async () => {
    if (
      !(
        account &&
        provider &&
        factoryAddress &&
        chainId &&
        positionId &&
        tickSpacing &&
        wTokenDecimal &&
        bTokenDecimal
      )
    )
      return;

    trackEvent(`${t.amp.clickUpdateExitPricesBtn} | ${t.amp.factoryPage}`, {
      positionId: positionId,
      factoryAddress: pair.factoryAddress,
      factoryLabel: `${pair.label} ${pair.rate}%`,
      wantToken: pair.wantToken.tokenSymbol,
      borrowToken: pair.borrowToken.tokenSymbol,
      upperExitPrice: newUpperPrice || upperPrice,
      lowerExitPrice: newLowerPrice || lowerPrice,
    });

    setShowModal(false);
    setIsExecuting(true);

    await updateFactoryStopLoss(
      account,
      provider,
      factoryAddress,
      chainId,
      positionId,
      wantTokenIsToken0,
      wTokenDecimal,
      bTokenDecimal,
      newUpperPrice || upperPrice,
      newLowerPrice || lowerPrice,
      (txHash: string) => {
        trackEvent(`${t.amp.updateExitPrices} | ${t.amp.factoryPage}`, {
          txHash: txHash,
          positionId: positionId,
          factoryAddress: pair.factoryAddress,
          factoryLabel: `${pair.label} ${pair.rate}%`,
          wantToken: pair.wantToken.tokenSymbol,
          borrowToken: pair.borrowToken.tokenSymbol,
          upperExitPrice: newUpperPrice || upperPrice,
          lowerExitPrice: newLowerPrice || lowerPrice,
        });
      }
    );
    updateUserActivePositions();

    setIsExecuting(false);
  };

  return (
    <CustomModal
      show={isShowModal}
      onHide={() => setShowModal(false)}
      title={t.factory.updateExitPrices}
    >
      <InputRowContainer>
        <InputContainer>
          <LabelContainer>
            <Label>{t.factory.lowerPrice}</Label>
          </LabelContainer>
          <Input
            type="number"
            placeholder={`${lowerPrice}`}
            onChange={(e) => setNewLowerPrice(+e.currentTarget.value)}
            onWheel={(event) => event.currentTarget.blur()}
          />
          <Unit>{wTokenSymbol}</Unit>
        </InputContainer>
        <InputContainer>
          <LabelContainer>
            <Label>{t.factory.upperPrice}</Label>
          </LabelContainer>
          <Input
            type="number"
            placeholder={`${upperPrice}`}
            onChange={(e) => setNewUpperPrice(+e.currentTarget.value)}
            onWheel={(event) => event.currentTarget.blur()}
          />
          <Unit>{wTokenSymbol}</Unit>
        </InputContainer>
      </InputRowContainer>
      <InfoContainer>
        <BalanceRow>
          <Label>{t.factory.currentPrice}</Label>
          <BValue>
            {currentPrice} {wTokenSymbol}
            <br />
            {`${t.factory.per} ${bTokenSymbol}`}
          </BValue>
        </BalanceRow>
      </InfoContainer>
      <BtnContainer>
        <Button
          btnType="primary"
          btnText={t.factory.update}
          onClick={handleUpdateStopLoss}
          disabled={!newUpperPrice && !newLowerPrice}
          isLoading={false}
        />
      </BtnContainer>
    </CustomModal>
  );
};

export default UpdateStopLoss;

const Unit = styled.span`
  font-size: 16px;
  line-height: 28px;
  color: ${Colors.ivory};
  margin: 0 8px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BtnContainer = styled.div`
  margin-top: 20px;
  button {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;

const LabelContainer = styled.div`
  position: absolute;
  padding: 0 4px;
  top: -14px;
  left: 8px;
  background-color: ${Colors.backgroundGray1};
`;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const InputRowContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const InputContainer = styled.div`
  flex: 1 1 60px;
  padding: 6px 12px 6px 12px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: 1px solid ${Colors.gray4};
  margin-top: 12px;
`;

const InfoContainer = styled.div`
  padding-top: 12px;
`;

const Input = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: ${Colors.ivory};
  :focus {
    border: none;
    outline: none;
  }
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12px;
`;

const BValue = styled(Unit)`
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  text-align: right;
`;
