import styled from "styled-components";
import { useAppSelector } from "../hooks";
import Colors from "../styles/Colors";
import { H4 } from "../styles/Fonts";
import { CHAIN_IDS_TO_NAMES } from "../utils/constant/chains";
import t from "../utils/content";
import { switchNetwork } from "../utils/switchNetwork";
import Button from "./Button";
import CustomModal from "./Modal";

const SwitchNetworkModal: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
}> = (props) => {
  const { isOpen, handleClose } = props;

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const handleSwitchNetwork = async () => {
    await switchNetwork(selectedChainId);
    handleClose();
  };

  return (
    <CustomModal
      title={t.common.switchNetwork}
      show={isOpen}
      onHide={handleClose}
    >
      <Description>{`${t.common.switchNetworkDesc1} ${CHAIN_IDS_TO_NAMES[selectedChainId]}${t.common.switchNetworkDesc2}`}</Description>
      <Button
        btnText={`${t.common.switchTo} ${CHAIN_IDS_TO_NAMES[selectedChainId]}`}
        btnType="primary"
        onClick={handleSwitchNetwork}
        disabled={false}
        isLoading={false}
      />
    </CustomModal>
  );
};

export default SwitchNetworkModal;

const Description = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 20px;
`;
