import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { getUniPoolTokenPricesAndTick } from "../utils/api/factoryV3Api";

type tokenProps = {
  tokenAddress: string;
  tokenDecimal: number;
  tokenSymbol: string;
  tokenFullName: string;
};

export type StateProps = {
  borrowTokenPrice: number;
  wantTokenPrice: number;
  tickSpacing: number;
  tick: number;
};

const useUniswapPoolState = (
  poolAddress: string,
  tokenA: tokenProps,
  tokenB: tokenProps,
  chainId: number,
  wantTokenIsToken0: boolean
) => {
  const [state, setState] = useState<StateProps>({
    borrowTokenPrice: 0,
    wantTokenPrice: 0,
    tickSpacing: 0,
    tick: 0,
  });

  const { provider } = useWeb3React();

  const refreshState = () => {
    setState({
      borrowTokenPrice: 0,
      wantTokenPrice: 0,
      tickSpacing: 0,
      tick: 0,
    });
  };

  useEffect(() => {
    refreshState();
  }, [poolAddress, wantTokenIsToken0]);

  const updatePoolStates = useCallback(async () => {
    if (!poolAddress || !tokenA || !tokenB || !chainId) return;

    const res = await getUniPoolTokenPricesAndTick(
      poolAddress,
      wantTokenIsToken0 ? tokenA : tokenB,
      wantTokenIsToken0 ? tokenB : tokenA,
      chainId,
      provider
    );

    if (res) {
      setState({
        borrowTokenPrice: wantTokenIsToken0 ? res.token0Price : res.token1Price,
        wantTokenPrice: wantTokenIsToken0 ? res.token1Price : res.token0Price,
        tickSpacing: res.tickSpacing,
        tick: wantTokenIsToken0 ? res.tick : -res.tick,
      });
    }
  }, [poolAddress, tokenA, tokenB, chainId, wantTokenIsToken0, provider]);

  useEffect(() => {
    updatePoolStates();
  }, [updatePoolStates]);

  return { ...state, updatePoolStates };
};

export default useUniswapPoolState;
