import t from "../content";
import { showErrorAlert, showSuccessAlert } from "../showAlert";

interface UserInfoProps {
  userName: string;
  twitterId: string;
}

const ginzaUrl =
  process.env.REACT_APP_NODE_ENV === "PRODUCTION"
    ? ""
    : "";

/**
 * Get the name of user's twitter account by user address and twitter id
 * @param account user address
 * @param twitterId twitter id
 * @returns user's twitter account name
 */
export const getUserTwitterUserName = async (
  account: string,
  twitterId: string
) => {
  let userName = "";
  try {
    const url = `${ginzaUrl}/stag/api/v1/diamond/account/${account}?twitter_id=${twitterId}`;
    const options = {
      method: "get",
    };
    const result = await fetch(url, options);
    const resultJson = await result.json();
    // Succeed
    if (result.status === 200) {
      userName = resultJson.account.TwitterAccount.username;
    }
  } catch (e) {}
  return userName;
};

/**
 * Get twitter authorization url
 * @param account user address
 * @returns twitter authorization url
 */
export const getTwitterAuthorizationUrl = async (account: string) => {
  const url = `${ginzaUrl}/stag/api/v2/oauth/twitter/${account}`;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson.url;
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get user's twitter account information
 * @param account user address
 * @param authorizationCode authorization code
 * @returns user's twitter account information
 */
export const getTwitterAccountInfo = async (
  account: string,
  authorizationCode: string
) => {
  const url = `${ginzaUrl}/stag/api/v2/diamond/twitter/auth?state=${account}&code=${authorizationCode}&redirect_uri=${window.location.origin}`;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);

  let userInfo: UserInfoProps = {
    userName: "",
    twitterId: "",
  };

  const resultJson = await result.json();
  if (result.status === 200) {
    const accessToken = resultJson.account.TwitterAccessToken.access_token;
    if (accessToken) {
      showSuccessAlert(t.succeed.linkTwitterSucceed);
    }
    const { username, id } = resultJson.account.TwitterAccount;
    userInfo = {
      userName: username,
      twitterId: id,
    };
  } else {
    if (
      resultJson.error.includes(
        "duplicate key value violates unique constraint"
      )
    ) {
      showErrorAlert(t.error.duplicateTwitterAccount);
    } else {
      showErrorAlert(t.error.twitterLinkageFailed);
    }
  }

  return userInfo;
};
