import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { H1 } from "../../../styles/Fonts";
import FactoryAccount from "./FactoryAccount";
import FactoryPositions from "./FactoryPositions";
import PoolBalances from "./PoolBalances";
import { motion } from "framer-motion";

type TabIds = "factoryAccount" | "poolBalances" | "factoryPositions";

const Portfolio: React.FC = () => {
  const [tabId, setTabId] = useState<TabIds>("factoryAccount");

  return (
    <Container>
      <Title>Portfolio</Title>
      <TabSelectorContainer>
        <TabSelector
          isActive={tabId === "factoryAccount"}
          onClick={() => setTabId("factoryAccount")}
        >
          Factory Account
        </TabSelector>
        <TabSelector
          isActive={tabId === "poolBalances"}
          onClick={() => setTabId("poolBalances")}
        >
          Pool Balances
        </TabSelector>
        <TabSelector
          isActive={tabId === "factoryPositions"}
          onClick={() => setTabId("factoryPositions")}
        >
          Factory Positions
        </TabSelector>
      </TabSelectorContainer>
      <MainContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={tabId}
      >
        {tabId === "factoryAccount" && <FactoryAccount />}
        {tabId === "poolBalances" && <PoolBalances />}
        {tabId === "factoryPositions" && <FactoryPositions />}
      </MainContainer>
    </Container>
  );
};

export default Portfolio;

const Container = styled.div``;

const Title = styled(H1)`
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  color: ${Colors.ivory};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }

  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const TabSelectorContainer = styled.div`
  display: inline-flex;
  gap: 12px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: flex;
    border-bottom: 1px solid ${Colors.gray6};
  }
`;

const TabSelector = styled.div<{ isActive: boolean }>`
  padding: 6px 12px;
  border-radius: 100px;
  color: ${({ isActive }) => (isActive ? Colors.gray6 : Colors.ivory)};
  border: 2px solid
    ${({ isActive }) => (isActive ? Colors.lightKhaki : "transparent")};
  background-color: ${({ isActive }) =>
    isActive ? Colors.lightKhaki : Colors.backgroundGray1};
  font-weight: 700;
  cursor: pointer;

  @media (max-width: 976px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 768px) {
    background-color: transparent;
    padding: 6px 4px;
    color: ${({ isActive }) => (isActive ? Colors.lightKhaki : Colors.ivory)};
    border: none;
    border-bottom: ${({ isActive }) =>
      isActive ? `2px solid ${Colors.lightKhaki}` : `none`};
    border-radius: 0;
  }
`;

const MainContainer = styled(motion.div)``;
