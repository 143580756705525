import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import Button from "../../../components/Button";
import { H4 } from "../../../styles/Fonts";
import { unwrapWrappedToken } from "../../../utils/api/lendingPoolApi";
import CustomModal from "../../../components/Modal";
import { getTruncNum } from "../../../utils/common";
import { useWeb3React } from "@web3-react/core";
import AmountInput from "../../../components/AmountInput";
import t from "../../../utils/content";
import { isSupportedChain } from "../../../utils/constant/chains";
import useTrackEvent from "../../../hooks/useTrackEvent";

const UnwrapWethModal: React.FC<{
  isShowModal: boolean;
  onHideModal: () => void;
  setIsUnwrapping: (v: boolean) => void;
  tokenBalance: string;
  updateTokenBalance: () => void;
  tokenAddress: string;
  tokenSymbol: string;
}> = (props) => {
  const {
    isShowModal,
    onHideModal,
    setIsUnwrapping,
    tokenBalance,
    updateTokenBalance,
    tokenAddress,
    tokenSymbol,
  } = props;

  const [wethAmount, setWethAmount] = useState<string>("0");

  const { account, chainId, provider } = useWeb3React();
  const trackEvent = useTrackEvent();

  const handleUnwrapWeth = async (address: string, amount: string) => {
    if (account && isSupportedChain(chainId) && provider) {
      onHideModal();
      setIsUnwrapping(true);

      trackEvent(`${t.amp.clickUnwrapTokenBtn} | ${t.amp.poolPage}`, {
        amount: amount,
        token: tokenSymbol,
      });

      try {
        await unwrapWrappedToken(
          address,
          tokenAddress,
          tokenSymbol,
          amount,
          chainId,
          provider,
          (txHash: string) => {
            trackEvent(`${t.amp.unwrapToken} | ${t.amp.poolPage}`, {
              txHash: txHash,
              amount: amount,
              token: tokenSymbol,
            });
          }
        );
        updateTokenBalance();
      } catch (e) {
        // console.log(e);
      }

      setIsUnwrapping(false);
      setWethAmount("0");
    }
  };

  return (
    <CustomModal
      show={isShowModal}
      onHide={onHideModal}
      title={`${t.pool.unwrapYour} ${tokenSymbol} ${
        t.pool.to
      } ${tokenSymbol.substring(1)}`}
    >
      <AmountInput
        setAmount={setWethAmount}
        tokenSymbol={tokenSymbol}
        tokenDecimal={18}
        showMaxBtn={true}
        maxAmount={tokenBalance}
      />
      <InfoContainer>
        <BalanceRow>
          <Label>{tokenSymbol}</Label>
          <Value>{`${getTruncNum(+tokenBalance, 18)} ${tokenSymbol}`}</Value>
        </BalanceRow>
      </InfoContainer>
      <BtnContainer>
        <Button
          btnType="primary"
          btnText={t.pool.unwrap}
          onClick={() =>
            account ? handleUnwrapWeth(account, wethAmount) : null
          }
          disabled={!wethAmount}
          isLoading={false}
        />
      </BtnContainer>
    </CustomModal>
  );
};

export default UnwrapWethModal;

const InfoContainer = styled.div`
  margin-top: 20px;
`;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const Value = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  color: ${Colors.ivory};
  text-align: right;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12px;
`;

const BtnContainer = styled.div`
  margin-top: 20px;
  button {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;
