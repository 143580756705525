import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { SmallRegular } from "../../../../styles/Fonts";
import FactoryPositionItem from "./FactoryPositionItem";
import useFactoryPairs from "../../../../hooks/useFactoryPairs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getUserActivePositions } from "../../../../redux/factoryAction";
import { useWeb3React } from "@web3-react/core";
import { PairProps } from "../../../../utils/constant/pairs";
import { UserActivePositionProps } from "../../../../redux/factorySlice";

const positionHeaders = [
  "Pair",
  "Position Value",
  "Earned Fee",
  "Fee APR",
  "Leverage",
];

const FactoryPositions: React.FC = () => {
  const pairs = useFactoryPairs();

  const { account, provider, chainId } = useWeb3React();

  const activePositions = useAppSelector(
    (state) => state.factory.userActivePositions
  );

  const dispatch = useAppDispatch();

  const updateUserActivePositions = useCallback(
    async (
      factoryAddress: string,
      wantTokenIsToken0: boolean,
      wantTokenAddress: string,
      wantTokenDecimal: number,
      wantTokenSymbol: string,
      borrowTokenAddress: string,
      borrowTokenDecimal: number,
      uniPoolAddress: string,
      lendingPoolAddress: string
    ) => {
      if (
        !(
          account &&
          factoryAddress &&
          wantTokenAddress &&
          wantTokenDecimal &&
          wantTokenSymbol &&
          borrowTokenAddress &&
          borrowTokenDecimal &&
          uniPoolAddress &&
          lendingPoolAddress &&
          chainId &&
          provider
        )
      )
        return;

      dispatch(
        getUserActivePositions(
          account,
          factoryAddress,
          wantTokenDecimal,
          borrowTokenDecimal,
          wantTokenSymbol,
          wantTokenIsToken0,
          chainId,
          provider
        )
      );
    },
    [account, dispatch, chainId, provider]
  );

  useEffect(() => {
    if (!pairs) return;
    pairs.forEach((pair) => {
      const {
        factoryAddress,
        wantToken,
        borrowToken,
        uniPoolAddress,
        lendingPoolAddress,
        wantTokenIsToken0,
      } = pair;
      updateUserActivePositions(
        factoryAddress,
        wantTokenIsToken0,
        wantToken.tokenAddress,
        wantToken.tokenDecimal,
        wantToken.tokenSymbol,
        borrowToken.tokenAddress,
        borrowToken.tokenDecimal,
        uniPoolAddress,
        lendingPoolAddress
      );
    });
  }, [pairs, updateUserActivePositions]);

  const positions = useMemo(() => {
    if (!chainId) return [];

    const positionsUnderChain = activePositions[chainId];

    const array: {
      pair: PairProps;
      position: UserActivePositionProps;
    }[] = [];

    pairs &&
      pairs.forEach((pair) => {
        const factoryPositions = positionsUnderChain?.[pair.factoryAddress];
        if (factoryPositions?.length > 0) {
          factoryPositions.forEach((pos) => {
            if (pos) {
              array.push({ pair, position: pos });
            }
          });
        }
      });
    return array;
  }, [activePositions, chainId, pairs]);

  return (
    <Container>
      <PortfolioContainer>
        <GrayContainer>
          <PositionHeaderRow>
            {positionHeaders.map((header) => (
              <HeaderText key={header}>{header}</HeaderText>
            ))}
          </PositionHeaderRow>
          {positions.length > 0 ? (
            positions.map((pos) => (
              <FactoryPositionItem key={pos.position?.positionId} pos={pos} />
            ))
          ) : (
            <NoRecordContainer>
              <NoRecord>No Record</NoRecord>
            </NoRecordContainer>
          )}
        </GrayContainer>
      </PortfolioContainer>
    </Container>
  );
};

export default FactoryPositions;

const Container = styled.div``;

const GrayContainer = styled.div`
  border-radius: 8px;
  padding: 24px;
  background-color: ${Colors.backgroundGray1};
`;

const PortfolioContainer = styled.div`
  > div:not(:first-child) {
    margin-top: 24px;
  }
`;

const HeaderText = styled(SmallRegular)`
  color: ${Colors.gray4};
`;

const PositionRow = styled.div`
  display: grid;
  grid-template-columns: 100px repeat(4, 1fr);
  align-items: center;
  > span:not(:first-child) {
    text-align: right;
  }
  @media (max-width: 768px) {
    display: block;
  }
`;

const PositionHeaderRow = styled(PositionRow)`
  padding: 0px 40px 0px 24px;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const NoRecordContainer = styled.div`
  text-align: center;
  padding: 40px 0;
`;

const NoRecord = styled.span`
  display: inline-block;
  padding: 4px 20px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGray4};
  margin: auto;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.ivory};
`;
