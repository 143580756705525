import { ethers, utils } from "ethers";
import farmAbi from "../../abi/farm.json";
import farmActionAbi from "../../abi/farmAction.json";
import factoryV3Abi from "../../abi/factoryV3.json";
import erc20Abi from "../../abi/erc20.json";
import { showErrorAlert as showError, showPendingAlert } from "../showAlert";
import { isSupportedChain } from "../constant/chains";
import { getPositionRangesFromV3 } from "./factoryV3Api";
import getProvider from "../getProvider";
import { formatUnits } from "ethers/lib/utils";
import { convertTickToPrice, getAllLogsOfAddress } from "./common";
import t from "../content";

/**
 * Register deposit into farm
 * @param accountAddress user address
 * @param farmAddress farm address
 * @param amount register deposit amount
 * @param tokenAddress token address
 * @param tokenDecimal token decimal
 * @param tokenSymbol token symbol
 * @param chainId chain id
 * @param provider provider
 * @returns transaction result
 */
export const registerDepositIntoFarm = async (
  accountAddress: string,
  farmAddress: string,
  amount: string,
  tokenAddress: string,
  tokenDecimal: number,
  tokenSymbol: string,
  chainId: number,
  provider: any
) => {
  if (!isSupportedChain(chainId)) {
    showError(t.error.switchNetwork);
  } else {
    const depositAmount = ethers.utils.parseUnits(
      amount.toString(),
      tokenDecimal
    );
    const signer = provider.getSigner(accountAddress);
    const usdc = new ethers.Contract(tokenAddress, erc20Abi, signer);
    const farm = new ethers.Contract(farmAddress, farmAbi, signer);
    const usdcBalance = await usdc.balanceOf(accountAddress);
    const allowance = await usdc.allowance(accountAddress, farmAddress);

    if (usdcBalance.lt(depositAmount)) {
      showError(
        `${t.error.tokenBalanceNotEnough1} ${tokenSymbol} ${t.error.tokenBalanceNotEnough2}`
      );
    } else {
      if (allowance.lt(depositAmount)) {
        try {
          const approveRes = await usdc.approve(farmAddress, depositAmount);
          await showPendingAlert(
            approveRes.hash,
            `${t.succeed.approve} ${tokenSymbol} ${t.succeed.succeed}`,
            provider
          );
        } catch (error: any) {
          if (error.message.includes("User denied transaction signature")) {
            showError(t.error.deniedTransaction);
          } else {
            showError(`${t.error.approve} ${tokenSymbol} ${t.error.failed}`);
          }
          return;
        }
      }

      try {
        const res = await farm.connect(signer).registerDeposit(depositAmount);
        await showPendingAlert(
          res.hash,
          t.succeed.registerDepositSucceed,
          provider
        );
      } catch (error: any) {
        if (error.message.includes("User denied transaction signature")) {
          showError(t.error.deniedTransaction);
        } else {
          showError(t.error.registerDepositFailed);
        }
      }
    }
  }
};

/**
 * Deposit into farm
 * @param accountAddress user address
 * @param farmAddress farm address
 * @param amount deposit amount
 * @param tokenAddress token address
 * @param tokenDecimal token decimal
 * @param tokenSymbol token symbol
 * @param chainId chain id
 * @param provider provider
 * @returns transaction result
 */
export const depositIntoFarm = async (
  accountAddress: string,
  farmAddress: string,
  amount: string,
  tokenAddress: string,
  tokenDecimal: number,
  tokenSymbol: string,
  chainId: number,
  provider: any
) => {
  if (!isSupportedChain(chainId)) {
    showError(t.error.switchNetwork);
  } else {
    const depositAmount = ethers.utils.parseUnits(
      amount.toString(),
      tokenDecimal
    );

    const signer = provider.getSigner(accountAddress);
    const usdc = new ethers.Contract(tokenAddress, erc20Abi, signer);
    const farm = new ethers.Contract(farmAddress, farmAbi, signer);
    const usdcBalance = await usdc.balanceOf(accountAddress);
    const allowance = await usdc.allowance(accountAddress, farmAddress);

    if (usdcBalance.lt(depositAmount)) {
      showError(
        `${t.error.tokenBalanceNotEnough1} ${tokenSymbol} ${t.error.tokenBalanceNotEnough2}`
      );
    } else {
      if (allowance.lt(depositAmount)) {
        try {
          const approveRes = await usdc.approve(farmAddress, depositAmount);
          await showPendingAlert(
            approveRes.hash,
            `${t.succeed.approve} ${tokenSymbol} ${t.succeed.succeed}`,
            provider
          );
        } catch (error: any) {
          if (error.message.includes("User denied transaction signature")) {
            showError(t.error.deniedTransaction);
          } else {
            showError(`${t.error.approve} ${tokenSymbol} ${t.error.failed}`);
          }
          return;
        }
      }

      try {
        const res = await farm.connect(signer).deposit(depositAmount);
        await showPendingAlert(res.hash, t.succeed.depositSucceed, provider);
      } catch (error: any) {
        if (error.message.includes("User denied transaction signature")) {
          showError(t.error.deniedTransaction);
        } else {
          showError(t.error.depositFailed);
        }
      }
    }
  }
};

/**
 * Register withdraw from farm
 * @param accountAddress user address
 * @param farmAddress farm address
 * @param amount register withdraw amount
 * @param tokenDecimal token decimal
 * @param tokenSymbol token symbol
 * @param chainId chain id
 * @param provider provider
 */
export const registerWithdrawFromFarm = async (
  accountAddress: string,
  farmAddress: string,
  amount: string,
  tokenDecimal: number,
  tokenSymbol: string,
  chainId: number,
  provider: any
) => {
  if (!isSupportedChain(chainId)) {
    showError(t.error.switchNetwork);
  } else {
    const withdrawAmount = ethers.utils.parseUnits(
      amount.toString(),
      tokenDecimal
    );
    const signer = provider.getSigner(accountAddress);
    const farm = new ethers.Contract(farmAddress, farmAbi, signer);
    const canWithdrawShares = await farm.balanceOf(accountAddress);
    const preRound = +(await farm.round()).toString() - 1;
    const withdrawShares = await farm.getSharesByDepositAmount(
      withdrawAmount,
      preRound
    );
    if (canWithdrawShares.lt(withdrawShares)) {
      showError(t.error.exceedWithdrawableBalance);
    } else {
      try {
        const res = await farm.connect(signer).registerWithdraw(withdrawShares);
        await showPendingAlert(
          res.hash,
          `${t.succeed.registerWithdraw} ${tokenSymbol} ${t.succeed.succeed}`,
          provider
        );
      } catch (error: any) {
        if (error.message.includes("Not in whitelist")) {
          showError(t.error.notInWhitelist);
        } else if (
          error.message.includes("User denied transaction signature")
        ) {
          showError(t.error.deniedTransaction);
        } else {
          showError(t.error.registerWithdrawFailed);
        }
      }
    }
  }
};

/**
 * Withdraw from farm
 * @param accountAddress user address
 * @param farmAddress farm address
 * @param amount withdraw amount
 * @param tokenDecimal token decimal
 * @param tokenSymbol token symbol
 * @param chainId chain id
 * @param provider provider
 */
export const withdrawFromFarm = async (
  accountAddress: string,
  farmAddress: string,
  amount: string,
  tokenDecimal: number,
  tokenSymbol: string,
  chainId: number,
  provider: any
) => {
  if (!isSupportedChain(chainId)) {
    showError(t.error.switchNetwork);
  } else {
    const withdrawAmount = ethers.utils.parseUnits(
      amount.toString(),
      tokenDecimal
    );
    const signer = provider.getSigner(accountAddress);
    const farm = new ethers.Contract(farmAddress, farmAbi, signer);
    const canWithdrawShares = await farm.estimateAvailableShares(
      accountAddress
    );
    const preRound = +(await farm.round()).toString() - 1;
    const withdrawShares = await farm.getSharesByDepositAmount(
      withdrawAmount,
      preRound
    );
    if (canWithdrawShares.lt(withdrawShares)) {
      showError(t.error.exceedWithdrawableBalance);
    } else {
      try {
        const res = await farm.connect(signer).withdraw(withdrawShares);
        await showPendingAlert(
          res.hash,
          `${t.succeed.withdraw} ${tokenSymbol} ${t.succeed.succeed}`,
          provider
        );
      } catch (error: any) {
        if (error.message.includes("Not in whitelist")) {
          showError(t.error.notInWhitelist);
        } else if (
          error.message.includes("User denied transaction signature")
        ) {
          showError(t.error.deniedTransaction);
        } else {
          showError(t.error.withdrawFailed);
        }
      }
    }
  }
};

/**
 * Get estimated balance in farm
 * @param accountAddress user address
 * @param farmAddress farm address
 * @param tokenDecimal token decimal
 * @param provider provider
 * @returns estimated balance
 */
export const getEstimatedBalanceInFarm = async (
  accountAddress: string,
  farmAddress: string,
  tokenDecimal: number,
  provider: any
) => {
  let balance = 0;

  try {
    const farm = new ethers.Contract(farmAddress, farmAbi, provider);
    balance = +formatUnits(
      await farm.estimateTotalAssets(accountAddress),
      tokenDecimal
    );
  } catch (e) {}

  return balance;
};

/**
 * Get previous round share price of farm
 * @param farmAddress farm address
 * @param tokenDecimal token decimal
 * @param chainId chain id
 * @param provider provider
 * @returns share price
 */
export const getPreRoundSharePriceOfFarm = async (
  farmAddress: string,
  tokenDecimal: number,
  chainId: number,
  provider: any
) => {
  let sharePrice = 0;

  try {
    const p = await getProvider(provider, chainId);
    const farm = new ethers.Contract(farmAddress, farmAbi, p);
    const round = await farm.round();
    sharePrice = +formatUnits(
      await farm.roundSharePrice(+round - 1),
      tokenDecimal
    );
  } catch (e) {}

  return sharePrice;
};

/**
 * Get total supply of farm
 * @param farmAddress farm address
 * @param tokenDecimal token decimal
 * @param chainId chain id
 * @param provider provider
 * @returns total supply
 */
export const getTotalSupplyOfFarm = async (
  farmAddress: string,
  tokenDecimal: number,
  chainId: number,
  provider: any
) => {
  let totalSupply = 0;

  try {
    const p = await getProvider(provider, chainId);
    const farm = new ethers.Contract(farmAddress, farmAbi, p);
    totalSupply = +formatUnits(await farm.totalAssets(), tokenDecimal);
  } catch (e) {}

  return totalSupply;
};

/**
 * Get first rollover time of farm
 * @param farmActionAddress farm action address
 * @param chainId chain id
 * @param provider provider
 * @returns first rollover time
 */
export const getFirstRolloverTime = async (
  farmActionAddress: string,
  chainId: number,
  provider: any
) => {
  let rolloverTime = 0;

  try {
    const p = await getProvider(provider, chainId);

    const currentBlockNumber = await p.getBlockNumber();
    const res = await getAllLogsOfAddress(
      p,
      farmActionAddress,
      0,
      currentBlockNumber
    );
    const iface = new ethers.utils.Interface(farmActionAbi);

    const parsedEvents = res.map((log: any) => {
      return { ...iface.parseLog(log) };
    });

    const times = parsedEvents
      .filter(
        (e: any) => e.name.toUpperCase() === "RolloverPosition".toUpperCase()
      )
      .map((e: any) => {
        return +e.args.rolloverTime.toString();
      });

    rolloverTime = times[0];
  } catch (e) {}

  return rolloverTime;
};

/**
 * Get price ranges of farm
 * @param factoryAddress factory address
 * @param farmActionAddress farm action address
 * @param chainId chain id
 * @param borrowTokenDecimal borrowToken decimal
 * @param wantTokenDecimal wantToken decimal
 * @param provider provider
 * @returns price ranges
 */
export const getPriceRangeOfFarm = async (
  factoryAddress: string,
  farmActionAddress: string,
  chainId: number,
  borrowTokenDecimal: number,
  wantTokenDecimal: number,
  provider: any
) => {
  let upperTickPrice = 0;
  let lowerTickPrice = 0;

  try {
    const p = await getProvider(provider, chainId);

    const factory = new ethers.Contract(factoryAddress, factoryV3Abi, p);
    const ids = await factory.getAccountPositionIds(farmActionAddress);

    if (ids[0]) {
      // Get Tick Range
      const { tickLower, tickUpper } = await getPositionRangesFromV3(
        +ids[0].toString(),
        chainId,
        p
      );
      const decimalDiff = borrowTokenDecimal - wantTokenDecimal;
      upperTickPrice = convertTickToPrice(+tickUpper, decimalDiff);
      lowerTickPrice = convertTickToPrice(+tickLower, decimalDiff);
    }
  } catch (e) {}

  return {
    upperTickPrice,
    lowerTickPrice,
  };
};

/**
 * Get registered withdrawn amount in farm
 * @param accountAddress user address
 * @param farmAddress farm address
 * @param tokenDecimal token decimal
 * @param provider provider
 * @returns registered withdrawn amount
 */
export const getRegisteredWithdrawnAmountInFarm = async (
  accountAddress: string,
  farmAddress: string,
  tokenDecimal: number,
  provider: any
) => {
  let registeredWithdrawnAmount = 0;

  try {
    const farm = new ethers.Contract(farmAddress, farmAbi, provider);
    const registerWithdrawShares = await farm.getRegisterWithdrawShares(
      accountAddress
    );
    const currRound = +(await farm.round());
    registeredWithdrawnAmount = +formatUnits(
      await farm.getWithdrawAmountByShares(
        registerWithdrawShares,
        currRound - 1
      ),
      tokenDecimal
    );
  } catch (e) {}

  return registeredWithdrawnAmount;
};

/**
 * Get registered deposit amount in farm
 * @param accountAddress user address
 * @param farmAddress farm address
 * @param tokenDecimal token decimal
 * @param provider provider
 * @returns registered deposit amount
 */
export const getRegisteredDepositAmountInFarm = async (
  accountAddress: string,
  farmAddress: string,
  tokenDecimal: number,
  provider: any
) => {
  let amount = 0;

  try {
    const farm = new ethers.Contract(farmAddress, farmAbi, provider);
    amount = +formatUnits(
      await farm.getRegisterDepositAssets(accountAddress),
      tokenDecimal
    );
  } catch (e) {}

  return amount;
};

/**
 * Get can withdraw amount in farm
 * @param accountAddress user address
 * @param farmAddress farm address
 * @param tokenDecimal token decimal
 * @param provider provider
 * @returns can withdraw amount
 */
export const getCanWithdrawAmountInFarm = async (
  accountAddress: string,
  farmAddress: string,
  tokenDecimal: number,
  provider: any
) => {
  let canWithdrawAmount = 0;

  try {
    const farm = new ethers.Contract(farmAddress, farmAbi, provider);
    const shares = await farm.balanceOf(accountAddress);
    const currRound = +(await farm.round());
    canWithdrawAmount = +formatUnits(
      await farm.getWithdrawAmountByShares(shares, currRound - 1),
      tokenDecimal
    );
  } catch (e) {}

  return canWithdrawAmount;
};

/**
 * Get Auto Vault state
 * @param farmAddress farm address
 * @param chainId chain id
 * @param provider provider
 * @returns state
 */
export const getAutoVaultState = async (
  farmAddress: string,
  chainId: number,
  provider: any
) => {
  let state = 0;

  try {
    const p = await getProvider(provider, chainId);
    const farm = new ethers.Contract(farmAddress, farmAbi, p);
    state = await farm.state();
  } catch (e) {}

  return state;
};

/**
 * Get Auto Farm logs
 * @param farmAddress farm address
 * @param userAddress user address
 * @param chainId chain id
 * @param provider provider
 * @returns logs
 */
export const getUserAutoFarmLogs = async (
  farmAddress: string,
  userAddress: string,
  chainId: number,
  provider: any
) => {
  let userRegisterDepositRecord = [];
  let userRegisterWithdrawRecord = [];
  let userDepositRecord = [];
  let userWithdrawRecord = [];

  if (isSupportedChain(chainId)) {
    const farm = new ethers.Contract(farmAddress, farmAbi, provider);
    const currentBlockNumber = await provider.getBlockNumber();
    const res = await getAllLogsOfAddress(
      provider,
      farmAddress,
      0,
      currentBlockNumber
    );

    const iface = new utils.Interface(farmAbi);

    const parsedEvents = res.map((log: any) => {
      return { blockNumber: log.blockNumber, ...iface.parseLog(log) };
    });

    // Filter by Event Name
    const registerDepositEvents = parsedEvents.filter(
      (e: any) =>
        !!e && e.name.toUpperCase() === "RegisterDeposit".toUpperCase()
    );
    const depositEvents = parsedEvents.filter(
      (e: any) => !!e && e.name.toUpperCase() === "Deposit".toUpperCase()
    );
    const registerWithdrawEvents = parsedEvents.filter(
      (e: any) =>
        !!e && e.name.toUpperCase() === "RegisterWithdraw".toUpperCase()
    );
    const withdrawEvents = parsedEvents.filter(
      (e: any) => !!e && e.name.toUpperCase() === "Withdraw".toUpperCase()
    );

    // Filter by User Address
    const userRegisterDepositEvents = registerDepositEvents.filter(
      (e: any) => e.args?.account.toUpperCase() === userAddress.toUpperCase()
    );
    const userDepositEvents = depositEvents.filter(
      (e: any) => e.args?.account.toUpperCase() === userAddress.toUpperCase()
    );
    const userRegisterWithdrawEvents = registerWithdrawEvents.filter(
      (e: any) => e.args?.account.toUpperCase() === userAddress.toUpperCase()
    );
    const userWithdrawEvents = withdrawEvents.filter(
      (e: any) => e.args?.account.toUpperCase() === userAddress.toUpperCase()
    );

    userRegisterDepositRecord = await Promise.all(
      userRegisterDepositEvents.map(async (item: any) => {
        const t = (await provider.getBlock(item.blockNumber)).timestamp;
        return {
          event: item.name,
          amount: formatUnits(item.args.amount, 6),
          timestamp: t,
        };
      })
    );
    userDepositRecord = await Promise.all(
      userDepositEvents.map(async (item: any) => {
        const t = (await provider.getBlock(item.blockNumber)).timestamp;
        return {
          event: item.name,
          amount: formatUnits(item.args.amountDeposited, 6),
          timestamp: t,
        };
      })
    );
    userRegisterWithdrawRecord = await Promise.all(
      userRegisterWithdrawEvents.map(async (item: any) => {
        const t = (await provider.getBlock(item.blockNumber)).timestamp;
        const amount = await farm.getWithdrawAmountByShares(
          item.args.shares,
          +item.args.round
        );
        return {
          event: item.name,
          amount: formatUnits(amount, 6),
          timestamp: t,
        };
      })
    );
    userWithdrawRecord = await Promise.all(
      userWithdrawEvents.map(async (item: any) => {
        const t = (await provider.getBlock(item.blockNumber)).timestamp;
        return {
          event: item.name,
          amount: formatUnits(item.args.amountWithdrawn, 6),
          timestamp: t,
        };
      })
    );
  }

  const records = [
    ...userRegisterDepositRecord,
    ...userRegisterWithdrawRecord,
    ...userDepositRecord,
    ...userWithdrawRecord,
  ].sort((a, b) => b.timestamp - a.timestamp);

  return records;
};

/**
 * Get Auto Vault round share prices
 * @param farmAddress farm address
 * @param chainId chain id
 * @param provider provider
 * @returns share prices
 */
export const getAutoVaultRoundSharePrices = async (
  farmAddress: string,
  chainId: number,
  provider: any
) => {
  const sharePrices = [];

  try {
    const p = await getProvider(provider, chainId);
    const farm = new ethers.Contract(farmAddress, farmAbi, p);
    let currentRound = await farm.round();
    for (let i = currentRound - 1; i > 0; i--) {
      const sharePrice = +formatUnits(await farm.roundSharePrice(i), 6);
      sharePrices.push({ round: i, sharePrice });
    }
    sharePrices.push({ round: 0, sharePrice: 1 });
  } catch (e) {}
  return sharePrices.sort((a, b) => a.round - b.round);
};
