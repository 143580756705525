import { faClone } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import Colors from "../../../styles/Colors";
import { H1, H2, H3 } from "../../../styles/Fonts";
import CreateReferralCodeModal from "./CreateReferralCodeModal";
import useUserRewards from "../../../hooks/useUserRewards";
import { useWeb3React } from "@web3-react/core";
import { claimReferralRewards } from "../../../utils/api/referralApi";
import { showErrorAlert } from "../../../utils/showAlert";
import useReferralIsExisted from "../../../hooks/useReferralIsExisted";

type Props = {
  referralCode: string;
  updateReferralCode: () => void;
  referreeCount: number;
};

const Referral: React.FC<Props> = (props) => {
  const { referralCode, updateReferralCode, referreeCount } = props;

  const [modalIsShow, setModalIsShow] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isClaiming, setIsClaiming] = useState<boolean>(false);

  const referralExisted = useReferralIsExisted();
  const { account, chainId, provider } = useWeb3React();
  const { claimedReward, unclaimedReward, claimableWeekParams, updateRewards } =
    useUserRewards();

  const onCopy = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}?referralCode=${referralCode}`
    );
  };

  const handleClaimReward = async () => {
    if (unclaimedReward === 0 || !claimableWeekParams) {
      showErrorAlert("No claimable rewards.");
      return;
    }

    if (account && chainId && provider) {
      setIsClaiming(true);

      try {
        await claimReferralRewards(
          account,
          chainId,
          provider,
          claimableWeekParams
        );
      } catch (e) {}

      setIsClaiming(false);
      updateRewards();
    }
  };

  return (
    <Container>
      {referralExisted && (
        <>
          <div>
            <Title>Referral</Title>
            <RowContainer>
              <LeftContainer>
                <Label>My Code : </Label>

                {!!referralCode ? (
                  <MyCodeRow>
                    <MyCodeValue>{referralCode}</MyCodeValue>
                    <CopyBtn onClick={onCopy}>
                      <CopyIcon icon={faClone} />
                      <span>Copy Referral Link</span>
                    </CopyBtn>
                  </MyCodeRow>
                ) : (
                  <BtnContainer>
                    <Button
                      btnType="primary"
                      btnText="Create Referral Code"
                      onClick={() => setModalIsShow(true)}
                      disabled={isCreating}
                      isLoading={isCreating}
                    />
                  </BtnContainer>
                )}
              </LeftContainer>
              <RightContainer>
                <Label>Total User Referred : </Label>
                <HighlightValue>{referreeCount}</HighlightValue>
              </RightContainer>
            </RowContainer>
            <CreateReferralCodeModal
              isShowModal={modalIsShow}
              setShowModal={setModalIsShow}
              setIsCreating={setIsCreating}
              updateReferralCode={updateReferralCode}
            />
          </div>
          <div>
            <Title>Rewards</Title>
            <RewardsContainer>
              <RewardRow>
                <RewardInfoContainer>
                  <RewardLabel>Unclaimed Rewards</RewardLabel>
                  <RewardValue>{unclaimedReward} USDC</RewardValue>
                </RewardInfoContainer>
                <ClaimBtnContainer>
                  <Button
                    btnText="Claim Rewards"
                    btnType="primary"
                    onClick={handleClaimReward}
                    isLoading={isClaiming}
                    disabled={false}
                  />
                </ClaimBtnContainer>
              </RewardRow>
              <RewardRow>
                <RewardInfoContainer>
                  <RewardLabel>Claimed Rewards</RewardLabel>
                  <RewardValue>{claimedReward} USDC</RewardValue>
                </RewardInfoContainer>
              </RewardRow>
            </RewardsContainer>
          </div>
        </>
      )}
      {!referralExisted && (
        <NoRecordContainer>
          <NoRecord>{`No Referral Program on this network`}</NoRecord>
        </NoRecordContainer>
      )}
    </Container>
  );
};

export default Referral;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled(H1)`
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  color: ${Colors.ivory};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }

  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  flex: 3;
  padding: 24px;
  border-radius: 8px;
  background: ${Colors.backgroundGray1};

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const RightContainer = styled(LeftContainer)`
  flex: 2;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const Label = styled(H2)`
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.ivory};
  margin-bottom: 12px;

  @media (max-width: 768px) {
    margin-bottom: 0px;
    margin-right: 12px;
    font-size: 14px;
  }
`;

const MyCodeRow = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const MyCodeValue = styled(H2)`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: ${Colors.ivory};
  margin-left: 12px;

  @media (max-width: 768px) {
    margin-left: 0px;
    font-size: 14px;
  }
`;

const CopyBtn = styled.button`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  padding: 6px 12px;
  margin-left: 20px;
  color: ${Colors.lightKhaki};
  border: 1px solid ${Colors.lightKhaki};
  border-radius: 8px;
  display: flex;
  align-items: center;
  :active {
    transform: scale(0.99);
  }
`;

const CopyIcon = styled(FontAwesomeIcon)`
  font-size: 15px;
  margin-right: 8px;
`;

const BtnContainer = styled.div`
  flex: none;
  margin-left: 12px;
`;

const HighlightValue = styled(H3)`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: ${Colors.ivory};

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const RewardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RewardRow = styled.div`
  background: ${Colors.backgroundGray1};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

const RewardInfoContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const RewardLabel = styled(H2)`
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.ivory};
  width: 300px;

  @media (max-width: 768px) {
    font-size: 14px;
    width: 150px;
    margin-bottom: 12px;
  }
`;

const RewardValue = styled(H2)`
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.ivory};

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ClaimBtnContainer = styled.div``;

const NoRecordContainer = styled.div`
  text-align: center;
  padding: 40px 0;
`;

const NoRecord = styled.span`
  display: inline-block;
  padding: 4px 20px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGray4};
  margin: auto;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.ivory};
`;
