import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useQuery } from "../../hooks";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import {
  CHAIN_IDS_TO_URL_IDS,
  CHAIN_URL_IDS_TO_IDS,
  isSupportedChain,
  SupportedChainId,
} from "../../utils/constant/chains";
import { useWeb3React } from "@web3-react/core";
import { updateSelectedChainId } from "../../redux/accountSlice";
import Colors from "../../styles/Colors";
import { H1 } from "../../styles/Fonts";
import Leaderboard from "./Leaderboard";
import t from "../../utils/content";
import useWantTokens from "../../hooks/useWantTokens";
import { PairProps } from "../../utils/constant/pairs";
import useLeaderboardPositions from "../../hooks/useLeaderboardPositions";

export interface TimeRangeProps {
  startTime: number;
  endTime: number;
}

const LeaderboardPage = () => {
  const [selectedToken, setSelectedToken] = useState<PairProps["wantToken"] | null>(null);
  const [timeRange, setTimeRange] = useState<TimeRangeProps>({
    startTime: 0,
    endTime: 0,
  });

  let query = useQuery();
  const startTime = query.get("startTime");
  const endTime = query.get("endTime");

  useEffect(() => {
    if (startTime && endTime) {
      setTimeRange({
        startTime: Math.floor(new Date(startTime).getTime() / 1000),
        endTime: Math.floor(new Date(endTime).getTime() / 1000),
      });
    }
  }, [startTime, endTime]);

  const {
    topUsersByPnL,
    topUserByPnLIsFetched,
    topOpenedByEarnedFee,
    topOpenedByEarnedFeeIsFetched,
    topOpenedByApr,
    topOpenedByAprIsFetched,
    topClosedByPnL,
    topClosedByPnLIsFetched,
    topClosedByPercentage,
    topClosedByPercentageIsFetched,
  } = useLeaderboardPositions(selectedToken, timeRange);

  const wantTokens = useWantTokens();

  useEffect(() => {
    if (wantTokens && wantTokens.length > 0) {
      setSelectedToken(wantTokens[0]);
    }
  }, [wantTokens]);

  const { chainName } = useParams();
  const { account, chainId } = useWeb3React();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigateToDefaultStatPage = useCallback(() => {
    const defaultChainId = SupportedChainId.ARBITRUM_ONE;
    const defaultChainName = CHAIN_IDS_TO_URL_IDS[defaultChainId];
    navigate(`/leaderboard/${defaultChainName}`);
  }, [navigate]);

  useEffect(() => {
    const urlChainId = chainName ? CHAIN_URL_IDS_TO_IDS[chainName] : 0;

    // 有連接 wallet
    if (account) {
      if (chainId && urlChainId !== chainId && isSupportedChain(chainId)) {
        const matchedChainName = CHAIN_IDS_TO_URL_IDS[chainId];
        navigate(`/leaderboard/${matchedChainName}`);
      }
    } else {
      // 有指定 network
      if (!!urlChainId && isSupportedChain(urlChainId)) {
        dispatch(updateSelectedChainId({ chainId: urlChainId }));
      } else if (!urlChainId || !isSupportedChain(urlChainId)) {
        navigateToDefaultStatPage();
      }
    }
  }, [
    dispatch,
    account,
    chainId,
    chainName,
    navigate,
    navigateToDefaultStatPage,
  ]);

  const handlePastDaysOnClick = (days: number) => {
    const now = new Date();
    const endTime = Math.floor(now.getTime() / 1000);
    const startTime = endTime - days * 24 * 60 * 60;
    setTimeRange({ startTime, endTime });
  };

  const handleFullTimeRangeOnClick = () => {
    setTimeRange({ startTime: 0, endTime: 0 });
  };

  const getDateString = (date: Date) => {
    return `${date.toLocaleDateString("en", {
      month: "long",
      day: "numeric",
    })}`;
  };

  const duration = useMemo(
    () => (timeRange.endTime - timeRange.startTime) / (24 * 60 * 60),
    [timeRange.endTime, timeRange.startTime]
  );

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <MainContent>
        <Title>{t.leaderboard.leaderboard}</Title>
        <FilterContainer>
          <FilterOptionContainer>
            <FilterLabelContainer>
              <FilterLabel>Token</FilterLabel>
            </FilterLabelContainer>
            <SelectorsContainer>
              {wantTokens &&
                wantTokens.map((token) => (
                  <SelectorItem
                    key={token.tokenSymbol}
                    isActive={selectedToken?.tokenSymbol === token.tokenSymbol}
                    onClick={() => setSelectedToken(token)}
                  >
                    <TokenIcon src={token.tokenIcon} />
                    <TokenName>{token.tokenSymbol}</TokenName>
                  </SelectorItem>
                ))}
            </SelectorsContainer>
          </FilterOptionContainer>
          <FilterOptionContainer>
            <FilterLabelContainer>
              <FilterLabel>Time Range</FilterLabel>
            </FilterLabelContainer>
            <SelectorsContainer>
              {startTime && endTime ? (
                <SelectorItem isActive={true}>
                  <TimeLabel onClick={() => {}}>{`Custom: ${getDateString(
                    new Date(timeRange.startTime * 1000)
                  )} - ${getDateString(
                    new Date(timeRange.endTime * 1000)
                  )}`}</TimeLabel>
                </SelectorItem>
              ) : (
                <>
                  <SelectorItem isActive={duration === 0}>
                    <TimeLabel onClick={() => handleFullTimeRangeOnClick()}>
                      ALL
                    </TimeLabel>
                  </SelectorItem>
                  <SelectorItem isActive={duration === 7}>
                    <TimeLabel onClick={() => handlePastDaysOnClick(7)}>
                      Past 7 Days
                    </TimeLabel>
                  </SelectorItem>
                  <SelectorItem isActive={duration === 30}>
                    <TimeLabel onClick={() => handlePastDaysOnClick(30)}>
                      Past 30 Days
                    </TimeLabel>
                  </SelectorItem>
                </>
              )}
            </SelectorsContainer>
          </FilterOptionContainer>
        </FilterContainer>
        {selectedToken?.tokenSymbol && (
          <LeaderboardsContainer>
            <div>
              {/* Top Users By Realized Profit */}
              <Leaderboard
                subtitle={t.leaderboard.profitLeaderboard}
                leftHeader={t.leaderboard.user}
                rightHeader={t.leaderboard.realizedProfit}
                topItems={topUsersByPnL}
                unit={selectedToken.tokenSymbol}
                type={undefined}
                showRank={true}
                decimal={selectedToken.tokenDecimal}
                isFetched={topUserByPnLIsFetched}
              />
            </div>
            <LeaderboardRow>
              {/* Top Opened Positions By Earned Fee Amount */}
              <Leaderboard
                subtitle={`${t.leaderboard.topOpenBy} ${selectedToken.tokenSymbol}`}
                leftHeader={t.leaderboard.positionId}
                rightHeader={t.leaderboard.earnedFee}
                topItems={topOpenedByEarnedFee}
                unit={selectedToken.tokenSymbol}
                type={"open"}
                showRank={true}
                decimal={selectedToken.tokenDecimal}
                isFetched={topOpenedByEarnedFeeIsFetched}
              />
              {/* Top Opened Positions By APR % */}
              <Leaderboard
                subtitle={t.leaderboard.topOpenByApr}
                leftHeader={t.leaderboard.positionId}
                rightHeader={t.leaderboard.feeApr}
                topItems={topOpenedByApr}
                unit={"%"}
                type={"open"}
                showRank={true}
                decimal={selectedToken.tokenDecimal}
                isFetched={topOpenedByAprIsFetched}
              />
            </LeaderboardRow>
            <LeaderboardRow>
              {/* Top Closed Positions By PnL Amount */}
              <Leaderboard
                subtitle={`${t.leaderboard.topCloseBy} ${selectedToken.tokenSymbol}`}
                leftHeader={t.leaderboard.positionId}
                rightHeader={t.leaderboard.pnl}
                topItems={topClosedByPnL}
                unit={selectedToken.tokenSymbol}
                type={"closed"}
                showRank={true}
                decimal={selectedToken.tokenDecimal}
                isFetched={topClosedByPnLIsFetched}
              />
              {/* Top Closed Positions By PnL % */}
              <Leaderboard
                subtitle={t.leaderboard.topCloseByPnlPercentage}
                leftHeader={t.leaderboard.positionId}
                rightHeader={t.leaderboard.pnlPercentage}
                topItems={topClosedByPercentage}
                unit={"%"}
                type={"closed"}
                showRank={true}
                decimal={selectedToken.tokenDecimal}
                isFetched={topClosedByPercentageIsFetched}
              />
            </LeaderboardRow>
          </LeaderboardsContainer>
        )}
      </MainContent>
    </Container>
  );
};

export default LeaderboardPage;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: row;
  padding: 60px 0 160px 0;
  @media (max-width: 576px) {
    align-items: flex-start;
    padding: 60px 0 120px 0;
  }
`;

const Title = styled(H1)`
  font-size: 36px;
  line-height: 44px;
  text-align: left;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
  @media (max-width: 576px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1020px;
  margin: auto;
  @media (max-width: 1200px) {
    padding: 0 24px;
  }
`;

const LeaderboardsContainer = styled.div`
  width: 100%;
`;

const LeaderboardRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  @media (max-width: 992px) {
    display: block;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
  background-color: ${Colors.backgroundGray4};
  padding: 20px;
  border-radius: 8px;
`;

const FilterOptionContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;

const SelectorItem = styled.div<{ isActive: boolean }>`
  min-height: 32px;
  padding: 6px 12px;
  border-radius: 100px;
  color: ${Colors.ivory};
  border: 1px solid
    ${({ isActive }) => (isActive ? Colors.lightKhaki : Colors.gray6)};
  background-color: ${Colors.backgroundGray4};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: transform 0.1s ease-in-out;
  :hover {
    transform: scale(1.05);
  }
  @media (max-width: 576px) {
    padding: 4px 8px;
    min-height: 28px;
  }
`;

const TokenIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const TokenName = styled.span`
  font-weight: 700;
  font-size: 14px;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const TimeLabel = styled.span`
  font-size: 14px;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const FilterLabelContainer = styled.div`
  min-width: 100px;
  min-height: 20px;
  border-right: 1px solid ${Colors.gray4};
  margin-right: 12px;
  display: flex;
  align-items: center;
  @media (max-width: 576px) {
    min-width: 80px;
    border-right: none;
  }
`;

const FilterLabel = styled.span`
  font-size: 14px;
  color: ${Colors.ivory};
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const SelectorsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`;
