import { createSlice } from "@reduxjs/toolkit";

type AutoVaultProps = {
  userRecords: { amount: number; timestamp: number; event: string }[];
  userRecordsIsFetched: boolean;
  pastPositions: [];
  roundSharePrices: { round: number; sharePrice: number }[];
};

const initialState: AutoVaultProps = {
  userRecords: [],
  userRecordsIsFetched: false,
  pastPositions: [],
  roundSharePrices: [],
};

const autoVaultSlice = createSlice({
  name: "autoVault",
  initialState: initialState,
  reducers: {
    setUserRecords(state, action) {
      state.userRecords = action.payload;
    },
    setUserRecordsIsFetched(state, action) {
      state.userRecordsIsFetched = action.payload;
    },
    clearUserRecords(state) {
      state.userRecords = [];
    },
    setPastPositions(state, action) {
      state.pastPositions = action.payload;
    },
    setRoundSharePrices(state, action) {
      state.roundSharePrices = action.payload;
    },
  },
});

export const {
  setUserRecords,
  setUserRecordsIsFetched,
  clearUserRecords,
  setPastPositions,
  setRoundSharePrices,
} = autoVaultSlice.actions;

export default autoVaultSlice.reducer;
