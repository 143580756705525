import React from "react";
import styled from "styled-components";

const PairTokenIcon: React.FC<{
  borrowTokenIcon: string;
  wantTokenIcon: string;
  size?: "lg" | "sm";
}> = ({ borrowTokenIcon, wantTokenIcon, size }) => {
  const height = size === "lg" ? 28 : 22;

  return (
    <PairIconsContainer>
      <PairIcon src={borrowTokenIcon} height={height} />
      <PairIcon src={wantTokenIcon} height={height} />
    </PairIconsContainer>
  );
};

export default PairTokenIcon;

const PairIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  img:first-child {
    margin-right: -4px;
  }
`;

const PairIcon = styled.img<{ height: number }>`
  height: ${(props) => props.height}px;

  @media (max-width: 768px) {
    height: ${(props) => props.height - 2}px;
  }
`;
