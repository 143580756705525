import { AnyAction, configureStore, ThunkAction } from "@reduxjs/toolkit";
import accountReducer from "./accountSlice";
import modalReducer from "./modalSlice";
import factoryReducer from "./factorySlice";
import autoVaultReducer from "./autoVaultSlice";
import evieVaultReducer from "./evieVaultSlice";

const isProduction = process.env.NODE_ENV === "production";

export const store = configureStore({
  reducer: {
    account: accountReducer,
    modal: modalReducer,
    factory: factoryReducer,
    autoVault: autoVaultReducer,
    evieVault: evieVaultReducer,
  },
  devTools: !isProduction,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
