import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import Button from "../../../components/Button";
import { H4 } from "../../../styles/Fonts";
import CustomModal from "../../../components/Modal";
import { PoolProps } from "../../../utils/constant/pools";
import { useWeb3React } from "@web3-react/core";
import { withdrawTokenFromPool } from "../../../utils/api/lendingPoolApi";
import AmountInput from "../../../components/AmountInput";
import t from "../../../utils/content";
import { showErrorAlert } from "../../../utils/showAlert";
import useTrackEvent from "../../../hooks/useTrackEvent";

type Props = {
  isShowModal: boolean;
  onHideModal: () => void;
  pool: PoolProps;
  updatePoolState: () => void;
  updateUserSupply: () => void;
  balance: string;
  setIsWithdrawing: (v: boolean) => void;
  updateTokenBalance: () => void;
  stakedAmount: string;
  lpTokenSymbol: string;
};

const WithdrawModal: React.FC<Props> = (props) => {
  const {
    isShowModal,
    onHideModal,
    pool,
    updatePoolState,
    updateUserSupply,
    balance,
    setIsWithdrawing,
    updateTokenBalance,
    stakedAmount,
    lpTokenSymbol,
  } = props;
  const { lendingPoolAddress: poolAddress, tokenSymbol, tokenDecimal } = pool;

  const [ethAmount, setEthAmount] = useState<string>("0");

  const { account, chainId, provider } = useWeb3React();
  const trackEvent = useTrackEvent();

  const handleWithdraw = async (address: string, amount: string) => {
    if (!chainId || !provider) {
      return;
    }

    if (!isNaN(parseFloat(amount)) && parseFloat(amount) <= 0) {
      showErrorAlert(t.error.inputWithdrawAmount);
      return;
    }

    onHideModal();
    setIsWithdrawing(true);

    trackEvent(`${t.amp.clickWithdrawBtn} | ${t.amp.poolPage}`, {
      amount: amount,
      poolAddress: poolAddress,
      token: tokenSymbol,
    });

    try {
      await withdrawTokenFromPool(
        address,
        poolAddress,
        tokenDecimal,
        tokenSymbol,
        amount,
        provider,
        (txHash: string) => {
          trackEvent(`${t.amp.withdrawToken} | ${t.amp.poolPage}`, {
            txHash: txHash,
            amount: amount,
            poolAddress: poolAddress,
            token: tokenSymbol,
          });
        }
      );
      updatePoolState();
      updateUserSupply();
      updateTokenBalance();
    } catch (e) {
      // console.log(e);
    }

    setIsWithdrawing(false);
    setEthAmount("0");
  };

  return (
    <CustomModal
      show={isShowModal}
      onHide={onHideModal}
      title={t.pool.withdraw}
    >
      <AmountInput
        setAmount={setEthAmount}
        tokenSymbol={tokenSymbol}
        tokenDecimal={tokenDecimal}
        showMaxBtn={true}
        maxAmount={balance.replace(/,/g, '')}
      />
      <InfoContainer>
        <BalanceRow>
          <Label>{t.pool.poolBalance}</Label>
          <Value>
            {balance || "-"}
            {` ${tokenSymbol}`}
          </Value>
        </BalanceRow>
        {+stakedAmount > 0 && (
          <BalanceRow>
            <Label>{t.pool.stakedAsset}</Label>
            <Value>
              {stakedAmount || "-"}
              {` ${lpTokenSymbol}`}
            </Value>
          </BalanceRow>
        )}
      </InfoContainer>
      <BtnContainer>
        <Button
          btnType="primary"
          btnText={t.pool.withdraw}
          onClick={() => (account ? handleWithdraw(account, ethAmount) : null)}
          disabled={!ethAmount}
          isLoading={false}
        />
      </BtnContainer>
    </CustomModal>
  );
};

export default WithdrawModal;

const InfoContainer = styled.div`
  margin-top: 20px;
`;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const Value = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
  color: ${Colors.ivory};
  text-align: right;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12px;
`;

const BtnContainer = styled.div`
  margin-top: 20px;
  button {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;
