import { createSlice } from "@reduxjs/toolkit";
import { evieVaultHistoricalResult } from "../utils/constant/evieVault";

type EvieVaultProps = {
  sharePrice: number;
  pastPositions: [];
  pastRoundSharePrices: [];
  historicalResult: any[];
};

const initialState: EvieVaultProps = {
  sharePrice: 0,
  pastPositions: [],
  pastRoundSharePrices: [],
  historicalResult: evieVaultHistoricalResult,
};

const autoVaultSlice = createSlice({
  name: "evieVault",
  initialState: initialState,
  reducers: {
    setSharePrice(state, action) {
      state.sharePrice = action.payload;
    },
    setPastPositions(state, action) {
      state.pastPositions = action.payload;
    },
    setPastRoundSharePrices(state, action) {
      state.pastRoundSharePrices = action.payload;
    }
  },
  
});

export const {
  setSharePrice,
  setPastPositions,
  setPastRoundSharePrices
} = autoVaultSlice.actions;

export default autoVaultSlice.reducer;
