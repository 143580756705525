import {
  diamondArbitrumAddresses as arbDmo,
  diamondGoerliAddresses as goerDmo,
  diamondPolygonMumbaiAddresses as polygonMumbaiDmo,
  diamondOptimismGoerliAddresses as optimismGoerliDmo,
  diamondPolygonAddresses as polygonDmo,
  diamondBnbChainAddresses as bnbDmo,
  diamondBnbTestnetAddresses as bnbTestnetDmo,
} from "./internalAddresses";
import {
  wethToken,
  usdcToken,
  uniToken,
  daiToken,
  gmxToken,
  maticToken,
  derc20Token,
  dusdcoken,
  arbToken,
  usdtToken,
  busdToken,
  cake2Token,
  cakeToken,
  wbnbToken,
  ldoToken
} from "./tokens";
import { ALL_UNI_POOLS, ALL_TOKENS } from "./externalAddresses";
import { SupportedChainId } from "./chains";

/*
  Slippage Setting (fee tier -> slippage)
  0.05% -> 0.5%; 0.3% -> 1%; 1% -> 2%
**/

const ARBITRUM_PAIRS = [
  {
    label: "WETH/USDC",
    rate: 0.05,
    pairId: "WETH-USDC-500",
    uniPairId: "UNISWAP3ARBITRUM:WETHUSDC",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.ARBITRUM_ONE].WETH_USDC_500,
    factoryAddress: arbDmo.factories.WETH_USDC,
    lendingPoolAddress: arbDmo.lendingPools.WETH,
    wantToken: {
      ...usdcToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].USDC,
    },
    borrowToken: {
      ...wethToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].WETH,
    },
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
    defaultSlippage: 0.5,
    wantTokenIsToken0: true,
    defaultRange: 15,
    liteModeRanges: [
      { range: 8, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.05 },
      { range: 25, exitPriceRange: 0.08 },
    ],
  },
  {
    label: "USDC/WETH",
    rate: 0.05,
    pairId: "USDC-WETH-500",
    uniPairId: "UNISWAP3ARBITRUM:USDCWETH",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.ARBITRUM_ONE].WETH_USDC_500,
    factoryAddress: arbDmo.factories.USDC_WETH_500,
    lendingPoolAddress: arbDmo.lendingPools.USDC,
    wantToken: {
      ...wethToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].WETH,
    },
    borrowToken: {
      ...usdcToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].USDC,
    },
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
    defaultSlippage: 0.5,
    wantTokenIsToken0: false,
    defaultRange: 15,
    liteModeRanges: [
      { range: 8, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.05 },
      { range: 25, exitPriceRange: 0.08 },
    ],
  },
  {
    label: "WETH/GMX",
    rate: 1,
    pairId: "WETH-GMX-10000",
    uniPairId: "UNISWAP3ARBITRUM:WETHGMX",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.ARBITRUM_ONE].WETH_GMX_10000,
    factoryAddress: arbDmo.factories.WETH_GMX,
    lendingPoolAddress: arbDmo.lendingPools.WETH,
    wantToken: {
      ...gmxToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].GMX,
    },
    borrowToken: {
      ...wethToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].WETH,
    },
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
    defaultSlippage: 2,
    wantTokenIsToken0: true,
    defaultRange: 20,
    liteModeRanges: [
      { range: 10, exitPriceRange: 0.03 },
      { range: 18, exitPriceRange: 0.07 },
      { range: 28, exitPriceRange: 0.09 },
    ],
  },
  {
    label: "ARB/USDC",
    rate: 0.05,
    pairId: "ARB-USDC-500",
    uniPairId: "COINBASE:ARBUSD",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.ARBITRUM_ONE].ARB_USDC_500,
    factoryAddress: arbDmo.factories.ARB_USDC_500,
    lendingPoolAddress: arbDmo.lendingPools.ARB,
    wantToken: {
      ...usdcToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].USDC,
    },
    borrowToken: {
      ...arbToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].ARB,
    },
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
    defaultSlippage: 0.5,
    wantTokenIsToken0: true,
    defaultRange: 15,
    liteModeRanges: [
      { range: 8, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.05 },
      { range: 25, exitPriceRange: 0.08 },
    ],
  },
  {
    label: "ARB/USDC",
    rate: 0.3,
    pairId: "ARB-USDC-3000",
    uniPairId: "COINBASE:ARBUSD",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.ARBITRUM_ONE].ARB_USDC_3000,
    factoryAddress: arbDmo.factories.ARB_USDC_3000,
    lendingPoolAddress: arbDmo.lendingPools.ARB,
    wantToken: {
      ...usdcToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].USDC,
    },
    borrowToken: {
      ...arbToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].ARB,
    },
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
    defaultSlippage: 1,
    wantTokenIsToken0: true,
    defaultRange: 15,
    liteModeRanges: [
      { range: 9, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.07 },
      { range: 24, exitPriceRange: 0.09 },
    ],
  },
  {
    label: "USDC/ARB",
    rate: 0.3,
    pairId: "USDC-ARB-3000",
    uniPairId: "1/COINBASE:ARBUSD",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.ARBITRUM_ONE].USDC_ARB_3000,
    factoryAddress: arbDmo.factories.USDC_ARB_3000,
    lendingPoolAddress: arbDmo.lendingPools.USDC,
    wantToken: {
      ...arbToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].ARB,
    },
    borrowToken: {
      ...usdcToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].USDC,
    },
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
    defaultSlippage: 1,
    wantTokenIsToken0: false,
    defaultRange: 15,
    liteModeRanges: [
      { range: 9, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.07 },
      { range: 24, exitPriceRange: 0.09 },
    ],
  },
  // {
  //   label: "ARB/USDC",
  //   rate: 1,
  //   pairId: "ARB-USDC-10000",
  //   uniPairId: "COINBASE:ARBUSD",
  //   uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.ARBITRUM_ONE].ARB_USDC_10000,
  //   factoryAddress: arbDmo.factories.ARB_USDC_10000,
  //   lendingPoolAddress: arbDmo.lendingPools.ARB,
  //   wantToken: {
  //     ...usdcToken,
  //     tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].USDC,
  //   },
  //   borrowToken: {
  //     ...arbToken,
  //     tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].ARB,
  //   },
  //   deployedChainId: SupportedChainId.ARBITRUM_ONE,
  //   defaultSlippage: 2,
  //   wantTokenIsToken0: true,
  //   defaultRange: 20,
  //   liteModeRanges: [
  //     { range: 10, exitPriceRange: 0.03 },
  //     { range: 18, exitPriceRange: 0.07 },
  //     { range: 28, exitPriceRange: 0.09 },
  //   ],
  // },
  {
    label: "WETH/ARB",
    rate: 0.05,
    pairId: "WETH-ARB-500",
    uniPairId: "UNISWAP3ARBITRUM:WETHARB",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.ARBITRUM_ONE].WETH_ARB_500,
    factoryAddress: arbDmo.factories.WETH_ARB_500,
    lendingPoolAddress: arbDmo.lendingPools.WETH,
    wantToken: {
      ...arbToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].ARB,
    },
    borrowToken: {
      ...wethToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].WETH,
    },
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
    defaultSlippage: 0.5,
    wantTokenIsToken0: true,
    defaultRange: 15,
    liteModeRanges: [
      { range: 8, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.05 },
      { range: 25, exitPriceRange: 0.08 },
    ],
  },
  {
    label: "WETH/ARB",
    rate: 0.3,
    pairId: "WETH-ARB-3000",
    uniPairId: "UNISWAP3ARBITRUM:WETHARB",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.ARBITRUM_ONE].WETH_ARB_3000,
    factoryAddress: arbDmo.factories.WETH_ARB_3000,
    lendingPoolAddress: arbDmo.lendingPools.WETH,
    wantToken: {
      ...arbToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].ARB,
    },
    borrowToken: {
      ...wethToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].WETH,
    },
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
    defaultSlippage: 1,
    wantTokenIsToken0: true,
    defaultRange: 15,
    liteModeRanges: [
      { range: 9, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.07 },
      { range: 24, exitPriceRange: 0.09 },
    ],
  },
  {
    label: "WETH/LDO",
    rate: 1,
    pairId: "WETH-LDO-10000",
    uniPairId: "UNISWAP3ARBITRUM:WETHLDO",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.ARBITRUM_ONE].WETH_LDO_10000,
    factoryAddress: arbDmo.factories.WETH_LDO_10000,
    lendingPoolAddress: arbDmo.lendingPools.WETH,
    wantToken: {
      ...ldoToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].LDO,
    },
    borrowToken: {
      ...wethToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].WETH,
    },
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
    defaultSlippage: 2,
    wantTokenIsToken0: false,
    defaultRange: 20,
    liteModeRanges: [
      { range: 10, exitPriceRange: 0.03 },
      { range: 18, exitPriceRange: 0.07 },
      { range: 28, exitPriceRange: 0.09 },
    ],
  },
  {
    label: "WETH/LDO",
    rate: 0.3,
    pairId: "WETH-LDO-3000",
    uniPairId: "UNISWAP3ARBITRUM:WETHLDO",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.ARBITRUM_ONE].WETH_LDO_3000,
    factoryAddress: arbDmo.factories.WETH_LDO_3000,
    lendingPoolAddress: arbDmo.lendingPools.WETH,
    wantToken: {
      ...ldoToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].LDO,
    },
    borrowToken: {
      ...wethToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].WETH,
    },
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
    defaultSlippage: 1,
    wantTokenIsToken0: false,
    defaultRange: 15,
    liteModeRanges: [
      { range: 9, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.07 },
      { range: 24, exitPriceRange: 0.09 },
    ],
  },
];

const GOERLI_PAIRS = [
  {
    label: "WETH/UNI",
    rate: 0.05,
    pairId: "WETH-UNI-500",
    uniPairId: "UNISWAP3ETH:WETHUNI",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.GOERLI].WETH_UNI,
    factoryAddress: goerDmo.factories.WETH_UNI,
    lendingPoolAddress: goerDmo.lendingPools.WETH,
    wantToken: {
      ...uniToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.GOERLI].UNI,
    },
    borrowToken: {
      ...wethToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.GOERLI].WETH,
    },
    deployedChainId: SupportedChainId.GOERLI,
    defaultSlippage: 0.5,
    wantTokenIsToken0: true,
    defaultRange: 15,
    liteModeRanges: [
      { range: 8, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.05 },
      { range: 25, exitPriceRange: 0.08 },
    ],
  },
  {
    label: "WETH/DAI",
    rate: 0.05,
    pairId: "WETH-DAI-500",
    uniPairId: "UNISWAP3ETH:WETHDAI",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.GOERLI].WETH_DAI,
    factoryAddress: goerDmo.factories.WETH_DAI,
    lendingPoolAddress: goerDmo.lendingPools.WETH,
    wantToken: {
      ...daiToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.GOERLI].DAI,
    },
    borrowToken: {
      ...wethToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.GOERLI].WETH,
    },
    deployedChainId: SupportedChainId.GOERLI,
    defaultSlippage: 0.5,
    wantTokenIsToken0: true,
    defaultRange: 15,
    liteModeRanges: [
      { range: 8, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.05 },
      { range: 25, exitPriceRange: 0.08 },
    ],
  },
  {
    label: "UNI/WETH",
    rate: 0.05,
    pairId: "UNI-WETH-500",
    uniPairId: "UNISWAP3ETH:WETHUNI",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.GOERLI].WETH_UNI,
    factoryAddress: goerDmo.factories.UNI_WETH,
    lendingPoolAddress: goerDmo.lendingPools.UNI,
    wantToken: {
      ...wethToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.GOERLI].WETH,
    },
    borrowToken: {
      ...uniToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.GOERLI].UNI,
    },
    deployedChainId: SupportedChainId.GOERLI,
    defaultSlippage: 0.5,
    wantTokenIsToken0: false,
    defaultRange: 15,
    liteModeRanges: [
      { range: 8, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.05 },
      { range: 25, exitPriceRange: 0.08 },
    ],
  },
];

const POLYGON_MUMBAI_PAIRS = [
  {
    label: "WMATIC/DERC20",
    rate: 0.05,
    pairId: "WMATIC-DERC20-500",
    uniPairId: "UNISWAP3POLYGON:WMATICUSDC",
    uniPoolAddress:
      ALL_UNI_POOLS[SupportedChainId.POLYGON_MUMBAI].WMATIC_DERC20_500,
    factoryAddress: polygonMumbaiDmo.factories.WMATIC_DERC20,
    lendingPoolAddress: polygonMumbaiDmo.lendingPools.WMATIC,
    wantToken: {
      ...derc20Token,
      tokenAddress: ALL_TOKENS[SupportedChainId.POLYGON_MUMBAI].DERC20,
    },
    borrowToken: {
      ...maticToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.POLYGON_MUMBAI].WMATIC,
    },
    deployedChainId: SupportedChainId.POLYGON_MUMBAI,
    defaultSlippage: 0.5,
    wantTokenIsToken0: true,
    defaultRange: 15,
    liteModeRanges: [
      { range: 8, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.05 },
      { range: 25, exitPriceRange: 0.08 },
    ],
  },
];

const OPTIMISM_GOERLI_PAIRS = [
  {
    label: "WETH/DUSDC",
    rate: 0.05,
    pairId: "WETH-DUSDC-500",
    uniPairId: "UNISWAP3ARBITRUM:WETHUSDC",
    uniPoolAddress:
      ALL_UNI_POOLS[SupportedChainId.OPTIMISM_GOERLI].WETH_DUSDC_500,
    factoryAddress: optimismGoerliDmo.factories.WETH_DUSDC,
    lendingPoolAddress: optimismGoerliDmo.lendingPools.WETH,
    wantToken: {
      ...dusdcoken,
      tokenAddress: ALL_TOKENS[SupportedChainId.OPTIMISM_GOERLI].DUSDC,
    },
    borrowToken: {
      ...wethToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.OPTIMISM_GOERLI].WETH,
    },
    deployedChainId: SupportedChainId.OPTIMISM_GOERLI,
    defaultSlippage: 0.5,
    wantTokenIsToken0: true,
    defaultRange: 15,
    liteModeRanges: [
      { range: 8, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.05 },
      { range: 25, exitPriceRange: 0.08 },
    ],
  },
];

const POLYGON_PAIRS = [
  {
    label: "WETH/USDC",
    rate: 0.05,
    pairId: "WETH-USDC-500",
    uniPairId: "UNISWAP3POLYGON:WETHUSDC",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.POLYGON].WETH_USDC_500,
    factoryAddress: polygonDmo.factories.WETH_USDC,
    lendingPoolAddress: polygonDmo.lendingPools.WETH,
    wantToken: {
      ...usdcToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.POLYGON].USDC,
    },
    borrowToken: {
      ...wethToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.POLYGON].WETH,
    },
    deployedChainId: SupportedChainId.POLYGON,
    defaultSlippage: 0.5,
    wantTokenIsToken0: false,
    defaultRange: 15,
    liteModeRanges: [
      { range: 8, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.05 },
      { range: 25, exitPriceRange: 0.08 },
    ],
  },
  {
    label: "MATIC/WETH",
    rate: 0.05,
    pairId: "MATIC-WETH-500",
    uniPairId: "UNISWAP3POLYGON:WMATICWETH",
    uniPoolAddress: ALL_UNI_POOLS[SupportedChainId.POLYGON].MATIC_WETH_500,
    factoryAddress: polygonDmo.factories.MATIC_WETH,
    lendingPoolAddress: polygonDmo.lendingPools.WMATIC,
    wantToken: {
      ...wethToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.POLYGON].WETH,
    },
    borrowToken: {
      ...maticToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.POLYGON].WMATIC,
    },
    deployedChainId: SupportedChainId.POLYGON,
    defaultSlippage: 0.5,
    wantTokenIsToken0: true,
    defaultRange: 15,
    liteModeRanges: [
      { range: 10, exitPriceRange: 0.035 },
      { range: 20, exitPriceRange: 0.07 },
      { range: 30, exitPriceRange: 0.09 },
    ],
  },
];

export const BNB_PAIRS = [
  {
    label: "CAKE/USDT",
    rate: 0.25,
    pairId: "CAKE-USDT-2500",
    uniPairId: "PANCAKESWAP:CAKEUSDT_A39AF1",
    uniPoolAddress:
      ALL_UNI_POOLS[SupportedChainId.BINANCE_SMART_CHAIN].CAKE_USDT_2500,
    factoryAddress: bnbDmo.factories.CAKE_USDT_2500,
    lendingPoolAddress: bnbDmo.lendingPools.CAKE,
    wantToken: {
      ...usdtToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_SMART_CHAIN].USDT,
    },
    borrowToken: {
      ...cakeToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_SMART_CHAIN].CAKE,
    },
    deployedChainId: SupportedChainId.BINANCE_SMART_CHAIN,
    defaultSlippage: 0.5,
    wantTokenIsToken0: true,
    defaultRange: 15,
    liteModeRanges: [
      { range: 8, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.05 },
      { range: 25, exitPriceRange: 0.08 },
    ],
  },
  {
    label: "CAKE/WBNB",
    rate: 0.25,
    pairId: "CAKE-BNB-2500",
    uniPairId: "BINANCE:CAKEBNB",
    uniPoolAddress:
      ALL_UNI_POOLS[SupportedChainId.BINANCE_SMART_CHAIN].CAKE_WBNB_2500,
    factoryAddress: bnbDmo.factories.CAKE_WBNB_2500,
    lendingPoolAddress: bnbDmo.lendingPools.CAKE,
    wantToken: {
      ...wbnbToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_SMART_CHAIN].WBNB,
    },
    borrowToken: {
      ...cakeToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_SMART_CHAIN].CAKE,
    },
    deployedChainId: SupportedChainId.BINANCE_SMART_CHAIN,
    defaultSlippage: 0.5,
    wantTokenIsToken0: true,
    defaultRange: 15,
    liteModeRanges: [
      { range: 8, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.05 },
      { range: 25, exitPriceRange: 0.08 },
    ],
  },
];

// Need Update
export const BNB_TESTNET_PAIRS = [
  {
    label: "CAKE2/BUSD",
    rate: 0.25,
    pairId: "CAKE2-BUSD-2500",
    uniPairId: "UNISWAP3POLYGON:WETHUSDC",
    uniPoolAddress:
      ALL_UNI_POOLS[SupportedChainId.BINANCE_TESTNET].CAKE2_BUSD_2500,
    factoryAddress: bnbTestnetDmo.factories.CAKE2_BUSD,
    lendingPoolAddress: bnbTestnetDmo.lendingPools.CAKE2,
    wantToken: {
      ...busdToken,
      tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_TESTNET].BUSD,
    },
    borrowToken: {
      ...cake2Token,
      tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_TESTNET].CAKE2,
    },
    deployedChainId: SupportedChainId.BINANCE_TESTNET,
    defaultSlippage: 0.5,
    wantTokenIsToken0: true,
    defaultRange: 15,
    liteModeRanges: [
      { range: 8, exitPriceRange: 0.03 },
      { range: 15, exitPriceRange: 0.05 },
      { range: 25, exitPriceRange: 0.08 },
    ],
  },
];

export const ALL_PAIRS: { [key: number]: PairProps[] } = {
  [SupportedChainId.ARBITRUM_ONE]: ARBITRUM_PAIRS,
  [SupportedChainId.GOERLI]: GOERLI_PAIRS,
  [SupportedChainId.POLYGON_MUMBAI]: POLYGON_MUMBAI_PAIRS,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_PAIRS,
  [SupportedChainId.POLYGON]: POLYGON_PAIRS,
  [SupportedChainId.BINANCE_SMART_CHAIN]: BNB_PAIRS,
  [SupportedChainId.BINANCE_TESTNET]: BNB_TESTNET_PAIRS,
};

export type PairProps = {
  label: string;
  rate: number;
  pairId: string;
  uniPairId: string;
  uniPoolAddress: string;
  factoryAddress: string;
  lendingPoolAddress: string;
  wantToken: {
    tokenSymbol: string;
    tokenFullName: string;
    tokenDecimal: number;
    tokenAddress: string;
    tokenIcon: string;
  };
  borrowToken: {
    tokenSymbol: string;
    tokenFullName: string;
    tokenDecimal: number;
    tokenAddress: string;
    tokenIcon: string;
  };
  deployedChainId: number;
  defaultSlippage: number;
  wantTokenIsToken0: boolean;
  defaultRange: number;
  liteModeRanges: { range: number; exitPriceRange: number }[];
};

export const defaultPair: PairProps = {
  label: "",
  rate: 0,
  pairId: "",
  uniPairId: "",
  uniPoolAddress: "",
  factoryAddress: "",
  lendingPoolAddress: "",
  wantToken: {
    tokenSymbol: "",
    tokenFullName: "",
    tokenDecimal: 0,
    tokenAddress: "",
    tokenIcon: "",
  },
  borrowToken: {
    tokenSymbol: "",
    tokenFullName: "",
    tokenDecimal: 0,
    tokenAddress: "",
    tokenIcon: "",
  },
  deployedChainId: 0,
  defaultSlippage: 0.5,
  wantTokenIsToken0: false,
  defaultRange: 0,
  liteModeRanges: [],
};
