import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import SettingColumn from "./SettingColumn";
import YourBot from "./YourUnibot";
import TourHint from "../../components/TourHint";
import MarketColumn from "./MarketColumn";
import FactoryAccount from "./FactoryAccount";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PairSection from "./PairSection";
import { motion } from "framer-motion";
import { useWeb3React } from "@web3-react/core";
import useAccountVaultBalance from "../../hooks/useAccountVaultBalance";
import useUniswapPoolState from "../../hooks/useUniswapPoolState";
import useLiquidityPoolApr from "../../hooks/useLiquidityPoolApr";
import {
  CHAIN_IDS_TO_URL_IDS,
  CHAIN_URL_IDS_TO_IDS,
  isSupportedChain,
  SupportedChainId,
} from "../../utils/constant/chains";
import { ALL_PAIRS, defaultPair, PairProps } from "../../utils/constant/pairs";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { updateSelectedChainId } from "../../redux/accountSlice";
import {
  clearUserRecords,
  getUserActivePositions,
  getUserClosedPositions,
  getUserPositionEntryTicks,
  setUniPoolData,
} from "../../redux/factoryAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import t from "../../utils/content";
import { DISCORD_URL } from "../../utils/constant/urls";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const FactoryPage: React.FC = () => {
  const { account, chainId, provider } = useWeb3React();
  const params = useParams();
  const { pairId, chainName } = params;
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const [pair, setPair] = useState<PairProps>(defaultPair);
  const [slippage, setSlippage] = useState<number>(pair.defaultSlippage);

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const {
    factoryAddress,
    lendingPoolAddress,
    wantToken,
    borrowToken,
    deployedChainId,
    uniPoolAddress,
    wantTokenIsToken0,
    uniPairId,
  } = pair;

  const { accountVaultBalance, updateAccountVaultBalance } =
    useAccountVaultBalance(
      wantToken.tokenAddress,
      wantToken.tokenDecimal,
      deployedChainId
    );
  const state = useUniswapPoolState(
    uniPoolAddress,
    borrowToken,
    wantToken,
    deployedChainId,
    wantTokenIsToken0
  );
  const { borrowTokenPrice, updatePoolStates } = state;
  const { apr: liquidityPoolApr, pcsRewardApr } =
    useLiquidityPoolApr(uniPoolAddress);

  const [marketsModalIsShow, setMarketsModalIsShow] = useState<boolean>(false);

  const navigateToDefault = useCallback(() => {
    const defaultChainId = SupportedChainId.ARBITRUM_ONE;
    const defaultChainName = CHAIN_IDS_TO_URL_IDS[defaultChainId];
    navigate(`/factory/${defaultChainName}` + search);
  }, [navigate, search]);

  useEffect(() => {
    const urlChainId = chainName ? CHAIN_URL_IDS_TO_IDS[chainName] : 0;

    // 有連接錢包
    if (account) {
      if (chainId && urlChainId !== chainId && isSupportedChain(chainId)) {
        const matchedChainName = CHAIN_IDS_TO_URL_IDS[chainId];
        navigate(`/factory/${matchedChainName}` + search);
      } else {
        const pairsUnderChainId = ALL_PAIRS[urlChainId];
        // 有指定 pairId
        const matchedPair = pairId
          ? pairsUnderChainId.find((p) => p.pairId === pairId)
          : null;
        if (matchedPair) {
          setPair(matchedPair);
        } else {
          navigate(`/factory/${chainName}` + search);
        }
      }
    } else {
      // 有指定 network
      if (!!urlChainId && isSupportedChain(urlChainId)) {
        const pairsUnderChainId = ALL_PAIRS[urlChainId];
        dispatch(updateSelectedChainId({ chainId: urlChainId }));
        // 有指定 pairId
        const matchedPair = pairId
          ? pairsUnderChainId.find((p) => p.pairId === pairId)
          : null;
        if (matchedPair) {
          setPair(matchedPair);
        } else {
          navigate(`/factory/${chainName}`);
        }
      } else if (!urlChainId || !isSupportedChain(urlChainId)) {
        navigateToDefault();
      }
    }
  }, [
    dispatch,
    account,
    chainId,
    chainName,
    pairId,
    navigate,
    navigateToDefault,
    search,
  ]);

  const renderTradingView = useMemo(() => {
    return (
      <AdvancedRealTimeChart
        symbol={uniPairId}
        theme="dark"
        hide_top_toolbar
        hide_side_toolbar
        autosize
        allow_symbol_change={false}
        interval="30"
        hide_legend
        timezone="Asia/Taipei"
        copyrightStyles={{
          parent: {
            fontSize: "12px",
          },
        }}
      />
    );
  }, [uniPairId]);

  useEffect(() => {
    setSlippage(pair.defaultSlippage);
  }, [pair.defaultSlippage]);

  const updateUserActivePositions = useCallback(async () => {
    if (
      !(
        account &&
        factoryAddress &&
        wantToken &&
        borrowToken &&
        uniPoolAddress &&
        lendingPoolAddress &&
        chainId &&
        provider &&
        isSupportedChain(chainId)
      )
    )
      return;

    if (!ALL_PAIRS[chainId].find((p) => p.factoryAddress === factoryAddress))
      return;

    dispatch(
      getUserActivePositions(
        account,
        factoryAddress,
        wantToken.tokenDecimal,
        borrowToken.tokenDecimal,
        wantToken.tokenSymbol,
        wantTokenIsToken0,
        chainId,
        provider
      )
    );
  }, [
    account,
    wantToken,
    borrowToken,
    uniPoolAddress,
    lendingPoolAddress,
    factoryAddress,
    dispatch,
    chainId,
    provider,
    wantTokenIsToken0,
  ]);

  const updateUserClosedPositions = useCallback(async () => {
    if (
      !(
        account &&
        factoryAddress &&
        uniPoolAddress &&
        wantToken &&
        borrowToken &&
        chainId &&
        provider &&
        isSupportedChain(chainId)
      )
    )
      return;

    if (
      !ALL_PAIRS[chainId].find((pair) => pair.factoryAddress === factoryAddress)
    )
      return;

    dispatch(
      getUserClosedPositions(
        account,
        factoryAddress,
        lendingPoolAddress,
        wantTokenIsToken0,
        wantToken.tokenDecimal,
        borrowToken.tokenDecimal,
        chainId,
        provider
      )
    );
  }, [
    account,
    wantToken,
    borrowToken,
    uniPoolAddress,
    factoryAddress,
    lendingPoolAddress,
    chainId,
    provider,
    dispatch,
    wantTokenIsToken0,
  ]);

  useEffect(() => {
    if (account) {
      updateUserActivePositions();
    }
  }, [account, updateUserActivePositions]);

  useEffect(() => {
    if (account) {
      updateUserClosedPositions();
      if (chainId && factoryAddress) {
        dispatch(
          getUserPositionEntryTicks(
            account,
            factoryAddress,
            chainId,
            wantTokenIsToken0
          )
        );
      }
    }
  }, [
    account,
    updateUserClosedPositions,
    dispatch,
    chainId,
    wantTokenIsToken0,
    factoryAddress,
  ]);

  useEffect(() => {
    if (!account) {
      dispatch(clearUserRecords());
    }
  }, [account, dispatch]);

  // Get Uniswap Pool Tvl and 24H Fee & Volume
  useEffect(() => {
    if (!uniPoolAddress || !deployedChainId) return;

    dispatch(
      setUniPoolData(
        uniPoolAddress,
        deployedChainId,
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    uniPoolAddress,
    borrowToken,
    wantToken,
    deployedChainId,
    // library,
    borrowToken.tokenAddress,
    borrowToken.tokenSymbol,
    borrowToken.tokenDecimal,
    wantToken.tokenAddress,
    wantToken.tokenSymbol,
    wantToken.tokenDecimal,
  ]);

  // Update Token Prices every 10 sec
  useEffect(() => {
    let interval = setInterval(() => {
      updatePoolStates();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [updatePoolStates]);

  useEffect(() => {
    if (marketsModalIsShow) {
      disableBodyScroll(ref.current);
    } else {
      enableBodyScroll(ref.current);
    }
  }, [marketsModalIsShow]);

  return (
    <Container ref={ref}>
      <MainContainer>
        <MarketsContainer>
          <MarketColumn
            isOpen={marketsModalIsShow}
            setIsOpen={setMarketsModalIsShow}
          />
        </MarketsContainer>
        <RowContainer
          key={pairId}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <MiddleContainer>
            <PairSection
              pair={pair}
              openModal={() => setMarketsModalIsShow(true)}
              liquidityPoolApr={liquidityPoolApr || 0}
              pcsRewardApr={pcsRewardApr}
              tokenPrice={borrowTokenPrice}
              setSlippage={setSlippage}
              slippage={slippage}
              defaultSlippage={pair.defaultSlippage}
            />
            <ChartContainer>{renderTradingView}</ChartContainer>
            <GrayLgContainer className="fac-s-3">
              <YourBot
                pair={pair}
                vaultBalance={accountVaultBalance}
                updateVaultBalance={updateAccountVaultBalance}
                uniPoolState={state}
                slippage={slippage}
                updateUserActivePositions={updateUserActivePositions}
                updateUserClosedPositions={updateUserClosedPositions}
              />
            </GrayLgContainer>
          </MiddleContainer>
          <RightContainer>
            <HintContainer>
              <FontAwesomeIcon
                icon={faLightbulb}
                color={Colors.lightKhaki}
                fontSize={18}
              />
              {selectedChainId === SupportedChainId.POLYGON ? (
                <HintText>
                  {t.factory.polygonHint1}
                  <Link href={DISCORD_URL} target="_blank">
                    {t.factory.discord}
                  </Link>
                  {t.factory.polygonHint2}
                </HintText>
              ) : (
                <HintText> {t.factory.betaHint}</HintText>
              )}
            </HintContainer>
            <GrayContainer>
              <FactoryAccount pair={pair} vaultBalance={accountVaultBalance} />
            </GrayContainer>
            <GrayContainer>
              <SettingColumn
                pair={pair}
                updateAccountBalance={updateAccountVaultBalance}
                uniPoolState={state}
                liquidityPoolApr={liquidityPoolApr || 0}
                slippage={slippage}
                pcsRewardApr={pcsRewardApr}
              />
            </GrayContainer>
            <GraySmContainer>
              <YourBot
                pair={pair}
                vaultBalance={accountVaultBalance}
                updateVaultBalance={updateAccountVaultBalance}
                uniPoolState={state}
                slippage={slippage}
                updateUserActivePositions={updateUserActivePositions}
                updateUserClosedPositions={updateUserClosedPositions}
              />
            </GraySmContainer>
          </RightContainer>
        </RowContainer>
      </MainContainer>
      <TourHint type="factorySteps" />
      <MarketsModal isOpen={marketsModalIsShow}>
        <MarketColumn
          isOpen={marketsModalIsShow}
          setIsOpen={setMarketsModalIsShow}
        />
      </MarketsModal>
    </Container>
  );
};

export default FactoryPage;

const Container = styled.div``;

const MainContainer = styled.div`
  margin: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RowContainer = styled(motion.div)`
  display: flex;
  margin-left: 250px;
  background-color: ${Colors.backgroundGray3};
  gap: 24px;
  padding: 24px;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
  @media (max-width: 976px) {
    margin-left: 0px;
  }
`;

const MarketsContainer = styled.div`
  background-color: ${Colors.backgroundGray2};
  width: 250px;
  height: 100vh;
  padding-top: 70px;
  position: fixed;
  top: 0;
  left: 0;
  @media (max-width: 976px) {
    display: none;
  }
`;

const MiddleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  @media (max-width: 976px) {
    flex: initial;
  }
`;

const RightContainer = styled.div`
  flex: 0 1 360px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
`;

const GrayContainer = styled.div`
  border-radius: 8px;
  background-color: ${Colors.backgroundGray1};
`;

const ChartContainer = styled.div`
  height: 400px;
  padding: 20px 20px 30px 20px;
  border-radius: 12px;
  background-color: ${Colors.backgroundGray1};
  @media (max-width: 976px) {
    height: 50vw;
  }
  @media (max-width: 786px) {
    min-height: 250px;
  }
`;

const MarketsModal = styled.div<{ isOpen: boolean }>`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: hidden;
    /* height: ${(props) => (props.isOpen ? "100vh" : "0px")}; */
    width: ${(props) => (props.isOpen ? "100vw" : "0px")};
    z-index: 15;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: #1e2229;
    transition: all 350ms ease-in-out;
  }
`;

const GrayLgContainer = styled(GrayContainer)`
  @media (max-width: 1200px) {
    display: none;
  }
`;

const GraySmContainer = styled(GrayContainer)`
  display: none;
  @media (max-width: 1200px) {
    display: block;
  }
`;

const HintContainer = styled.div`
  border-radius: 8px;
  background-color: ${Colors.backgroundGray1};
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const HintText = styled.span`
  font-size: 13px;
  line-height: 22px;
  color: ${Colors.ivory};
  padding: 0px 0 0px 12px;
  border-left: 1px solid ${Colors.gray5};
`;

const Link = styled.a`
  color: ${Colors.lightKhaki};
`;
