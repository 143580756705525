import { useWeb3React } from "@web3-react/core";
import React, { Fragment, useCallback } from "react";
import { useAppSelector } from "../hooks";
import useIsUnderCorrectNetwork from "../hooks/useIsUnderCorrectNetwork";
import { CHAIN_IDS_TO_URL_IDS } from "../utils/constant/chains";
import { ALL_PAIRS } from "../utils/constant/pairs";
import { NavigateWithQuery } from "./NavigateWithQuery";

const PrivateRoute: React.FC<{
  children: React.ReactNode;
  redirectPath: string;
}> = ({ children, redirectPath }) => {
  const { account } = useWeb3React();

  const isUnderCorrectNetwork = useIsUnderCorrectNetwork();

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const navigateToDefaultPair = useCallback(() => {
    const defaultChainName = CHAIN_IDS_TO_URL_IDS[selectedChainId];
    return (
      <NavigateWithQuery
        to={`/factory/${defaultChainName}/${ALL_PAIRS[selectedChainId][0].pairId}`}
      />
    );
  }, [selectedChainId]);

  if (!account || !isUnderCorrectNetwork) {
    return navigateToDefaultPair();
  }

  return <Fragment>{children}</Fragment>;
};

export default PrivateRoute;
