import React from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { Line } from "react-chartjs-2";

const ActualChart: React.FC<{
  roundSharePrices: { 
    round: number;
    sharePrice: number;
    date: string;
    day: number;
  }[];
}> = (props) => {
  const { roundSharePrices } = props;

  const labels = roundSharePrices.map((round) =>round.date.slice(0, 10));
  const sharePrices = roundSharePrices.map((round) => round.sharePrice);

  return (
    <Container>
      <Line
        options={{
          scales: {
            y: {
              display: true,
              title: {
                display: true,
                text: "Share Price",
                color: Colors.ivory,
                padding: {
                  bottom: 16,
                },
              },
              grid: {
                color: "rgba(266, 266, 266, 0.1)",
                display: true,
              },
              ticks: {
                color: Colors.gray3,
                autoSkip: true,
                autoSkipPadding: 12,
              },
            },
            x: {
              display: true,
              title: {
                display: true,
                text: "Date",
                color: Colors.ivory,
                padding: {
                  top: 16,
                },
              },
              grid: {
                display: false,
              },
              ticks: {
                color: Colors.gray3,
                maxRotation: 0,
                align: "start",
                maxTicksLimit: 4,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
              position: "top",
              labels: {
                boxHeight: 12,
                boxWidth: 12,
                color: Colors.ivory,
              },
            },
            tooltip: {
              intersect: false,
              enabled: true,
              position: "average",
              displayColors: false,
              callbacks: {
                title: function (context) {
                  let title = context[0].label;
                  return `Date: ${title}`;
                },
                label: (context) => {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    label += `${context.parsed.y.toLocaleString()}`;
                  }
                  return label;
                },
              },
            },
          },
          // animation: false,
          responsive: true,
          maintainAspectRatio: true,
          hover: {
            mode: "nearest",
            intersect: false,
          },
        }}
        data={{
          labels: labels,
          datasets: [
            {
              label: "Share Price",
              data: sharePrices,
              borderColor: "#0d84f0",
              pointBorderColor: "#0d84f0",
              pointBackgroundColor: Colors.ivory,
              pointBorderWidth: 2,
              pointRadius: 0,
              pointHoverBackgroundColor: Colors.ivory,
              pointHoverBorderColor: "#0d84f0",
              pointHoverRadius: 5,
            },
          ],
        }}
      />
    </Container>
  );
};

export default ActualChart;

const Container = styled.div`
  width: 100%;
`;
