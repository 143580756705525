import React from "react";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { H2, H4 } from "../../styles/Fonts";
import LeaderboardItem from "./LeaderboardItem";
import t from "../../utils/content";

type Props = {
  subtitle: string;
  leftHeader: string;
  rightHeader: string;
  topItems:
    | {
        leftValue: string;
        rightValue: number;
        url?: string;
      }[]
    | null;
  unit: string;
  type?: "open" | "closed";
  showRank: boolean;
  decimal: number;
  isFetched: boolean;
};

const Leaderboard: React.FC<Props> = (props) => {
  const {
    subtitle,
    leftHeader,
    rightHeader,
    topItems,
    unit,
    type,
    showRank,
    decimal,
    isFetched,
  } = props;

  return (
    <div>
      <Subtitle>{subtitle}</Subtitle>
      <LeaderboardContainer>
        <HeaderRow>
          <HeaderText>{leftHeader}</HeaderText>
          <HeaderText>{rightHeader}</HeaderText>
        </HeaderRow>
        {!isFetched && (
          <NoRecordContainer>
            <ClipLoader color={Colors.ivory} loading={true} />
          </NoRecordContainer>
        )}
        {isFetched && (
          <>
            {topItems && topItems?.length > 0 ? (
              topItems.map((item, i) => (
                <LeaderboardItem
                  record={item}
                  unit={unit}
                  type={type}
                  key={item.leftValue}
                  rank={showRank ? i + 1 : undefined}
                  decimal={decimal}
                />
              ))
            ) : (
              <NoRecordContainer>
                <NoRecord>{t.common.noRecord}</NoRecord>
              </NoRecordContainer>
            )}
          </>
        )}
      </LeaderboardContainer>
    </div>
  );
};

export default Leaderboard;

const Subtitle = styled(H2)`
  margin-top: 40px;
  font-size: 18px;
  line-height: 28px;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

const LeaderboardContainer = styled.div`
  padding: 12px 24px 36px 24px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGray4};
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  > h4:second-child {
    text-align: right;
  }
`;

const HeaderText = styled(H4)`
  color: ${Colors.gray4};
  font-size: 14px;
  line-height: 22px;
  margin-left: 20px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const NoRecordContainer = styled.div`
  text-align: center;
  padding: 60px 0;
`;

const NoRecord = styled.span`
  display: inline-block;
  padding: 4px 20px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGray4};
  margin: auto;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.ivory};
`;
