import React from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { H1, H3, H4 } from "../../styles/Fonts";
import { getTruncNum } from "../../utils/common";
import { motion } from "framer-motion";
import { BarLoader } from "react-spinners";
import t from "../../utils/content";

type SummaryProps = {
  totalRecord: number;
  tvl: number;
  cumulativeFee: number;
};

const Summary: React.FC<SummaryProps> = (props) => {
  const { totalRecord, tvl, cumulativeFee } = props;

  const _renderLoader = () => {
    return (
      <LoaderContainer>
        <BarLoader color={Colors.ivory} width={50} />
      </LoaderContainer>
    );
  };

  return (
    <Container>
      <Title>{t.stats.stats}</Title>
      <StatsRow>
        <StatsCol>
          <StatsLabel>{t.stats.tvl}</StatsLabel>
          <StatsValue>
            {tvl ? `$${getTruncNum(tvl, 6)}` : _renderLoader()}
          </StatsValue>
        </StatsCol>
        <StatsCol>
          <StatsLabel>{t.stats.totalEarned}</StatsLabel>
          <StatsValue>
            {cumulativeFee
              ? `$${getTruncNum(cumulativeFee, 6)}`
              : _renderLoader()}
          </StatsValue>
        </StatsCol>
        <StatsCol>
          <StatsLabel>{t.stats.totalPosition}</StatsLabel>
          <StatsValue>{totalRecord ? totalRecord : _renderLoader()}</StatsValue>
        </StatsCol>
      </StatsRow>
    </Container>
  );
};

export default Summary;

const Container = styled(motion.div)``;

const Title = styled(H1)`
  font-size: 36px;
  line-height: 44px;
  text-align: left;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
  @media (max-width: 576px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

const StatsRow = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
    border-radius: 8px;
    overflow: hidden;
    background-color: ${Colors.backgroundGray4};
    padding: 20px;
  }
`;

const StatsCol = styled.div`
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGray4};
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px;
    background-color: transparent;
    padding: 0px;
  }
`;

const StatsLabel = styled(H4)`
  color: ${Colors.gray1};
  font-size: 14px;
  line-height: 20px;
`;

const StatsValue = styled(H3)`
  color: ${Colors.gray1};
  margin-top: 8px;
  font-size: 20px;
  line-height: 28px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 0px;
  }
  @media (max-width: 576px) {
    font-size: 14px;
    line-height: 22px;
    margin-top: 0px;
  }
`;

const LoaderContainer = styled.div`
  margin-top: 8px;
  min-height: 28px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    min-height: 24px;
    margin-top: 0px;
  }
  @media (max-width: 576px) {
    min-height: 22px;
  }
`;
