import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { H4 } from "../../../styles/Fonts";

export const InfoDiv = styled.div`
  color: ${Colors.ivory};
  background-color: ${Colors.backgroundGray4};
  border-radius: 4px;
`;

export const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
  @media (max-width: 768px) {
  }
`;

export const PanelBtn = styled.div<{ isSelect: boolean }>`
  flex: 1;
  background-color: rgba(150, 150, 150, 0.4);
  padding: 2px;
  font-size: 12px;
  line-height: 22px;
  font-weight: ${(props) => (props.isSelect ? "bold" : "normal")};
  border-radius: 4px;
  color: ${(props) => (props.isSelect ? Colors.lightKhaki : Colors.ivory)};
  border: 1px solid
    ${(props) => (props.isSelect ? Colors.lightKhaki : "transparent")};
  cursor: pointer;
  text-align: center;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms;

  &:hover {
    background-color: rgba(150, 150, 150, 0.7);
  }
`;

export const InputContainer = styled.div<{ $isFocus: boolean }>`
  flex: 1 1 60px;
  padding: 6px 12px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: 1px solid ${Colors.lightKhaki};
  margin-top: 8px;
  height: 36px;
  box-shadow: ${props => props.$isFocus && `0 0 4px ${Colors.lightKhaki}`};
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: ${Colors.ivory};

  :focus {
    border: none;
    outline: none;
  }

  ::placeholder {
  font-weight: normal;
  color: ${Colors.gray5};
 }
`;

export const Unit = styled.span`
  font-size: 13px;
  line-height: 22px;
  color: ${Colors.ivory};
  margin: 0 8px;
`;