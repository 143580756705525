const account = {
  airdrop: {
    title: "Airdrop",
    totalClaimed: "Total Claimed Airdrop Rewards",
    claimable: "Unlocked",
    unvested: "Locked",
    claim: "Claim",
    deposit: "Deposit in Pool",
    nextDate: "Next vesting date:",
    switchToClaim1: "Switch to ",
    switchToClaim2: " to claim",
  }

};

export default account;