import { useCallback, useEffect, useState } from "react";
import {
  getShare,
} from "../utils/api/evieVaultApi";
import { useWeb3React } from "@web3-react/core";

const useUserEvieVaultState = (farmAddress: string, tokenDecimal: number) => {
  const { account, provider } = useWeb3React();
  const [withdrawableAmount, setWithdrawableAmount] = useState<number>(0);


  const refreshState = () => {
    setWithdrawableAmount(0);
  };

  const getCanWithdrawnAmount = useCallback(
    async (_account: string) => {
      const amount = await getShare(
        _account,
        farmAddress,
        tokenDecimal,
        provider
      );
      setWithdrawableAmount(+amount);
    },
    [farmAddress, tokenDecimal, provider]
  );

  const updateUserAutoVaultState = useCallback(() => {
    if (!account) {
      refreshState();
    } else {
      // Get Account Status in Auto Vault
      getCanWithdrawnAmount(account);
    }
  }, [
    account,
    getCanWithdrawnAmount,
  ]);

  useEffect(() => {
    updateUserAutoVaultState();
  }, [updateUserAutoVaultState]);

  return {
    withdrawableAmount,
    updateUserAutoVaultState,
  };
};

export default useUserEvieVaultState;
