import { SupportedChainId } from "./chains";

export const SUBGRAPH_URLS: {
  [key: number]: string;
} = {
  [SupportedChainId.ARBITRUM_ONE]:
    "https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-dev",
  [SupportedChainId.POLYGON]:
    "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon",
  [SupportedChainId.GOERLI]:
    "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-gorli",
  [SupportedChainId.POLYGON_MUMBAI]: "",
  [SupportedChainId.OPTIMISM_GOERLI]: "",
  [SupportedChainId.BINANCE_SMART_CHAIN]:
    "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-bsc",
  [SupportedChainId.BINANCE_TESTNET]:
    "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-chapel",
};
