import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Colors from "../styles/Colors";
import { useNavigate, useLocation } from "react-router-dom";
import { navs } from "../utils/constant/navs";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setSelectWalletModalStatus } from "../redux/modalAction";
import SelectWalletModal from "./SelectWalletModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowUpRightFromSquare,
  faBars,
  faXmark,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import Contacts from "./Contacts";
import { useWeb3React } from "@web3-react/core";
import {
  CHAIN_IDS_TO_URL_IDS,
  isSupportedChain,
} from "../utils/constant/chains";
import logo from "../images/diamond.png";
import { connectors } from "../utils/constant/connectors";
import NetworkSelectorBtn from "./NetworkSelectorBtn";
import { getIsInjected, getSlicedAddress } from "../utils/api/common";
import { isMobile } from "../utils/userAgent";
import useIsUnderCorrectNetwork from "../hooks/useIsUnderCorrectNetwork";
import SwitchNetworkModal from "./SwitchNetworkModal";
import t from "../utils/content";
import { UNIBOT_DOC_URL } from "../utils/constant/urls";
import useTrackEvent from "../hooks/useTrackEvent";
import { ALL_PAIRS } from "../utils/constant/pairs";
import EndOdServiceModal from "./EndOfServiceModal";
import { SmallRegular } from "../styles/Fonts";

const Header = () => {
  const selectWalletModalIsOpen = useAppSelector(
    (state) => state.modal.selectWalletModalIsOpen
  );
  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const [disconnectIsShow, setDisconnectIsShow] = useState(false);
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [showSwitchNetworkModal, setShowSwitchNetworkModal] = useState(false);
  const [isEndOfServiceModalShow, setIsEndOfServiceModalShow] = useState(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const disconnectRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const { account, chainId } = useWeb3React();
  const isInjected = getIsInjected();
  const isUnderCorrectNetwork = useIsUnderCorrectNetwork();
  const trackEvent = useTrackEvent();
  const connectedWallet = localStorage.getItem("connectedWallet");

  const setSelectModalIsOpen = async (status: boolean) => {
    dispatch(setSelectWalletModalStatus(status));
  };

  const handleNavOnClick = (path: string) => {
    trackEvent(`${t.amp.clickNavBtn}`, {
      path: path,
    });

    const selectedChainName = CHAIN_IDS_TO_URL_IDS[selectedChainId];
    const paths = navs.map((nav) => nav.path);
    if (path === "/") {
      navigate(`/${selectedChainName}`);
    } else if (paths.includes(path)) {
      if (path === "/factory") {
        account
          ? navigate(
              `${path}/${selectedChainName}/${ALL_PAIRS[selectedChainId][0].pairId}`
            )
          : navigate(`${path}/${selectedChainName}`);
      } else {
        navigate(`${path}/${selectedChainName}`);
      }
    } else {
      navigate(`${path}`);
    }
    setNavIsOpen(false);
  };

  const tryDeactivateConnector = async () => {
    setDisconnectIsShow(false);
    try {
      const injectedConnector = connectors.injected[0];
      await injectedConnector.deactivate?.();
      await injectedConnector.resetState();

      if (localStorage.getItem("connectedWallet") === "WALLET_CONNECT") {
        await connectors.walletConnect[0].deactivate();
        await connectors.walletConnect[0].resetState();
      }

      trackEvent(`${t.amp.clickDisconnectWalletBtn}`);
      localStorage.removeItem("connectedWallet");
    } catch (e) {
      console.log("disconnect failed:", e);
    }
    setNavIsOpen(false);
  };

  // If isInjected and isMobile - Connect Automatically
  useEffect(() => {
    if (isInjected && isMobile) {
      connectors.injected[0].activate();
      localStorage.setItem("connectedWallet", "INJECTED");
    }
  }, [isInjected]);

  // If Connected - Re-connect Automatically
  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (!connectedWallet) {
        return null;
      } else if (connectedWallet === "INJECTED") {
        try {
          await connectors.injected[0].activate();
        } catch (e) {
          console.log("Connect injected on page load failed:", e);
        }
      } else if (connectedWallet === "WALLET_CONNECT") {
        try {
          await connectors.walletConnect[0].connectEagerly();
        } catch (e) {
          console.log("Connect wc on page load failed:", e);
        }
      }
    };
    connectWalletOnPageLoad();
  }, [connectedWallet]);

  useEffect(() => {
    if (!disconnectIsShow) return;
    function handleClick(event: Event) {
      if (
        disconnectRef.current &&
        !disconnectRef.current.contains(event.target as Node)
      ) {
        setDisconnectIsShow(false);
      }
    }
    window.addEventListener("click", handleClick);
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener("click", handleClick);
  }, [disconnectIsShow]);

  return (
    <Container>
      <EndServiceBanner
        onClick={() => {
          setIsEndOfServiceModalShow(true);
        }}
      >
        <SmallRegularText>
          <WarnIcon icon={faCircleExclamation} />
          Diamond Protocol will be shut down on May 31, 2024.{" "}
          <SubText>Please withdraw your assets as soon as possible.</SubText>
        </SmallRegularText>
      </EndServiceBanner>
      <MainContent>
        <LogoContainer onClick={() => handleNavOnClick(`/`)}>
          <LogoImage src={logo} alt="diamond protocol" />
        </LogoContainer>
        <Menu
          onClick={() => setNavIsOpen(!navIsOpen)}
          className={!(window.innerWidth > 1200) ? "connect-wallet" : ""}
        >
          <MenuImage icon={faBars} />
        </Menu>
        <FlexContainer open={navIsOpen}>
          <NavsContainer>
            <Close isShow={navIsOpen} onClick={() => setNavIsOpen(!navIsOpen)}>
              <CloseImage icon={faXmark} />
            </Close>
            <Navs>
              {navs.map((nav) => (
                <Nav
                  key={nav.label}
                  onClick={() => handleNavOnClick(nav.path)}
                  isSelect={pathname.includes(nav.path)}
                >
                  {nav.label}
                </Nav>
              ))}
              <DocNav
                onClick={() => {
                  window.open(UNIBOT_DOC_URL, "_blank");
                  trackEvent(`${t.amp.clickNavBtn}`, { path: UNIBOT_DOC_URL });
                }}
                isSelect={false}
              >
                {t.common.doc}
                <LinkIcon icon={faArrowUpRightFromSquare} />
              </DocNav>
            </Navs>
          </NavsContainer>
          <ButtonContainer>
            {!!account && isUnderCorrectNetwork && (
              <AccNavs>
                <Nav
                  onClick={() => {
                    handleNavOnClick("/account");
                    trackEvent(`${t.amp.clickNavBtn}`, { path: "/account" });
                  }}
                  isSelect={pathname.includes("account")}
                >
                  {t.common.account}
                </Nav>
              </AccNavs>
            )}
            <NetworkSelectorBtn handleCloseNav={() => setNavIsOpen(false)} />
            <ConnectWalletContainer ref={disconnectRef}>
              <ConnectWalletBtn
                isIncorrectNetwork={!!account && !isSupportedChain(chainId)}
                className={window.innerWidth > 1200 ? "connect-wallet" : ""}
                ref={ref}
                onClick={
                  account && isSupportedChain(chainId)
                    ? () => setDisconnectIsShow((pre) => !pre)
                    : !account
                    ? () => {
                        setSelectModalIsOpen(true);
                        trackEvent(`${t.amp.clickConnectWalletBtn}`);
                      }
                    : () => setShowSwitchNetworkModal(true)
                }
              >
                {account ? (
                  isSupportedChain(chainId) ? (
                    <AddressRowContainer>
                      <AddressText>{getSlicedAddress(account)}</AddressText>
                      <DownIcon className="downIcon" icon={faAngleDown} />
                    </AddressRowContainer>
                  ) : (
                    t.common.wrongNetwork
                  )
                ) : (
                  t.common.connectWallet
                )}
              </ConnectWalletBtn>
              {(!!connectedWallet || account) && (
                <DisconnectContainer
                  className="disconnect-container"
                  isShow={disconnectIsShow}
                >
                  <DisconnectBtn onClick={tryDeactivateConnector}>
                    Disconnect
                  </DisconnectBtn>
                </DisconnectContainer>
              )}
            </ConnectWalletContainer>
          </ButtonContainer>
          <ContactsContainer>
            <Contacts />
          </ContactsContainer>
        </FlexContainer>
      </MainContent>
      <SelectWalletModal
        isOpen={selectWalletModalIsOpen}
        handleClose={() => {
          setSelectModalIsOpen(false);
          setNavIsOpen(false);
        }}
      />
      <SwitchNetworkModal
        isOpen={showSwitchNetworkModal}
        handleClose={() => {
          setShowSwitchNetworkModal(false);
        }}
      />
      <EndOdServiceModal
        isShowModal={isEndOfServiceModalShow}
        onHideModal={() => setIsEndOfServiceModalShow(false)}
      />
    </Container>
  );
};

export default Header;

const Container = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100px;
  background-color: ${Colors.backgroundGray2};
  border-bottom: 6px solid rgba(0, 0, 0, 0.3);
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 70px;
  padding: 0 24px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

const FlexContainer = styled.div<{ open: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: hidden;
    height: ${(props) => (props.open ? "100vh" : "0px")};
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: #1e2229;
    transition: all 350ms ease-in-out;
  }
`;

const NavsContainer = styled.nav`
  height: 100%;
  flex: 1;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: initial;
    height: auto;
    width: 100%;
  }
`;

const Navs = styled.ul`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  column-gap: 16px;
  max-width: 500px;
  align-items: center;
  padding-left: 40px;
  padding-right: 10px;
  @media (max-width: 1200px) {
    flex-direction: column;
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const AccNavs = styled(Navs)`
  padding-left: 0;
  margin-left: 20px;

  @media (max-width: 1200px) {
    margin-left: 0;
  }
`;

const Nav = styled.li<{ isSelect: boolean }>`
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  color: ${(props) => (props.isSelect ? Colors.lightKhaki : Colors.ivory)};
  font-family: Poppins, Open Sans, Goldman-Sans, Helvetica Neue, Helvetica,
    Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  :hover {
    color: ${Colors.lightKhaki};
  }
  @media (max-width: 1200px) {
    padding: 12px 12px;
    margin: 4px 0;
    width: 100%;
    text-align: center;
  }
`;

const DocNav = styled(Nav)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  flex: 0 1 300px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  @media (max-width: 1200px) {
    flex: initial;
    flex-direction: column;
    gap: 0px;
    margin-bottom: 20px;
  }
`;

const ConnectWalletBtn = styled.button<{ isIncorrectNetwork: boolean }>`
  min-width: 160px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  cursor: default;
  color: ${(props) =>
    props.isIncorrectNetwork ? Colors.danger.default : Colors.ivory};
  min-height: 36px;
  border: 1px solid
    ${(props) =>
      props.isIncorrectNetwork
        ? Colors.danger.default
        : "rgba(220, 220, 220, 0.3)"};
  border-radius: 4px;
  text-transform: uppercase;

  :hover {
    background-color: ${Colors.backgroundGray5};
  }

  @media (max-width: 1200px) {
    margin: 18px 0;
    width: 180px;
  }
`;

const ConnectWalletContainer = styled.div`
  position: relative;
`;

const DisconnectContainer = styled.div<{ isShow: boolean }>`
  display: ${(props) => (props.isShow ? "block" : "none")};
  width: 160px;
  position: absolute;
  top: calc(100%);
  right: 0;
  background-color: ${Colors.backgroundGray2};
  transition: 300ms ease-in-out all;
  padding-top: 12px;
  @media (max-width: 1200px) {
    width: 180px;
    position: static;
    padding-top: 0;
  }
`;

const DisconnectBtn = styled.button`
  width: 100%;
  height: 36px;
  border: 1px solid rgba(220, 220, 220, 0.3);
  border-radius: 4px;
  background-color: ${Colors.backgroundGray2};
  color: ${Colors.ivory};
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;

  :hover {
    background-color: ${Colors.backgroundGray5};
  }

  @media (max-width: 1200px) {
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    background-color: ${Colors.backgroundGray2};
  }
`;

const AddressRowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddressText = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: ${Colors.ivory};
  text-transform: none;
`;

const DownIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-left: 8px;
  transition: all 0.4s ease;
`;

const LogoImage = styled.img`
  width: 170px;
  height: auto;
  @media (max-width: 1200px) {
    width: 140px;
  }
`;

const Close = styled.div<{ isShow: boolean }>`
  display: none;
  @media (max-width: 1200px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    padding: 20px;
  }
`;

const CloseImage = styled(FontAwesomeIcon)`
  width: 24px;
  height: 24px;
  color: ${Colors.ivory};
`;

const Menu = styled.div`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

const MenuImage = styled(FontAwesomeIcon)`
  width: 24px;
  height: 24px;
  color: ${Colors.ivory};
`;

const ContactsContainer = styled.div`
  margin-left: 16px;
  @media (max-width: 1200px) {
    margin-top: 20px;
    margin-left: 0px;
    flex-direction: row;
    justify-content: center;
    display: flex;
  }
`;

const LinkIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
`;

const SubText = styled.span`
  @media (max-width: 1200px) {
    display: none;
  }
`;

const SmallRegularText = styled(SmallRegular)`
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

const WarnIcon = styled(FontAwesomeIcon)`
  margin-right: 4px;
`;

const EndServiceBanner = styled.div`
  background-color: ${Colors.lightKhaki};
  width: 100%;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
