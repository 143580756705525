import { AppThunk } from "./store";
import {
  setSharePrice,
  setPastPositions,
  setPastRoundSharePrices,
} from "./evieVaultSlice";
import { getSharePriceOfFarm } from "../utils/api/evieVaultApi";
import {
  getEvieVaultPositionsFromGinza,
  getPastRoundSharePricesFromGinze,
} from "../utils/api/ginzaApi";
import { formatUnits } from "ethers/lib/utils";

export const getSharePrice =
  (
    farmAddress: string,
    chainId: number,
    decimal: number,
    provider: any
  ): AppThunk =>
  async (dispatch) => {
    try {
      const price = await getSharePriceOfFarm(
        farmAddress,
        decimal,
        chainId,
        provider
      );
      dispatch(setSharePrice(price));
    } catch (error) {}
  };

export const getEvieVaultPositions =
  (farmActionAddress: string, chainId: number): AppThunk =>
  async (dispatch) => {
    try {
      const records = await getEvieVaultPositionsFromGinza(
        farmActionAddress,
        chainId
      );
      let parsedRecords = []
      records.map(item => {
        let openPositionRecord = {
          type: "Open Position",
          positionId: item.PositionId,
          time: item.OpenTime,
          wantAmount: item.WantAmount,
          txHash: item.OpenTx
        }
        parsedRecords.push(openPositionRecord)
        if (item.CloseBlockNumber) {
          let closePositionRecord = {
            type: "Close Position",
            positionId: item.PositionId,
            time: item.CloseTime,
            wantAmount: item.AmountAfterRepaid,
            txHash: item.CloseTx
          }
          parsedRecords.push(closePositionRecord)
        }
      })
      let sortedParsedRecords = parsedRecords.sort((a,b) => b.time - a.time)
      dispatch(setPastPositions(sortedParsedRecords));
    } catch (error) {}
  };

export const getPastRoundSharePrices =
  (chainId: number, vaultId: string): AppThunk =>
  async (dispatch) => {
    try {
      const prices = await getPastRoundSharePricesFromGinze(chainId, vaultId);
      const parsedPrices = prices
        .sort((a, b) => a.ID - b.ID)
        .map((item) => ({
          sharePrice: +formatUnits(item.token_price, 18).slice(0, 8),
          date: item.date,
        }));
      dispatch(setPastRoundSharePrices(parsedPrices));
    } catch (error) {}
  };
