import React from "react";
import styled from "styled-components";
import TokenItem from "./TokenItem";
import useVaultTokens from "../../../../hooks/useVaultTokens";

const FactoryAccount: React.FC = () => {
  const vaultTokens = useVaultTokens();

  return (
    <Container>
      <FactoryContainer>
        {!!vaultTokens &&
          vaultTokens.map((token) => (
            <TokenItem key={token.tokenAddress} token={token} />
          ))}
      </FactoryContainer>
    </Container>
  );
};

export default FactoryAccount;

const Container = styled.div``;

const FactoryContainer = styled.div`
  margin-top: 20px;
  > div:not(:first-child) {
    margin-top: 24px;
  }
`;
