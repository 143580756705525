import React from "react";
import styled from "styled-components";
import Colors from "../styles/Colors";
import Button from "../components/Button";
import { H4 } from "../styles/Fonts";
import CustomModal from "../components/Modal";
import { useAppDispatch } from "../hooks";
import { setSelectWalletModalStatus } from "../redux/modalAction";
import { useWeb3React } from "@web3-react/core";
import useReferralCode from "../hooks/useReferralCode";
import { setReferralCode } from "../utils/api/referralApi";
import t from "../utils/content";
import useReferralIsExisted from "../hooks/useReferralIsExisted";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "../utils/constant/chains";
import { switchNetwork } from "../utils/switchNetwork";

const SetReferralCodeModal: React.FC<{
  isShowModal: boolean;
  setShowModal: (v: boolean) => void;
  code: string;
}> = (props) => {
  const { isShowModal, setShowModal, code } = props;

  const dispatch = useAppDispatch();
  const referralCodeExisted = useReferralIsExisted();

  const setSelectModalIsOpen = async (status: boolean) => {
    dispatch(setSelectWalletModalStatus(status));
  };

  const { account, chainId, provider } = useWeb3React();
  const { updateReferralCode } = useReferralCode();

  const handleSetCode = async () => {
    if (!account || !code || !chainId || !provider) return;

    setShowModal(false);

    await setReferralCode(account, chainId, provider, code);

    updateReferralCode();
  };

  return (
    <CustomModal
      show={isShowModal}
      onHide={() => setShowModal(false)}
      title={t.common.referralCodeDetected}
    >
      <InfoContainer>
        <Description>{t.common.referralCode}</Description>
        <CodeText>{code}</CodeText>
        <Description>{t.common.detected}</Description>
      </InfoContainer>
      <BtnContainer>
        {account ? (
          referralCodeExisted ? (
            <Button
              btnType="primary"
              btnText={t.common.setReferralCode}
              onClick={handleSetCode}
              disabled={false}
              isLoading={false}
            />
          ) : (
            <Button
              btnType="primary"
              btnText={`${t.common.switchTo} ${
                CHAIN_IDS_TO_NAMES[SupportedChainId.ARBITRUM_ONE]
              }`}
              onClick={() => switchNetwork(SupportedChainId.ARBITRUM_ONE)}
              disabled={false}
              isLoading={false}
            />
          )
        ) : (
          <Button
            btnType="primary"
            btnText={t.common.connectWalletToContinue}
            onClick={() => setSelectModalIsOpen(true)}
            disabled={false}
            isLoading={false}
          />
        )}
      </BtnContainer>
    </CustomModal>
  );
};

export default SetReferralCodeModal;

const BtnContainer = styled.div`
  margin-top: 24px;
  button {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;

const Description = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const CodeText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: ${Colors.gray3};
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${Colors.backgroundGray4};
  margin: 0 4px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;
