import { useWeb3React } from "@web3-react/core";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import useIsUnderCorrectNetwork from "../../hooks/useIsUnderCorrectNetwork";
import Colors from "../../styles/Colors";
import { H3, H4 } from "../../styles/Fonts";
import { getTruncNum } from "../../utils/common";
import t from "../../utils/content";
import { showErrorAlert } from "../../utils/showAlert";

type PairProps = {
  wantToken: {
    tokenAddress: string;
    tokenDecimal: number;
    tokenSymbol: string;
  };
};

const FactoryAccount: React.FC<{ pair: PairProps; vaultBalance: string }> = ({
  pair,
  vaultBalance,
}) => {
  const { wantToken } = pair;
  const { tokenSymbol, tokenAddress } = wantToken;

  const { account } = useWeb3React();
  const navigate = useNavigate();
  const isUnderCorrectNetwork = useIsUnderCorrectNetwork();

  const isConnected = useMemo(
    () => account && isUnderCorrectNetwork,
    [account, isUnderCorrectNetwork]
  );

  const handleGoDeposit = () => {
    if (!account) {
      showErrorAlert(t.error.connectWallet);
      return;
    }
    if (!isUnderCorrectNetwork) {
      showErrorAlert(t.error.switchNetwork);
      return;
    }
    navigate(`/account/portfolio?token=${tokenAddress}`);
  };

  return (
    <Container className="fac-s-1">
      <Title>{t.factory.yourFactoryAccount}</Title>
      <BalanceRow>
        <BalanceLabel>{t.factory.balance}</BalanceLabel>
        <BalanceValue>{`${
          isConnected ? getTruncNum(+vaultBalance, 6) : "-"
        } ${tokenSymbol}`}</BalanceValue>
      </BalanceRow>
      <Button
        btnType="primary"
        btnText={t.factory.goDeposit}
        onClick={handleGoDeposit}
        disabled={false}
        isLoading={false}
      />
    </Container>
  );
};

export default FactoryAccount;

const Container = styled.div`
  padding: 24px;
`;

const Title = styled(H3)`
  color: ${Colors.ivory};
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  padding-bottom: 12px;
  border-bottom: 0.5px solid gray;
`;

const BalanceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`;

const BalanceValue = styled(H4)`
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  color: ${Colors.ivory};
`;

const BalanceLabel = styled(H4)`
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  color: ${Colors.ivory};
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
