import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import Button from "../../../../components/Button";
import { H4 } from "../../../../styles/Fonts";
import CustomModal from "../../../../components/Modal";
import { useWeb3React } from "@web3-react/core";
import { depositTokenIntoBalanceVault } from "../../../../utils/api/balanceVaultApi";
import AmountInput from "../../../../components/AmountInput";
import useTokenApproval from "../../../../hooks/useTokenApproval";
import { ALL_VAULTS } from "../../../../utils/constant/internalAddresses";
import { useAppSelector } from "../../../../hooks";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CHAIN_IDS_TO_NAMES } from "../../../../utils/constant/chains";
import { showErrorAlert } from "../../../../utils/showAlert";
import t from "../../../../utils/content";
import useTrackEvent from "../../../../hooks/useTrackEvent";

type Props = {
  isShowModal: boolean;
  setShowModal: (v: boolean) => void;
  setIsDepositing: (v: boolean) => void;
  updateBalances: () => void;
  tokenSymbol: string;
  tokenAddress: string;
  tokenDecimal: number;
  tokenBalance: string;
  deployedChainId: number;
  setBridgeModalIsShow: () => void;
  updateRecords: () => void;
};

const DepositModal: React.FC<Props> = (props) => {
  const {
    isShowModal,
    setShowModal,
    setIsDepositing,
    updateBalances,
    tokenSymbol,
    tokenAddress,
    tokenDecimal,
    tokenBalance,
    deployedChainId,
    setBridgeModalIsShow,
    updateRecords,
  } = props;

  const { account, chainId, provider } = useWeb3React();
  const trackEvent = useTrackEvent();

  const [amount, setAmount] = useState<string>("0");

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const {
    tokenAmountIsApproved,
    checkTokenAllowance,
    isGranting,
    isGranted,
    grantTokenAllowance,
    initTokenApprovalStates,
  } = useTokenApproval({
    tokenAmount: amount,
    tokenAddress,
    tokenDecimal,
    contractAddress: ALL_VAULTS[selectedChainId],
  });

  const onHide = () => {
    setShowModal(false);
    initTokenApprovalStates();
    setAmount("0");
  };

  const handleOnDeposit = async () => {
    if (!chainId) {
      return;
    }

    if (!isNaN(parseFloat(amount)) && parseFloat(amount) <= 0) {
      showErrorAlert(t.error.inputDepositAmount);
      return;
    }

    if (
      account &&
      amount &&
      chainId &&
      tokenAddress &&
      tokenDecimal &&
      provider
    ) {
      trackEvent(`${t.amp.clickDepositBtn} | ${t.amp.accountPage}`, {
        amount: amount,
        tokenSymbol: tokenSymbol,
      });

      onHide();
      setIsDepositing(true);

      await depositTokenIntoBalanceVault(
        account,
        tokenAddress,
        tokenDecimal,
        amount,
        chainId,
        provider,
        (txHash: string) => {
          trackEvent(`${t.amp.depositToken} | ${t.amp.accountPage}`, {
            txHash: txHash,
            amount: amount,
            tokenSymbol: tokenSymbol,
          });
        }
      );
      setIsDepositing(false);
      updateBalances();
      updateRecords();
    }
  };

  useEffect(() => {
    if (parseFloat(amount) > 0) {
      checkTokenAllowance();
    }
  }, [amount, checkTokenAllowance]);

  return (
    <CustomModal
      show={isShowModal}
      onHide={onHide}
      title={`Deposit ${tokenSymbol}`}
    >
      <AmountInput
        setAmount={setAmount}
        tokenSymbol={tokenSymbol}
        tokenDecimal={tokenDecimal}
        showMaxBtn={true}
        maxAmount={tokenBalance}
      />
      <InfoContainer>
        <BalanceRow>
          <Label>Wallet Balance</Label>
          <Value>
            {tokenBalance} {tokenSymbol}
          </Value>
        </BalanceRow>
      </InfoContainer>
      <BtnContainer>
        {account && (
          <Fragment>
            {!!amount && !tokenAmountIsApproved && !isGranted && (
              <Button
                btnType="primary"
                btnText={`Approve use of ${tokenSymbol}`}
                onClick={
                  !isGranting
                    ? () => {
                        grantTokenAllowance();
                        trackEvent(
                          "Click Approve Allowance Button | Account Page",
                          {
                            amount: amount,
                            tokenSymbol: tokenSymbol,
                          }
                        );
                      }
                    : () => {}
                }
                disabled={!amount}
                isLoading={isGranting}
              />
            )}
            {isGranted && (
              <Reminder>
                <FontAwesomeIcon icon={faCheckCircle} color={Colors.green} />
                <ReminderText>You can now deposit</ReminderText>
              </Reminder>
            )}
          </Fragment>
        )}
        <Button
          btnType="primary"
          btnText="Deposit"
          onClick={handleOnDeposit}
          disabled={!amount || (!!account && !tokenAmountIsApproved)}
          isLoading={false}
        />
        <BridgeBtn
          onClick={setBridgeModalIsShow}
        >{`Bridge Your Asset to ${CHAIN_IDS_TO_NAMES[deployedChainId]}`}</BridgeBtn>
      </BtnContainer>
    </CustomModal>
  );
};

export default DepositModal;

const InfoContainer = styled.div`
  margin-top: 20px;
`;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const Value = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
  color: ${Colors.ivory};
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

const BtnContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  button:first-child {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;

const BridgeBtn = styled.button`
  width: 100%;
  padding: 4px 12px;
  border: 1px solid ${Colors.ivory};
  border-radius: 100px;
  font-size: 13px;
  line-height: 22px;
  color: ${Colors.ivory};
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  :hover {
    border: 1px solid ${Colors.lightKhaki};
    color: ${Colors.lightKhaki};
  }
  :active {
    transform: scale(0.99);
  }
`;

const Reminder = styled.div`
  width: 100%;
  min-height: 32px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.ivory};
  background-color: ${Colors.backgroundGray4};
  border: 1px solid ${Colors.gray5};
  padding: 4px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (max-width: 768px) {
    min-height: 28px;
    min-width: 80px;
  }
`;

const ReminderText = styled(H4)`
  font-size: 16px;
  line-height: 24px;
  font-family: Fira Sans, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  /* font-weight: 600; */
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
  }
`;
