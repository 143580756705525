import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getUserTwitterUserName } from "../utils/api/twitterApi";
import { useWeb3React } from "@web3-react/core";

const useTwitterAccount = () => {
  const { account } = useWeb3React();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(["twitter-id"]);
  const twitterId = cookies["twitter-id"];

  const [userName, setUserName] = useState<string>("");

  const setTwitterId = useCallback(
    (twitterId: string) => {
      setCookie("twitter-id", twitterId);
    },
    [setCookie]
  );

  // Get twitter user name if twitter id is provided
  const getUserName = useCallback(async () => {
    if (account && twitterId) {
      const userName = await getUserTwitterUserName(account, twitterId);
      if (!userName) {
        setTwitterId("");
      } else {
        setUserName(userName);
      }
    }
  }, [account, twitterId, setTwitterId]);

  const initState = useCallback(() => {
    setUserName("");
  }, []);

  // Remove twitter id in cookie if account is changed
  useEffect(() => {
    initState();
    if (account) {
      getUserName();
    }
  }, [initState, getUserName, account]);

  return { userName, setTwitterId, twitterId };
};

export default useTwitterAccount;
