import { toChecksumAddress } from "ethereum-checksum-address";
import { BigNumber } from "ethers"
import { SupportedChainId } from "../constant/chains";
import { getPositionRangesFromV3 } from "./factoryV3Api";
import { formatUnits } from "ethers/lib/utils";
import { convertTickToPrice, getTokenPriceFromBinance } from "./common";
import { UserClosedPositionProps } from "../../redux/factorySlice";
import { TimeRangeProps } from "../../pages/LeaderboardPage";
import { RecordProps } from "../../redux/accountSlice";
import { ALL_VAULTS, diamondAddresses } from "../constant/internalAddresses";

const MAINNET_URL = "";
const TESTNET_URL = "";

/**
 * Ginza API for fetching data
 */
const urls = {
  [SupportedChainId.ARBITRUM_ONE]: MAINNET_URL,
  [SupportedChainId.GOERLI]: TESTNET_URL,
  [SupportedChainId.POLYGON_MUMBAI]: TESTNET_URL,
  [SupportedChainId.OPTIMISM_GOERLI]: TESTNET_URL,
  [SupportedChainId.POLYGON]: MAINNET_URL,
  [SupportedChainId.BINANCE_SMART_CHAIN]: MAINNET_URL,
  [SupportedChainId.BINANCE_TESTNET]: TESTNET_URL,
};

/**
 * Network names used in Ginza API
 */
const chains = {
  [SupportedChainId.ARBITRUM_ONE]: "arbitrum",
  [SupportedChainId.GOERLI]: "goerli",
  [SupportedChainId.POLYGON_MUMBAI]: "polygon_test",
  [SupportedChainId.OPTIMISM_GOERLI]: "optimism_test",
  [SupportedChainId.POLYGON]: "polygon",
  [SupportedChainId.BINANCE_SMART_CHAIN]: "bsc",
  [SupportedChainId.BINANCE_TESTNET]: "bsc_test",
};

/**
 * Get Auto Vault cumulative fee from Ginza
 * @param factoryAddress factory address
 * @param farmActionAddress farm action address
 * @param chainId chain id
 * @returns cumulative fee
 */
export const getAutoVaultCumulativeFeeFromGinza = async (
  factoryAddress: string,
  farmActionAddress: string,
  chainId: SupportedChainId
) => {
  const factoryId = await getGinzaFactoryIdByAddress(factoryAddress, chainId);

  const url = `${
    urls[chainId]
  }/stag/api/v1/diamond_factory/${factoryId}/positions?type=closed&page=1&limit=100&user_addr=${toChecksumAddress(
    farmActionAddress
  )}`;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    const records = resultJson.records;
    const ethPrice = await getTokenPriceFromBinance("WETH");
    const borrowTokenFee = records.reduce(
      (
        pre: number,
        curr: { PositionInfo: { WantTokenFee: string; BorrowTokenFee: string } }
      ) => pre + +curr.PositionInfo.BorrowTokenFee,
      0
    );
    const wantTokenFee = records.reduce(
      (
        pre: number,
        curr: { PositionInfo: { WantTokenFee: string; BorrowTokenFee: string } }
      ) => pre + +curr.PositionInfo.WantTokenFee,
      0
    );
    const cumulativeFee =
      +formatUnits(borrowTokenFee.toString(), 18) * ethPrice +
      +formatUnits(wantTokenFee.toString(), 6);
    return cumulativeFee;
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get factory ids from Ginza
 * @param chainId chain id
 * @returns factory ids
 */
export const getGinzaFactoryIds = async (chainId: SupportedChainId) => {
  let url = `${urls[chainId]}/stag/api/v1/diamond_factory`;

  if (chainId) {
    url += `?chain=${chains[chainId].toLowerCase()}`;
  }

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson.data.map((fac: { [key: string]: string }) => fac.ID);
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get factory id by address
 * @param factoryAddress factory address
 * @param chainId chain id
 * @returns factory id
 */
export const getGinzaFactoryIdByAddress = async (
  factoryAddress: string,
  chainId: SupportedChainId
) => {
  let url = `${urls[chainId]}/stag/api/v1/diamond_factory`;

  if (chainId) {
    url += `?chain=${chains[chainId].toLowerCase()}`;
  }

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();

  // Succeed
  if (result.status === 200) {
    const matchedData = resultJson.data.find(
      (item: { [key: string]: string }) =>
        item.ContractAddr.toLowerCase() === factoryAddress.toLowerCase()
    );
    return matchedData.ID;
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get positions from Ginza
 * @param chainId chain id
 * @param type open or closed
 * @returns factory positions
 */
export const getGinzaFactoryPositions = async (
  chainId: SupportedChainId,
  type: "open" | "closed"
) => {
  const url = `${
    urls[chainId]
  }/stag/api/v1/diamond_factory/all/positions?limit=100&type=${type}&chain=${chains[
    chainId
  ].toLowerCase()}`;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return {
      records: resultJson.records,
      totalRecord: resultJson.total_record,
    };
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get factory summary from Ginza
 * @param chainId chain id
 * @param id factory id
 * @returns factory summary
 */
export const getGinzaFactorySummary = async (
  chainId: SupportedChainId,
  id: string
) => {
  const url = `${urls[chainId]}/stag/api/v1/diamond_factory/${id}/summary`;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson as {
      token_info: { [key: string]: { symbol: string; decimal: number } };
      close_position_borrow_token_total_fee: string;
      close_position_want_token_total_fee: string;
      open_position_borrow_token_total_fee: string;
      open_position_want_token_total_fee: string;
    };
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get APR of Uniswap V3 liquidity pool from Ginza
 * To calculate the APR of a specific range, use the following formula:
 * APR = fee_apr * range / specific_range
 * @param poolAddress pool address
 * @returns APR when range is 50%
 */
export const getGinzaUniPoolApr = async (poolAddress: string) => {
  const url = `${process.env.REACT_APP_UNIBOT_EBST_API_URL}/stag/api/v1/uniswap_apr/data`;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    const matchedItem = resultJson.find(
      (item: { [key: string]: any }) =>
        item.pool.toUpperCase() === poolAddress.toUpperCase()
    );
    // The range used to run backtesting
    const range = +matchedItem.range / 100;

    return matchedItem ? +matchedItem.fee_apr * range : 0;
  }
  // Failed
  throw new Error(resultJson.error);
};

interface BalanceProps {
  symbol: string;
  decimal: string;
  sum: string;
}

/**
 * Get balances summary from Ginza
 * @param chainId
 * @returns balances summary
 */
export const getGinzaBalancesSummary = async (chainId: SupportedChainId) => {
  const url = `${
    urls[chainId]
  }/stag/api/v1/helpers/info/balances_summary?chain=${chains[
    chainId
  ].toLowerCase()}`;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    let balanceObj: { [key: string]: BalanceProps } = {};
    const balances = resultJson.data?.Results;
    balances.forEach((balance: { [key: string]: string }) => {
      const symbol = balance.Symbol;
      if (balanceObj[symbol]) {
        balanceObj[symbol] = {
          ...balanceObj[symbol],
          sum: BigNumber.from(balance.Amount).add(BigNumber.from(balanceObj[symbol].sum)).toString()
        };
      } else {
        balanceObj[symbol] = {
          symbol: balance.Symbol,
          decimal: balance.Decimal,
          sum: balance.Amount,
        };
      }
    });
    return Object.values(balanceObj);
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get user's referral info from Ginza
 * @param userAddress user address
 * @param chainId chain id
 * @returns user's referral info
 */
export const getGinzaUserReferral = async (
  userAddress: string,
  chainId: SupportedChainId
) => {
  const url = `${urls[chainId]}/stag/api/v1/diamond_referrer/query/get_referral?address=${userAddress}&detail=true`;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    const { referral, referee, referee_count } = resultJson.data;

    return {
      referralCode: referral?.ReferralCode,
      referree: referee,
      referreeCount: referee_count,
    };
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get user's referrer info from Ginza
 * @param userAddress user address
 * @param chainId chain id
 * @returns user's referrer info
 */
export const getGinzaUserReferrer = async (
  userAddress: string,
  chainId: SupportedChainId
) => {
  const url = `${urls[chainId]}/stag/api/v1/diamond_referrer/query/get_referrer_info?address=${userAddress}`;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    const { referral } = resultJson.data;

    return {
      referrerAddress: referral.Address,
      referrerCode: referral.ReferralCode,
    };
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get user's referral reward info from Ginza
 * @param userAddress user address
 * @param chainId chain id
 * @returns user's referral reward info
 */
export const getGinzaRewardRecords = async (
  userAddress: string,
  chainId: SupportedChainId
) => {
  if (
    !(
      chainId === SupportedChainId.ARBITRUM_ONE ||
      chainId === SupportedChainId.POLYGON_MUMBAI
    )
  ) {
    return;
  }

  const networkNames = {
    [SupportedChainId.ARBITRUM_ONE]: "arbitrum",
    [SupportedChainId.POLYGON_MUMBAI]: "mumbai",
  };

  const url = `${process.env.REACT_APP_UNIBOT_EBST_API_URL}/stag/api/v1/reward_program/user_reward/${userAddress}?network=${networkNames[chainId]}`;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson;
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get user's closed positions from Ginza
 * @param userAddress user address
 * @param factoryAddress factory address
 * @param chainId chain id
 * @param borrowTokenDecimal borrowToken decimal
 * @param wantTokenDecimal  wantToken decimal
 * @param wantTokenIsToken0 wantToken is token0
 * @param provider provider
 * @returns user's closed positions
 */
export const getUserClosedPositionsFromGinza = async (
  userAddress: string,
  factoryAddress: string,
  chainId: SupportedChainId,
  borrowTokenDecimal: number,
  wantTokenDecimal: number,
  wantTokenIsToken0: boolean,
  provider: any
) => {
  const factoryId = await getGinzaFactoryIdByAddress(factoryAddress, chainId);

  const url = `${
    urls[chainId]
  }/stag/api/v1/diamond_factory/${factoryId}/positions?type=closed&page=1&limit=100&user_addr=${toChecksumAddress(
    userAddress
  )}`;

  const options = {
    method: "get",
  };

  const result = await fetch(url, options);
  const resultJson = await result.json();

  // Succeed
  if (result.status === 200) {
    const { records } = resultJson;

    const parsedPositionInfos: UserClosedPositionProps[] = await Promise.all(
      records
        .filter(
          (i: { [key: string]: any }) => Object.keys(i.PositionInfo).length > 0
        )
        .map(async (position: any) => {
          const {
            PositionId: positionId,
            PositionInfo: positionInfo,
            OpenTime: startTime,
            CloseTime: closeTime,
            CloseTicker: closeTick,
            AmountAfterRepaid: amountAfterRepaid,
            CloseEvent: eventName,
            ReserveWant: reserveWantAmount,
          } = position;

          const {
            WantTokenAmountAtStart: _wantTokenAmountAtStart,
            ReserveAmount: reserveAmount,
            BorrowRatio: borrowRatio,
            StartPriceTick: startPriceTick,
            WantTokenFee: wantTokenFee,
            BorrowTokenFee: borrowTokenFee,
            UserReward: cakeReward,
            FeeReward: feeReward,
            RewardTokenValueInWant: rewardTokenValueInWant,
          } = positionInfo;
          const wantTokenAmountAtStart = formatUnits(
            BigNumber.from(_wantTokenAmountAtStart),
            wantTokenDecimal
          );
          const decimalDiff = borrowTokenDecimal - wantTokenDecimal;

          // get ranges from uni v3
          let upperPrice = 0;
          let lowerPrice = 0;
          try {
            const { tickLower, tickUpper } = await getPositionRangesFromV3(
              +positionId,
              chainId,
              provider
            );
            if (tickLower && tickUpper) {
              upperPrice = convertTickToPrice(
                wantTokenIsToken0 ? +tickUpper : -tickLower,
                decimalDiff
              );
              lowerPrice = convertTickToPrice(
                wantTokenIsToken0 ? +tickLower : -tickUpper,
                decimalDiff
              );
            }
          } catch (error) {
            // console.log(error);
          }
          return {
            positionId: positionId || "",
            startWantAmount: wantTokenAmountAtStart,
            endWantAmount:
              formatUnits(amountAfterRepaid.toString(), wantTokenDecimal) || "",
            reserveAmount:
              formatUnits(reserveAmount.toString(), wantTokenDecimal) || "",
            upperTickPrice: upperPrice,
            lowerTickPrice: lowerPrice,
            borrowRatio: borrowRatio / 10000,
            startTime: startTime,
            closeTime: closeTime,
            startPrice: convertTickToPrice(
              wantTokenIsToken0 ? startPriceTick : -startPriceTick,
              decimalDiff
            ),
            closePrice: convertTickToPrice(
              wantTokenIsToken0 ? closeTick : -closeTick,
              decimalDiff
            ),
            eventName: eventName,
            reserveWantAmount:
              formatUnits(reserveWantAmount.toString(), wantTokenDecimal) || "",
            wantTokenFee:
              formatUnits(wantTokenFee.toString(), wantTokenDecimal) || "",
            borrowTokenFee:
              formatUnits(borrowTokenFee.toString(), borrowTokenDecimal) || "",
            cakeReward: cakeReward
              ? formatUnits(cakeReward.toString(), 18)
              : "",
            rewardTokenValueInWantToken: rewardTokenValueInWant
              ? formatUnits(BigNumber.from(cakeReward).sub(BigNumber.from(feeReward)).mul(BigNumber.from(rewardTokenValueInWant)).div(BigNumber.from(cakeReward)), 18)
              : "",
          } as UserClosedPositionProps;
        })
    );

    return parsedPositionInfos;
  }

  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get auto vault positions from Ginza
 * @param factoryAddress factory address
 * @param farmActionAddress farm action address
 * @param chainId chain id
 * @returns auto vault positions
 */
export const getAutoVaultPositionsFromGinza = async (
  factoryAddress: string,
  farmActionAddress: string,
  chainId: SupportedChainId
) => {
  const factoryId = await getGinzaFactoryIdByAddress(factoryAddress, chainId);

  const url = `${
    urls[chainId]
  }/stag/api/v1/diamond_factory/${factoryId}/positions?type=closed&page=1&limit=100&user_addr=${toChecksumAddress(
    farmActionAddress
  )}`;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    const records = resultJson.records;
    return records;
  }
  // Failed
  throw new Error(resultJson.error);
};

export const getEvieVaultPositionsFromGinza = async (
  farmActionAddress: string,
  chainId: SupportedChainId
) => {
  const url = `${
    urls[chainId]
  }/stag/api/v1/diamond_factory/all/positions?limit=100&user_addr=${toChecksumAddress(
    farmActionAddress
  )}`;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    const records = resultJson.records;
    return records;
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get user's deposited records of lending pool from Ginza
 * @param userAddress user address
 * @param lendingPoolAddress lending pool address
 * @param chainId chain id
 * @param tokenDecimal token decimal
 * @returns user's deposited records
 */
export const getUserLendingPoolDepositedRecordFromGinza = async (
  userAddress: string,
  lendingPoolAddress: string,
  chainId: SupportedChainId,
  tokenDecimal: number
) => {
  const depositedRecordObj: { [key: string]: number } = {};

  const fetchData = async (page: number) => {
    const url = `${
      urls[chainId]
    }/stag/api/v1/cache/lending_pool/logs/deposits?address=${toChecksumAddress(
      userAddress
    )}&lending_pool_addr=${toChecksumAddress(lendingPoolAddress)}&chain_tag=${
      chains[chainId]
    }&page=${page}&limit=10`;

    const options = {
      method: "get",
    };

    const result = await fetch(url, options);
    const resultJson = await result.json();
    if (result.status === 200) {
      const records: { AmountDeposited: string; TxHash: string }[] =
        resultJson.records;
      records.forEach((record) => {
        const amount = +formatUnits(record.AmountDeposited, tokenDecimal);
        const txHash = record.TxHash;
        depositedRecordObj[txHash] = amount;
      });

      if (resultJson.total_page > resultJson.page) {
        await fetchData(resultJson.page + 1);
      }
    }
  };

  await fetchData(1);

  return depositedRecordObj;
};

/**
 * Get user's withdrawn records of lending pool from Ginza
 * @param userAddress user address
 * @param lendingPoolAddress lending pool address
 * @param chainId chain id
 * @param tokenDecimal token decimal
 * @returns user's withdrawn records
 */
export const getUserLendingPoolWithdrawnRecordFromGinza = async (
  userAddress: string,
  lendingPoolAddress: string,
  chainId: SupportedChainId,
  tokenDecimal: number
) => {
  const withdrawnRecordObj: { [key: string]: number } = {};

  const fetchData = async (page: number) => {
    const url = `${
      urls[chainId]
    }/stag/api/v1/cache/lending_pool/logs/withdraw?address=${toChecksumAddress(
      userAddress
    )}&lending_pool_addr=${toChecksumAddress(lendingPoolAddress)}&chain_tag=${
      chains[chainId]
    }&page=${page}&limit=10`;

    const options = {
      method: "get",
    };

    const result = await fetch(url, options);
    const resultJson = await result.json();
    if (result.status === 200) {
      const records: { AmountWithdrawn: string; TxHash: string }[] =
        resultJson.records;
      records.forEach((record) => {
        const amount = +formatUnits(record.AmountWithdrawn, tokenDecimal);
        const txHash = record.TxHash;
        withdrawnRecordObj[txHash] = amount;
      });

      if (resultJson.total_page > resultJson.page) {
        await fetchData(resultJson.page + 1);
      }
    }
  };

  await fetchData(1);

  return withdrawnRecordObj;
};

/**
 * Get latest cached block number from Ginza
 * @param chainId chain id
 * @returns latest cached block number
 */
export const getGinzaLatestCachedBlockNumber = async (
  chainId: SupportedChainId
) => {
  let blockNumber: number = 0;

  const url = `${urls[chainId]}/stag/api/v1/cache/chains
  `;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();

  if (result.status === 200) {
    const chainList = resultJson.data;
    const matchedChain = chainList.filter(
      (i: { Chain: string }) => i.Chain === chains[chainId]
    );
    blockNumber = +matchedChain[0].LastCacheBlockNum;
  }

  return blockNumber;
};

/**
 * Get top positions from Ginza
 * @param tokenSymbol token symbol
 * @param chainId chain id
 * @param type open or closed
 * @param sortBy pnl / pnl_percent / earn_fee / fee_apr
 * @param timeRanges time ranges
 * @returns top positions
 */
export const getGinzaFactoryTopPositions = async (
  tokenSymbol: string,
  chainId: SupportedChainId,
  type: "open" | "closed",
  sortBy: "pnl" | "pnl_percent" | "earn_fee" | "fee_apr",
  timeRanges: TimeRangeProps
) => {
  let url = `${urls[chainId]}/stag/api/v1/calculate/factory/all/positions/${type}/${sortBy}?limit=5&want_token=${tokenSymbol}&chain=${chains[chainId]}`;

  if (timeRanges.endTime - timeRanges.startTime > 0) {
    url += `&start_time=${timeRanges.startTime}&end_time=${timeRanges.endTime}`;
  }

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson.data.records;
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get top users from Ginza
 * @param tokenSymbol token symbol
 * @param chainId chain id
 * @param timeRanges time ranges
 * @returns top users
 */
export const getGinzaFactoryTopUsers = async (
  tokenSymbol: string,
  chainId: SupportedChainId,
  timeRanges: TimeRangeProps
) => {
  let url = `${urls[chainId]}/stag/api/v2/calculate/factory/all/positions/closed/user_rank/sum_pnl?limit=10&want_token=${tokenSymbol}&chain=${chains[chainId]}`;

  if (timeRanges.endTime - timeRanges.startTime > 0) {
    url += `&start_time=${timeRanges.startTime}&end_time=${timeRanges.endTime}`;
  }

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson.data;
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get entry ticks of user's positions
 * @param userAddress user address
 * @param factoryAddress factory address
 * @param chainId chain id
 * @param wantTokenIsToken0 wantToken is token0
 * @returns entry ticks
 */
export const getGinzaOpenedPositionEntryTicks = async (
  userAddress: string,
  factoryAddress: string,
  chainId: SupportedChainId,
  wantTokenIsToken0: boolean
) => {
  const factoryId = await getGinzaFactoryIdByAddress(factoryAddress, chainId);

  const url = `${
    urls[chainId]
  }/stag/api/v1/diamond_factory/${factoryId}/positions?type=open&page=1&limit=100&user_addr=${toChecksumAddress(
    userAddress
  )}`;

  const options = {
    method: "get",
  };

  const result = await fetch(url, options);
  const resultJson = await result.json();

  // Succeed
  if (result.status === 200) {
    const { records } = resultJson;
    const entryTicks: { [key: string]: number } = {};
    records.forEach(
      (record: { PositionInfo: { PositionId: string }; EntryTick: number }) => {
        entryTicks[record.PositionInfo.PositionId] = wantTokenIsToken0
          ? +record.EntryTick
          : -record.EntryTick;
      }
    );
    return entryTicks;
  }

  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get user's position info by position id from Ginza
 * @param userAddress user address
 * @param factoryAddress factory address
 * @param chainId chain id
 * @param positionId position id
 * @returns position info
 */
export const getUserPositionByIdFromGinza = async (
  userAddress: string,
  factoryAddress: string,
  chainId: SupportedChainId,
  positionId: number
) => {
  const factoryId = await getGinzaFactoryIdByAddress(factoryAddress, chainId);

  const url = `${
    urls[chainId]
  }/stag/api/v1/diamond_factory/${factoryId}/positions?page=1&limit=100&user_addr=${toChecksumAddress(
    userAddress
  )}&position_id=${positionId}`;

  const options = {
    method: "get",
  };

  const result = await fetch(url, options);
  const resultJson = await result.json();

  // Succeed
  if (result.status === 200) {
    const { records } = resultJson;

    return records[0];
  }

  // Failed
  throw new Error(resultJson.error);
};

/**
 * Get user action records of lending pool and balance vault from Ginza
 * @param userAddress user address
 * @param contractAddress contract address
 * @param chainId chain id
 * @param actionType deposit or withdraw
 * @param contractType lending_pool or balance_vault
 * @param tokenAddress token address
 * @returns records
 */
export const getUserRecordFromGinza = async (
  userAddress: string,
  contractAddress: string,
  chainId: SupportedChainId,
  actionType: "deposit" | "withdraw",
  contractType: "lending_pool" | "balance_vault",
  tokenAddress?: string
) => {
  const records: RecordProps[] = [];

  const actions = {
    deposit: "deposits",
    withdraw: "withdraw",
  };

  const getRecord = async (_page: number) => {
    let url = `${urls[chainId]}/stag/api/v1/cache/${contractType}/logs/${
      actions[actionType]
    }?address=${toChecksumAddress(
      userAddress
    )}&${contractType}_addr=${toChecksumAddress(contractAddress)}&chain_tag=${
      chains[chainId]
    }&page=${_page}&limit=10`;

    if (contractType === "balance_vault" && tokenAddress) {
      url += `&token=${toChecksumAddress(tokenAddress)}`;
    }

    const options = {
      method: "get",
    };

    const result = await fetch(url, options);
    const resultJson = await result.json();

    if (result.status === 200) {
      const parsedRecords = resultJson.records.map((record: any) => {
        let amount = "";

        switch (actionType) {
          case "deposit":
            amount = record.AmountDeposited || record.Amount;
            break;
          case "withdraw":
            amount = record.AmountWithdrawn || record.Amount;
            break;
          default:
            break;
        }

        return {
          timestamp: record.Timestamp,
          txHash: record.TxHash,
          actionType: actionType,
          amount: amount,
        };
      });
      records.push(...parsedRecords);
      if (resultJson.total_page > resultJson.page) {
        await getRecord(+resultJson.page + 1);
      }
    }
  };

  await getRecord(1);

  return records;
};

/**
 * Get user's balance vault record from Ginza
 * @param userAddress user address
 * @param tokenAddress token address
 * @param chainId chain id
 * @returns records
 */
export const getUserBalanceVaultRecordFromGinza = async (
  userAddress: string,
  tokenAddress: string,
  chainId: SupportedChainId
) => {
  const depositRecords: RecordProps[] = await getUserRecordFromGinza(
    userAddress,
    ALL_VAULTS[chainId],
    chainId,
    "deposit",
    "balance_vault",
    tokenAddress
  );
  const withdrawalRecords: RecordProps[] = await getUserRecordFromGinza(
    userAddress,
    ALL_VAULTS[chainId],
    chainId,
    "withdraw",
    "balance_vault",
    tokenAddress
  );
  return [...depositRecords, ...withdrawalRecords].sort(
    (a, b) => +b.timestamp - +a.timestamp
  );
};

/**
 * Get user's lending pool record from Ginza
 * @param userAddress user address
 * @param lendingPoolAddress lending pool address
 * @param chainId chain id
 * @returns records
 */
export const getUserLendingPoolRecordFromGinza = async (
  userAddress: string,
  lendingPoolAddress: string,
  chainId: SupportedChainId
) => {
  const depositRecords = await getUserRecordFromGinza(
    userAddress,
    lendingPoolAddress,
    chainId,
    "deposit",
    "lending_pool"
  );
  const withdrawalRecords = await getUserRecordFromGinza(
    userAddress,
    lendingPoolAddress,
    chainId,
    "withdraw",
    "lending_pool"
  );

  return [...depositRecords, ...withdrawalRecords].sort(
    (a, b) => +b.timestamp - +a.timestamp
  );
};

/**
 * Get PCS Reward APR from Ginza
 * @param poolAddress pool address
 * @returns PCS Reward APR
 */
export const getGinzaPCSRewardApr = async (poolAddress: string) => {
  const url = `https://mpulj9l2j7.execute-api.ap-northeast-1.amazonaws.com/stag/api/v1/pcs/farm/apr`;
  const options = {
    method: "get",
  };

  let apr = 0;

  try {
    const result = await fetch(url, options);
    const resultJson = await result.json();
    if (result.status === 200) {
      const matchedItem = resultJson.find(
        (item: { [key: string]: any }) =>
          item.Pool.toUpperCase() === poolAddress.toUpperCase()
      );
      // If no matched item (Testnet), return the first item
      apr = matchedItem ? +matchedItem.FarmApr : resultJson[0].FarmApr;
    }
  } catch (e) {}

  return apr;
};

/**
 * Get user's staking record from Ginza
 * @param userAddress user address
 * @param stakingAddress staking address
 * @param chainId chain id
 * @returns records
 */
export const getUserStakingRecordFromGinza = async (
  userAddress: string,
  stakingAddress: string,
  chainId: SupportedChainId
) => {
  const records: RecordProps[] = [];

  const getRecord = async (_page: number) => {
    let url = `${
      urls[chainId]
    }/stag/api/v1/cache/staking_pool/asset/events?user=${toChecksumAddress(
      userAddress
    )}&staking_pool_addr=${toChecksumAddress(stakingAddress)}&chain_tag=${
      chains[chainId]
    }&page=${_page}&limit=10`;

    const options = {
      method: "get",
    };

    const result = await fetch(url, options);
    const resultJson = await result.json();

    const events: { [key: string]: string } = {
      RewardPaid: "claimReward",
      Withdrawn: "unstake",
      Staked: "stake",
    };

    if (result.status === 200) {
      const parsedRecords = resultJson.records.map((record: any) => {
        return {
          timestamp: record.Timestamp,
          txHash: record.TxHash,
          actionType: events[record.EventType],
          amount: record.Amount,
        };
      });
      records.push(...parsedRecords);
      if (resultJson.total_page > resultJson.page) {
        await getRecord(+resultJson.page + 1);
      }
    }
  };

  await getRecord(1);

  return records;
};

export const getAirdropFromGinza = async (
  userAddress: string,
  chainId: SupportedChainId
) => {

  const url = `${process.env.REACT_APP_UNIBOT_EBST_API_URL}/stag/api/v1/reward_program/user_reward/${userAddress}?network=${chains[chainId]}&reward_contract_addr=${diamondAddresses[chainId].airdrop}`;

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  
  if (result.status === 200) {
    return resultJson;
  }
  
  throw new Error(resultJson.error);
};

export const getPastRoundSharePricesFromGinze = async (
  chainId: SupportedChainId,
  vaultId: string
) => {
    let url = `${
      urls[chainId]
    }/stag/api/v1/invest_vault/${vaultId}/shares`;

    const options = {
      method: "get",
    };

    const result = await fetch(url, options);
    const resultJson = await result.json();

    if (result.status === 200) {
      return resultJson.data;
    }
    // Failed
    throw new Error(resultJson.error);
};
