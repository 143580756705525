const Colors = {
  primary: {
    default: "#00CC99",
    dark: "#5f8f7b",
  },
  success: {
    light: "#F6FFED",
    default: "#52C41A",
  },
  danger: {
    light: "#FFF1F0",
    default: "#e65f6a",
  },
  warning: {
    light: "#FFFBE6",
    default: "#FAAD14",
  },
  diamond: {
    primary: "#D0FB51",
    secondary: "#7B51FB",
  },
  gray1: "#FFFFFF",
  gray2: "#F7F7F7",
  gray3: "#E0E0E0",
  gray4: "#B2B2B2",
  gray5: "#8C8C8C",
  gray6: "#323232",
  gray7: "#000000",
  blue: "#0d6efd",
  midNightGreen: "#003D45",
  lightKhaki: "#DDFF1F",
  ivory: "#FFFFF2",
  backgroundGray1: "#242628",
  backgroundGray2: "#1d1d1d",
  backgroundGray3: "#09090a",
  backgroundGray4: "#64646433",
  backgroundGray5: "rgba(220, 220, 220, 0.1)",
  green: "#40c87f",
};

export default Colors;
