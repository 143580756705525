import { SupportedChainId, UnsupportedChainId } from "./chains";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { providers } from "ethers";
import { RPC_URLS } from "./rpc";

export const RPC_PROVIDERS: {
  [key: number]: StaticJsonRpcProvider;
} = {
  [UnsupportedChainId.MAINNET]: new providers.JsonRpcProvider(
    RPC_URLS[UnsupportedChainId.MAINNET]
  ),
  [SupportedChainId.GOERLI]: new providers.JsonRpcProvider(
    RPC_URLS[SupportedChainId.GOERLI]
  ),
  [SupportedChainId.OPTIMISM_GOERLI]: new providers.JsonRpcProvider(
    RPC_URLS[SupportedChainId.OPTIMISM_GOERLI]
  ),
  [SupportedChainId.ARBITRUM_ONE]: new providers.JsonRpcProvider(
    RPC_URLS[SupportedChainId.ARBITRUM_ONE]
  ),
  [SupportedChainId.POLYGON]: new providers.JsonRpcProvider(
    RPC_URLS[SupportedChainId.POLYGON]
  ),
  [SupportedChainId.POLYGON_MUMBAI]: new providers.JsonRpcProvider(
    RPC_URLS[SupportedChainId.POLYGON_MUMBAI]
  ),
  [SupportedChainId.BINANCE_SMART_CHAIN]: new providers.JsonRpcProvider(
    RPC_URLS[SupportedChainId.BINANCE_SMART_CHAIN]
  ),
  [SupportedChainId.BINANCE_TESTNET]: new providers.JsonRpcProvider(
    RPC_URLS[SupportedChainId.BINANCE_TESTNET]
  ),
};
