import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { ALL_REFERRALS } from "../utils/constant/referrals";

const useReferralIsExisted = () => {
  const [isExisted, setIsExisted] = useState<boolean>(false);

  const { chainId } = useWeb3React();

  useEffect(() => {
    if (chainId && !!ALL_REFERRALS[chainId]) {
      setIsExisted(true);
    }
  }, [chainId]);

  return isExisted;
};

export default useReferralIsExisted;
