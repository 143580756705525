import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import useReferralCode from "../../hooks/useReferralCode";
import useTwitterAccount from "../../hooks/useTwitterAccount";
import useUserNfts from "../../hooks/useUserNfts";
import Colors from "../../styles/Colors";
import Portfolio from "./Portfolio";
import Profile from "./Profile";
import Referral from "./Referral";
import SideBar from "./SideBar";
import Airdrop from "./Airdrop";

const AccountPage: React.FC = () => {
  const { accTab } = useParams();

  const { referralCode, updateReferralCode, referreeCount, referrerCode } =
    useReferralCode();
  const { nfts, isFetching } = useUserNfts();
  const { userName } = useTwitterAccount();
  const navigate = useNavigate();

  useEffect(() => {
    !accTab && navigate("/account/profile");
  }, [accTab, navigate]);

  const mainContents: Record<string, any> = {
    profile: (
      <Profile
        referrerCode={referrerCode}
        updateReferralCode={updateReferralCode}
        nfts={nfts}
        nftIsFetching={isFetching}
        twitterUserName={userName}
      />
    ),
    portfolio: <Portfolio />,
    referral: (
      <Referral
        referreeCount={referreeCount}
        referralCode={referralCode}
        updateReferralCode={updateReferralCode}
      />
    ),
    airdrop: <Airdrop />
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <RowContainer>
        <MarketsContainer>
          <SideBar />
        </MarketsContainer>
        <MainContentContainer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key={accTab}
        >
          {accTab && mainContents[accTab]}
        </MainContentContainer>
      </RowContainer>
    </Container>
  );
};

export default AccountPage;

const Container = styled(motion.div)`
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - 70px);
  overflow-y: hidden;
`;

const RowContainer = styled(motion.div)`
  display: flex;
  background-color: ${Colors.backgroundGray3};
  height: 100%;

  @media (max-width: 976px) {
    flex-direction: column;
  }
`;

const MarketsContainer = styled.div`
  background-color: ${Colors.backgroundGray2};
  flex: 0 0 200px;

  @media (max-width: 976px) {
    flex: none;
    width: 100%;
    position: fixed;
    order: 1;
    z-index: 1;
    bottom: 0;
    left: 0;
    border-top: 6px solid rgba(0, 0, 0, 0.3);
  }
`;

const MainContentContainer = styled(motion.div)`
  flex: 1;
  overflow-y: scroll;
  padding: 32px;

  @media (max-width: 976px) {
    padding-bottom: 100px;
  }
`;
