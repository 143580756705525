import { SupportedChainId } from "./chains";

type AddressProps = {
  tokens: {
    [key: string]: string;
  };
  uniPools: {
    [key: string]: string;
  };
  uniPositionManager: string;
};

export const arbitrumAddresses: AddressProps = {
  tokens: {
    WETH: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    USDC: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    GMX: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    ARB: "0x912ce59144191c1204e64559fe8253a0e49e6548",
    LDO: "0x13Ad51ed4F1B7e9Dc168d8a00cB3f4dDD85EfA60",
  },
  uniPools: {
    WETH_USDC_500: "0xc31e54c7a869b9fcbecc14363cf510d1c41fa443",
    WETH_USDC_3000: "0x17c14d2c404d167802b16c450d3c99f88f2c4f4d",
    WETH_GMX_10000: "0x80a9ae39310abf666a87c743d6ebbd0e8c42158e",
    // ARB_USDC_10000: "0xa8328bf492ba1b77ad6381b3f7567d942b000baf",
    WETH_ARB_3000: "0x92c63d0e701caae670c9415d91c474f686298f00",
    ARB_USDC_3000: "0x81c48d31365e6b526f6bbadc5c9aafd822134863",
    WETH_ARB_500: "0xc6f780497a95e246eb9449f5e4770916dcd6396a",
    USDC_ARB_3000: "0x81c48d31365e6b526f6bbadc5c9aafd822134863",
    ARB_USDC_500: "0xcda53b1f66614552f834ceef361a8d12a0b8dad8",
    WETH_LDO_10000: "0x25ab7dc4ddcacb6fe75694904db27602175245f1",
    WETH_LDO_3000: "0x52f9d14bed8ce6536da063aaf274ae2747ef4853"
  },
  uniPositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
};

export const goerliAddresses: AddressProps = {
  tokens: {
    WETH: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
    UNI: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    DAI: "0xdc31Ee1784292379Fbb2964b3B9C4124D8F89C60",
  },
  uniPools: {
    WETH_UNI: "0x07A4f63f643fE39261140DF5E613b9469eccEC86",
    WETH_DAI: "0x1738f9aAB1d370a6d0fd56a18f113DbD9e1DCd4e",
  },
  uniPositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
};

export const polygonMumbaiAddresses: AddressProps = {
  tokens: {
    WMATIC: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    DERC20: "0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1",
  },
  uniPools: {
    WMATIC_DERC20_500: "0x5453dC68Cc19378E42d8fb543b773015100fD15C",
  },
  uniPositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
};

export const optimismGoerliAddresses: AddressProps = {
  tokens: {
    DUSDC: "0x131F97986Af73162540F04c18bfF771603BbDfe5",
    WETH: "0xE51080bbe5EF1D4f12D2b432577EAc1b0E59b471",
  },
  uniPools: {
    WETH_DUSDC_500: "0xF2af99Fa12f7dF87318B417497a08216eb54618a",
  },
  uniPositionManager: "0x39Ca85Af2F383190cBf7d7c41ED9202D27426EF6",
};

export const polygonAddresses: AddressProps = {
  tokens: {
    USDC: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    WETH: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    WMATIC: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  },
  uniPools: {
    WETH_USDC_500: "0x45dda9cb7c25131df268515131f647d726f50608",
    MATIC_WETH_500: "0x86f1d8390222a3691c28938ec7404a1661e618e0",
  },
  uniPositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
};

export const bnbAddresses: AddressProps = {
  tokens: {
    USDT: "0x55d398326f99059ff775485246999027b3197955",
    CAKE: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    WBNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
  },
  uniPools: {
    CAKE_USDT_2500: "0x7f51c8aaa6b0599abd16674e2b17fec7a9f674a1",
    CAKE_WBNB_2500: "0x133B3D95bAD5405d14d53473671200e9342896BF"
  },
  uniPositionManager: "0x46A15B0b27311cedF172AB29E4f4766fbE7F4364",
};

export const bnbTestnetAddresses: AddressProps = {
  tokens: {
    BUSD: "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814",
    CAKE2: "0x8d008B313C1d6C7fE2982F62d32Da7507cF43551",
    WBNB: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
  },
  uniPools: {
    CAKE2_BUSD_2500: "0x427d29C609A85AA3aaF87Aff65C392D72588ceC2",
  },
  uniPositionManager: "0x427bF5b37357632377eCbEC9de3626C71A5396c1",
};

export const V3_POSITION_MANAGERS = {
  [SupportedChainId.ARBITRUM_ONE]: arbitrumAddresses.uniPositionManager,
  [SupportedChainId.GOERLI]: goerliAddresses.uniPositionManager,
  [SupportedChainId.POLYGON_MUMBAI]: polygonMumbaiAddresses.uniPositionManager,
  [SupportedChainId.OPTIMISM_GOERLI]:
    optimismGoerliAddresses.uniPositionManager,
  [SupportedChainId.POLYGON]: polygonAddresses.uniPositionManager,
  [SupportedChainId.BINANCE_SMART_CHAIN]: bnbAddresses.uniPositionManager,
  [SupportedChainId.BINANCE_TESTNET]: bnbTestnetAddresses.uniPositionManager,
};

export const ALL_TOKENS: { [key: string]: { [key: string]: string } } = {
  [SupportedChainId.ARBITRUM_ONE]: arbitrumAddresses.tokens,
  [SupportedChainId.GOERLI]: goerliAddresses.tokens,
  [SupportedChainId.POLYGON_MUMBAI]: polygonMumbaiAddresses.tokens,
  [SupportedChainId.OPTIMISM_GOERLI]: optimismGoerliAddresses.tokens,
  [SupportedChainId.POLYGON]: polygonAddresses.tokens,
  [SupportedChainId.BINANCE_SMART_CHAIN]: bnbAddresses.tokens,
  [SupportedChainId.BINANCE_TESTNET]: bnbTestnetAddresses.tokens,
};

export const ALL_UNI_POOLS: { [key: string]: { [key: string]: string } } = {
  [SupportedChainId.ARBITRUM_ONE]: arbitrumAddresses.uniPools,
  [SupportedChainId.GOERLI]: goerliAddresses.uniPools,
  [SupportedChainId.POLYGON_MUMBAI]: polygonMumbaiAddresses.uniPools,
  [SupportedChainId.OPTIMISM_GOERLI]: optimismGoerliAddresses.uniPools,
  [SupportedChainId.POLYGON]: polygonAddresses.uniPools,
  [SupportedChainId.BINANCE_SMART_CHAIN]: bnbAddresses.uniPools,
  [SupportedChainId.BINANCE_TESTNET]: bnbTestnetAddresses.uniPools,
};
