import React, { useRef, useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import Button from "../../../components/Button";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { isMobile } from "../../../utils/userAgent";
import { faHandPointUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import background from "../../../images/performance_background.png";
import { getTruncNum } from "../../../utils/common";
import CustomModal from "../../../components/Modal";
import t from "../../../utils/content";
import { UNIBOT_V2_APP_URL } from "../../../utils/constant/urls";
import useReferralCode from "../../../hooks/useReferralCode";

const ShareModal: React.FC<{
  isShowModal: boolean;
  setShowModal: (v: boolean) => void;
  position: {
    leverage: number;
    feeApr: number;
    fee: number;
    wantAmount: number;
    startTime: number;
    currentTime: number;
    tokenSymbol: string;
    tokenDecimal: number;
    pair: string;
  };
}> = (props) => {
  const { isShowModal, setShowModal, position } = props;
  const { leverage, feeApr, fee, startTime, currentTime, tokenSymbol, pair } =
    position;

  const [imageUrl, setImageUrl] = useState("");

  const canvasRef = useRef(null);

  const { referralCode } = useReferralCode();

  const referralUrl =
    UNIBOT_V2_APP_URL + (referralCode ? `?referralCode=${referralCode}` : "");

  const downloadPng = () => {
    const link = document.createElement("a");
    link.download = "performance.jpeg";
    link.href = imageUrl;
    link.click();
  };

  const getPrefix = (num: number) => {
    return num > 0 ? "+" : num < 0 ? "-" : "";
  };

  const getImageUrl = async () => {
    if (canvasRef.current === null) {
      return;
    }
    const element = canvasRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/png");
    setImageUrl(data);
  };

  useEffect(() => {
    getImageUrl();
  }, [canvasRef, position]);

  function makeHumanReadable(num: number, singular: string) {
    return num > 0
      ? num + (num === 1 ? ` ${singular}, ` : ` ${singular}s, `)
      : "";
  }

  // Generate Duration String
  function toDaysMinutesSeconds(totalSeconds: number) {
    if (totalSeconds < 3600) {
      const minutes = +getTruncNum(totalSeconds / 60, 2);
      const minutesStr = makeHumanReadable(minutes, "minute");
      return `${minutesStr}`.replace(/,\s*$/, "");
    } else if (totalSeconds < 3600 * 24) {
      const hours = +getTruncNum(totalSeconds / 3600, 2);
      const hoursStr = makeHumanReadable(hours, "hour");
      return `${hoursStr}`.replace(/,\s*$/, "");
    } else {
      const days = +getTruncNum(totalSeconds / (3600 * 24), 2);
      const daysStr = makeHumanReadable(days, "day");
      return `${daysStr}`.replace(/,\s*$/, "");
    }
  }

  return (
    <CustomModal
      title={t.factory.shareYourPerformance}
      show={isShowModal}
      onHide={() => setShowModal(false)}
    >
      <ImageContainer>
        {!imageUrl && (
          <ChartContainer ref={canvasRef}>
            <Image alt={"performanceBackground"} src={background} />
            <TextContainer>
              <PairRow>
                <LeverageText>{leverage}X</LeverageText>
                <Line />
                <LeverageText>{pair}</LeverageText>
              </PairRow>
              <AprText>
                {`${getPrefix(position.feeApr)}${getTruncNum(feeApr, 2)}`}%
              </AprText>
              <LabelText>{t.factory.earnedFee}</LabelText>
              <PriceText>
                {getTruncNum(fee, 6)} {tokenSymbol}
              </PriceText>
              <LabelText>{t.factory.duration}</LabelText>
              <PriceText>
                {toDaysMinutesSeconds(currentTime - startTime)}
              </PriceText>
              <QRCodeContainer>
                <QRCode
                  size={128}
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                  value={referralUrl}
                  viewBox={`0 0 128 128`}
                  level="M"
                />
              </QRCodeContainer>
              {referralCode && (
                <>
                  <LabelText>Referral Code</LabelText>
                  <ReferralCodeText>{referralCode}</ReferralCodeText>
                </>
              )}
            </TextContainer>
          </ChartContainer>
        )}
        {imageUrl && (
          <Fragment>
            <Image alt={"performance"} src={imageUrl} style={{ zIndex: 1 }} />
          </Fragment>
        )}
      </ImageContainer>
      {isMobile ? (
        <Row>
          <Icon icon={faHandPointUp} />
          <Text>{t.factory.longPressToDownload}</Text>
        </Row>
      ) : (
        <BtnContainer>
          <Button
            btnText={t.factory.download}
            onClick={downloadPng}
            disabled={!imageUrl}
            isLoading={false}
            btnType={"primary"}
          />
        </BtnContainer>
      )}
    </CustomModal>
  );
};

export default ShareModal;

const BtnContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

const ImageContainer = styled.div`
  margin: auto;
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 576px) {
    height: 500px;
  }
`;

const ChartContainer = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
`;

const TextContainer = styled.div`
  position: absolute;
  left: 30px;
  top: 110px;
  @media (max-width: 576px) {
    top: 100px;
  }
`;

const IcebergText = styled.h3`
  color: ${Colors.lightKhaki};
  font-family: Iceberg, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
`;

const TTFirsText = styled.h3`
  color: ${Colors.ivory};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
`;

const PairRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const LeverageText = styled(TTFirsText)`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
`;

const Line = styled.div`
  width: 44px;
  height: 1px;
  background-color: ${Colors.lightKhaki};
  margin: 0 8px;
`;

const AprText = styled(IcebergText)`
  font-size: 40px;
  line-height: 44px;
  margin-top: 16px;
  margin-bottom: 30px;
  @media (max-width: 576px) {
    font-size: 36px;
    line-height: 40px;
  }
`;

const LabelText = styled(TTFirsText)`
  font-size: 11px;
  line-height: 18px;
  font-weight: 600;
  margin-top: 12px;
  text-transform: uppercase;
`;

const PriceText = styled(IcebergText)`
  font-size: 20px;
  line-height: 24px;
  margin-top: 6px;
  /* text-transform: uppercase; */
`;

const QRCodeContainer = styled.div`
  margin-top: 30px;
  padding: 2px;
  width: 54px;
  background-color: ${Colors.gray1};
`;

const ReferralCodeText = styled(TTFirsText)`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-top: 6px;
`;

const Image = styled.img`
  margin: auto;
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  @media (max-width: 576px) {
    height: 500px;
  }
`;

const Text = styled.h3`
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.gray3};
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${Colors.gray3};
  font-size: 16px;
  margin-right: 8px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
