import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import {
  getUserLendingPoolDepositedAmount,
  getUserLendingPoolWithdrawnAmount,
  getExchangeRate,
  getUserPoolShares,
  getUserStakedAmount,
  getUserStakeRewardAmount,
} from "../utils/api/lendingPoolApi";
import { isSupportedChain } from "../utils/constant/chains";
import { getTruncNum } from "../utils/common";

export interface IUserPoolSupply {
  depositedAmount: number | null;
  withdrawnAmount: number | null;
  estimatedExchangeRate: number;
  shares: string | null;
  balance: string | null;
  earned: string | null;
  updateUserSupply: () => void;
  stakedAmount: string | null;
  rewardAmount: string | null;
  stakedAmountInPoolToken: string | null;
}

const useUserPoolSupply = (
  lendingPoolAddress: string,
  tokenDecimal: number,
  stakingAddress?: string
) => {
  const { account, chainId, provider } = useWeb3React();

  const [depositedAmount, setDepositedAmount] = useState<number | null>(null);
  const [withdrawnAmount, setWithdrawnAmount] = useState<number | null>(null);
  const [estimatedExchangeRate, setEstimatedExchangeRate] = useState<
    number | null
  >(null);
  const [shares, setShares] = useState<string | null>(null);
  const [balance, setBalance] = useState<string | null>(null);
  const [earned, setEarned] = useState<string | null>(null);
  const [stakedAmount, setStakedAmount] = useState<string | null>(null);
  const [rewardAmount, setRewardAmount] = useState<string | null>(null);
  const [stakedAmountInPoolToken, setStakedAmountInPoolToken] = useState<
    string | null
  >(null);

  const getWithdrawnAmount = useCallback(async () => {
    if (account && isSupportedChain(chainId)) {
      const amount = await getUserLendingPoolWithdrawnAmount(
        account,
        lendingPoolAddress,
        chainId,
        tokenDecimal,
        provider
      );
      setWithdrawnAmount(amount);
    } else {
      setWithdrawnAmount(0);
    }
  }, [account, lendingPoolAddress, chainId, tokenDecimal, provider]);

  const getExchangeRates = useCallback(async () => {
    if (!chainId) return;
    const rate = await getExchangeRate(lendingPoolAddress, chainId, provider);
    setEstimatedExchangeRate(rate);
  }, [chainId, lendingPoolAddress, provider]);

  const getShares = useCallback(async () => {
    if (account && lendingPoolAddress && provider) {
      const shares = await getUserPoolShares(
        account,
        lendingPoolAddress,
        tokenDecimal,
        provider
      );
      setShares(shares);
    } else {
      setShares("0");
    }
  }, [account, lendingPoolAddress, provider, tokenDecimal]);

  const getDepositedAmount = useCallback(async () => {
    if (account && isSupportedChain(chainId)) {
      const amount = await getUserLendingPoolDepositedAmount(
        account,
        lendingPoolAddress,
        chainId,
        tokenDecimal,
        provider
      );
      if (amount !== 0) {
        // 有兩種狀況 amount 會是 0
        // 1. 真的是 0 => 那也不用 setDepositedAmount 來顯示 earn，因為也是 0
        // 2. API 有問題 => 那也不用 setDepositedAmount 來顯示 earn，因為算不出來
        setDepositedAmount(amount);
      }
    } else {
    }
  }, [account, lendingPoolAddress, chainId, tokenDecimal, provider]);

  const getStakedAmount = useCallback(async () => {
    if (account && isSupportedChain(chainId) && stakingAddress) {
      const amount = await getUserStakedAmount(
        account,
        stakingAddress,
        tokenDecimal,
        provider
      );
      setStakedAmount(amount);
    } else {
      setStakedAmount("0");
    }
  }, [account, chainId, tokenDecimal, provider, stakingAddress]);

  const getRewardAmount = useCallback(async () => {
    if (account && isSupportedChain(chainId) && stakingAddress) {
      const amount = await getUserStakeRewardAmount(
        account,
        stakingAddress,
        tokenDecimal,
        provider
      );
      setRewardAmount(amount);
    } else {
      setRewardAmount("0");
    }
  }, [account, chainId, tokenDecimal, provider, stakingAddress]);

  useEffect(() => {
    if (estimatedExchangeRate && shares) {
      setBalance(
        getTruncNum(+shares * estimatedExchangeRate, tokenDecimal)
      );
    }
  }, [shares, estimatedExchangeRate, tokenDecimal]);

  useEffect(() => {
    if (
      shares === null ||
      estimatedExchangeRate === null ||
      depositedAmount === null ||
      withdrawnAmount === null ||
      stakedAmountInPoolToken === null
    ) {
      return;
    }
    setEarned(
      (
        +shares * estimatedExchangeRate +
        +stakedAmountInPoolToken -
        (depositedAmount - withdrawnAmount)
      ).toString()
    );
  }, [
    shares,
    estimatedExchangeRate,
    depositedAmount,
    withdrawnAmount,
    tokenDecimal,
    stakedAmountInPoolToken,
  ]);

  useEffect(() => {
    if (stakedAmount !== null && estimatedExchangeRate !== null) {
      setStakedAmountInPoolToken(
        (+stakedAmount * estimatedExchangeRate).toString()
      );
    } else {
      setStakedAmountInPoolToken("0");
    }
  }, [stakedAmount, estimatedExchangeRate, tokenDecimal]);

  const refreshState = () => {
    setDepositedAmount(null);
    setWithdrawnAmount(null);
    setShares(null);
    setBalance(null);
    setEarned(null);
    setStakedAmount(null);
    setRewardAmount(null);
    setStakedAmountInPoolToken(null);
  };

  const updateUserSupply = useCallback(() => {
    refreshState();

    getDepositedAmount();
    getWithdrawnAmount();
    getExchangeRates();
    getShares();

    // if (stakingAddress) {
    getStakedAmount();
    getRewardAmount();
    // }
  }, [
    getDepositedAmount,
    getWithdrawnAmount,
    getExchangeRates,
    getShares,
    getStakedAmount,
    // stakingAddress,
    getRewardAmount,
  ]);

  useEffect(() => {
    if (!lendingPoolAddress || !account || !chainId) return;
    updateUserSupply();
  }, [lendingPoolAddress, account, chainId, updateUserSupply]);

  return {
    depositedAmount,
    withdrawnAmount,
    estimatedExchangeRate,
    shares,
    balance,
    earned,
    updateUserSupply,
    stakedAmount,
    rewardAmount,
    stakedAmountInPoolToken,
  } as IUserPoolSupply;
};

export default useUserPoolSupply;
