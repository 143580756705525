import { BigNumber, ethers } from "ethers";
import referralAbi from "../../abi/referral.json";
import { showErrorAlert, showPendingAlert } from "../showAlert";
import { ALL_REFERRALS, ALL_REWARD_VESTING } from "../constant/referrals";
import rewardVestingAbi from "../../abi/rewardVesting.json";
import t from "../content";

/**
 * Create referral code
 * @param userAddress user address
 * @param chainId chain id
 * @param provider provider
 * @param code referral code
 * @returns transaction result
 */
export const createReferralCode = async (
  userAddress: string,
  chainId: number,
  provider: any,
  code: string
) => {
  let res;

  const signer = provider.getSigner(userAddress);
  const referral = new ethers.Contract(
    ALL_REFERRALS[chainId],
    referralAbi,
    signer
  );

  try {
    res = await referral.createReferralCode(userAddress, code);
    await showPendingAlert(
      res.hash,
      t.succeed.createReferralCodeSucceed,
      provider
    );
  } catch (error: any) {
    if (error.code === "ACTION_REJECTED") {
      showErrorAlert(t.error.deniedTransaction);
    } else if (
      error.message.includes(
        "This referral code has already been assigned to an address."
      )
    ) {
      showErrorAlert(t.error.referralCodeAssignedAlready);
    } else if (
      error.message.includes(
        "This address already has a referral code assigned."
      )
    ) {
      showErrorAlert(t.error.referralCodeCreatedAlready);
    } else {
      showErrorAlert(t.error.createReferralCodeFailed);
    }
  }

  return res;
};

/**
 * Set referral code
 * @param userAddress user address
 * @param chainId chain id
 * @param provider provider
 * @param code referral code
 * @returns transaction result
 */
export const setReferralCode = async (
  userAddress: string,
  chainId: number,
  provider: any,
  code: string
) => {
  let res;

  const signer = provider.getSigner(userAddress);
  const referral = new ethers.Contract(
    ALL_REFERRALS[chainId],
    referralAbi,
    signer
  );

  try {
    res = await referral.setReferralCode(code);
    await showPendingAlert(
      res.hash,
      t.succeed.setReferralCodeSucceed,
      provider
    );
  } catch (error: any) {
    if (error.code === "ACTION_REJECTED") {
      showErrorAlert(t.error.deniedTransaction);
    } else if (
      error.message.includes(
        "You cannot be a referee of a referral code you own"
      )
    ) {
      showErrorAlert(t.error.cantUseOwnReferralCode);
    } else if (error.message.includes("referral code has already been set")) {
      showErrorAlert(t.error.referralCodeSetAlready);
    } else if (
      error.message.includes("No referral code was supplied or found")
    ) {
      showErrorAlert(t.error.referralCodeNotExist);
    } else {
      showErrorAlert(t.error.setReferralCodeFailed);
    }
  }

  return res;
};

/**
 * Claim referral rewards by week number
 * @param userAddress user address
 * @param chainId chain id
 * @param provider provider
 * @param claimWeeks claim weeks
 * @returns transaction result
 */
export const claimReferralRewards = async (
  userAddress: string,
  chainId: number,
  provider: any,
  claimWeeks: (number | string[] | BigNumber)[][]
) => {
  let res;

  const signer = provider.getSigner(userAddress);
  const rewardVesting = new ethers.Contract(
    ALL_REWARD_VESTING[chainId],
    rewardVestingAbi,
    signer
  );

  try {
    res = await rewardVesting.claimWeeks(userAddress, claimWeeks);
    await showPendingAlert(res.hash, t.succeed.claimRewardSucceed, provider);
  } catch (error: any) {
    if (error.code === "ACTION_REJECTED") {
      showErrorAlert(t.error.deniedTransaction);
    } else if (error.message.includes("Invalid claim")) {
      showErrorAlert(t.error.invalidClaim);
    } else {
      showErrorAlert(t.error.claimRewardFailed);
    }
  }

  return res;
};

/**
 * Check if the reward has already been claimed
 * @param userAddress user address
 * @param chainId chain id
 * @param provider provider
 * @param weekNum week number
 * @returns true if claimed, false otherwise
 */
export const getRewardClaimStatus = async (
  userAddress: string,
  chainId: number,
  provider: any,
  weekNum: number
) => {
  let res = false;

  const signer = provider.getSigner(userAddress);
  const rewardVesting = new ethers.Contract(
    ALL_REWARD_VESTING[chainId],
    rewardVestingAbi,
    signer
  );

  try {
    res = await rewardVesting.claimed(weekNum, userAddress);
  } catch (error: any) {
    // console.log(error);
  }

  return res;
};
