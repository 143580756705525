import { toHex } from "./api/common";
import { isSupportedChain } from "./constant/chains";
import { networkParams } from "./constant/networks";

export const switchNetwork = async (_chainId: number) => {
  if (!isSupportedChain(_chainId)) return;

  try {
    await window?.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: toHex(+_chainId) }],
    });
  } catch (switchError: any) {
    if (switchError.code === 4902) {
      try {
        await window?.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [networkParams[_chainId]],
        });
      } catch (error) {
        // console.log(error);
      }
    }
  }
};
