import { BigNumber, ethers, utils } from "ethers";
import { isSupportedChain, SupportedChainId } from "../constant/chains";
import erc20Abi from "../../abi/erc20.json";
import { showErrorAlert as showError } from "../showAlert";
import t from "../content";

const MAINNET_URL_ENDPOINT = "";
const TESTNET_URL_ENDPOINT = "";

const urls = {
  [SupportedChainId.ARBITRUM_ONE]: `${MAINNET_URL_ENDPOINT}/46b1431e-2bc8-47a5-a28a-914d1bc8bab4`,
  [SupportedChainId.GOERLI]: `${TESTNET_URL_ENDPOINT}/6b429bf1-0b60-47d1-8b04-ac834a395205`,
  [SupportedChainId.POLYGON_MUMBAI]: `${TESTNET_URL_ENDPOINT}/6b429bf1-0b60-47d1-8b04-ac834a395205`,
  [SupportedChainId.OPTIMISM_GOERLI]: `${TESTNET_URL_ENDPOINT}/6b429bf1-0b60-47d1-8b04-ac834a395205`,
  [SupportedChainId.POLYGON]: `${MAINNET_URL_ENDPOINT}/09b1570a-2a71-4ab8-80e6-764b4f7a5369`,
  [SupportedChainId.BINANCE_SMART_CHAIN]: `${MAINNET_URL_ENDPOINT}/a29ce89d-ba63-4e48-b25c-f0ce6f69cd12`,
  [SupportedChainId.BINANCE_TESTNET]: `${TESTNET_URL_ENDPOINT}/6b429bf1-0b60-47d1-8b04-ac834a395205`,
};

/**
 * Get merkle proof from server
 * @param userAddress user address
 * @param chinId chain id
 * @returns merkle proof
 */
export const getProof = async (
  userAddress: string,
  chinId: SupportedChainId
) => {
  let url = `${urls[chinId]}/merkleproof?client_addr=${userAddress}`;

  const options = {
    method: "get",
  };

  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson.proof;
  }
  // Failed
  throw new Error(resultJson.error);
};

/**
 * Wait for transaction to be confirmed
 * @param provider provider
 * @param hash transaction hash
 * @returns transaction result
 */
export const waitForTransaction = async (provider: any, hash: string) => {
  const res = await provider.waitForTransaction(hash);
  return res;
};

/**
 * Get sliced address
 * @param address account address
 * @returns account address with sliced middle
 */
export const getSlicedAddress = (address: string) =>
  `${address.slice(0, 6)}...${address.slice(
    address.length - 4,
    address.length
  )}`;

/**
 * Check if user's environment has metamask
 * @returns true if metamask existed, false otherwise
 */
export function getIsMetaMask(): boolean {
  return window.ethereum?.isMetaMask ?? false;
}

/**
 * Check if user's environment has injected provider
 * @returns true if injected, false otherwise
 */
export function getIsInjected(): boolean {
  return Boolean(window.ethereum);
}

/**
 * Turn number to hex
 * @param num number
 * @returns hex string
 */
export const toHex = (num: number) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

/**
 * Give permission for the contract to access the token
 * @param userAddress user address
 * @param amount token amount
 * @param tokenAddress token address
 * @param tokenDecimal token decimal
 * @param contractAddress contract address
 * @param chainId chain id
 * @param provider provider
 * @returns true if succeed, false otherwise
 */
export const grantTokenApproval = async (
  userAddress: string,
  amount: string,
  tokenAddress: string,
  tokenDecimal: number,
  contractAddress: string,
  chainId: number,
  provider: any
) => {
  if (!isSupportedChain(chainId)) {
    showError(t.error.switchNetwork);
    return false;
  }

  const signer = provider.getSigner(userAddress);
  const token = new ethers.Contract(tokenAddress, erc20Abi, signer);
  const approveAmount = utils.parseUnits(amount.toString(), tokenDecimal);

  let isSucceed = false;

  try {
    const approveRes = await token.approve(contractAddress, approveAmount);
    const txRes = await waitForTransaction(provider, approveRes.hash);
    if (txRes.status === 1) {
      isSucceed = true;
    }
  } catch (e: any) {
    if (e.code === "ACTION_REJECTED") {
      showError(t.error.deniedTransaction);
    } else {
      showError(t.error.approveTokenFailed);
    }
  }

  return isSucceed;
};

/**
 * Check if user has granted enough allowance for the contract to access the token
 * @param userAddress user address
 * @param amount token amount
 * @param tokenAddress token address
 * @param tokenDecimal token decimal
 * @param contractAddress contract address
 * @param chainId chain id
 * @param provider provider
 * @returns true if succeed, false otherwise
 */
export const getTokenAllowanceIsEnough = async (
  userAddress: string,
  amount: string,
  tokenAddress: string,
  tokenDecimal: number,
  contractAddress: string,
  chainId: number,
  provider: any
) => {
  if (!isSupportedChain(chainId)) {
    showError(t.error.switchNetwork);
    return;
  }

  const signer = provider.getSigner(userAddress);
  const token = new ethers.Contract(tokenAddress, erc20Abi, signer);
  const approveAmount = utils.parseUnits(amount.toString(), tokenDecimal);
  const allowance = await token.allowance(userAddress, contractAddress);

  return allowance.gte(approveAmount);
};

/**
 * Get all event logs of the address
 * @param provider provider
 * @param address address
 * @param start start block
 * @param end end block
 * @returns all logs of the address
 */
export const getAllLogsOfAddress = async (
  provider: any,
  address: string,
  start: number,
  end: number
) => {
  let result: any = [];

  try {
    const getLogs = async (
      provider: any,
      address: string,
      start: number,
      end: number
    ) => {
      try {
        const res = await provider.getLogs({
          address,
          fromBlock: start,
          toBlock: end,
        });
        if (res.length > 0) {
          result.push(...res);
        }
      } catch (e: any) {
        if (e.data.message.includes("query returned more than")) {
          const middle = Math.round((start + end) / 2);
          await getLogs(provider, address, start, middle);
          await getLogs(provider, address, middle + 1, end);
        }
      }
    };

    await getLogs(provider, address, start, end);
  } catch (e) {}

  return result;
};

/**
 * Convert tick to price
 * @param tick price tick
 * @param decimal token decimal
 * @returns price
 */
export const convertTickToPrice = (tick: number, decimal: number) => {
  return 1.0001 ** +tick * 10 ** decimal;
};

/**
 * Convert price to tick
 * @param price price
 * @param borrowTokenDecimal borrow token decimal
 * @param wantTokenDecimal want token decimal
 * @param type floor or ceil
 * @returns price tick
 */
export const convertPriceToTick = (
  price: number,
  borrowTokenDecimal: number,
  wantTokenDecimal: number,
  type: "floor" | "ceil"
) => {
  return BigNumber.from(
    Math[type](
      Math.log(
        Math.trunc(price * 10 ** wantTokenDecimal) /
          10 ** wantTokenDecimal /
          10 ** (borrowTokenDecimal - wantTokenDecimal)
      ) / Math.log(1.0001)
    )
  );
};

/**
 * Get token price from Binance API
 * @param symbol token symbol
 * @returns token price
 */
export const getTokenPriceFromBinance = async (symbol: string) => {
  let url = `https://api.binance.com/api/v3/avgPrice?symbol=`;

  switch (symbol) {
    case "WETH":
      url += "ETHUSDT";
      break;
    case "WMATIC":
      url += "MATICUSDT";
      break;
    case "WBNB":
      url += "BNBUSDT";
      break;
    case "USDT":
      url += "USDCUSDT";
      break;
    // For PCS - BNB Testnet
    case "CAKE2":
      url += "CAKEUSDT";
      break;
    default:
      url += symbol.toUpperCase() + "USDT";
  }

  const options = {
    method: "get",
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return +resultJson.price;
  }
  // Failed
  throw new Error(resultJson.error);
};
