import React from "react";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import Colors from "../styles/Colors";

const Button: React.FC<{
  btnType: string;
  btnText: string;
  disabled: boolean;
  onClick: () => void;
  isLoading: boolean;
}> = (props) => {
  const { btnType, btnText, disabled, onClick, isLoading } = props;

  const getColor = (type: string) => {
    let color;
    switch (type) {
      case "primary":
        color = Colors.gray6;
        break;
      case "secondary":
        color = Colors.lightKhaki;
        break;
      default:
        color = Colors.gray6;
    }
    return color;
  };

  const getBackgroundColor = (type: string) => {
    let color;
    switch (type) {
      case "primary":
        color = Colors.lightKhaki;
        break;
      case "secondary":
        color = "transparent";
        break;
      default:
        color = Colors.lightKhaki;
    }
    return color;
  };

  const getBorder = (type: string) => {
    let border;
    switch (type) {
      case "primary":
        border = `1px solid ${Colors.lightKhaki}`;
        break;
      case "secondary":
        border = `1px solid ${Colors.lightKhaki}`;
        break;
      default:
        border = `1px solid ${Colors.lightKhaki}`;
    }
    return border;
  };

  const getHoverColor = (type: string) => {
    let color;
    switch (type) {
      case "primary":
        color = Colors.gray6;
        break;
      case "secondary":
        color = Colors.ivory;
        break;
      default:
        color = Colors.gray6;
    }
    return color;
  };

  const getHoverBackgroundColor = (type: string) => {
    let color;
    switch (type) {
      case "primary":
        color = Colors.ivory;
        break;
      case "secondary":
        color = "transparent";
        break;
      default:
        color = Colors.ivory;
    }
    return color;
  };

  const getHoverBorder = (type: string) => {
    let border;
    switch (type) {
      case "primary":
        border = `1px solid ${Colors.ivory}`;
        break;
      case "secondary":
        border = `1px solid ${Colors.ivory}`;
        break;
      default:
        border = `1px solid ${Colors.ivory}`;
    }
    return border;
  };

  return (
    <Btn
      type="button"
      color={getColor(btnType)}
      backgroundColor={getBackgroundColor(btnType)}
      border={getBorder(btnType)}
      hoverColor={getHoverColor(btnType)}
      hoverBackgroundColor={getHoverBackgroundColor(btnType)}
      hoverBorder={getHoverBorder(btnType)}
      disabled={disabled}
      onClick={onClick}
    >
      {isLoading && <ClipLoader color={getColor(btnType)} size={14} />}
      {btnText}
    </Btn>
  );
};

export default Button;

const Btn = styled.button<{
  backgroundColor: string;
  border: string;
  hoverBackgroundColor: string;
  hoverBorder: string;
  hoverColor: string;
}>`
  width: 100%;
  min-height: 32px;
  min-width: 100px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  padding: 4px 12px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  font-family: Fira Sans, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
    min-height: 28px;
    min-width: 80px;
  }
  :hover {
    background-color: ${(props) =>
      props.disabled ? props.backgroundColor : props.hoverBackgroundColor};
    border: ${(props) => (props.disabled ? props.border : props.hoverBorder)};
    color: ${(props) => (props.disabled ? props.color : props.hoverColor)};
  }
`;
