import { useEffect, useState } from "react";
import { ALL_PAIRS, PairProps } from "../utils/constant/pairs";
import { useNavigate } from "react-router-dom";
import { SupportedChainId, isSupportedChain } from "../utils/constant/chains";
import { useAppSelector } from "../hooks";

const defaultChainId = SupportedChainId.ARBITRUM_ONE;

const useFactoryPairs = () => {
  const navigate = useNavigate();

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const [pairs, setPairs] = useState<PairProps[] | null>(null);

  // Set Pair Info Based On Params (or Redirect)
  useEffect(() => {
    if (isSupportedChain(selectedChainId)) {
      const pairsUnderChainId = ALL_PAIRS[selectedChainId];
      setPairs(pairsUnderChainId);
    } else {
      const pairsUnderChainId = ALL_PAIRS[defaultChainId];
      setPairs(pairsUnderChainId);
    }
  }, [navigate, selectedChainId]);

  return pairs;
};

export default useFactoryPairs;
