import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { H4 } from "../../styles/Fonts";
import Colors from "../../styles/Colors";

const Pagination: React.FC<{
  currPage: number;
  maxPage: number;
  setPage: (v: (pre: number) => number) => void;
}> = ({ currPage, maxPage, setPage }) => {
  return (
    <PaginationContainer>
      <PaginationIcon
        icon={faAngleLeft}
        disabled={!(currPage > 1)}
        onClick={() => {
          if (currPage > 1) {
            setPage((pre) => pre - 1);
          }
        }}
      />
      <PaginationLabel>{`${currPage} / ${maxPage}`}</PaginationLabel>
      <PaginationIcon
        icon={faAngleRight}
        disabled={!(currPage < maxPage)}
        onClick={() => {
          if (currPage < maxPage) {
            setPage((pre) => pre + 1);
          }
        }}
      />
    </PaginationContainer>
  );
};

export default Pagination;

const PaginationContainer = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const PaginationLabel = styled(H4)`
  color: ${Colors.gray1};
  font-size: 14px;
  line-height: 22px;
`;

const PaginationIcon = styled(FontAwesomeIcon)<{ disabled: boolean }>`
  font-size: 12px;
  line-height: 22px;
  color: ${Colors.gray1};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  padding: 8px 12px;
  background-color: ${Colors.backgroundGray4};
  border-radius: 100px;
  :hover {
    cursor: ${(props) => (props.disabled ? "inherit" : "pointer")};
  }
  :active {
    transform: scale(0.9);
  }
`;
