import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { PoolProps } from "../../../utils/constant/pools";
import { useWeb3React } from "@web3-react/core";
import useUserPoolSupply from "../../../hooks/useUserPoolSupply";
import useLendingPoolState from "../../../hooks/useLendingPoolState";
import useTokenBalance from "../../../hooks/useTokenBalance";
import useIsUnderCorrectNetwork from "../../../hooks/useIsUnderCorrectNetwork";

import TokenSection from "./TokenSection";
import StakingSection from "./StakingSection";
import DepositModal from "../Modals/DepositModal";
import WithdrawModal from "../Modals/WithdrawModal";
import WrapEthModal from "../Modals/WrapEthModal";
import UnwrapWethModal from "../Modals/UnwrapWethModal";
import SelectBridgeModal from "../../../components/SelectBridgeModal";
import StakeModal from "../Modals/StakeModal";
import UnstakeModal from "../Modals/UnstakeModal";

export type ModalProps =
  | "Deposit"
  | "Withdraw"
  | "Wrap"
  | "Unwrap"
  | "Bridge"
  | "Staking"
  | "Unstaking";

const MarketItem: React.FC<{
  pool: PoolProps;
}> = ({ pool }) => {
  const {
    lendingPoolAddress: poolAddress,
    tokenAddress,
    tokenDecimal,
    tokenSymbol,
    deployedChainId,
    stakingAddress,
  } = pool;

  const { account } = useWeb3React();
  const isUnderCorrectNetwork = useIsUnderCorrectNetwork();
  const isConnected = useMemo(
    () => !!account && isUnderCorrectNetwork,
    [account, isUnderCorrectNetwork]
  );

  const [showModal, setShowModal] = useState<ModalProps | null>(null);
  const [isDepositing, setIsDepositing] = useState<boolean>(false);
  const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false);
  const [isWrapping, setIsWrapping] = useState<boolean>(false);
  const [isUnwrapping, setIsUnwrapping] = useState<boolean>(false);
  const [stakingIsShow, setStakingIsShow] = useState<boolean>(false);
  const [isStaking, setIsStaking] = useState<boolean>(false);
  const [isUnstaking, setIsUnstaking] = useState<boolean>(false);
  const [isClaiming, setIsClaiming] = useState<boolean>(false);

  const userPoolState = useUserPoolSupply(
    poolAddress,
    tokenDecimal,
    stakingAddress
  );
  const poolState = useLendingPoolState(
    poolAddress,
    tokenSymbol,
    tokenDecimal,
    deployedChainId,
    stakingAddress
  );
  const { tokenBalance, updateTokenBalance } = useTokenBalance({
    tokenAddress,
    tokenDecimal,
  });

  return (
    <>
      <Container>
        <TokenSection
          setShowModal={setShowModal}
          pool={pool}
          isDepositing={isDepositing}
          isWithdrawing={isWithdrawing}
          isWrapping={isWrapping}
          isUnwrapping={isUnwrapping}
          stakingIsShow={stakingIsShow}
          setStakingIsShow={setStakingIsShow}
          poolState={poolState}
          userPoolState={userPoolState}
          isConnected={isConnected}
        />
        <StakingSection
          stakingIsShow={stakingIsShow}
          setShowModal={setShowModal}
          userPoolState={userPoolState}
          pool={pool}
          isClaiming={isClaiming}
          setIsClaiming={setIsClaiming}
          poolState={poolState}
          isConnected={isConnected}
        />
      </Container>

      {/* Modals */}
      <DepositModal
        isShowModal={showModal === "Deposit"}
        onHideModal={() => setShowModal(null)}
        pool={pool}
        updatePoolState={poolState.updateLendingPoolState}
        updateUserSupply={userPoolState.updateUserSupply}
        setIsDepositing={setIsDepositing}
        tokenBalance={tokenBalance}
        updateTokenBalance={updateTokenBalance}
        showBridgeModal={() => {
          setShowModal("Bridge");
        }}
      />
      <WrapEthModal
        isShowModal={showModal === "Wrap"}
        onHideModal={() => setShowModal(null)}
        setIsWrapping={setIsWrapping}
        tokenBalance={tokenBalance}
        updateTokenBalance={updateTokenBalance}
        tokenAddress={tokenAddress}
        tokenSymbol={tokenSymbol}
      />
      <UnwrapWethModal
        isShowModal={showModal === "Unwrap"}
        onHideModal={() => setShowModal(null)}
        setIsUnwrapping={setIsUnwrapping}
        tokenBalance={tokenBalance}
        updateTokenBalance={updateTokenBalance}
        tokenAddress={tokenAddress}
        tokenSymbol={tokenSymbol}
      />
      <WithdrawModal
        isShowModal={showModal === "Withdraw"}
        onHideModal={() => setShowModal(null)}
        pool={pool}
        updatePoolState={poolState.updateLendingPoolState}
        updateUserSupply={userPoolState.updateUserSupply}
        balance={userPoolState.balance || "0"}
        setIsWithdrawing={setIsWithdrawing}
        updateTokenBalance={updateTokenBalance}
        stakedAmount={userPoolState.stakedAmount || "0"}
        lpTokenSymbol={poolState.lpTokenSymbol}
      />
      <SelectBridgeModal
        targetTokenAddress={tokenAddress}
        deployedChainId={deployedChainId}
        show={showModal === "Bridge"}
        onHide={() => {
          setShowModal("Deposit");
        }}
        tokenSymbol={tokenSymbol}
      />
      <StakeModal
        isShowModal={showModal === "Staking"}
        onHideModal={() => setShowModal(null)}
        pool={pool}
        updatePoolState={poolState.updateLendingPoolState}
        updateUserSupply={userPoolState.updateUserSupply}
        setIsStaking={setIsStaking}
        isStaking={isStaking}
        shares={userPoolState.shares !== null ? userPoolState.shares : "0"}
        lpTokenSymbol={poolState.lpTokenSymbol}
      />
      <UnstakeModal
        isShowModal={showModal === "Unstaking"}
        onHideModal={() => setShowModal(null)}
        pool={pool}
        updatePoolState={poolState.updateLendingPoolState}
        updateUserSupply={userPoolState.updateUserSupply}
        setIsUnstaking={setIsUnstaking}
        isUnstaking={isUnstaking}
        stakedAmount={userPoolState.stakedAmount || "0"}
        lpTokenSymbol={poolState.lpTokenSymbol}
      />
    </>
  );
};

export default MarketItem;

const Container = styled.div`
  background-color: ${Colors.backgroundGray4};
  border-radius: 8px;
`;
