import { useWeb3React } from "@web3-react/core";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../hooks";
import { updateSelectedChainId } from "../redux/accountSlice";
import Colors from "../styles/Colors";
import {
  CHAIN_IDS_TO_URL_IDS,
  CHAIN_URL_IDS_TO_IDS,
  isSupportedChain,
  SupportedChainId,
} from "../utils/constant/chains";
import { networkOptions } from "../utils/constant/networks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { ConnectionType } from "../utils/constant/connectors";
import { getConnection } from "../utils/constant/connectors";

const isProduction = process.env.REACT_APP_NODE_ENV === "PRODUCTION";

const NetworkSelectorBtn: React.FC<{ handleCloseNav: () => void }> = ({
  handleCloseNav,
}) => {
  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const allNetworkIds = isProduction
    ? Object.keys(networkOptions).filter(
        (chainId) =>
          +chainId !== SupportedChainId.GOERLI &&
          +chainId !== SupportedChainId.POLYGON_MUMBAI &&
          +chainId !== SupportedChainId.OPTIMISM_GOERLI &&
          +chainId !== SupportedChainId.BINANCE_TESTNET
      )
    : Object.keys(networkOptions).filter(
        (chainId) =>
          +chainId !== SupportedChainId.GOERLI &&
          +chainId !== SupportedChainId.POLYGON_MUMBAI &&
          +chainId !== SupportedChainId.OPTIMISM_GOERLI
      );
  const restNetworks = allNetworkIds.filter(
    (chainId) => +chainId !== +selectedChainId
  );

  const selectorRef = useRef<HTMLDivElement>(null);

  const [selectorIsOpen, setSelectorIsOpen] = useState<boolean>(false);

  const { chainId, hooks, connector } = useWeb3React();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { useSelectedIsActive } = hooks;
  const isActive = useSelectedIsActive(connector);
  const connectedWallet = localStorage.getItem("connectedWallet");

  const restNetworksExist = restNetworks.length > 0;

  useEffect(() => {
    if (!selectorIsOpen) return;
    function handleClick(event: Event) {
      if (
        selectorRef.current &&
        !selectorRef.current.contains(event.target as Node)
      ) {
        setSelectorIsOpen(false);
      }
    }
    window.addEventListener("click", handleClick);
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener("click", handleClick);
  }, [selectorIsOpen]);

  const switchNetwork = async (
    _chainId: number,
    connectedWallet: ConnectionType | null
  ) => {
    if (!isSupportedChain(_chainId)) return;
    if (!connectedWallet) return;

    const connector = getConnection(connectedWallet);

    try {
      await connector[0].activate(_chainId);
    } catch (e) {
      console.log("Switch network failed:", e);
    }
  };

  const handleNetworkSwitch = async (id: number) => {
    setSelectorIsOpen(false);
    handleCloseNav();

    const mainPath = pathname.split("/")[1];
    const isMainPathAccount = mainPath === "account";
    const chainName = CHAIN_IDS_TO_URL_IDS[id];
    const isNetworkId = !!CHAIN_URL_IDS_TO_IDS[mainPath];

    if (isActive) {
      if (chainId && id !== +chainId) {
        await switchNetwork(id, connectedWallet as ConnectionType | null);
        dispatch(updateSelectedChainId({ chainId: id }));
         navigate(
          isNetworkId
            ? `/${chainName}`
            : isMainPathAccount
            ? `/${mainPath}`
            : `/${mainPath}/${chainName}`
        );
      }
    } else {
      dispatch(updateSelectedChainId({ chainId: id }));
       navigate(
          isNetworkId
            ? `/${chainName}`
            : isMainPathAccount
            ? `/${mainPath}`
            : `/${mainPath}/${chainName}`
        );
    }
  };

  useEffect(() => {
    if (isActive && chainId !== selectedChainId && isSupportedChain(chainId)) {
      dispatch(updateSelectedChainId({ chainId: chainId }));
    }
  }, [isActive, chainId, selectedChainId, dispatch]);

  return (
    <Container ref={selectorRef}>
      <Button onClick={() => setSelectorIsOpen((pre) => !pre)}>
        <Image src={networkOptions[selectedChainId]?.icon} alt="eth icon" />
        <Text>{networkOptions[selectedChainId].label}</Text>
        {restNetworksExist && <Icon icon={faAngleDown} />}
      </Button>
      {restNetworksExist && (
        <SelectorsContainer isShow={selectorIsOpen}>
          {restNetworks.map((chainId) => (
            <SelectorBtn
              key={chainId}
              onClick={() => handleNetworkSwitch(+chainId)}
            >
              <SelectorIcon
                src={networkOptions[+chainId]?.icon}
                alt="eth icon"
              />
              <SelectorText>{networkOptions[+chainId].label}</SelectorText>
            </SelectorBtn>
          ))}
        </SelectorsContainer>
      )}
    </Container>
  );
};

export default NetworkSelectorBtn;

const Container = styled.div`
  position: relative;
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  width: 160px;
  border: 1px solid rgba(220, 220, 220, 0.3);
  border-radius: 4px;

  :hover {
    background-color: ${Colors.backgroundGray5};
  }

  @media (max-width: 1200px) {
    margin-right: 0px;
    margin-top: 18px;
    padding: 4px 0;
    justify-content: center;
    width: 180px;
  }
`;

const Image = styled.img`
  width: 24px;
  height: 20px;
  object-fit: contain;
`;

const Text = styled.h2`
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  color: ${Colors.ivory};
  margin-left: 4px;
  white-space: nowrap;
`;

const SelectorsContainer = styled.div<{ isShow: boolean }>`
  display: ${(props) => (props.isShow ? "block" : "none")};
  width: 160px;
  position: absolute;
  top: calc(100% + 12px);
  right: 0;
  background-color: ${Colors.backgroundGray2};
  border: 1px solid rgba(220, 220, 220, 0.3);
  border-radius: 4px;
  transition: 300ms ease-in-out all;
  @media (max-width: 1200px) {
    width: 180px;
    position: static;
    margin-top: 12px;
  }
`;

const SelectorBtn = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 0 8px 12px;
  border-radius: 4px;
  :hover {
    background-color: ${Colors.backgroundGray5};
  }
`;

const SelectorIcon = styled.img`
  flex: 0 0 28px;
  width: 22px;
  height: 20px;
  object-fit: contain;
`;

const SelectorText = styled.h3`
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  color: ${Colors.ivory};
  margin-left: 8px;
  text-align: left;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${Colors.ivory};
  margin-left: 8px;
`;
