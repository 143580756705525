import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { H4 } from "../../../styles/Fonts";

const navItems = [
  { navText: "Profile", url: "/account/profile" },
  { navText: "Portfolio", url: "/account/portfolio" },
  // { navText: "Referral", url: "/account/referral" },
  // { navText: "Airdrop", url: "/account/airdrop" },
];

const SideBar: React.FC = () => {
  return (
    <Container>
      {navItems.map((n, i) => (
        <NavContainer to={n.url} key={i}>
          <ActiveIcon />
          <NavText>{n.navText}</NavText>
        </NavContainer>
      ))}
    </Container>
  );
};

export default SideBar;

const NavContainer = styled(NavLink)`
  display: flex;
  gap: 12px;
  padding: 12px 0;
  margin: 8px 0;
  cursor: pointer;

  @media (max-width: 976px) {
    background-color: transparent;
    margin: 0 0;
    flex: 1;
    justify-content: center;

    &.active {
      background-color: ${Colors.backgroundGray1};
    }
  }
`;

const NavText = styled(H4)`
  color: ${Colors.ivory};
  font-size: 18px;
  font-weight: 600;

  ${NavContainer}.active & {
    color: ${Colors.lightKhaki};
  }

  @media (max-width: 976px) {
    font-size: 14px;
  }
`;

const ActiveIcon = styled.div`
  width: 6px;
  background-color: transparent;

  ${NavContainer}.active & {
    background-color: ${Colors.lightKhaki};
  }

  @media (max-width: 976px) {
    display: none;
  }
`;

const Container = styled.div`
  padding: 24px;

  @media (max-width: 976px) {
    display: flex;
    justify-content: space-around;
    padding: 0px;
  }
`;
