import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { H4 } from "../../../styles/Fonts";
import LabelWithHint from "../../../components/LabelWithHint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { getTruncNum, handleOpenV3PositionPage } from "../../../utils/common";
import { PairProps } from "../../../utils/constant/pairs";
import { UserClosedPositionProps } from "../../../redux/factorySlice";
import { isPanCakeSwap } from "../../../utils/constant/chains";
import PairTokenIcon from "../../../components/PairTokenIcon";
import {
  AMOUNT_DECIMAL,
  getPriceDecimal,
} from "../../../utils/constant/decimals";
import t from "../../../utils/content";
import NumberWithHint from "../../../components/NumberWithHint";

const ClosedPosition: React.FC<{
  pair: PairProps;
  position: UserClosedPositionProps;
}> = (props) => {
  const { position, pair } = props;
  const {
    positionId,
    startWantAmount,
    reserveAmount,
    endWantAmount,
    closePrice,
    eventName,
    startPrice,
    startTime,
    closeTime,
    upperTickPrice,
    lowerTickPrice,
    borrowRatio,
    reserveWantAmount,
    wantTokenFee,
    borrowTokenFee,
    cakeReward,
    rewardTokenValueInWantToken,
  } = position;
  const { wantToken, borrowToken, deployedChainId } = pair;
  const { tokenSymbol } = wantToken;
  const { tokenSymbol: borrowTokenSymbol } = borrowToken;

  const [isShow, setIsShow] = useState<boolean>(false);

  const tokenFeeIsFetched = wantTokenFee || borrowTokenFee;

  const isPancakeSwap = isPanCakeSwap(deployedChainId);

  const pnl =
    +endWantAmount - +startWantAmount - (+reserveAmount - +reserveWantAmount) +
    (rewardTokenValueInWantToken
      ? +rewardTokenValueInWantToken
      : 0);

  return (
    <Container>
      <Layer1Container>
        <ValueContainer>
          <Layer1Col>
            <LabelContainer>
              <HeadText>{t.factory.pair}</HeadText>
            </LabelContainer>
            <PairNameRow>
              <PairTokenIcon
                borrowTokenIcon={pair.borrowToken.tokenIcon}
                wantTokenIcon={pair.wantToken.tokenIcon}
              />
              <ValueText>{pair.label}</ValueText>
            </PairNameRow>
          </Layer1Col>
          <Layer1Col>
            <LabelContainer>
              <LabelWithHint
                labelComponent={<HeadText>{t.factory.event}</HeadText>}
                hintText=""
              />
            </LabelContainer>
            <ValueText>{eventName}</ValueText>
          </Layer1Col>
          <Layer1Col>
            <LabelContainer>
              <LabelWithHint
                labelComponent={<HeadText>{t.factory.openAmount}</HeadText>}
                hintText={t.factory.openAmountDesc}
              />
            </LabelContainer>
            <ValueText>
              {`${getTruncNum(
                +startWantAmount,
                AMOUNT_DECIMAL
              )} ${tokenSymbol}`}
            </ValueText>
          </Layer1Col>
          <Layer1Col>
            <LabelContainer>
              <LabelWithHint
                labelComponent={<HeadText>{t.factory.closeAmount}</HeadText>}
                hintText={t.factory.closeAmountDesc}
              />
            </LabelContainer>
            <NumberWithHint
              labelComponent={
                <ValueText>
                  {`${getTruncNum(
                    +endWantAmount +
                      (rewardTokenValueInWantToken
                        ? +rewardTokenValueInWantToken
                        : 0),
                    AMOUNT_DECIMAL
                  )} ${tokenSymbol}`}
                </ValueText>
              }
              hints={
                isPanCakeSwap(deployedChainId)
                  ? [
                      `• LP Amount: ${getTruncNum(
                        +endWantAmount,
                        AMOUNT_DECIMAL
                      )} ${wantToken.tokenSymbol}`,
                      `• CAKE Reward: ${getTruncNum(
                        cakeReward ? +cakeReward : 0,
                        AMOUNT_DECIMAL
                      )} CAKE`,
                      `(≈ ${getTruncNum(
                        rewardTokenValueInWantToken
                          ? +rewardTokenValueInWantToken
                          : 0,
                        AMOUNT_DECIMAL
                      )} ${wantToken.tokenSymbol})`,
                    ]
                  : []
              }
            />
          </Layer1Col>
        </ValueContainer>
        <BtnContainer>
          <Arrow
            icon={isShow ? faAngleUp : faAngleDown}
            color={Colors.ivory}
            onClick={() => setIsShow((pre) => !pre)}
          />
        </BtnContainer>
      </Layer1Container>
      <Layer2Container isShow={isShow}>
        <Layer2Row>
          <Layer2Col>
            <Row>
              <LabelWithHint
                labelComponent={
                  <Layer2Label>{t.factory.positionId}</Layer2Label>
                }
                hintText={t.factory.positionIdDesc}
              />
              <Icon
                icon={faArrowUpRightFromSquare}
                onClick={() => {
                  handleOpenV3PositionPage(
                    positionId.toString(),
                    deployedChainId,
                    isPancakeSwap
                  );
                }}
              />
            </Row>
            <Layer2Value>{positionId}</Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.pnl}</Layer2Label>}
              hintText={t.factory.closedPositionPnlDesc}
            />
            <NumberWithHint
              labelComponent={
                <Layer2Value>
                  {`${getTruncNum(pnl, AMOUNT_DECIMAL)} ${tokenSymbol}`}
                </Layer2Value>
              }
              hints={
                isPanCakeSwap(deployedChainId)
                  ? [
                      `• LP PnL: ${getTruncNum(
                        pnl -
                          (rewardTokenValueInWantToken
                            ? +rewardTokenValueInWantToken
                            : 0),
                        AMOUNT_DECIMAL
                      )} ${wantToken.tokenSymbol}`,
                      `• CAKE Reward: ${getTruncNum(
                        cakeReward ? +cakeReward : 0,
                        AMOUNT_DECIMAL
                      )} CAKE`,
                      `(≈ ${getTruncNum(
                        rewardTokenValueInWantToken
                          ? +rewardTokenValueInWantToken
                          : 0,
                        AMOUNT_DECIMAL
                      )} ${wantToken.tokenSymbol})`,
                    ]
                  : []
              }
            />
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.startPrice}</Layer2Label>}
              hintText=""
            />
            <Layer2Value>{`${getTruncNum(
              +startPrice,
              getPriceDecimal(+startPrice)
            )} ${tokenSymbol} ${
              t.factory.per
            } ${borrowTokenSymbol}`}</Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.closePrice}</Layer2Label>}
              hintText=""
            />
            <Layer2Value>{`${getTruncNum(
              +closePrice,
              getPriceDecimal(+closePrice)
            )} ${tokenSymbol} ${
              t.factory.per
            } ${borrowTokenSymbol}`}</Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.startTime}</Layer2Label>}
              hintText=""
            />
            <Layer2Value>
              {new Date(+startTime * 1000).toLocaleString("en")}
            </Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.closedTime}</Layer2Label>}
              hintText=""
            />
            <Layer2Value>
              {new Date(closeTime * 1000).toLocaleString("en")}
            </Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.range}</Layer2Label>}
              hintText=""
            />
            <Layer2Value>
              {lowerTickPrice && upperTickPrice
                ? `${getTruncNum(
                    +lowerTickPrice,
                    getPriceDecimal(+lowerTickPrice)
                  )} - ${getTruncNum(
                    +upperTickPrice,
                    getPriceDecimal(+upperTickPrice)
                  )} ${wantToken.tokenSymbol} ${t.factory.per} ${
                    borrowToken.tokenSymbol
                  }`
                : "-"}
            </Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.uniswapFee}</Layer2Label>}
              hintText={t.factory.uniswapFeeDesc}
            />
            {tokenFeeIsFetched ? (
              <Layer2Value>{`${getTruncNum(+wantTokenFee, AMOUNT_DECIMAL)} ${
                wantToken.tokenSymbol
              } & ${getTruncNum(+borrowTokenFee, AMOUNT_DECIMAL)} ${
                borrowToken.tokenSymbol
              }`}</Layer2Value>
            ) : (
              <Layer2Value>-</Layer2Value>
            )}
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.leverage}</Layer2Label>}
              hintText=""
            />
            <Layer2Value>{+borrowRatio + 1}X</Layer2Value>
          </Layer2Col>
          {isPancakeSwap && (
            <Layer2Col>
              <LabelWithHint
                labelComponent={<Layer2Label>CAKE Reward</Layer2Label>}
                hintText={""}
              />
              <Layer2Value>
                {cakeReward
                  ? `${getTruncNum(
                      +(cakeReward || 0),
                      AMOUNT_DECIMAL
                    )} CAKE (≈ ${getTruncNum(
                      rewardTokenValueInWantToken
                        ? +rewardTokenValueInWantToken
                        : 0,
                      AMOUNT_DECIMAL
                    )} ${wantToken.tokenSymbol}})`
                  : "-"}
              </Layer2Value>
            </Layer2Col>
          )}
        </Layer2Row>
      </Layer2Container>
    </Container>
  );
};

export default ClosedPosition;

const Container = styled.div``;

const LabelContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    flex: initial;
  }
`;

const HeadText = styled(H4)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: ${Colors.gray1};
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const ValueText = styled(H4)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: ${Colors.gray1};
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
  @media (max-width: 768px) {
    flex: initial;
  }
`;

const Layer1Container = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  button {
    border-radius: 4px;
    font-size: 13px;
    line-height: 18px;
    padding: 4px 8px;
    width: auto;
    margin: auto;
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 12px 0;
  }
`;

const ValueContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Layer1Col = styled.div`
  flex: 1 1 200px;
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    flex: inherit;
    justify-content: space-between;
  }
`;

const Layer2Container = styled.div<{ isShow: boolean }>`
  max-height: ${(props) => (props.isShow ? "600px" : "0px")};
  transition: all ease 300ms;
  overflow: hidden;
`;

const Layer2Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${Colors.backgroundGray4};
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const Layer2Col = styled.div`
  width: 50%;
  padding: 6px 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
  }
`;

const Layer2Label = styled(H4)`
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  color: ${Colors.gray1};
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const Layer2Value = styled(H4)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: ${Colors.gray1};
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    text-align: right;
  }
`;

const BtnContainer = styled.div`
  flex: 0 0 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    flex: initial;
    margin: 6px 0;
  }
`;

const Arrow = styled(FontAwesomeIcon)`
  padding: 4px 8px;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 8px;
  color: ${Colors.ivory};
  font-size: 12px;
  cursor: pointer;
`;

const PairNameRow = styled.div`
  display: flex;
  align-items: center;
`;
