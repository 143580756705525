import { PCS_V3_APP_URL, UNISWAP_V3_INFO_URL, ARBITRUM_URL, BNB_URL, POLYGON_URL, TOKOCRYPTO_URL, RAZER_URL, IVCRYPTO_URL, RBCAP_URL, SPARTAN_URL, VERTEX_VENTURES_URL, PERPETUAL_URL } from "../constant/urls";

const landing = {
  slogan: "Elevate Your Liquidity",
  subTitle: "Provide liquidity with leverage and automation",
  startBtn: "Start",
  supportDexDesc: "We support the top decentralized exchanges",
  supportDex: [
    {
      url: UNISWAP_V3_INFO_URL,
      alt: "UNISWAP",
      logo: "uniswap",
    },
    {
      url: PCS_V3_APP_URL,
      alt: "PancakeSwap",
      logo: "pancakeswap"
    }
  ],
  features: [
    {
      icon: "airdrop",
      title: "Airdrop",
      desc: "Claim your Airdrop tokens",
      btn: "Check Now",
      nav: "/account/airdrop",
      highlight: "New",
    },
    {
      icon: "coins",
      title: "Trade",
      desc: "Provide liquidity with up to 3X leverage and automation",
      btn: "Trade Now",
      nav: "/factory"
    },
    {
      icon: "handCoins",
      title: "Earn",
      desc: "Deposit your asset with low risk and sustainable yield",
      btn: "Earn Now",
      nav: "/pool"
    },
    {
      icon: "chartBar",
      title: "Leaderboard",
      desc: "Learn from the best traders and positions",
      btn: "Go to Leaderboard",
      nav: "/leaderboard"
    }
  ],
  supportChains: [
    {
      url: ARBITRUM_URL,
      alt: "Arbitrum",
      logo: "arbitrum"
    },
    {
      url: BNB_URL,
      alt: "Binance Smart Chain",
      logo: "bnb"
    },
    // {
    //   url: POLYGON_URL,
    //   alt: "Polygon",
    //   logo: "polygon"
    // }
  ],
  supportChainDesc: "We are available on fast and gas efficient blockchains",
  trustedPartners: "Trusted Partners",
  partners: [
    {
      url: TOKOCRYPTO_URL,
      alt: "Tokocrypto",
      logo: "tokocrypto"
    },
    {
      url: RAZER_URL,
      alt: "Razer",
      logo: "razer"
    },
    {
      url: IVCRYPTO_URL,
      alt: "Infinity Ventures Crypto",
      logo: "ivcrypto"
    },
    {
      url: RBCAP_URL,
      alt: "Red Building Capital",
      logo: "rbcap"
    },
    {
      url: SPARTAN_URL,
      alt: "Spartan Group",
      logo: "spartan"
    },
    {
      url: VERTEX_VENTURES_URL,
      alt: "Vertex Ventures",
      logo: "vertexventures"
    },
    {
      url: PERPETUAL_URL,
      alt: "Perpetual Protocol",
      logo: "perpetual"
    }
  ],
  quantstamp: "Quantstamp",
  securityDesc: "Our smart contracts are audited by Quantstamp, a world leading security company",
  readReportBtn: "Read the Audit Report ↗︎",
};

export default landing;
