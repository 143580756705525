import React from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { H2 } from "../../styles/Fonts";
import t from "../../utils/content";

const NoMatchPage: React.FC = () => {
  return (
    <Container>
      <TextContainer>
        <SubTitle>{t.common.noMatchPage}</SubTitle>
      </TextContainer>
    </Container>
  );
};

export default NoMatchPage;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 80px 0 160px 0;
  @media (max-width: 576px) {
    align-items: flex-start;
    padding: 60px 0 120px 0;
  }
`;

const TextContainer = styled.div`
  width: 90%;
  max-width: 1020px;
  margin: auto;
  @media (max-width: 576px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const SubTitle = styled(H2)`
  font-size: 32px;
  line-height: 40px;
  margin: 80px 0 40px 0;
  text-align: center;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  @media (max-width: 576px) {
    margin: 60px 0 32px 0;
    font-size: 24px;
    line-height: 32px;
  }
`;
