import React from "react";
import styled from "styled-components";
import Colors from "../styles/Colors";
import { Modal } from "react-bootstrap";
import { H4 } from "../styles/Fonts";

const CustomModal: React.FC<{
  title: string;
  children: React.ReactNode;
  show: boolean;
  onHide: () => void;
}> = (props) => {
  const { title, children, show, onHide } = props;

  return (
    <ModalContainer show={show} onHide={onHide}>
      <Header closeButton closeVariant="white">
        <HeaderTitle>{title}</HeaderTitle>
      </Header>
      <Body>{children}</Body>
    </ModalContainer>
  );
};

export default CustomModal;

const ModalContainer = styled(Modal)`
  display: none;
  .modal-content {
    background-color: ${Colors.backgroundGray1};
    width: 90%;
    max-width: 350px;
    margin: auto;
  }
`;

const Header = styled(Modal.Header)`
  border-bottom: 0.5px solid ${Colors.gray3};
  padding: 16px 24px;
`;

const HeaderTitle = styled(H4)`
  color: ${Colors.ivory};
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Body = styled(Modal.Body)`
  padding: 24px 24px 32px 24px;
`;
