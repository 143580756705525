import { useWeb3React } from "@web3-react/core";
import { track } from "@amplitude/analytics-node";

const useTrackEvent = () => {
  const { chainId, account } = useWeb3React();

  const trackEvent = (
    eventName: string,
    eventProperties?: { [key: string]: number | string }
  ) => {
    const deviceId = localStorage.getItem("deviceId");
    track(
      eventName,
      chainId
        ? { ...eventProperties, chainId: chainId, userAddress: account }
        : eventProperties,
      {
        user_id: account || undefined,
        device_id: deviceId || undefined,
      }
    );
  };

  return trackEvent;
};

export default useTrackEvent;
