import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import Button from "../../../components/Button";
import { H4 } from "../../../styles/Fonts";
import CustomModal from "../../../components/Modal";
import { showErrorAlert } from "../../../utils/showAlert";
import t from "../../../utils/content";

type Props = {
  isShowModal: boolean;
  setShowModal: (v: boolean) => void;
  slippage: number;
  setSlippage: (v: number) => void;
  defaultSlippage: number;
};

const SetSlippage: React.FC<Props> = (props) => {
  const { isShowModal, setShowModal, slippage, setSlippage, defaultSlippage } =
    props;

  const [slippageInput, setSlippageInput] = useState<string>("");
  const [isUseAuto, setIsUseAuto] = useState<boolean>(true);

  useEffect(() => {
    if (isShowModal) {
      setSlippageInput(slippage.toString());
    }
  }, [isShowModal, slippage]);

  const handleUseAuto = () => {
    setSlippageInput(defaultSlippage.toString());
    setIsUseAuto(true);
  };

  const handleOnSave = () => {
    if (+slippageInput < defaultSlippage) {
      showErrorAlert(`${t.error.slippageTooLow} ${defaultSlippage}%`);
      return;
    }

    if (0 >= +slippageInput || +slippageInput > 100) {
      showErrorAlert(t.error.slippageNotValid);
      return;
    }

    setSlippage(+slippageInput);
    setShowModal(false);
  };

  return (
    <CustomModal
      show={isShowModal}
      onHide={() => setShowModal(false)}
      title={t.factory.setting}
    >
      <Label>{t.factory.slippageTolerance}</Label>
      <InputsContainer>
        <AutoBtn onClick={handleUseAuto} isUseAuto={isUseAuto}>
          {t.factory.auto}
        </AutoBtn>
        <SlippageInputContainer>
          <SlippageInput
            type={"number"}
            value={slippageInput || undefined}
            onChange={(e) => {
              setSlippageInput(e.currentTarget.value);
              setIsUseAuto(false);
            }}
            placeholder={slippageInput}
            onWheel={(event) => event.currentTarget.blur()}
          />
          <Unit>%</Unit>
        </SlippageInputContainer>
      </InputsContainer>
      <BtnContainer>
        <Button
          btnType="primary"
          btnText={t.factory.save}
          onClick={handleOnSave}
          disabled={!slippageInput}
          isLoading={false}
        />
      </BtnContainer>
    </CustomModal>
  );
};

export default SetSlippage;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
`;

const Unit = styled.span`
  font-size: 14px;
  line-height: 28px;
  color: ${Colors.ivory};
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BtnContainer = styled.div`
  margin-top: 20px;
  button {
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
  }
`;

const InputsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 12px;
  gap: 8px;
`;

const AutoBtn = styled.button<{ isUseAuto: boolean }>`
  height: 32px;
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.isUseAuto ? Colors.lightKhaki : Colors.gray4)};
  font-size: 14px;
  font-weight: ${(props) => (props.isUseAuto ? "bold" : "normal")};
  color: ${(props) => (props.isUseAuto ? Colors.lightKhaki : Colors.ivory)};
  padding: 4px 12px;
`;

const SlippageInputContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid ${Colors.gray4};
  border-radius: 8px;
  padding: 0 12px;
`;

const SlippageInput = styled.input`
  flex-grow: 1;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: ${Colors.ivory};
  :focus {
    border: none;
    outline: none;
  }
`;
