import {
  diamondArbitrumAddresses as arbDmo,
  diamondGoerliAddresses as goerDmo,
  diamondPolygonMumbaiAddresses as polygonMumbaiDmo,
  diamondOptimismGoerliAddresses as optimismGoerliDmo,
  diamondPolygonAddresses as polygonDmo,
} from "./internalAddresses";
import { SupportedChainId } from "./chains";
import {
  busdToken,
  derc20Token,
  maticToken,
  uniToken,
  usdcToken,
  wethToken,
} from "./tokens";
import { ALL_TOKENS } from "./externalAddresses";

const ARBITRUM_VAULT = {
  label: "WETH/USDC",
  wantToken: {
    ...usdcToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].USDC,
  },
  deployedChainId: SupportedChainId.ARBITRUM_ONE,
  vaultAddress: "0x2bb5A396c799aA469dA5e6dA514d426D608FAE87",
  actionAddress: "0x65aB81b4Cb41dB1482C56751b314C117c7f2Fa74",
  vaultID: "4a0f27f8-f985-4dbe-a11c-8e83f32ce2c8"
};

const BINANCE_TESTNET_VAULT = {
  label: "BUSD",
  wantToken: {
    ...busdToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_TESTNET].BUSD,
  },
  deployedChainId: SupportedChainId.BINANCE_TESTNET,
  vaultAddress: "0xE6d4C92b36DCA198E80382F43b9FE1298B1e7D7f",
  actionAddress: "0x9337b1dA01cA117773f86A91fdFF22657c1ddA5B",
  vaultID: "5b46e1eb-d389-4357-81d4-c4002ec6217f"
};

export const ALL_EVIE_VAULT: { [key: number]: FarmProps } = {
  [SupportedChainId.ARBITRUM_ONE]: ARBITRUM_VAULT,
  [SupportedChainId.BINANCE_TESTNET]: BINANCE_TESTNET_VAULT,
};

export type FarmProps = {
  label: string;
  wantToken: {
    tokenSymbol: string;
    tokenFullName: string;
    tokenDecimal: number;
    tokenAddress: string;
    tokenIcon: string;
  };
  deployedChainId: number;
  vaultAddress: string;
  actionAddress: string;
  vaultID: string;
};

export const evieVaultHistoricalResult = [
  {
    sharePrice: "1",
    date: "2023/01/31"
  },
  {
    sharePrice: "1.007376",
    date: "2023/02/02"
  },
  {
    sharePrice: "1.041478",
    date: "2023/02/16",
  },
  {
    sharePrice: "1.048593",
    date: "2023/02/22",
  },
  {
    sharePrice: "1.061938",
    date: "2023/02/26",
  },
  {
    sharePrice: "1.071344",
    date: "2023/03/02",
  },
  {
    sharePrice: "1.096653",
    date: "2023/03/10",
  },
  {
    sharePrice: "1.106576",
    date: "2023/03/11",
  },
  {
    sharePrice: "1.113654",
    date: "2023/03/13",
  },
  {
    sharePrice: "1.127901",
    date: "2023/03/17",
  },
  {
    sharePrice: "1.151035",
    date: "2023/03/22",
  },
  {
    sharePrice: "1.154272247",
    date: "2023/03/23",
  },
  {
    sharePrice: "1.174182",
    date: "2023/03/29",
  },
  {
    sharePrice: "1.192145",
    date: "2023/04/03",
  },
  {
    sharePrice: "1.170789",
    date: "2023/04/08",
  },
  {
    sharePrice: "1.183226",
    date: "2023/04/13",
  },
  {
    sharePrice: "1.195548",
    date: "2023/04/19",
  },
  {
    sharePrice: "1.258108",
    date: "2023/05/29",
  },
  {
    sharePrice: "1.288445",
    date: "2023/06/22",
  },
  {
    sharePrice: "1.302529",
    date: "2023/06/30",
  },
  {
    sharePrice: "1.313293",
    date: "2023/07/12",
  },
  {
    sharePrice: "1.318590",
    date: "2023/07/14",
  },
  {
    sharePrice: "1.318487",
    date: "2023/07/21",
  },
  {
    sharePrice: "1.354872",
    date: "2023/11/02",
  },
  {
    sharePrice: "1.375436",
    date: "2023/11/09",
  },
  {
    sharePrice: "1.402298",
    date: "2023/11/15",
  },
  {
    sharePrice: "1.422952",
    date: "2023/11/20",
  },
  {
    sharePrice: "1.438244",
    date: "2023/11/24",
  },
  {
    sharePrice: "1.440064",
    date: "2023/12/01",
  },
  {
    sharePrice: "1.445414",
    date: "2023/12/07",
  },
  {
    sharePrice: "1.451234",
    date: "2023/12/12",
  },
  {
    sharePrice: "1.456369",
    date: "2023/12/14",
  },
  {
    sharePrice: "1.468289",
    date: "2023/12/19",
  },

]

