import { ethers, utils } from "ethers";
import farmAbi from "../../abi/farm.json";
import evieVaultAbi from "../../abi/evieVault.json";
import evieActionAbi from "../../abi/evieAction.json";
import farmActionAbi from "../../abi/farmAction.json";
import factoryV3Abi from "../../abi/factoryV3.json";
import erc20Abi from "../../abi/erc20.json";
import { showErrorAlert as showError, showPendingAlert } from "../showAlert";
import { isSupportedChain } from "../constant/chains";
import { getPositionRangesFromV3 } from "./factoryV3Api";
import getProvider from "../getProvider";
import { formatUnits } from "ethers/lib/utils";
import { convertTickToPrice, getAllLogsOfAddress } from "./common";
import t from "../content";

export const getShare = async (
  accountAddress: string,
  farmAddress: string,
  tokenDecimal: number,
  provider: any
) => {
  // const p = await getProvider(provider, chainId);
  const farm = new ethers.Contract(farmAddress, evieVaultAbi, provider);
  const shares = await farm.balanceOf(accountAddress);
  return formatUnits(shares, tokenDecimal)
}

export const getSharePriceOfFarm = async (
  farmAddress: string,
  tokenDecimal: number,
  chainId: number,
  provider: any
) => {
  let sharePrice = 1;

  try {
    const p = await getProvider(provider, chainId);
    const farm = new ethers.Contract(farmAddress, evieVaultAbi, p);
    let totalSupply = await farm.totalSupply();
    let totalAsset = await farm.totalAsset()
    if (totalSupply != 0 && totalAsset != 0) {
      sharePrice = totalAsset / totalSupply
    }
  } catch (e) {
    console.log(e)
  }

  return sharePrice;
};

export const getActionRolloverTime = async (
  actionAddress: string,
  chainId: number,
  provider: any
) => {
  let rolloverTime = 0
  try {
    const p = await getProvider(provider, chainId);
    const action = new ethers.Contract(actionAddress, evieActionAbi, p);
    rolloverTime = (await action.rolloverTime()).toNumber()
  } catch (e) {
    console.log("getActionRolloverTime", e)
  }

  return rolloverTime;
};

export const getVaultCurrentRoundStartTime = async (
  farmAddress: string,
  chainId: number,
  provider: any
) => {
  let time = 0;

  try {
    const p = await getProvider(provider, chainId);
    const farm = new ethers.Contract(farmAddress, evieVaultAbi, p);
    time = (await farm.currentRoundStartTimestamp()).toNumber()
  } catch (e) {
    console.log(e)
  }

  return time;
}

export const withdraw = async (
  accountAddress: string,
  farmAddress: string,
  amount: string,
  tokenDecimal: number,
  tokenSymbol: string,
  chainId: number,
  provider: any
) => {
  if (!isSupportedChain(chainId)) {
    showError(t.error.switchNetwork);
  } else {
    const withdrawAmount = ethers.utils.parseUnits(
      amount.toString(),
      tokenDecimal
    );

    const signer = provider.getSigner(accountAddress);
    const farm = new ethers.Contract(farmAddress, farmAbi, signer);
    const canWithdrawShares = await farm.balanceOf(
      accountAddress
    );
    if (canWithdrawShares.lt(withdrawAmount)) {
      showError(t.error.exceedWithdrawableBalance);
    } else {
      try {
        const res = await farm.connect(signer).withdraw(withdrawAmount);
        await showPendingAlert(
          res.hash,
          `${t.succeed.withdraw} ${tokenSymbol} ${t.succeed.succeed}`,
          provider
        );
      } catch (error: any) {
        if (error.message.includes("Not in whitelist")) {
          showError(t.error.notInWhitelist);
        } else if (
          error.message.includes("User denied transaction signature")
        ) {
          showError(t.error.deniedTransaction);
        } else {
          showError(t.error.withdrawFailed);
        }
      }
    }
  }
};

export const getTotalSupplyOfEvieVault = async (
  farmAddress: string,
  tokenDecimal: number,
  chainId: number,
  provider: any
) => {
  let totalSupply = 0;

  try {
    const p = await getProvider(provider, chainId);
    const farm = new ethers.Contract(farmAddress, evieVaultAbi, p);
    totalSupply = +formatUnits(await farm.totalAsset(), tokenDecimal);
  } catch (e) {
    console.log("e",e)
  }

  return totalSupply;
};