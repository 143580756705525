import { faExplosion, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { useNavigate, useParams } from "react-router-dom";
import PairTokenIcon from "../../components/PairTokenIcon";
import { ALL_PAIRS, PairProps } from "../../utils/constant/pairs";
import {
  CHAIN_URL_IDS_TO_IDS,
  isPanCakeSwap,
} from "../../utils/constant/chains";
import t from "../../utils/content";
import useTrackEvent from "../../hooks/useTrackEvent";
import { useAppSelector } from "../../hooks";

const MarketColumn: React.FC<{
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const { pairId, chainName } = useParams();
  const trackEvent = useTrackEvent();

  const [pairs, setPairs] = useState<PairProps[]>([]);

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  useEffect(() => {
    const urlChainId = chainName ? CHAIN_URL_IDS_TO_IDS[chainName] : null;
    if (!!urlChainId) {
      const pairsUnderChainId = ALL_PAIRS[urlChainId];
      setPairs(pairsUnderChainId);
    }
  }, [chainName]);

  const isPancakeSwap = isPanCakeSwap(selectedChainId);

  return (
    <Container>
      <TitleRow>
        <PairRow>
          <Icon icon={faExplosion} />
          <MarketTitle>{`${
            isPancakeSwap ? t.factory.pancakeSwap : t.factory.uniswap
          } ${t.factory.markets}`}</MarketTitle>
        </PairRow>
        <Close isOpen={isOpen} onClick={() => setIsOpen(false)}>
          <CloseIcon icon={faXmark} />
        </Close>
      </TitleRow>
      <MarketItems>
        {pairs &&
          pairs.map((pair) => (
            <NavRow
              key={pair.label + pair.rate}
              isSelected={pair.pairId === pairId}
              onClick={() => {
                trackEvent(`${t.amp.clickSwitchPairBtn}`, {
                  factoryLabel: `${pair.label} ${pair.rate}%`,
                  factoryAddress: pair.factoryAddress,
                });
                setIsOpen(false);
                navigate(`/factory/${chainName}/${pair.pairId}`);
              }}
            >
              <PairTokenIcon
                borrowTokenIcon={pair.borrowToken.tokenIcon}
                wantTokenIcon={pair.wantToken.tokenIcon}
              />
              <PairName>{`${pair.label}`}</PairName>
              <Ratio>{`${pair.rate}%`}</Ratio>
            </NavRow>
          ))}
      </MarketItems>
    </Container>
  );
};

export default MarketColumn;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 18px 24px;
  border-bottom: 0.5px solid #474646;
  flex: 0;
`;

const PairRow = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 15px;
  color: ${Colors.gray3};
  margin-right: 10px;
`;

const MarketTitle = styled.h2`
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  color: ${Colors.gray3};
`;

const Ratio = styled.h2`
  flex: 1;
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  text-align: right;
  color: ${Colors.gray3};
  text-overflow: ellipsis;
  @media (max-width: 1200px) {
    text-align: left;
  }
`;

const MarketItems = styled.div`
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
  flex: 1;
`;

const NavRow = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  background-color: ${(props) => props.isSelected && "#2a2a2a"};
  cursor: pointer;
`;

const PairName = styled.h2`
  flex: 0 0 100px;
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  color: ${Colors.gray3};
`;

const Close = styled.div<{ isOpen: boolean }>`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
`;

const CloseIcon = styled(FontAwesomeIcon)`
  width: 24px;
  height: 24px;
  color: ${Colors.ivory};
`;
