import React from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { H4 } from "../../../styles/Fonts";
import Button from "../../../components/Button";
import { ModalProps } from "./MarketItem";
import { IUserPoolSupply } from "../../../hooks/useUserPoolSupply";
import { PoolProps } from "../../../utils/constant/pools";
import { useWeb3React } from "@web3-react/core";
import t from "../../../utils/content";
import useTrackEvent from "../../../hooks/useTrackEvent";
import { claimStakeReward } from "../../../utils/api/lendingPoolApi";
import { showErrorAlert } from "../../../utils/showAlert";
import { ILendingPoolState } from "../../../hooks/useLendingPoolState";
import { getTruncNum } from "../../../utils/common";
import { AMOUNT_DECIMAL } from "../../../utils/constant/decimals";

interface Props {
  stakingIsShow: boolean;
  setShowModal: (v: ModalProps | null) => void;
  userPoolState: IUserPoolSupply;
  pool: PoolProps;
  isClaiming: boolean;
  setIsClaiming: (v: boolean) => void;
  poolState: ILendingPoolState;
  isConnected: boolean;
}

const StakingSection: React.FC<Props> = (props) => {
  const {
    stakingIsShow,
    setShowModal,
    userPoolState,
    pool,
    isClaiming,
    setIsClaiming,
    poolState,
    isConnected,
  } = props;
  const { tokenSymbol, lendingPoolAddress, stakingAddress } = pool;
  const { rewardAmount, stakedAmount } = userPoolState;
  const { lpTokenSymbol, rewardApr } = poolState;

  const { chainId, account, provider } = useWeb3React();
  const trackEvent = useTrackEvent();

  const handleClaim = async (address: string) => {
    if (!chainId) {
      return;
    }

    if (!!rewardAmount && +rewardAmount === 0) {
      showErrorAlert(t.error.noRewardToClaim);
      return;
    }

    setIsClaiming(true);

    trackEvent(`${t.amp.clickClaimRewardBtn} | ${t.amp.poolPage}`, {
      poolAddress: lendingPoolAddress,
      token: tokenSymbol,
    });

    try {
      await claimStakeReward(
        address,
        stakingAddress || "",
        provider,
        (txHash: string) => {
          trackEvent(`${t.amp.claimReward} | ${t.amp.poolPage}`, {
            txHash: txHash,
            poolAddress: lendingPoolAddress,
            token: tokenSymbol,
          });
        }
      );
      userPoolState.updateUserSupply();
    } catch (e) {
      // console.log(e);
    }

    setIsClaiming(false);
  };

  return (
    <BottomContainer isShow={stakingIsShow}>
      <StakingRow>
        <StakingAPRCol>
          <Label>Staking APR</Label>
          <CakeAprText>
            {rewardApr !== null ? `${rewardApr.toFixed(2)} %` : "-"}
          </CakeAprText>
        </StakingAPRCol>
        <StakingDetailContainer>
          <div>
            <Label>Recent CAKE2 Profit</Label>
            <ValueText>
              {isConnected && rewardAmount !== null
                ? `${getTruncNum(+rewardAmount, AMOUNT_DECIMAL)}`
                : "-"}
            </ValueText>
          </div>
          <BtnsContainer>
            <Button
              btnText="Harvest"
              btnType="primary"
              isLoading={isClaiming}
              disabled={isClaiming || !account}
              onClick={() => (account ? handleClaim(account) : {})}
            />
          </BtnsContainer>
        </StakingDetailContainer>
        <StakingDetailContainer>
          <div>
            <Label>{`${lpTokenSymbol} Staked`}</Label>
            <ValueText>
              {isConnected && stakedAmount !== null
                ? `${getTruncNum(+stakedAmount, AMOUNT_DECIMAL)}`
                : "-"}
            </ValueText>
          </div>
          <BtnsContainer>
            <Button
              btnText="Unstake"
              btnType="primary"
              isLoading={false}
              disabled={!account}
              onClick={() => setShowModal("Unstaking")}
            />
            <Button
              btnText="Stake"
              btnType="primary"
              isLoading={false}
              disabled={!account}
              onClick={() => setShowModal("Staking")}
            />
          </BtnsContainer>
        </StakingDetailContainer>
      </StakingRow>
    </BottomContainer>
  );
};

export default StakingSection;

const HeadText = styled(H4)`
  display: block;
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
    white-space: nowrap;
  }
`;

const ValueText = styled(HeadText)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${Colors.gray1};
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    text-align: right;
  }
`;

const BottomContainer = styled.div<{ isShow: boolean }>`
  max-height: ${(props) => (props.isShow ? "1000px" : "0px")};
  transition: all 200ms ease-in-out;
  overflow-y: hidden;
`;

const StakingRow = styled.div`
  display: flex;
  gap: 12px;
  padding: 24px;
  border-top: 6px solid ${Colors.backgroundGray3};

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const StakingAPRCol = styled.div`
  flex: 0 0 120px;
  padding: 12px;
  @media (max-width: 992px) {
    flex: none;
    display: flex;
    justify-content: space-between;
  }
`;

const StakingDetailContainer = styled.div`
  flex: 0 1 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.backgroundGray1};
  border-radius: 8px;
  padding: 24px;
  gap: 12px;
  @media (max-width: 992px) {
    flex: none;
    padding: 12px 16px;
  }
`;

const Label = styled(HeadText)`
  color: ${Colors.ivory};
  margin-bottom: 4px;
`;

const CakeAprText = styled(ValueText)`
  font-family: Poppins, Open Sans, Goldman-Sans, Helvetica Neue, Helvetica,
    Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  color: ${Colors.green};
  font-weight: bold;
`;

const BtnsContainer = styled.div`
  display: flex;
  gap: 12px;
  button {
    font-size: 14px;
    line-height: 18px;
  }
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;
