import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { H1, H2 } from "../../styles/Fonts";
import Markets from "./Markets";
import { SmallRegular } from "../../styles/Fonts";
import TourHint from "../../components/TourHint";
import { useWeb3React } from "@web3-react/core";
import { useNavigate, useParams } from "react-router-dom";
import { ALL_POOLS, PoolProps } from "../../utils/constant/pools";
import {
  CHAIN_IDS_TO_URL_IDS,
  CHAIN_URL_IDS_TO_IDS,
  isSupportedChain,
  SupportedChainId,
} from "../../utils/constant/chains";
import { updateSelectedChainId } from "../../redux/accountSlice";
import { useAppDispatch } from "../../hooks";
import t from "../../utils/content";
import { UNIBOT_POOL_DOC_URL } from "../../utils/constant/urls";

const PoolPage: React.FC = () => {
  const { account, chainId } = useWeb3React();
  const { chainName } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [pools, setPools] = useState<PoolProps[]>([]);

  const navigateToDefaultPools = useCallback(() => {
    const defaultChainId = SupportedChainId.ARBITRUM_ONE;
    const defaultChainName = CHAIN_IDS_TO_URL_IDS[defaultChainId];
    navigate(`/pool/${defaultChainName}`);
  }, [navigate]);

  useEffect(() => {
    const urlChainId = chainName ? CHAIN_URL_IDS_TO_IDS[chainName] : 0;

    // 有連接 wallet
    if (account) {
      if (chainId && urlChainId !== chainId && isSupportedChain(chainId)) {
        const matchedChainName = CHAIN_IDS_TO_URL_IDS[chainId];
        navigate(`/pool/${matchedChainName}`);
      } else {
        const _pools = ALL_POOLS[urlChainId];
        setPools(_pools);
      }
    } else {
      // 有指定 network
      if (!!urlChainId && isSupportedChain(urlChainId)) {
        dispatch(updateSelectedChainId({ chainId: urlChainId }));
        const _pools = ALL_POOLS[urlChainId];
        setPools(_pools);
        // 有指定 pairId
      } else if (!urlChainId || !isSupportedChain(urlChainId)) {
        navigateToDefaultPools();
      }
    }
  }, [dispatch, account, chainId, chainName, navigate, navigateToDefaultPools]);

  return (
    <Container>
      <MainContainer>
        <div>
          <Title>{t.pool.pool}</Title>
          <Description>
            {t.pool.poolDesc1}
            <a href={UNIBOT_POOL_DOC_URL} target="_blank" rel="noreferrer">
              {t.pool.poolDesc2}
            </a>
            .
          </Description>
        </div>
        {pools && (
          <div>
            <TitleRow>
              <Subtitle>{t.pool.markets}</Subtitle>
            </TitleRow>
            <Markets pools={pools} />
          </div>
        )}
      </MainContainer>
      <TourHint type="poolSteps" />
    </Container>
  );
};

export default PoolPage;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  padding: 24px 24px 60px 24px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 768px) {
    width: 100%;
    gap: 20px;
  }
`;

const Title = styled(H1)`
  font-size: 36px;
  line-height: 44px;
  text-align: left;
  padding: 32px 0 12px 0;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  @media (max-width: 576px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

const Subtitle = styled(H2)`
  font-size: 20px;
  line-height: 32px;
  text-align: left;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Description = styled(SmallRegular)`
  display: block;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: ${Colors.gray1};
  margin-top: 12px;
  a {
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    margin-top: 6px;
    font-size: 12px;
    line-height: 20px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 24px 0;
  @media (max-width: 768px) {
    margin: 24px 0;
  }
  button {
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    padding: 4px 8px;
    width: 100px;
    margin: auto;
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
