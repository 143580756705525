import styled from "styled-components";
import Colors from "../../../styles/Colors";
import React, { useEffect, useState } from "react";
import { H4 } from "../../../styles/Fonts";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import useUserAssets from "../../../hooks/useUserAssets";

ChartJS.register(ArcElement, Tooltip, Legend);

const Assets: React.FC = () => {
  const {
    lendingPoolBalance,
    factoryBalance,
    balanceVaultBalance,
    isAllFetched,
  } = useUserAssets();

  const [totalBalance, setTotalBalance] = useState<number | null>(null);

  const colors: string[] = [
    "rgba(255, 99, 132)",
    "rgba(54, 162, 235)",
    "rgba(255, 206, 86)",
  ];
  const categories: string[] = [
    "Pool Balance",
    "Factory Positions",
    "Factory Account",
  ];
  const balances: (number | null)[] = [
    lendingPoolBalance,
    factoryBalance,
    balanceVaultBalance,
  ];

  const data: {
    labels: string[];
    datasets: {
      data: (number | null)[];
      backgroundColor: string[];
      borderWidth: number;
    }[];
  } = {
    labels: categories,
    datasets: [
      {
        data: balances,
        backgroundColor: colors,
        borderWidth: 0,
      },
    ],
  };

  useEffect(() => {
    if (isAllFetched) {
      setTotalBalance(
        +(lendingPoolBalance! + factoryBalance! + balanceVaultBalance!).toFixed(
          2
        )
      );
    }
  }, [isAllFetched, lendingPoolBalance, factoryBalance, balanceVaultBalance]);

  return (
    <Container>
      <BalanceContainer>
        <Label>Total Balance</Label>
        <Value>{totalBalance !== null ? `≈ $${totalBalance}` : "-"}</Value>
      </BalanceContainer>
      {totalBalance !== null && totalBalance > 0 && (
        <RightContainer>
          <ChartContainer>
            <Pie
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </ChartContainer>
          <CharLabelContainer>
            {categories.map((category, i) => (
              <LabelRow key={category}>
                <LabelIcon backgroundColor={colors[i]} />
                <CategoryLabel>{category}</CategoryLabel>
                {totalBalance !== null && balances[i] !== null && (
                  <CategoryValue>{`≈ $${balances[i]} (${(
                    ((balances[i] || 0) / totalBalance) *
                    100
                  ).toFixed(2)}%)`}</CategoryValue>
                )}
              </LabelRow>
            ))}
          </CharLabelContainer>
        </RightContainer>
      )}
    </Container>
  );
};

export default Assets;

const Container = styled.div`
  background: ${Colors.backgroundGray1};
  padding: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 576px) {
    padding: 24px 20px;
  }
`;

const BalanceContainer = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    flex: none;
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #e0e0e0;
  }
`;

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 20px;
  @media (max-width: 1200px) {
    flex: 2;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ChartContainer = styled.div`
  position: relative;
  width: 160px;
  height: 160px;
  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }
`;

const CharLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

const LabelRow = styled.div`
  display: flex;
  gap: 12px;
`;

const LabelIcon = styled.div<{ backgroundColor: string }>`
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.backgroundColor};
  @media (max-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

const CategoryLabel = styled(H4)`
  color: ${Colors.gray4};
  font-size: 14px;
  line-height: 20px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const CategoryValue = styled(H4)`
  color: ${Colors.ivory};
  font-size: 14px;
  line-height: 20px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Label = styled(H4)`
  color: ${Colors.ivory};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const Value = styled(H4)`
  color: ${Colors.ivory};
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
