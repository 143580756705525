import React from "react";
import styled from "styled-components";
import CustomModal from "./Modal";
import { H4 } from "../styles/Fonts";
import Colors from "../styles/Colors";

const EndOdServiceModal: React.FC<{
  isShowModal: boolean;
  onHideModal: () => void;
}> = (props) => {
  const { isShowModal, onHideModal } = props;

  const closeModal = () => {
    onHideModal();
  };

  return (
    <CustomModal
      show={isShowModal}
      onHide={closeModal}
      title={"End of Service Announcement"}
    >
      <ReminderText
        style={{ display: "flex", flexDirection: "column", rowGap: "6px" }}
      >
        <span>Dear Diamond Protocol Users,</span>{" "}
        <span>
          After careful consideration by our team, we have made the decision to
          discontinue the development of this product.
        </span>
        <span style={{ color: Colors.lightKhaki }}>
          We will close the product on
          <span style={{ fontWeight: "bold" }}> May 31, 2024</span>, so please
          withdraw your assets from Diamond as soon as possible.
        </span>
        <span>
          Starting now, we will no longer provide services such as stop loss and
          liquidation, so please do not deposit or open positions.
        </span>
        <span>
          We apologize for any inconvenience this may cause and thank you for
          your participation and support during this time.
        </span>
        Sincerely,
        <br /> Diamond Protocol Team
      </ReminderText>
    </CustomModal>
  );
};

export default EndOdServiceModal;

const ReminderText = styled(H4)`
  color: ${Colors.gray3};
  font-size: 16px;
  line-height: 24px;
  font-family: Fira Sans, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  /* font-weight: 600; */
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
  }
`;
