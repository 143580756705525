import { useEffect, useState, useCallback } from "react";
import { useAppSelector } from "../hooks";
import { SupportedChainId, isSupportedChain } from "../utils/constant/chains";
import { ALL_PAIRS, PairProps } from "../utils/constant/pairs";

const defaultChainId = SupportedChainId.ARBITRUM_ONE;

const useWantTokens = () => {
  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );
  const [wantTokens, setWantTokens] = useState<PairProps["wantToken"][] | null>(null);
  const filterUniqueTokens = useCallback(
    (pairsUnderChainId: PairProps[]) => {
        // use JSON to check uniqueness and parse it back
        const wantTokenInJsonFormat = pairsUnderChainId.map(item => JSON.stringify(item.wantToken))
        return [...new Set(wantTokenInJsonFormat)].map(item => JSON.parse(item));
    },
    []
  )

  useEffect(() => {
    if (isSupportedChain(selectedChainId)) {
      const pairsUnderChainId = ALL_PAIRS[selectedChainId];
      const wantTokensUnderChainId = filterUniqueTokens(pairsUnderChainId)
      setWantTokens(wantTokensUnderChainId);
    } else {
      const pairsUnderChainId = ALL_PAIRS[defaultChainId];
      const wantTokensUnderChainId = filterUniqueTokens(pairsUnderChainId)
      setWantTokens(wantTokensUnderChainId);
    }
  }, [selectedChainId, filterUniqueTokens]);
  return wantTokens;
};

export default useWantTokens;
