import BigNumber from "bignumber.js";
import {
  PCS_V3_APP_URL,
  UNISWAP_V3_APP_URL,
  UNISWAP_V3_INFO_URL,
} from "./constant/urls";
import { CHAIN_IDS_TO_UNISWAP_IDS } from "./constant/chains";

/* eslint-disable import/prefer-default-export */
export const openNewTab = (url: string) => {
  window.open(url, "_blank");
};

export const nFormatter = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const getTruncNum = (num: number, decimal: number) => {
  return (Math.trunc(num * 10 ** decimal) / 10 ** decimal).toLocaleString(
    "en",
    { maximumFractionDigits: decimal }
  );
};

export const formatBigNumber = (num: string, displayDecimal?: number) => {
  return new BigNumber(num)
    .toFormat(displayDecimal)
    .replace(/(\.\d*?)0+$/, "$1")
    .replace(/\.$/, "");
};

export const trimAmountInput = (num: string) => {
  return num.replace(/[\s,]+/g, "");
};

export const getTimeString = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minute =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  return `${year}-${month}-${day} ${hour}:${minute}`;
};

export const slicedTxHash = (txHash: string) => {
  return `${txHash.slice(0, 6)}...${txHash.slice(-4)}`;
};

export const onCopy = (t: string) => {
  navigator.clipboard.writeText(t);
};

export const handleOpenV3PoolLink = (
  _address: string,
  deployedChainId: number,
  isPancakeSwap: boolean
) => {
  if (isPancakeSwap) {
    openNewTab(`${PCS_V3_APP_URL}info/v3/pairs/${_address}`);
  } else {
    const chainName = CHAIN_IDS_TO_UNISWAP_IDS[deployedChainId];
    openNewTab(`${UNISWAP_V3_INFO_URL}#/${chainName}/pools/${_address}`);
  }
};

export const handleOpenV3PositionPage = (
  _positionId: string,
  deployedChainId: number,
  isPancakeSwap: boolean
) => {
  if (isPancakeSwap) {
    openNewTab(
      `${PCS_V3_APP_URL}liquidity/${_positionId}?chain=${CHAIN_IDS_TO_UNISWAP_IDS[deployedChainId]}`
    );
  } else {
    openNewTab(
      `${UNISWAP_V3_APP_URL}#/pool/${_positionId}?chain=${CHAIN_IDS_TO_UNISWAP_IDS[deployedChainId]}`
    );
  }
};

export const formatDate = (originalDate: string) => {
  const dateObject = new Date(originalDate);
  const formattedDate = `${dateObject.getFullYear()}/${(
    dateObject.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${dateObject.getDate().toString().padStart(2, "0")}`;
  return formattedDate;
};
