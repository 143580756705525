import React from "react";
import styled from "styled-components";
import LabelWithHint from "../../../components/LabelWithHint";
import Colors from "../../../styles/Colors";
import { H4 } from "../../../styles/Fonts";
import { PairProps } from "../../../utils/constant/pairs";
import t from "../../../utils/content";
import {
  Leverage05,
  Leverage10,
  Leverage15,
  Leverage20,
} from "../LeverageItem";
import { InfoDiv, PanelBtn } from "./CommonParts";

type Props = {
  isLiteMode: boolean;
  pair: PairProps;
  leverage: number;
  setLeverage: (v: number) => void;
  leverageOptions: number[];
};

const Leverage: React.FC<Props> = (props) => {
  const {
    isLiteMode,
    pair: {
      wantToken: { tokenSymbol: wTokenSymbol },
      borrowToken: { tokenSymbol: bTokenSymbol },
      wantTokenIsToken0,
    },
    leverage,
    setLeverage,
    leverageOptions,
  } = props;

  return (
    <div>
      <LabelWithHint
        labelComponent={<Label>{t.factory.leverage}</Label>}
        hintText={
          <>
            <span>{`${t.factory.leverageDesc1} ${wTokenSymbol}${t.factory.leverageDesc2}`}</span>
            {isLiteMode && (
              <>
                <br />
                <span>{t.factory.leverageLiteModeHint}</span>
              </>
            )}
          </>
        }
      />
      <LeverageButtonsContainer>
        {leverageOptions.map((ratio) => (
          <PanelBtn
            key={ratio}
            isSelect={leverage === ratio}
            onClick={() => setLeverage(ratio)}
          >
            {ratio + 1 + "x"}
          </PanelBtn>
        ))}
      </LeverageButtonsContainer>
      <Output>
        {leverage === 2 && (
          <Leverage20
            borrowTokenSymbol={bTokenSymbol}
            wantTokenSymbol={wTokenSymbol}
            wantTokenIsToken0={wantTokenIsToken0}
          />
        )}
        {leverage === 1.5 && (
          <Leverage15
            borrowTokenSymbol={bTokenSymbol}
            wantTokenSymbol={wTokenSymbol}
            wantTokenIsToken0={wantTokenIsToken0}
          />
        )}
        {leverage === 1 && (
          <Leverage10
            borrowTokenSymbol={bTokenSymbol}
            wantTokenSymbol={wTokenSymbol}
            wantTokenIsToken0={wantTokenIsToken0}
          />
        )}
        {leverage === 0.5 && (
          <Leverage05
            borrowTokenSymbol={bTokenSymbol}
            wantTokenSymbol={wTokenSymbol}
            wantTokenIsToken0={wantTokenIsToken0}
          />
        )}
      </Output>
    </div>
  );
};

export default Leverage;

const Label = styled(H4)`
  color: ${Colors.gray3};
  font-size: 13px;
  line-height: 22px;
  @media (max-width: 768px) {
  }
`;

const Output = styled(InfoDiv)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 28px;
  margin-top: 12px;
  font-size: 16px;
  line-height: 28px;
`;

const LeverageButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 8px;
`;
