import {
  arbToken,
  cake2Token,
  cakeToken,
  maticToken,
  uniToken,
  usdcToken,
  usdtToken,
  wethToken,
} from "./tokens";
import {
  diamondArbitrumAddresses as arbDmo,
  diamondGoerliAddresses as goerDmo,
  diamondPolygonMumbaiAddresses as polygonMumbaiDmo,
  diamondOptimismGoerliAddresses as optimismGoerliDmo,
  diamondPolygonAddresses as polygonDmo,
  diamondBnbChainAddresses as bnbDmo,
  diamondBnbTestnetAddresses as bnbTestnetDmo,
  ALL_STAKINGS,
} from "./internalAddresses";
import { ALL_TOKENS } from "./externalAddresses";
import { SupportedChainId } from "./chains";

const ARBITRUM_POOLS = [
  {
    lendingPoolAddress: arbDmo.lendingPools.WETH,
    ...wethToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].WETH,
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
    isWrapToken: true,
  },
  {
    lendingPoolAddress: arbDmo.lendingPools.USDC,
    ...usdcToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].USDC,
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
  },
  {
    lendingPoolAddress: arbDmo.lendingPools.ARB,
    ...arbToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.ARBITRUM_ONE].ARB,
    deployedChainId: SupportedChainId.ARBITRUM_ONE,
  },
];

const GOERLI_POOLS = [
  {
    lendingPoolAddress: goerDmo.lendingPools.WETH,
    ...wethToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.GOERLI].WETH,
    deployedChainId: SupportedChainId.GOERLI,
    isWrapToken: true,
  },
  {
    lendingPoolAddress: goerDmo.lendingPools.UNI,
    ...uniToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.GOERLI].UNI,
    deployedChainId: SupportedChainId.GOERLI,
  },
];

const POLYGON_MUMBAI_POOLS = [
  {
    lendingPoolAddress: polygonMumbaiDmo.lendingPools.WMATIC,
    ...maticToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.POLYGON_MUMBAI].WMATIC,
    deployedChainId: SupportedChainId.POLYGON_MUMBAI,
  },
];

const OPTIMISM_GOERLI_POOLS = [
  {
    lendingPoolAddress: optimismGoerliDmo.lendingPools.WETH,
    ...wethToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.OPTIMISM_GOERLI].WETH,
    deployedChainId: SupportedChainId.OPTIMISM_GOERLI,
  },
];

const POLYGON_POOLS = [
  {
    lendingPoolAddress: polygonDmo.lendingPools.WETH,
    ...wethToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.POLYGON].WETH,
    deployedChainId: SupportedChainId.POLYGON,
  },
  {
    lendingPoolAddress: polygonDmo.lendingPools.WMATIC,
    ...maticToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.POLYGON].WMATIC,
    deployedChainId: SupportedChainId.POLYGON,
    isWrapToken: true,
  },
];

const BNB_POOLS = [
  {
    lendingPoolAddress: bnbDmo.lendingPools.CAKE,
    ...cakeToken,
    tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_SMART_CHAIN].CAKE,
    deployedChainId: SupportedChainId.BINANCE_SMART_CHAIN,
  },
];

const BNB_TESTNET_POOLS = [
  {
    lendingPoolAddress: bnbTestnetDmo.lendingPools.CAKE2,
    ...cake2Token,
    tokenAddress: ALL_TOKENS[SupportedChainId.BINANCE_TESTNET].CAKE2,
    deployedChainId: SupportedChainId.BINANCE_TESTNET,
    stakingAddress: ALL_STAKINGS[SupportedChainId.BINANCE_TESTNET]?.CAKE2,
  },
];

export const ALL_POOLS: { [key: number]: PoolProps[] } = {
  [SupportedChainId.ARBITRUM_ONE]: ARBITRUM_POOLS,
  [SupportedChainId.GOERLI]: GOERLI_POOLS,
  [SupportedChainId.POLYGON_MUMBAI]: POLYGON_MUMBAI_POOLS,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_POOLS,
  [SupportedChainId.POLYGON]: POLYGON_POOLS,
  [SupportedChainId.BINANCE_SMART_CHAIN]: BNB_POOLS,
  [SupportedChainId.BINANCE_TESTNET]: BNB_TESTNET_POOLS,
};

export type PoolProps = {
  lendingPoolAddress: string;
  tokenSymbol: string;
  tokenFullName: string;
  tokenDecimal: number;
  tokenAddress: string;
  deployedChainId: number;
  tokenIcon: string;
  stakingAddress?: string;
  isWrapToken?: boolean;
};
