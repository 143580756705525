import { isSupportedChain, SupportedChainId } from "./constant/chains";
import { RPC_PROVIDERS } from "./constant/providers";

const getProvider = async (provider: any, chainId: SupportedChainId) => {
  let useProvider;

  // check if provider existed
  if (provider) {
    const { chainId: connectedChainId } = await provider.getNetwork();

    // check if connected network is supported
    if (isSupportedChain(connectedChainId)) {
      useProvider = provider;
    } else {
      useProvider = isSupportedChain(chainId)
        ? RPC_PROVIDERS[chainId]
        : RPC_PROVIDERS[SupportedChainId.ARBITRUM_ONE];
    }
  } else {
    useProvider = isSupportedChain(chainId)
      ? RPC_PROVIDERS[chainId]
      : RPC_PROVIDERS[SupportedChainId.ARBITRUM_ONE];
  }

  return useProvider;
};

export default getProvider;
