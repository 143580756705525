import { useEffect, useState } from "react";
import { ALL_POOLS, PoolProps } from "../utils/constant/pools";
import { SupportedChainId, isSupportedChain } from "../utils/constant/chains";
import { useAppSelector } from "../hooks";

const defaultChainId = SupportedChainId.ARBITRUM_ONE;

const useLendingPools = () => {
  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const [pools, setPools] = useState<PoolProps[] | null>(null);

  // Set Pair Info Based On Params (or Redirect)
  useEffect(() => {
    if (isSupportedChain(selectedChainId)) {
      const pairsUnderChainId = ALL_POOLS[selectedChainId];
      setPools(pairsUnderChainId);
    } else {
      const pairsUnderChainId = ALL_POOLS[defaultChainId];
      setPools(pairsUnderChainId);
    }
  }, [selectedChainId]);

  return pools;
};

export default useLendingPools;
