import { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  getGinzaUserReferral,
  getGinzaUserReferrer,
} from "../utils/api/ginzaApi";

const useReferralCode = () => {
  const { account, chainId } = useWeb3React();

  const [referralCode, setReferralCode] = useState<string>("");
  const [referrerAddress, setReferrerAddress] = useState<string>("");
  const [referrerCode, setReferrerCode] = useState<string>("");
  const [referrees, setReferrees] = useState<string[]>([]);
  const [referreeCount, setReferreeCount] = useState<number>(0);

  const getReferral = useCallback(async () => {
    if (!account || !chainId) return;

    const {
      referralCode: _code,
      referree,
      referreeCount,
    } = await getGinzaUserReferral(account, chainId);
    setReferralCode(_code);
    setReferrees(referree);
    setReferreeCount(referreeCount);
  }, [account, chainId]);

  const getReferrer = useCallback(async () => {
    if (!account || !chainId) return;

    const { referrerAddress: _address, referrerCode: _code } =
      await getGinzaUserReferrer(account, chainId);
    setReferrerAddress(_address);
    setReferrerCode(_code);
  }, [account, chainId]);

  const updateReferralCode = useCallback(async () => {
    getReferral();
    getReferrer();
  }, [getReferral, getReferrer]);

  const initState = () => {
    setReferralCode("");
    setReferrerAddress("");
    setReferrerCode("");
    setReferrees([]);
    setReferreeCount(0);
  };

  // Set Pair Info Based On Params (or Redirect)
  useEffect(() => {
    if (account) {
      initState();
      updateReferralCode();
    }
  }, [updateReferralCode, account]);

  return {
    referralCode,
    updateReferralCode,
    referrerCode,
    referrerAddress,
    referrees,
    referreeCount,
  };
};

export default useReferralCode;
