import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { H4 } from "../../styles/Fonts";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActivePositionModal from "./ActivePositionModal";
import ClosedPositionModal from "./ClosedPositionModal";
import { ALL_PAIRS, PairProps } from "../../utils/constant/pairs";
import { useAppSelector } from "../../hooks";
import { getTruncNum } from "../../utils/common";
import { AMOUNT_DECIMAL } from "../../utils/constant/decimals";

type Props = {
  record: {
    leftValue: string;
    rightValue: number;
    url?: string;
    [key: string]: any;
  };
  unit: string;
  type?: "closed" | "open";
  rank?: number;
  decimal: number;
};

const LeaderboardItem: React.FC<Props> = (props) => {
  const { record: item, unit, type, rank, decimal } = props;

  const [pair, setPair] = useState<PairProps | null>(null);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  useEffect(() => {
    if (item?.DiamondFactoryV3?.ContractAddr) {
      const factoryAddress = item.DiamondFactoryV3.ContractAddr;
      const pair = ALL_PAIRS[selectedChainId].find(
        (pair) =>
          pair.factoryAddress.toLowerCase() === factoryAddress.toLowerCase()
      );
      if (pair) {
        setPair(pair);
      }
    }
  }, [selectedChainId, item]);

  const getDisplayValue = (value: number) => {
    if (unit !== "%") {
      return getTruncNum(+Math.trunc(+value) / 10 ** decimal, AMOUNT_DECIMAL);
    } else {
      return value;
    }
  };

  return (
    <ItemRow>
      <RightRow>
        {rank && <Rank>{rank}</Rank>}
        <Value>{item.leftValue}</Value>
      </RightRow>
      <ValueRow>
        <Value>
          {getDisplayValue(item.rightValue)} {unit}
        </Value>
        {!!type && (
          <Icon
            icon={faArrowUpRightFromSquare}
            onClick={() => setShowDetailModal(true)}
          />
        )}
      </ValueRow>
      {item.PositionId && pair && (
        <Fragment>
          {type === "open" && (
            <ActivePositionModal
              isShowModal={showDetailModal}
              setShowModal={setShowDetailModal}
              record={item}
              pair={pair}
            />
          )}
          {type === "closed" && (
            <ClosedPositionModal
              isShowModal={showDetailModal}
              setShowModal={setShowDetailModal}
              record={item}
              pair={pair}
            />
          )}
        </Fragment>
      )}
    </ItemRow>
  );
};

export default LeaderboardItem;

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.backgroundGray4};
  border-radius: 8px;
  padding: 16px 12px;
  border: 1px solid ${Colors.gray6};
  div:not(:first-child) {
    justify-content: flex-end;
    text-align: right;
  }
  h4:not(:first-child) {
    text-align: right;
  }
`;

const RightRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const ValueRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const Value = styled(H4)`
  color: ${Colors.gray1};
  font-size: 16px;
  line-height: 22px;
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const Rank = styled(Value)`
  font-weight: 600;
  width: 28px;
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${Colors.ivory};
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;
