import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChartBar, Coins, HandCoins, Icon } from "@phosphor-icons/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ReactComponent as airdrop } from "../../images/airdrop.svg";
import arbitrum from "../../images/arbitrum.png";
import bnb from "../../images/bnb.png";
import ivcrypto from "../../images/ivcrypto.png";
import pancakeswap from "../../images/pancakeSwap_full.png";
import perpetual from "../../images/perpetual.png";
import polygon from "../../images/polygon.png";
import quantstamp from "../../images/quantstamp.svg";
import razer from "../../images/razer.png";
import rbcap from "../../images/rbcap.png";
import spartan from "../../images/spartan.png";
import tokocrypto from "../../images/tokocrypto.png";
import uniswap from "../../images/uniswap_full.png";
import vertexventures from "../../images/vertexventures.png";
import { setSelectWalletModalStatus } from "../../redux/modalAction";
import Colors from "../../styles/Colors";
import { H1, H3, SmallRegular } from "../../styles/Fonts";
import { CHAIN_IDS_TO_URL_IDS } from "../../utils/constant/chains";
import { AUDIT_REPORTS_URL, QUANTSTAMP_URL } from "../../utils/constant/urls";
import t from "../../utils/content";

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const connectedWallet = localStorage.getItem("connectedWallet");

  const selectedChainId = useAppSelector(
    (state) => state.account.selectedChainId
  );

  const selectedChainName = CHAIN_IDS_TO_URL_IDS[selectedChainId];

  const logos: Record<string, string> = {
    uniswap: uniswap,
    pancakeswap: pancakeswap,
    arbitrum: arbitrum,
    bnb: bnb,
    polygon: polygon,
    tokocrypto: tokocrypto,
    razer: razer,
    ivcrypto: ivcrypto,
    rbcap: rbcap,
    spartan: spartan,
    vertexventures: vertexventures,
    perpetual: perpetual,
  };

  const icons: Record<string, Icon | React.FunctionComponent> = {
    airdrop: airdrop,
    coins: Coins,
    handCoins: HandCoins,
    chartBar: ChartBar,
  };

  const handleFeatureBtn = async (nav: string) => {
    if (nav.includes("account")) {
      if (!connectedWallet) {
        dispatch(setSelectWalletModalStatus(true));
      } else {
        navigate(nav);
      }
    } else {
      navigate(`${nav}/${selectedChainName}`);
    }
  };

  useEffect(() => {
    navigate(`/${selectedChainName}`);
  }, [navigate, selectedChainName]);

  return (
    <Container>
      <BackgroundBlur $color={Colors.gray1} $inset="15vh -10vw auto auto" />
      <BackgroundBlur
        $color={Colors.lightKhaki}
        $inset="auto auto 20vh -10vw"
      />
      <MainContainer>
        <SloganContainer>
          <Title>{t.landing.slogan}</Title>
          <SubTitle>{t.landing.subTitle}</SubTitle>
          <SloganButtonContainer>
            <Button
              btnType="primary"
              btnText={t.landing.startBtn}
              onClick={() => {
                navigate(`/pool/${selectedChainName}`);
              }}
              disabled={false}
              isLoading={false}
            />
          </SloganButtonContainer>
        </SloganContainer>
        <DexContainer>
          <DeXTitle>{t.landing.supportDexDesc}</DeXTitle>
          <DexLogosContainer>
            {t.landing.supportDex.map((dex, i) => {
              return (
                <a
                  href={dex.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  key={i}
                >
                  <DexLogo
                    src={logos[dex.logo]}
                    alt={dex.alt}
                    $height={dex.alt === "PancakeSwap" ? "30px" : "36px"}
                  />
                </a>
              );
            })}
          </DexLogosContainer>
        </DexContainer>
        {/* <FeaturesContainer>
          {t.landing.features.map((feature, i) => {
            let { icon, title, desc, btn, nav, highlight } = feature;
            const Icon = icons[icon];
            return (
              <Feature key={i} $highlight={!!highlight}>
                {highlight && <NewMark>{highlight}</NewMark>}
                <IconTitleWrapper>
                  <Icon
                    color={Colors.gray5}
                    weight="light"
                    style={{ height: 40, width: 40 }}
                  />
                  <FeatureTitle>{title}</FeatureTitle>
                </IconTitleWrapper>
                <FeatureDesc>{desc}</FeatureDesc>
                <FeatureBtnContainer>
                  <Button
                    btnType="primary"
                    btnText={btn}
                    onClick={() => handleFeatureBtn(nav)}
                    disabled={false}
                    isLoading={false}
                  />
                </FeatureBtnContainer>
              </Feature>
            );
          })}
        </FeaturesContainer> */}
        <DownIcon icon={faAnglesDown} />
        <ChainsContainer>
          <ChainLogosContainer>
            {t.landing.supportChains.map((chain, i) => {
              return (
                <a
                  href={chain.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  key={i}
                >
                  <ChainLogo
                    src={logos[chain.logo]}
                    alt={t.networks[chain.alt]}
                    $height="60px"
                  />
                </a>
              );
            })}
          </ChainLogosContainer>
          <ChainsDescTitle>{t.landing.supportChainDesc}</ChainsDescTitle>
        </ChainsContainer>
        <PartnersContainer>
          <ChainsDescTitle>{t.landing.trustedPartners}</ChainsDescTitle>
          <PartnersLogosContainer>
            {t.landing.partners.map((partner, i) => {
              return (
                <a
                  href={partner.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  key={i}
                >
                  <PartnerLogoCard>
                    <PartnerLogo
                      src={logos[partner.logo]}
                      alt={t.networks[partner.alt]}
                      $height="60px"
                    />
                  </PartnerLogoCard>
                </a>
              );
            })}
          </PartnersLogosContainer>
        </PartnersContainer>
        <AuditContainer>
          <a href={QUANTSTAMP_URL} target="_blank" rel="noreferrer noopener">
            <AuditLogo src={quantstamp} alt="Quantstamp" $height="60px" />
          </a>
          <DescBtnWrapper>
            <AuditDesc>{t.landing.securityDesc}</AuditDesc>
            <Button
              btnType="primary"
              btnText={t.landing.readReportBtn}
              onClick={() => {
                window.open(AUDIT_REPORTS_URL, "_blank");
              }}
              disabled={false}
              isLoading={false}
            />
          </DescBtnWrapper>
        </AuditContainer>
      </MainContainer>
    </Container>
  );
};

export default HomePage;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const MainContainer = styled.div`
  padding: 24px 24px 120px 24px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 768px) {
    width: 100%;
    gap: 20px;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(H1)`
  font-size: 56px;
  line-height: 1.1;
  text-align: left;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  transition: all 300ms;

  @media (max-width: 576px) {
    font-size: 48px;
    text-align: center;
  }
`;

const SubTitle = styled(SmallRegular)`
  font-size: 24px;
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  line-height: 1.3;
  transition: all 300ms;

  @media (max-width: 576px) {
    font-size: 20px;
    text-align: center;
  }
`;

const DescTitle = styled(H3)`
  color: ${Colors.gray1};
  font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;

  @media (max-width: 576px) {
    font-size: 20px;
  }
`;

const BackgroundBlur = styled.div<{ $color: string; $inset: string }>`
  width: 10vw;
  height: 10vw;
  background-color: ${(props) => props.$color};
  opacity: 0.6;
  position: absolute;
  inset: ${(props) => props.$inset};
  box-shadow: 0 0 20vw 10vw ${(props) => props.$color};
  border-radius: 50%;
`;

const Logo = styled.img<{ $height?: string }>`
  height: ${(props) => props.$height || "36px"};
  object-fit: contain;
`;

const SloganContainer = styled(SectionContainer)`
  gap: 24px;
  margin: 60px 0px;

  @media (max-width: 576px) {
    align-items: center;
  }
`;

const SloganButtonContainer = styled.div`
  width: 36px;

  @media (max-width: 576px) {
    margin-left: -36px;
  }
`;

const DexContainer = styled(SectionContainer)`
  gap: 16px;

  @media (max-width: 768px) {
    margin-bottom: 60px;
  }

  @media (max-width: 576px) {
    text-align: center;
  }
`;

const DeXTitle = styled(DescTitle)`
  @media (max-width: 576px) {
    text-align: center;
  }
`;

const DexLogosContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  @media (max-width: 576px) {
    width: 100%;
    justify-content: center;
  }
`;

const DexLogo = styled(Logo)`
  @media (max-width: 576px) {
    width: 40vw;
    height: auto;
  }
`;

const ChainLogo = styled(Logo)`
  transition: all 300ms;
  -webkit-box-reflect: below 0px
    linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  transform-origin: 50% 0;

  &:hover {
    transform: translateY(-5px) scale(1.1);
  }
`;

const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(250px, 100%), 1fr));
  gap: 16px;
  transition: all 500ms;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const NewMark = styled.div`
  color: ${Colors.lightKhaki};
  border: 1px ${Colors.lightKhaki} solid;
  font-size: 13px;
  padding: 1px;
  width: 50px;
  height: 20px;
  text-align: center;
  position: absolute;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* transform: skewX(-15deg); */

  @media (max-width: 768px) {
    right: 20px;
    left: auto;
  }
`;

const Feature = styled.div<{ $highlight?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) =>
    props.$highlight
      ? "linear-gradient(160deg,#fffff233 -3.34%,#1b1b1c33 154.29%)"
      : Colors.backgroundGray4};
  padding: 36px 32px 32px;
  height: 300px;
  justify-content: space-between;
  gap: 16px;
  border-radius: 8px;
  transition: all 500ms;
  flex-shrink: 1;
  position: relative;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    gap: 3px;
    padding: 16px 16px 24px 16px;
    align-items: start;
  }
`;

const IconTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 6px;
  }
`;

const FeatureTitle = styled(DescTitle)`
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const FeatureDesc = styled.p`
  font-size: 14px;
  color: ${Colors.gray4};
  text-align: center;
  line-height: 120%;

  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
    margin-bottom: 12px;
  }
`;

const FeatureBtnContainer = styled.div`
  margin-top: auto;
  width: 100%;

  @media (max-width: 768px) {
    width: 200px;
  }
`;

const DownIcon = styled(FontAwesomeIcon)`
  color: ${Colors.gray5};
  font-size: 20px;
  margin: 36px 0px;
`;

const ChainsContainer = styled(SectionContainer)`
  gap: 36px;
  margin-bottom: 60px;
`;

const ChainsDescTitle = styled(DescTitle)`
  text-align: center;
`;

const ChainLogosContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 36px;
`;

const PartnersContainer = styled(SectionContainer)`
  gap: 36px;
  margin: 60px 0;
`;

const PartnersLogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media (max-width: 363px) {
    gap: 8px;
  }
`;

const PartnerLogoCard = styled.div`
  background-color: ${Colors.backgroundGray4};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5% 10%;
  transition: all 300ms;
  border-radius: 8px;
  width: 250px;
  height: 180px;

  &:hover {
    background-color: #64646450;
  }

  @media (max-width: 576px) {
    width: 200px;
    height: 144px;
    padding: 10%;
  }

  @media (max-width: 463px) {
    width: 150px;
    height: 108px;
  }

  @media (max-width: 363px) {
    width: 140px;
    height: 80px;
    padding: 5%;
  }
`;

const PartnerLogo = styled(Logo)`
  transition: all 300ms;
  width: fit-content;
  height: fit-content;
  max-width: 100%;
  max-height: 100%;
`;

const AuditContainer = styled(SectionContainer)`
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #1b1b1c;
  width: 100%;
  height: 300px;
  padding: 36px;
  z-index: 1;
  border-radius: 8px;
  transition: all 500ms;
  margin-top: 36px;

  &:hover {
    transform: scale(1.01);

    @media (max-width: 768px) {
      transform: scale(1.05);
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    height: max-content;
    gap: 20px;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    justify-content: center;
    gap: 6px;
  }
`;

const AuditDesc = styled.p`
  color: ${Colors.gray1};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: ${Colors.gray4};

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 120%;
  }
  @media (max-width: 576px) {
    text-align: center;
  }
`;

const DescBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 36px;
  max-width: 500px;
  @media (max-width: 768px) {
    width: 100%;
    flex-shrink: 1;
    margin-left: 0;
  }
`;

const AuditLogo = styled(Logo)`
  @media (max-width: 768px) {
    width: 100%;
    flex-shrink: 2;
  }
  @media (max-width: 576px) {
    max-width: 250px;
    flex-shrink: 2;
  }
`;
