import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { H4 } from "../../../styles/Fonts";
import AddCollateral from "../Modals/AddCollateral";
import RemoveCollateral from "../Modals/RemoveCollateral";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowUpRightFromSquare,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { getLiquidationPrice } from "../../../utils/calculation/getLiquidationPrice";
import LabelWithHint from "../../../components/LabelWithHint";
import { getTruncNum, handleOpenV3PositionPage } from "../../../utils/common";
import { Dropdown } from "react-bootstrap";
import ShareModal from "../Modals/ShareModal";
import { BarLoader, BeatLoader } from "react-spinners";
import { PairProps } from "../../../utils/constant/pairs";
import { useWeb3React } from "@web3-react/core";
import { UserActivePositionProps } from "../../../redux/factorySlice";
import { StateProps } from "../../../hooks/useUniswapPoolState";
import UpdateStopLoss from "../Modals/UpdateStopLoss";
import {
  closeFactoryPosition,
  collectFactoryFee,
} from "../../../utils/api/factoryV3Api";
import { isPanCakeSwap } from "../../../utils/constant/chains";
import PairTokenIcon from "../../../components/PairTokenIcon";
import {
  AMOUNT_DECIMAL,
  FEE_DECIMAL,
  getPriceDecimal,
} from "../../../utils/constant/decimals";
import { useAppSelector } from "../../../hooks";
import t from "../../../utils/content";
import useTrackEvent from "../../../hooks/useTrackEvent";
import NumberWithHint from "../../../components/NumberWithHint";

const ActivePosition: React.FC<{
  position: UserActivePositionProps;
  currency: string;
  pair: PairProps;
  decimal: number;
  updateUserActivePositions: () => void;
  updateUserClosedPositions: () => void;
  updateVaultBalance: () => void;
  vaultBalance: string;
  uniPoolState: StateProps;
  slippage: number;
}> = (props) => {
  const {
    position,
    pair,
    updateUserActivePositions,
    updateUserClosedPositions,
    updateVaultBalance,
    vaultBalance,
    uniPoolState,
    slippage,
  } = props;
  const {
    positionId,
    stopLossLowerPrice,
    stopLossUpperPrice,
    wantAmount,
    reserveAmount,
    debtValue,
    upperTickPrice,
    lowerTickPrice,
    entryPrice: openPrice,
    healthFactor,
    borrowRatio,
    reserveAmountAtStart,
    positionCreateTimestamp,
    wantTokenFee,
    borrowTokenFee,
    positionValueInWantToken,
    rewardAmount,
    rewardAmountInWantToken,
  } = position;
  const {
    wantToken,
    borrowToken,
    factoryAddress,
    deployedChainId,
    wantTokenIsToken0,
  } = pair;
  const { tokenDecimal: wTokenDecimal, tokenSymbol: wTokenSymbol } = wantToken;
  const { tokenSymbol: bTokenSymbol, tokenDecimal: bTokenDecimal } =
    borrowToken;
  const { borrowTokenPrice } = uniPoolState;

  const entryTicks = useAppSelector(
    (state) => state.factory.userPositionEntryTicks
  );
  const entryPrice = !!entryTicks[positionId]
    ? 1.0001 ** entryTicks[positionId] * 10 ** (bTokenDecimal - wTokenDecimal)
    : "";

  const { account, chainId, provider } = useWeb3React();
  const trackEvent = useTrackEvent();

  const [isExecuting, setIsExecuting] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isSharePerfOpen, setIsSharePerfOpen] = useState<boolean>(false);
  const [addCollateralIsShow, setAddCollateralIsShow] =
    useState<boolean>(false);
  const [removeCollateralIsShow, setRemoveCollateralIsShow] =
    useState<boolean>(false);
  const [showUpdateStopLoss, setShowUpdateStopLoss] = useState<boolean>(false);

  const currentTimestamp = useMemo(
    () => Math.floor(new Date().getTime() / 1000),
    []
  );

  const isPancakeSwap = isPanCakeSwap(deployedChainId);

  // Action

  const handleClosePosition = async (_positionId: string) => {
    if (account && _positionId && chainId && provider) {
      trackEvent(`${t.amp.clickClosePositionBtn} | ${t.amp.factoryPage}`, {
        positionId: _positionId,
        factoryAddress: factoryAddress,
        factoryLabel: `${pair.label} ${pair.rate}%`,
        wantToken: wantToken.tokenSymbol,
        borrowToken: borrowToken.tokenSymbol,
      });

      setIsExecuting(true);

      await closeFactoryPosition(
        account,
        factoryAddress,
        _positionId,
        wantTokenIsToken0 ? uniPoolState.tick : -uniPoolState.tick,
        (slippage / 100) * 10000,
        chainId,
        provider,
        (txHash: string) => {
          trackEvent(`${t.amp.closePosition} | ${t.amp.factoryPage}`, {
            txHash: txHash,
            positionId: _positionId,
            factoryAddress: factoryAddress,
            factoryLabel: `${pair.label} ${pair.rate}%`,
            wantToken: wantToken.tokenSymbol,
            borrowToken: borrowToken.tokenSymbol,
          });
        }
      );

      setIsExecuting(false);

      updateUserActivePositions();
      updateUserClosedPositions();
      updateVaultBalance();
    }
  };

  const handleCollectFee = async (_positionId: string) => {
    if (account && _positionId && chainId && provider) {
      trackEvent(`${t.amp.clickCollectFeeBtn} | ${t.amp.factoryPage}`, {
        positionId: _positionId,
        factoryAddress: factoryAddress,
        factoryLabel: `${pair.label} ${pair.rate}%`,
        wantToken: wantToken.tokenSymbol,
        borrowToken: borrowToken.tokenSymbol,
      });

      setIsExecuting(true);

      await collectFactoryFee(
        account,
        factoryAddress,
        _positionId,
        wantTokenIsToken0 ? uniPoolState.tick : -uniPoolState.tick,
        (slippage / 100) * 10000, // 0.5% slippage
        chainId,
        provider,
        (txHash: string) => {
          trackEvent(`${t.amp.collectFee} | ${t.amp.factoryPage}`, {
            txHash: txHash,
            positionId: _positionId,
            factoryAddress: factoryAddress,
            factoryLabel: `${pair.label} ${pair.rate}%`,
            wantToken: wantToken.tokenSymbol,
            borrowToken: borrowToken.tokenSymbol,
          });
        }
      );

      setIsExecuting(false);
      updateUserActivePositions();
      updateVaultBalance();
    }
  };

  // Position Info Calculation

  const fee = useMemo(
    () =>
      borrowTokenPrice
        ? +(
            +wantTokenFee +
            +borrowTokenFee * +borrowTokenPrice +
            (!!rewardAmountInWantToken ? +rewardAmountInWantToken : 0)
          )
        : 0,
    [borrowTokenFee, borrowTokenPrice, wantTokenFee, rewardAmountInWantToken]
  );

  const feeApy = useMemo(
    () =>
      borrowTokenPrice &&
      currentTimestamp &&
      positionCreateTimestamp &&
      wantAmount
        ? (+(+wantTokenFee + +borrowTokenFee * +borrowTokenPrice) /
            +wantAmount) *
          ((60 * 60 * 24) / (+currentTimestamp - +positionCreateTimestamp)) *
          365 *
          100
        : 0,
    [
      currentTimestamp,
      positionCreateTimestamp,
      wantAmount,
      wantTokenFee,
      borrowTokenFee,
      borrowTokenPrice,
    ]
  );

  const rewardApr = useMemo(
    () =>
      currentTimestamp && positionCreateTimestamp && wantAmount
        ? ((rewardAmountInWantToken || 0) / +wantAmount) *
          ((60 * 60 * 24) / (+currentTimestamp - +positionCreateTimestamp)) *
          365 *
          100
        : 0,
    [
      currentTimestamp,
      positionCreateTimestamp,
      wantAmount,
      rewardAmountInWantToken,
    ]
  );

  const pnl = useMemo(
    () =>
      positionValueInWantToken && wantAmount && debtValue
        ? +positionValueInWantToken -
          +wantAmount -
          +debtValue +
          +reserveAmountAtStart
        : 0,
    [positionValueInWantToken, wantAmount, debtValue, reserveAmountAtStart]
  );

  const liquidationPrice = useMemo(
    () =>
      wantAmount &&
      borrowRatio &&
      debtValue &&
      (entryPrice || openPrice) &&
      lowerTickPrice &&
      upperTickPrice &&
      borrowTokenPrice
        ? getLiquidationPrice(
            +wantAmount,
            +borrowRatio,
            +debtValue,
            +entryPrice || +openPrice,
            +lowerTickPrice,
            +upperTickPrice,
            +(+wantTokenFee + +borrowTokenFee * +borrowTokenPrice),
            +borrowTokenPrice,
            +reserveAmount
          )
        : 0,
    [
      wantAmount,
      borrowRatio,
      debtValue,
      entryPrice,
      lowerTickPrice,
      upperTickPrice,
      wantTokenFee,
      borrowTokenFee,
      borrowTokenPrice,
      reserveAmount,
      openPrice,
    ]
  );

  const borrowTokenAmount = useMemo(
    () => (debtValue && borrowTokenPrice ? +debtValue / borrowTokenPrice : 0),
    [debtValue, borrowTokenPrice]
  );

  return (
    <Container key={positionId}>
      <Layer1Container>
        <Layer1Col>
          <LabelContainer>
            <LabelWithHint
              labelComponent={<HeadText>{t.factory.pair}</HeadText>}
              hintText=""
            />
          </LabelContainer>
          <PairNameRow>
            <PairTokenIcon
              borrowTokenIcon={pair.borrowToken.tokenIcon}
              wantTokenIcon={pair.wantToken.tokenIcon}
            />
            <ValueText>{pair.label}</ValueText>
          </PairNameRow>
        </Layer1Col>
        <Layer1Col>
          <LabelContainer>
            <LabelWithHint
              labelComponent={<HeadText>{t.factory.positionValue}</HeadText>}
              hintText={t.factory.positionValueDesc}
            />
          </LabelContainer>
          <ValueText>
            {`${getTruncNum(
              +positionValueInWantToken,
              AMOUNT_DECIMAL
            )} ${wTokenSymbol}`}
          </ValueText>
        </Layer1Col>
        <Layer1Col>
          <LabelContainer>
            <LabelWithHint
              labelComponent={<HeadText>{t.factory.feeApr}</HeadText>}
              hintText={t.factory.feeAprDesc}
            />
          </LabelContainer>
          <NumberWithHint
            labelComponent={
              <ValueText>{`${getTruncNum(
                feeApy + rewardApr,
                FEE_DECIMAL
              )} %`}</ValueText>
            }
            hints={
              isPancakeSwap
                ? [
                    `• LP Fee APR: ${getTruncNum(feeApy, FEE_DECIMAL)} %`,
                    `• CAKE Reward APR: ${getTruncNum(
                      rewardApr,
                      FEE_DECIMAL
                    )} %`,
                  ]
                : []
            }
          />
        </Layer1Col>
        <BtnContainer>
          <ManageDropDown>
            <ManageToggle id="dropdown-basic">
              {isExecuting ? (
                <BeatLoader color={Colors.gray6} size={10} />
              ) : (
                t.factory.manage
              )}
            </ManageToggle>
            <ManageMenu>
              <ManageItem
                onClick={() => setAddCollateralIsShow(true)}
                disabled={isExecuting}
              >
                {t.factory.addCollateral}
              </ManageItem>
              <ManageItem
                onClick={() => setRemoveCollateralIsShow(true)}
                disabled={isExecuting}
              >
                {t.factory.removeCollateral}
              </ManageItem>
              {/* Fee Collecting is not supported in PCS */}
              {!isPancakeSwap && (
                <ManageItem
                  onClick={() => handleCollectFee(positionId)}
                  disabled={isExecuting}
                >
                  {t.factory.collectFee}
                </ManageItem>
              )}
              <ManageItem
                onClick={() => setShowUpdateStopLoss(true)}
                disabled={isExecuting}
              >
                {t.factory.updateExitPrices}
              </ManageItem>
              <ManageItem
                onClick={() => handleClosePosition(positionId)}
                disabled={isExecuting}
              >
                {t.factory.closePosition}
              </ManageItem>
            </ManageMenu>
          </ManageDropDown>
          <ClickableIcon
            icon={faShareNodes}
            color={Colors.ivory}
            disabled={!feeApy}
            onClick={() =>
              !!feeApy ? setIsSharePerfOpen((pre) => !pre) : null
            }
          />
          <ClickableIcon
            icon={faAngleDown}
            color={Colors.ivory}
            disabled={false}
            onClick={() => setIsShow((pre) => !pre)}
          />
        </BtnContainer>
      </Layer1Container>
      <Layer2Container isShow={isShow}>
        <Layer2Row>
          <Layer2Col>
            <Row>
              <LabelWithHint
                labelComponent={
                  <Layer2Label>{t.factory.positionId}</Layer2Label>
                }
                hintText={t.factory.positionIdDesc}
              />
              <Icon
                icon={faArrowUpRightFromSquare}
                onClick={() => {
                  handleOpenV3PositionPage(
                    positionId,
                    deployedChainId,
                    isPancakeSwap
                  );
                }}
              />
            </Row>
            <Layer2Value>{positionId}</Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={
                <Layer2Label>{t.factory.positionAmount}</Layer2Label>
              }
              hintText={t.factory.positionAmountDesc}
            />
            <Layer2Value>
              {`${getTruncNum(+wantAmount, AMOUNT_DECIMAL)} ${wTokenSymbol}`}
            </Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.totalDebt}</Layer2Label>}
              hintText={`${t.factory.totalDebtDesc1} ${bTokenSymbol} ${t.factory.totalDebtDesc2}`}
            />
            <Layer2Value>
              {`${
                borrowTokenAmount
                  ? getTruncNum(borrowTokenAmount, AMOUNT_DECIMAL)
                  : "-"
              } ${bTokenSymbol} (≈ ${getTruncNum(
                +debtValue,
                AMOUNT_DECIMAL
              )} ${wTokenSymbol})`}
            </Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={
                <Layer2Label>{t.factory.extraCollateral}</Layer2Label>
              }
              hintText={t.factory.extraCollateralDesc}
            />
            <Layer2Value>
              {`${getTruncNum(+reserveAmount, AMOUNT_DECIMAL)} ${wTokenSymbol}`}
            </Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.pnl}</Layer2Label>}
              hintText={t.factory.pnlDesc}
            />
            <NumberWithHint
              labelComponent={
                <Layer2Value>
                  {`${getTruncNum(
                    pnl,
                    wTokenDecimal > 8 ? 12 : AMOUNT_DECIMAL
                  )} ${wTokenSymbol}`}
                </Layer2Value>
              }
              hints={
                isPancakeSwap
                  ? [
                      `• LP PnL: ${getTruncNum(
                        +pnl - (rewardAmountInWantToken ? +rewardAmountInWantToken : 0),
                        wTokenDecimal > 8 ? 12 : AMOUNT_DECIMAL
                      )} ${wTokenSymbol}`,
                      `• CAKE Reward: ${getTruncNum(
                        rewardAmount ? +rewardAmount : 0,
                        AMOUNT_DECIMAL
                      )} CAKE`,
                      `(≈ ${getTruncNum(
                        rewardAmountInWantToken ? +rewardAmountInWantToken : 0,
                        AMOUNT_DECIMAL
                      )} ${wTokenSymbol})`,
                    ]
                  : []
              }
            />
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.range}</Layer2Label>}
              hintText={t.factory.positionRangeDesc}
            />
            <Layer2Value>
              {`${getTruncNum(
                +lowerTickPrice,
                getPriceDecimal(+lowerTickPrice)
              )} - ${getTruncNum(
                +upperTickPrice,
                getPriceDecimal(+upperTickPrice)
              )} ${wTokenSymbol} `}
            </Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.exitPrices}</Layer2Label>}
              hintText={t.factory.exitPricesDesc}
            />
            <Layer2Value>
              {`${getTruncNum(
                +stopLossLowerPrice,
                getPriceDecimal(+stopLossLowerPrice)
              )} & ${getTruncNum(
                +stopLossUpperPrice,
                getPriceDecimal(+stopLossUpperPrice)
              )} ${wTokenSymbol}`}
            </Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.uniswapFee}</Layer2Label>}
              hintText={t.factory.uniswapFeeDesc}
            />
            <NumberWithHint
              labelComponent={
                <Layer2Value>
                  {`${getTruncNum(fee, AMOUNT_DECIMAL)} ${wTokenSymbol}`}
                </Layer2Value>
              }
              hints={[
                `${getTruncNum(
                  +wantTokenFee,
                  AMOUNT_DECIMAL
                )} ${wTokenSymbol} & ${getTruncNum(
                  +borrowTokenFee,
                  AMOUNT_DECIMAL
                )} ${bTokenSymbol}`,
              ]}
            />
          </Layer2Col>
          {isPancakeSwap && (
            <Layer2Col>
              <LabelWithHint
                labelComponent={<Layer2Label>CAKE Reward</Layer2Label>}
                hintText={""}
              />
              <Layer2Value>{`${getTruncNum(
                +(rewardAmount || 0),
                AMOUNT_DECIMAL
              )} CAKE (≈ ${getTruncNum(
                rewardAmountInWantToken || 0,
                AMOUNT_DECIMAL
              )} ${wTokenSymbol})`}</Layer2Value>
            </Layer2Col>
          )}
          <Layer2Col>
            <LabelWithHint
              labelComponent={
                <Layer2Label>{t.factory.positionCreatedTime}</Layer2Label>
              }
              hintText=""
            />
            <Layer2Value>
              {positionCreateTimestamp
                ? new Date(positionCreateTimestamp * 1000).toLocaleString("en")
                : "-"}
            </Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.entryPrice}</Layer2Label>}
              hintText={entryPrice ? "" : t.factory.entryPriceDesc}
            />
            <Layer2Value>
              {entryPrice ? (
                `${getTruncNum(
                  +entryPrice,
                  getPriceDecimal(+entryPrice)
                )} ${wTokenSymbol} ${t.factory.per} ${bTokenSymbol}`
              ) : (
                <BarLoaderContainer>
                  <BarLoader color={Colors.gray1} speedMultiplier={0.5} />
                </BarLoaderContainer>
              )}
            </Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={<Layer2Label>{t.factory.leverage}</Layer2Label>}
              hintText={t.factory.positionLeverageDesc}
            />
            <Layer2Value>{+borrowRatio + 1}X</Layer2Value>
          </Layer2Col>
          <Layer2Col>
            <LabelWithHint
              labelComponent={
                <Layer2Label>{t.factory.healthFactor}</Layer2Label>
              }
              hintText={t.factory.healthFactorDesc}
            />
            <MultiContainer>
              <Layer2Value>{getTruncNum(+healthFactor, 4)}</Layer2Value>
              <Layer2ValueSmall>{`Rough Est. Liquidation Price:`}</Layer2ValueSmall>
              <Layer2ValueSmall>{`${getTruncNum(
                +liquidationPrice,
                getPriceDecimal(+liquidationPrice)
              )} ${wTokenSymbol} ${
                t.factory.per
              } ${bTokenSymbol}`}</Layer2ValueSmall>
            </MultiContainer>
          </Layer2Col>
        </Layer2Row>
      </Layer2Container>

      {/* Modals */}
      <AddCollateral
        isShowModal={addCollateralIsShow}
        setShowModal={setAddCollateralIsShow}
        positionId={positionId}
        setIsExecuting={setIsExecuting}
        updateUserActivePositions={updateUserActivePositions}
        updateVaultBalance={updateVaultBalance}
        pair={pair}
        vaultBalance={vaultBalance}
      />
      <RemoveCollateral
        isShowModal={removeCollateralIsShow}
        setShowModal={setRemoveCollateralIsShow}
        positionId={positionId}
        setIsExecuting={setIsExecuting}
        reserveAmount={reserveAmount}
        updateUserActivePositions={updateUserActivePositions}
        updateVaultBalance={updateVaultBalance}
        pair={pair}
      />
      <UpdateStopLoss
        isShowModal={showUpdateStopLoss}
        setShowModal={setShowUpdateStopLoss}
        positionId={positionId}
        setIsExecuting={setIsExecuting}
        updateUserActivePositions={updateUserActivePositions}
        pair={pair}
        currentPrice={+borrowTokenPrice}
        previousStopLoss={{
          upperPrice: +stopLossUpperPrice,
          lowerPrice: +stopLossLowerPrice,
        }}
      />
      <ShareModal
        isShowModal={isSharePerfOpen}
        setShowModal={setIsSharePerfOpen}
        position={{
          leverage: +borrowRatio + 1,
          feeApr: feeApy,
          fee: fee,
          wantAmount: +wantAmount,
          startTime: +positionCreateTimestamp,
          currentTime: +currentTimestamp,
          tokenSymbol: wTokenSymbol,
          tokenDecimal: wTokenDecimal,
          pair: pair.label,
        }}
      />
    </Container>
  );
};

export default ActivePosition;

const Container = styled.div``;

const LabelContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    flex: initial;
  }
`;

const HeadText = styled(H4)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: ${Colors.gray1};
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const ValueText = styled(H4)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: ${Colors.gray1};
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
  @media (max-width: 768px) {
    flex: initial;
  }
`;

const Layer1Container = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  button {
    border-radius: 4px;
    font-size: 13px;
    line-height: 18px;
    padding: 4px 8px;
    width: auto;
    margin: auto;
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 12px 0;
  }
`;

const BtnContainer = styled.div`
  flex: 1 1 160px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  @media (max-width: 768px) {
    flex: 0 0 200px;
  }
  button {
    margin: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    flex: initial;
    margin: 12px 0 6px 0;
    gap: 6px;
  }
`;

const ClickableIcon = styled(FontAwesomeIcon)<{ disabled: boolean }>`
  padding: 4px;
  cursor: ${(props) => (props.disabled ? "inherit" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Layer2Container = styled.div<{ isShow: boolean }>`
  max-height: ${(props) => (props.isShow ? "600px" : "0px")};
  transition: all ease 300ms;
  overflow-y: hidden;
`;

const Layer2Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${Colors.backgroundGray4};
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const Layer2Col = styled.div`
  width: 50%;
  padding: 6px 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
  }
`;

const Layer2Label = styled(H4)`
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  color: ${Colors.gray3};
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    flex: 1 1 100px;
  }
`;

const Layer2Value = styled(H4)`
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  color: ${Colors.gray1};
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    text-align: right;
  }
`;

const Layer1Col = styled.div`
  flex: 1 1 160px;
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    flex: inherit;
    justify-content: space-between;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 8px;
  color: ${Colors.ivory};
  font-size: 12px;
  cursor: pointer;
`;

const ManageDropDown = styled(Dropdown)`
  button {
    padding: 0 12px;
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn-primary {
    color: inherit;
    background-color: ${Colors.lightKhaki};
    border-color: ${Colors.lightKhaki};
    :hover {
      color: inherit;
      background-color: ${Colors.lightKhaki};
      border-color: ${Colors.lightKhaki};
    }
    :focus {
      box-shadow: none;
    }
    :active {
      transform: scale(0.95);
    }
    transition: all ease 200ms;
  }
  @media (max-width: 768px) {
    button {
      padding: 0 12px;
      width: 90px;
      min-height: 28px;
    }
  }
`;

const ManageToggle = styled(Dropdown.Toggle)`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  min-height: 32px;
  ::after {
    margin-left: 8px;
    vertical-align: 1px;
  }
`;

const ManageMenu = styled(Dropdown.Menu)`
  background-color: ${Colors.gray6};
  box-shadow: 2px 2px 10px ${Colors.backgroundGray3};
`;

const ManageItem = styled(Dropdown.Item)`
  font-size: 13px;
  line-height: 22px;
  color: ${Colors.ivory};
  font-family: Open Sans, Goldman-Sans, Helvetica Neue, Helvetica, Roboto,
    PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
  :focus {
    background-color: inherit;
  }
`;

const PairNameRow = styled.div`
  display: flex;
  align-items: center;
`;

const Layer2ValueSmall = styled(Layer2Value)`
  font-size: 12px;
  line-height: 18px;
  color: ${Colors.gray4};
`;

const BarLoaderContainer = styled.div`
  height: 22px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: flex-end;
  }
`;

const MultiContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    align-items: flex-end;
  }
`;
